import { ITabParams } from '../../config/types';
import { openLookupsTab } from '../../components/MetaRenderers/Lookups/LookupsUtils';
import { openDataDictionaryTab } from '../../components/MetaRenderers/DataDictionary/DataDictionaryUtils';
import { openPagesTab } from '../../components/MetaRenderers/Pages/PagesUtils';
import { openModulesTab } from '../../components/MetaRenderers/Modules/ModulesUtils';
import { openModuleSectionsTab } from '../../components/MetaRenderers/ModuleSections/ModuleSectionsUtils';
import { openWorkflowStatesTab } from '../../components/MetaRenderers/WorkflowStates/WorkflowStatesUtils';
import { openTaskConfigsTab } from '../../components/MetaRenderers/TaskConfigs/TaskConfigsUtils';
import { builderPageTypes as BPT } from '../../config/constants/builderPageTypes';

export function openInitialEditorTab(props: ITabParams): boolean {
  let returnedBoolean = false;

  if (!props.ref) {
    console.log('dock ref is undefined');
  } else {
    switch (props.type) {
      case BPT.pages:
        openPagesTab(props);
        returnedBoolean = true;
        break;

      case BPT.lookups:
        openLookupsTab(props);
        returnedBoolean = true;
        break;

      case BPT.dataDictionaries:
        openDataDictionaryTab(props);
        returnedBoolean = true;
        break;

      case BPT.modules:
        openModulesTab(props);
        returnedBoolean = true;
        break;

      case BPT.moduleSections:
        openModuleSectionsTab(props);
        returnedBoolean = true;
        break;

      case BPT.workflowStates:
        openWorkflowStatesTab(props);
        returnedBoolean = true;
        break;

      case BPT.taskConfigs:
        openTaskConfigsTab(props);
        returnedBoolean = true;
        break;

      default:
        break;
    }
  }

  return returnedBoolean;
}
