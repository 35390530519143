import React, { useCallback, useEffect, useRef, useState } from 'react';
import BuilderGrid from '../BuilderGrid';
import { portalInfo } from '../../config/constants/portalInfo';
import { IDocumentsProps } from '../../config/types';
import { getDocumentGridColumns } from '../../config/constants/columnDefinitions';
import { Button } from 'react-bootstrap';

/** Default columns definition */
const defaultColDef = {
  sortable: true,
  unSortIcon: true,
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  autoHeight: true,
  resizable: true
};

/**
 * Documents component
 * @param {object} props for current line component
 * @return {jsx} component
 */
const Documents = ({ documents, actionMenuItems, getRef }: IDocumentsProps) => {
  const columnDefs = getDocumentGridColumns(actionMenuItems);

  const [selectedCount, setSelectedCount] = useState(0);

  const gridRef = useRef<any>(null);

  useEffect(() => {
    gridRef.current?.api.sizeColumnsToFit();
  }, [selectedCount]);

  function saveRef(ref: any) {
    gridRef.current = ref;
    getRef(ref);
  }

  const HelperButtonComponent = useCallback(
    () => (
      <div
        style={{ height: '15%' }}
        className='d-flex justify-content-start align-items-center gap-2'
      >
        <Button
          className='btn btn-sm'
          type='button'
          onClick={() => console.log('Bulk Download')}
        >
          Download {!!selectedCount && `(${selectedCount})`}
        </Button>
        <Button
          className='btn btn-sm btn-danger'
          type='button'
          onClick={() => console.log('Bulk Delete')}
        >
          Delete {!!selectedCount && `(${selectedCount})`}
        </Button>
      </div>
    ),
    [selectedCount]
  );

  const GridComponent = useCallback(
    () => (
      <div style={{ height: '85%' }}>
        <BuilderGrid
          getRef={(ref: any) => saveRef(ref)}
          id='documentGrid'
          columnDefs={columnDefs}
          data={documents}
          defaultColDef={defaultColDef}
          theme={portalInfo.agGridConfig.theme}
          pagination={portalInfo.agGridConfig.pagination}
          setAllSelectedRows={(rows: any[]) => {
            setSelectedCount(rows.length);
          }}
        />
      </div>
    ),
    [documents]
  );

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {HelperButtonComponent()}
      {GridComponent()}
    </div>
  );
};
export default Documents;
