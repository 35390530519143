import { builderPageTypes } from '../config/constants/builderPageTypes';
import DockLayout from 'rc-dock';
import {
  IDdGridUpdateData,
  ILookupGridUpdateData,
  IModuleGridUpdateData,
  IModuleSectionGridUpdateData,
  IPageGridUpdateData,
  ITaskConfigGridUpdateData,
  IWorkflowStateGridUpdateData
} from '../config/types';
import { handleUpdateDD } from './updateHandler/handleUpdateDD';
import { handleUpdateLookup } from './updateHandler/handleUpdateLookup';
import { handleUpdatePage } from './updateHandler/handleUpdatePage';
import { handleUpdateModule } from './updateHandler/handleUpdateModule';
import { handleUpdateModuleSection } from './updateHandler/handleUpdateModuleSection';
import { handleUpdateTaskConfig } from './updateHandler/handleUpdateTaskConfig';
import { handleUpdateWorkflowState } from './updateHandler/handleUpdateWorkflowState';
import {
  ValidationResults
  // WorkflowStateCollection
} from '@digitalworkflow/dwtranslateclient';

type ISupportedDataTypes =
  | ILookupGridUpdateData
  | IDdGridUpdateData
  | IPageGridUpdateData
  | IModuleGridUpdateData
  | ITaskConfigGridUpdateData
  | IModuleSectionGridUpdateData
  | IWorkflowStateGridUpdateData;

export async function updateDataEverywhere(
  ref: DockLayout | null | undefined,
  id: string,
  data: ISupportedDataTypes,
  type: builderPageTypes,
  reduxUpdater: () => void
) {
  if (!Object.keys(data).length) return false;

  let saveResults: ValidationResults = {} as ValidationResults;

  switch (type) {
    case builderPageTypes.lookups:
      if (isLookup(data)) {
        saveResults = await handleUpdateLookup(ref, id, data, reduxUpdater);
      }
      break;
    case builderPageTypes.dataDictionaries:
      if (isDD(data)) {
        saveResults = await handleUpdateDD(ref, id, data, reduxUpdater);
      }
      break;
    case builderPageTypes.pages:
      if (isPage(data)) {
        saveResults = await handleUpdatePage(ref, id, data, reduxUpdater);
      }
      break;
    case builderPageTypes.modules:
      if (isModule(data)) {
        saveResults = await handleUpdateModule(ref, id, data, reduxUpdater);
      }
      break;
    case builderPageTypes.moduleSections:
      if (isModuleSection(data)) {
        saveResults = await handleUpdateModuleSection(
          ref,
          id,
          data,
          reduxUpdater
        );
      }
      break;
    case builderPageTypes.taskConfigs:
      if (isTaskConfig(data)) {
        saveResults = await handleUpdateTaskConfig(ref, id, data, reduxUpdater);
      }
      break;
    case builderPageTypes.workflowStates:
      if (isWorkflowState(data)) {
        saveResults = await handleUpdateWorkflowState(
          ref,
          id,
          data,
          reduxUpdater
        );
      }
      break;
  }
  return saveResults;
}

function isLookup(data: ISupportedDataTypes): data is ILookupGridUpdateData {
  return (data as ILookupGridUpdateData) !== undefined;
}
function isDD(data: ISupportedDataTypes): data is IDdGridUpdateData {
  return (data as ILookupGridUpdateData) !== undefined;
}
function isPage(data: ISupportedDataTypes): data is IPageGridUpdateData {
  return (data as IPageGridUpdateData) !== undefined;
}
function isModule(data: ISupportedDataTypes): data is IModuleGridUpdateData {
  return (data as IModuleGridUpdateData) !== undefined;
}
function isModuleSection(
  data: ISupportedDataTypes
): data is IModuleSectionGridUpdateData {
  return (data as IModuleSectionGridUpdateData) !== undefined;
}
function isWorkflowState(
  data: ISupportedDataTypes
): data is IWorkflowStateGridUpdateData {
  return (data as IWorkflowStateGridUpdateData) !== undefined;
}
function isTaskConfig(
  data: ISupportedDataTypes
): data is ITaskConfigGridUpdateData {
  return (data as ITaskConfigGridUpdateData) !== undefined;
}
