/* eslint-disable camelcase */
import { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import {
  createGridMetaFromDocument,
  extractGridMetaFromTab,
  generateTab,
  insertEmptyRow,
  insertDataRow,
  lintProcessor
} from '../../../utils';
import {
  getDefaultColumns,
  getMetaGridDefaultCol,
  getPageMetaGridCols,
  getPageStepColumns
} from '../../../config/constants/columnDefinitions';
import {
  LogicEngineProcessor,
  Page,
  PageCollection,
  PageDocumentType,
  PageStepType
  // SyncableRecordSaveResults
} from '@digitalworkflow/dwtranslateclient';
import { portalInfo } from '../../../config/constants/portalInfo';
import { ITabParams, GridApi, GridMetaData } from '../../../config/types';
import { handlePageSaveProps } from '../../Forms/PageForm';
import { builderPageTypes } from '../../../config/constants/builderPageTypes';
import { updateCurrentGridDetail } from '../../CurrentGridDetail/updateCurrentGridDetail';
import { STEPTYPE } from '../../../config/constants/stepType';
import { GlobalHashHelper } from '../../../utils/HashHelper';

/**
 * @return Promise<boolean>
 * @returns true - if page step is added successfully
 * @returns false - if page step is not added successfully
 */
export async function addStep(
  key: string = '',
  gridApi?: GridApi,
  data?: Partial<PageStepType>,
  index?: number
): Promise<Page> {
  const pages = await PageCollection.getAllPagesByRoute(key);
  const page = (
    pages.length > 0 ? pages[0] : PageCollection.createPage()
  ) as Page;
  const steps: any[] | undefined = [];
  gridApi?.forEachNode((rowNode) => {
    if (rowNode.data.isEmptyOrTrace !== true)
      steps.push(JSON.parse(JSON.stringify(rowNode.data)));
  });
  if (data && index !== undefined) {
    const rowIndex =
      gridApi?.getFocusedCell()?.rowIndex ??
      gridApi?.getSelectedNodes()?.[0]?.rowIndex ??
      0;
    steps.splice(rowIndex + index + 1, 0, data);
  }

  let rownum = 0;
  page.data.steps = steps
    .map((step) => {
      if (areAllValuesEmpty(step)) {
        return;
      }
      step.rownum = ++rownum;
      return step;
    })
    .filter((step) => step !== undefined);
  await page.save();
  return page;
}

function areAllValuesEmpty(obj: handlePageSaveProps): boolean {
  const keysOfPageSaveProps: Array<keyof PageStepType> = [
    'execute_if',
    'field_id',
    'option_1',
    'option_2',
    'option_3',
    'step_option',
    'step_type',
    'style',
    'text_en',
    'view_groups'
  ];

  for (const key of keysOfPageSaveProps) {
    if (obj[key] !== '' && obj[key] !== undefined) {
      return false;
    }
  }
  return true;
}

export function openPagesTab({
  ref,
  data: pages,
  groups,
  rows,
  currentPage,
  title,
  firstTab = true,
  gridReferences,
  updateMetaData,
  fetchData,
  setSelectedRows,
  setSelectedRowIndex,
  setCurrentEventAPI,
  setRowSelectedFlag,
  setTabsRef,
  updateData
}: ITabParams) {
  if (!ref) {
    console.log('no ref provided, returning...');
    return;
  }

  const defaultColDef: ColDef = getDefaultColumns(
    portalInfo.agGridConfig.sortableColumn
  );

  let data: Partial<PageStepType>[] = [];
  let key: string = '';
  let page: PageDocumentType | undefined;

  if (firstTab) {
    if (!pages) {
      console.log('pages in redux empty, returning...');
      return;
    }

    if (!pages.length || !groups?.length) {
      console.log('pages keys || groups empty, returning...');
      return;
    }
    key = GlobalHashHelper.hasOption('tab')
      ? GlobalHashHelper.getOption('tab')
      : pages[0].data.route;
    if (!key || key === 'undefined') {
      console.log('pages first key empty, returning...');
      return;
    }

    const pageInfo = [...pages].find((page) => page.data.route === key) as Page;
    page = pageInfo?.data as PageDocumentType;

    if (page.steps && page.steps.length !== 0) {
      page.steps.forEach((step: any) => {
        data.push(step);
      });
    }

    data.push(createPageStepRowData(data.length));
  } else {
    if (!rows || !title) {
      console.log('rows||title empty, returning...');
      return;
    }
    page = currentPage;
    key = title;
    data = [...rows, createPageStepRowData(rows.length)];
  }

  const columnDefs: ColDef[] = getPageStepColumns(
    key,
    gridReferences,
    updateData
  );

  function onUpdateAPI(api: GridApi) {
    if (api.getSelectedRows() && api.getSelectedRows().length > 0) {
      // Sort the selected rows.
      const selectedNodes = api.getSelectedNodes();
      const selectedIndices = selectedNodes.map((node) => node.rowIndex);
      selectedIndices.sort((a: any, b: any) => a - b);
      const selectedRows = selectedIndices
        .filter((index) => index !== null)
        .map((index) => api.getDisplayedRowAtIndex(index as number)?.data);
      // --------------------------------
      setSelectedRows?.(selectedRows);
      setRowSelectedFlag(true);
    }
    setCurrentEventAPI(api);
  }

  // function deleteStep(
  //   pageSteps: PageStepType[],
  //   index: number = 0
  // ): PageStepType[] {
  //   if (pageSteps && pageSteps.length && pageSteps[index]) {
  //     // Step found, remove it
  //     return [...pageSteps.slice(0, index), ...pageSteps.slice(index + 1)];
  //   }
  //   return [] as PageStepType[];
  // }

  /**
   * creates a new Lookup object (Partial<LookupDocumentType> type) with empty string values
   * @returns new Lookup object
   */
  function createPageStepRowData(
    index: number,
    value?: string
  ): Partial<PageStepType> {
    const newStep: Partial<PageStepType> = {
      step_type: value ?? '',
      step_option: '',
      text_en: '',
      field_id: '',
      option_1: '',
      option_2: '',
      option_3: '', // New for Digital Workflow
      view_groups: '',
      execute_if: '',
      style: '', // New for Digital Workflow
      rownum: index + 1
    };
    return newStep;
  }

  function autoCorrectCurlyBraces(expression: string): string {
    // If the expression is empty, return it as-is
    if (!expression) {
      return expression;
    }
    // If the expression is already wrapped in double curly braces, return it as-is
    if (/^{{.*}}$/.test(expression)) {
      const logicLinter = new LogicEngineProcessor();
      const result = logicLinter.configureLogicCell(expression);

      if (result.isValid) {
        return result.formatted;
      }
      return expression;
    }
    // If trimming the expression removed all characters, return it as-is
    if (!expression.trim()) {
      return expression;
    }
    // Trim the expression to remove leading and trailing whitespace
    const trimmedExpression = expression.trim();

    // Remove incomplete double curly braces from the expression
    let cleanedExpression = trimmedExpression
      .replace(/{{/g, '') // Remove opening double curly braces
      .replace(/}}/g, ''); // Remove closing double curly braces

    // Trim again in case removing braces left leading/trailing whitespace
    cleanedExpression = cleanedExpression.trim();

    // Wrap the cleaned expression in double curly braces
    return `{{ ${cleanedExpression} }}`;
  }

  /**
   * last row edit - creates new row, add lookup in DB
   * other row edit - edits current row, edit lookup in DB
   * delete all cells data - deletes object from DB
   * @param e event triggred on cell value change
   */
  async function onCellValueChanged(e: CellValueChangedEvent): Promise<Page> {
    const executeIf = autoCorrectCurlyBraces(e.data?.execute_if);
    e.data.execute_if = executeIf;
    e.node.setData(e.data);
    autoCompletePage(e);
    // const allEmpty = areAllValuesEmpty(e.data);

    /**
     * @reason
     * Line commented as it was causing issues in ag-grid.
     * It will out focus ag grid cell after edit
     */
    // setSelectedRows([e.data]);

    // if (e.data.IsEmptyInsert) {
    //   const currentRowIndex = e.api.getFocusedCell()?.rowIndex;
    //   if (currentRowIndex === e.api.getDisplayedRowCount() - 1) {
    //     insertEmptyRow(
    //       builderPageTypes.pages,
    //       e.data.route,
    //       e.data.group,
    //       e.api
    //     );
    //   }
    //   return await addStep(key, e.api);
    // } else {
    if (e.api.getFocusedCell()?.rowIndex === e.api.getDisplayedRowCount() - 1) {
      insertEmptyRow(builderPageTypes.pages, e.data.route, e.data.group, e.api);
    }
    return await addStep(key, e.api);
    // }
  }

  // /**
  //  * @return Promise<boolean>
  //  * @returns true - if pages is deleted successfully
  //  * @returns false - if pages is not deleted successfully
  //  */
  // async function delStep(e: CellValueChangedEvent): Promise<Page> {
  //   const pages = await PageCollection.getAllPagesByRoute(key);
  //   const page = (
  //     pages.length > 0 ? pages[0] : PageCollection.createPage()
  //   ) as Page;

  //   const pageSteps = deleteStep(
  //     [...(page.data?.steps ?? [])],
  //     e?.rowIndex ?? 0
  //   );

  //   page.data.steps = [...pageSteps];
  //   await page.save();

  //   return page;
  // }

  async function localCellValueChanged(e: CellValueChangedEvent) {
    // const updatedRecord = await onCellValueChanged(e);

    await onCellValueChanged(e);
    fetchData();

    /**
     * @reason
     * Code block commented as it was causing issues in ag-grid
     */

    /*
    if (!ref) return;
    const newGridMeta: GridMetaData = createGridMetaFromDocument(updatedRecord);
    updateCurrentGridDetail({
      type: builderPageTypes.pages,
      ref,
      data: newGridMeta
    });
    */
  }

  const autoCompletePage = (e: CellValueChangedEvent) => {
    let countLimit = 0;
    let endStepType: string | null = null;
    switch (e.newValue) {
      case STEPTYPE.beginChart:
        countLimit = 3;
        endStepType = STEPTYPE.endChart;
        break;

      case STEPTYPE.beginForm:
        countLimit = 3;
        endStepType = STEPTYPE.endForm;
        break;

      case STEPTYPE.beginGroup:
        countLimit = 2;
        endStepType = STEPTYPE.endGroup;
        break;

      case STEPTYPE.beginTabs:
        countLimit = 2;
        endStepType = STEPTYPE.endTabs;
        break;

      case STEPTYPE.beginList:
        countLimit = 3;
        endStepType = STEPTYPE.endList;
        break;

      case STEPTYPE.if:
        countLimit = 1;
        endStepType = STEPTYPE.endIf;
        break;

      case STEPTYPE.beginLoop:
        countLimit = 1;
        endStepType = STEPTYPE.endLoop;
        break;

      case STEPTYPE.beginMacro:
        countLimit = 1;
        endStepType = STEPTYPE.endMacro;
        break;

      case STEPTYPE.beginSection:
        countLimit = 3;
        endStepType = STEPTYPE.endSection;
        break;

      case STEPTYPE.beginTable:
        countLimit = 3;
        endStepType = STEPTYPE.endTable;
        break;

      case STEPTYPE.beginTest:
        countLimit = 1;
        endStepType = STEPTYPE.endTest;
        break;

      default:
        return;
    }

    if (e.rowIndex !== null) {
      let currentRowIndex = e.rowIndex;
      for (let count = 0; count < countLimit; count++) {
        insertEmptyRow(
          builderPageTypes.pages,
          e.data.route,
          e.data.group,
          e.api,
          ++currentRowIndex
        );
      }

      if (e.data.rownum === undefined) {
        ++currentRowIndex;
      }

      if (endStepType) {
        insertDataRow(
          builderPageTypes.pages,
          e.api,
          ++currentRowIndex,
          createPageStepRowData(currentRowIndex, endStepType),
          e.api.getSelectedRows()
        );
      }
    }
  };

  const pageData = PageCollection.createPage();
  const _data = JSON.parse(JSON.stringify(pageData.data));
  for (const key of page ? Object.keys(page) : []) {
    _data[key] = page && page[key];
  }
  pageData.data = _data;

  const gridMetaData: GridMetaData | undefined =
    pageData && createGridMetaFromDocument(pageData);

  const updatedRows = data ? lintProcessor(data as PageStepType[]) : [];

  const pagesMetaGridCols = getPageMetaGridCols();
  const pagesMetaGridDefaultCol = getMetaGridDefaultCol();

  const tab = generateTab({
    id: key,
    title: key,
    group: builderPageTypes.pages,
    data: JSON.parse(JSON.stringify(updatedRows)),
    theme: portalInfo.agGridConfig.theme,
    pagination: portalInfo.agGridConfig.pagination,
    defaultColDef,
    columnDefs,
    gridReferences: gridReferences,
    gridMetaData,
    metaGridCols: pagesMetaGridCols,
    metaGridDefaultCol: pagesMetaGridDefaultCol,
    onMetaCellValueChanged: (e: CellValueChangedEvent) => {
      updateMetaData && updateMetaData(e.data);
    },
    onCellValueChanged: async (e: CellValueChangedEvent) =>
      localCellValueChanged(e),
    setSelectedRowIndex,
    onUpdateAPI: (api: GridApi) => {
      onUpdateAPI(api);
    },
    setTabsRef
  });

  if (firstTab) {
    ref.updateTab('Loading', tab, true);
    updateCurrentGridDetail({
      type: builderPageTypes.pages,
      ref,
      data: extractGridMetaFromTab(ref, key + '_tab')
    });
  } else {
    ref.dockMove(tab, 'grid_dock', 'middle');
  }
}
