export function getGroupInfo(type: string) {
  const lastActiveProject = JSON.parse(
    localStorage.getItem('lastActiveProject') || '{}'
  );
  const project = lastActiveProject.project_id;

  return JSON.stringify({
    project: project || undefined,
    type: type
  });
}
