import {
  IGenericAnyObject,
  ColDef,
  GridApi,
  RowDataTransaction
} from '../config/types';
import { builderPageTypes as BPT } from '../config/constants/builderPageTypes';
interface IPredefinedData {
  data: IGenericAnyObject;
}

export function insertEmptyRow(
  type?: string,
  key?: string,
  group?: string,
  currentEventApi?: GridApi,
  selectedRowIndex?: number,
  selectedRows?: any[],
  predefinedData?: IPredefinedData
) {
  if (!currentEventApi) {
    console.log('no event');
    return;
  }
  currentEventApi.forEachNode((node) => {
    node.setExpanded(false);
  });
  const colDef: ColDef[] = currentEventApi.getColumnDefs() as ColDef[];
  let data: IGenericAnyObject = {};
  let addIndex: number | undefined;

  colDef?.splice(0, 1);

  if (predefinedData?.data) {
    data = predefinedData.data;
    console.log('insert1 ', data);
  } else {
    addIndex = selectedRowIndex ?? -1;
    colDef?.forEach((col) => {
      data[col.field as string] = '';
    });

    console.log('insert2 ', data, addIndex);

    if (type === BPT.lookups) {
      data.key = selectedRows?.[0].key ?? key;

      data.doc = Array.isArray(data.doc) ? data.doc : [];
      data.doc.push({ fid: '', val: '', tid: 0 });

      data.group = selectedRows?.[0].group ?? group;
    }
    if (type === BPT.dataDictionaries) {
      data.table_name = key ?? selectedRows?.[0].table_name;

      data.validations = Array.isArray(data.validations)
        ? data.validations
        : [];
      data.validations.push({
        type: 'warning',
        enabled: '',
        condition: '',
        message: ''
      });
    }
    if (type === BPT.pages) {
      data.IsEmptyInsert = true;
    }
  }

  const transaction: RowDataTransaction = {
    add: [data],
    addIndex: addIndex || -1
  };
  currentEventApi.applyTransaction(transaction);
  currentEventApi.ensureIndexVisible(addIndex ? addIndex + 1 : -1);
}
