/* eslint-disable camelcase */
import {
  createGridMetaFromDocument,
  extractGridMetaFromTab,
  generateTab
} from '../../../utils';
import {
  getDefaultColumns,
  getMetaGridDefaultCol,
  getModuleColumns,
  getModuleMetaGridCols
} from '../../../config/constants/columnDefinitions';
import {
  Module,
  ModuleCollection,
  // ModuleCollection,
  ModuleDocumentType
} from '@digitalworkflow/dwtranslateclient';
import { portalInfo } from '../../../config/constants/portalInfo';

import {
  ITabParams,
  CellValueChangedEvent,
  ColDef,
  // ModuleDocumentType,
  GridApi,
  GridMetaData
  // IGenericAnyObject,
  // ITreeViewData,
  // DockLayout
} from '../../../config/types';
import { builderPageTypes } from '../../../config/constants/builderPageTypes';
import { updateCurrentGridDetail } from '../../CurrentGridDetail/updateCurrentGridDetail';
import { GlobalHashHelper } from '../../../utils/HashHelper';

/**
 * @return Promise<boolean>
 * @returns true - if module is added successfully
 * @returns false - if module is not added successfully
 */
export async function addModule(
  data?: Partial<ModuleDocumentType>,
  e?: CellValueChangedEvent
): Promise<any | undefined> {
  let response: any = {};
  if (e) {
    response = await ModuleCollection.addModule({
      module_name: e.data.module_name,
      parent_id: e.data.parent_id,
      default_status: 'NewRequest'
    });
    e.data.id = response.record.data.id;
  } else if (data) {
    response = await ModuleCollection.addModule({
      module_name: data.module_name,
      parent_id: data.parent_id,
      default_status: 'NewRequest'
    });
  } else {
    console.log('neither event nor form called addModule()');
  }
  return response;
}

/**
 * @return Promise<boolean>
 * @returns true - if module is updated successfully
 * @returns false - if module is not updated successfully
 */
async function updateModule(e: CellValueChangedEvent): Promise<Module | false> {
  const response = await ModuleCollection.updateModule(e.data.id, e.data);
  return response && response.saveResults.did_save && response.record;
}

export async function openModulesTab({
  ref,
  data: modules,
  groups,
  rows,
  title,
  firstTab = true,
  gridReferences,
  updateMetaData,
  fetchData,
  setSelectedRows,
  setSelectedRowIndex,
  setCurrentEventAPI,
  setRowSelectedFlag,
  setTabsRef,
  updateData
}: ITabParams) {
  if (!ref) {
    console.log('no ref provided, returning...');
    return;
  }

  const defaultColDef: ColDef = getDefaultColumns(
    portalInfo.agGridConfig.sortableColumn
  );

  const columnDefs: ColDef[] = getModuleColumns(updateData);

  let data: Partial<ModuleDocumentType>[] = [];
  let key: string = '';

  if (firstTab) {
    if (!modules) {
      console.log('modules in redux empty, returning...');
      return;
    }

    const moduleKeys = Object.keys(modules);

    if (!moduleKeys.length || !groups?.length) {
      console.log('modules keys || groups empty, returning...');
      return;
    }

    key = GlobalHashHelper.hasOption('tab')
      ? GlobalHashHelper.getOption('tab')
      : moduleKeys[0];

    if (!key || key === 'undefined') {
      console.log('modules first key empty, returning...');
      return;
    }

    data = [modules[key].data];
  } else {
    if (!rows || !title) {
      console.log('rows||title empty, returning...');
      return;
    }

    key = title;
    data = [...rows];
  }

  function onUpdateAPI(api: GridApi) {
    if (api.getSelectedRows() && api.getSelectedRows().length > 0) {
      setSelectedRows?.(api.getSelectedRows());
      setRowSelectedFlag(true);
    }
    setCurrentEventAPI(api);
  }
  /**
   * last row edit - creates new row, add module in DB
   * other row edit - edits current row, edit module in DB
   * delete all cells data - deletes object from DB
   * @param e event triggred on cell value change
   */
  async function onCellValueChanged(
    e: CellValueChangedEvent
  ): Promise<Module | false> {
    // const isDeleted = false;
    const isUpdated = await updateModule(e);
    setSelectedRows([e.data]);
    return isUpdated;
  }

  async function localCellValueChanged(e: CellValueChangedEvent) {
    const isUpdated = await onCellValueChanged(e);
    if (!isUpdated || !ref) return;

    fetchData();

    const newGridMeta: GridMetaData = createGridMetaFromDocument(isUpdated);

    updateCurrentGridDetail({
      type: builderPageTypes.modules,
      ref,
      data: newGridMeta
    });
  }

  const moduleData = await ModuleCollection.getModuleByName(
    data[0].module_name ?? ''
  );
  const gridMetaData: GridMetaData | undefined =
    moduleData && createGridMetaFromDocument(moduleData);

  const metaGridCols = getModuleMetaGridCols();
  const metaGridDefaultCol = getMetaGridDefaultCol();

  const tab = generateTab({
    id: key,
    title: key,
    group: builderPageTypes.modules,
    data: data,
    theme: portalInfo.agGridConfig.theme,
    pagination: portalInfo.agGridConfig.pagination,
    defaultColDef,
    columnDefs,
    gridReferences,
    gridMetaData,
    metaGridCols,
    metaGridDefaultCol,
    onCellValueChanged: (e: CellValueChangedEvent) => localCellValueChanged(e),
    setSelectedRowIndex,
    onUpdateAPI: (api: GridApi) => onUpdateAPI(api),
    setTabsRef,
    onMetaCellValueChanged: (e: CellValueChangedEvent) => {
      updateMetaData && updateMetaData(e.data);
    },
    dockRef: ref
  });

  console.log(groups, data, key);

  if (firstTab) {
    ref.updateTab('Loading', tab, true);

    updateCurrentGridDetail({
      type: builderPageTypes.modules,
      ref,
      data: extractGridMetaFromTab(ref, key + '_tab')
    });
  } else {
    ref.dockMove(tab, 'grid_dock', 'middle');
  }
}
