import { MenuItem } from '@digitalworkflow/dwreactcommon';

/** Menu Items definition */
export const menuItems: Array<MenuItem> = [
  {
    item_type: 'Menu',
    title_en: 'Project Settings',
    icon: 'fa-light fa-gear',
    view_groups: 'Everyone',
    children: [
      {
        item_type: 'Link',
        subtitle_en: 'Pages',
        tooltip_en: 'Page Editor',
        icon: 'fa-regular fa-file',
        route: '/editor/pages',
        view_groups: '"Everyone'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Data Dictionaries',
        tooltip_en: 'Data Dictionaries Editor',
        icon: 'fa-solid fa-table',
        route: '/editor/data-dictionaries',
        view_groups: '"Everyone'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Lookups',
        tooltip_en: 'Lookup Tables Editor',
        icon: 'fas fa-file-magnifying-glass',
        route: '/editor/lookups',
        view_groups: '"Everyone'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Modules',
        tooltip_en: 'Modules Editor',
        icon: 'fa-solid fa-cube',
        route: '/editor/modules',
        view_groups: '"Everyone'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Module Sections',
        tooltip_en: 'Module Sections Editor',
        icon: 'fa-solid fa-cubes',
        route: '/editor/module-sections',
        view_groups: '"Everyone'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Workflow States',
        tooltip_en: 'Workflow States Editor',
        icon: 'fa-solid fa-sitemap',
        route: '/editor/workflow-states',
        view_groups: '"Everyone'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Task Configs',
        tooltip_en: 'Task Configs Editor',
        icon: 'fa-solid fa-cogs',
        route: '/editor/task-configs',
        view_groups: '"Everyone'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Project Documents',
        tooltip_en: 'Project Documents',
        icon: 'fa-regular fa-file ',
        route: '/project-documents',
        view_groups: '"Everyone'
      }
    ]
  },
  {
    item_type: 'Menu',
    title_en: 'Publish',
    icon: 'fas fa-upload',
    view_groups: 'Everyone',
    children: [
      {
        item_type: 'Link',
        subtitle_en: 'Publish to Dev',
        tooltip_en: 'Publish to Development',
        icon: 'fa-regular fa-code',
        route: '/pub_dev',
        view_groups: '"Everyone'
      }
    ]
  },
  {
    item_type: 'Menu',
    title_en: 'Admin Tools',
    // icon: 'fas fa-utensils',
    view_groups: 'Everyone',
    children: []
  },
  {
    item_type: 'Menu',
    title_en: 'Permissions',
    // icon: 'fas fa-utensils',
    view_groups: 'Everyone',
    children: []
  },
  {
    item_type: 'Menu',
    title_en: 'External Connections',
    // icon: 'fas fa-utensils',
    view_groups: 'Everyone',
    children: []
  }
];
