import React from 'react';
import searchIcon from '../../assets/images/search.png';
import style from './SearchInput.module.scss';

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  searchInputStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}

const SearchInput = ({
  searchInputStyle,
  wrapperStyle,
  ...props
}: SearchInputProps) => {
  return (
    <div className={style.searchContainer} style={wrapperStyle}>
      <div className={style.searchInput} style={searchInputStyle}>
        <img src={searchIcon} height='20px' />
        <input {...props} />
      </div>
    </div>
  );
};

export default SearchInput;
