import { ReactComponent as VideoIcon } from '../../assets/images/video.svg';
import { ReactComponent as ImageIcon } from '../../assets/images/image.svg';
import { ReactComponent as WordIcon } from '../../assets/images/word.svg';
import React, { useMemo } from 'react';
import style from './IconOfFileType.module.scss';

interface IIconOfFileType {
  url: string;
}

function IconOfFileType({ url }: IIconOfFileType) {
  // const getParentStyle = (bg: string) => {
  //   return {
  //     height: 30,
  //     display: 'flex',
  //     justifyContent: 'start',
  //     backgroundColor: bg
  //   };
  // };
  const fileTypes = [
    {
      type: ['png', 'jpg', 'jpeg'],
      Icon: (
        <div className={`${style.fileType + ' ' + style.image}`}>
          <ImageIcon />
        </div>
      )
    },
    {
      type: ['mp4'],
      Icon: (
        <div className={`${style.fileType + ' ' + style.video}`}>
          <VideoIcon />
        </div>
      )
    },
    {
      type: ['doc', 'docx'],
      Icon: (
        <div className={`${style.fileType + ' ' + style.word}`}>
          <WordIcon />
        </div>
      )
    },
    {
      type: ['csv'],
      Icon: (
        <div className={`${style.fileType + ' ' + style.csv}`}>
          <i className='fa-solid fa-file-csv' />
        </div>
      )
    },
    {
      type: ['ppt', 'pptx'],
      Icon: (
        <div className={`${style.fileType + ' ' + style.ppt}`}>
          <i className='fa-solid fa-file-powerpoint' />
        </div>
      )
    },
    {
      type: ['xls', 'xlsx'],
      Icon: (
        <div className={`${style.fileType + ' ' + style.xls}`}>
          <i className='fas fa-file-excel' />
        </div>
      )
    },
    {
      type: ['pdf'],
      Icon: (
        <div className={`${style.fileType + ' ' + style.pdf}`}>
          <i className='fa-solid fa-file-pdf' />
        </div>
      )
    }
  ];

  const splitArray = url.split('.');
  const extension = splitArray[splitArray.length - 1];
  const icon = useMemo(() => {
    let tempIcon = (
      <div className={`${style.fileType + ' ' + style.default}`}>
        <i className='fa-solid fa-file-exclamation' />
      </div>
    );

    fileTypes.forEach((type) => {
      console.log('type', type);
      if (type.type.indexOf(extension) !== -1) tempIcon = type.Icon;
    });

    return tempIcon;
  }, [url]);

  return icon;
}

export default IconOfFileType;
