/** Style for Dropdown */
export const SelectDropdownStyle = {
  container: (baseStyle: any) => ({
    ...baseStyle,
    display: 'flex',
    flex: 1
  }),
  control: (baseStyle: any) => ({
    ...baseStyle,
    flex: 1,
    maxHeight: '100px',
    overflowY: 'auto'
  }),
  singleValue: (baseStyle: any) => ({
    ...baseStyle,
    whiteSpace: 'pre-wrap'
  }),
  multiValueLabel: (baseStyle: any) => ({
    ...baseStyle,
    whiteSpace: 'pre-wrap'
  }),
  input: (baseStyle: any) => ({
    ...baseStyle,
    fontSize: '14px',
    fontWeight: '500'
  })
};
