import { AppConfig } from '../config/constants/AppConfig';
import { builderPageTypes } from '../config/constants/builderPageTypes';
import { GridApi, IUpdateOpenedEditorsOfATypeProps } from '../config/types';

export function updateOpenedEditorsOfAType({
  type,
  changeEvent,
  gridReferences
}: IUpdateOpenedEditorsOfATypeProps) {
  let tabId: string;
  let currentApi: GridApi = {} as GridApi;

  switch (type) {
    case builderPageTypes.lookups:
      tabId = changeEvent.key + '_tab';
      break;
    case builderPageTypes.dataDictionaries:
      tabId = changeEvent.table_name + '_tab';
      break;
    case builderPageTypes.pages:
      tabId = changeEvent.route + '_tab';
      break;
    case builderPageTypes.modules:
      tabId = changeEvent.module_name + '_tab';
      break;
    case builderPageTypes.moduleSections:
      tabId = changeEvent.section_name + '_tab';
      break;
    case builderPageTypes.workflowStates:
      tabId = changeEvent.name + '_tab';
      break;
    case builderPageTypes.taskConfigs:
      tabId = changeEvent.name + '_tab';
      break;
  }

  gridReferences.current.forEach((refObj) => {
    if (tabId === refObj.tabId) {
      currentApi = refObj.gridApi;
    }
  });

  if (!currentApi.applyTransaction) {
    !!AppConfig.evLiveSyncLogs &&
      console.log('grid not opened for changed document, returning...');
    return;
  }

  if (type === builderPageTypes.pages) {
    let index = 0;

    currentApi.forEachNode((rowNode) => {
      if (rowNode.data?.isEmptyOrTrace) return;

      rowNode.setData(changeEvent.steps[index]);
      index++;
    });
  } else {
    !currentApi.getRowNode(changeEvent.id)
      ? insertGridRow(changeEvent, currentApi)
      : changeEvent._deleted
      ? deleteGridRow(changeEvent, currentApi)
      : updateGridRow(changeEvent, currentApi);
  }

  // recomended by ag grid, to avoid redraw over redraw
  setTimeout(function () {
    currentApi.redrawRows();
  }, 0);
}

function insertGridRow(changeEvent: any, currentApi: GridApi) {
  currentApi.applyTransaction({
    add: [changeEvent],
    addIndex: currentApi.getLastDisplayedRow()
  });
}

function updateGridRow(changeEvent: any, currentApi: GridApi) {
  currentApi.applyTransaction({ update: [changeEvent] });
}

function deleteGridRow(changeEvent: any, currentApi: GridApi) {
  currentApi.applyTransaction({ remove: [changeEvent] });
}
