// import { DataTools, StringOptions } from '@digitalworkflow/dwtranslateclient';

/** Helper class that manages the Hash as a way to set/get options that can be bookmarked */

export const GlobalHashHelper = {
  /** Returns true if the option is set to anything */
  hasOption: (strKey: string) => {
    // const existing = DataTools.internalValidateString(
    //   document.location.hash
    // ).replace('#', '');
    // const opt = new StringOptions(existing);
    // return opt.checkOption(strKey);

    /**
     * @summary commented until bug resolved
     */
    console.log(strKey);
    return false;
  },

  /** Update the option to a given value */
  setOption: (strKey: string, strValue: string | number | boolean) => {
    // const existing = DataTools.internalValidateString(
    //   document.location.hash
    // ).replace('#', '');
    // const opt = new StringOptions(existing);
    // opt.updateValue(strKey, strValue);
    // document.location.hash = opt.packToString();

    /**
     * @summary commented until bug resolved
     */
    console.log(strKey, strValue);
  },

  getOption: (strKey: string) => {
    // const existing = DataTools.internalValidateString(
    //   document.location.hash
    // ).replace('#', '');
    // const opt = new StringOptions(existing);
    // console.log(existing, opt);

    // const value = opt.checkOption(strKey, true);
    // return value;

    /**
     * @summary commented until bug resolved
     */
    console.log(strKey);
    return '';
  }
};
