import React from 'react';
import style from './ImageCell.module.scss';

interface ImageCellProps {
  src: string;
}
const ImageCell = ({ src }: ImageCellProps) => {
  return (
    <div className={style.imageContainer}>
      <img src={src} />
    </div>
  );
};

export default ImageCell;
