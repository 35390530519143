import {
  LocalDatabaseManager,
  ModuleDocumentType
} from '@digitalworkflow/dwtranslateclient';
import React, { useEffect, useState, useRef } from 'react';
import { AgGrid } from '@digitalworkflow/dwreactcommon';
import { Button, Spinner } from 'reactstrap';

function DebugModules() {
  const [rowData, setRowData] = useState<ModuleDocumentType[] | null>(null);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const gridApiRef = useRef<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const all = await LocalDatabaseManager.instance().modules.findAll({
          project_id: { $exists: true }
        });
        setRowData(all);
        console.log(all);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const defaultColDef = {
    sortable: false,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    flex: 1
  };

  const columnDefs = [
    {
      field: 'id',
      headerName: 'id',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true
    },
    { field: 'module_name', headerName: 'module_name' },
    { field: 'project_id', headerName: 'project_id' },
    { field: 'start_page', headerName: 'start_page' },
    { field: 'parent_id', headerName: 'parent_id' }
  ];

  const handleForceSync = async () => {
    try {
      setIsSyncing(true);
      const result = await LocalDatabaseManager.instance().modules.forceSync(
        {}
      );
      console.log(result);
      setIsSyncing(false);
    } catch (error) {
      console.error('Error while syncing:', error);
    }
  };

  const handleGridReady = (params: any) => {
    gridApiRef.current = params.api;
  };

  const handleDeleteSelected = async () => {
    if (gridApiRef.current) {
      const selectedRows = gridApiRef.current.getSelectedRows();
      if (selectedRows.length === 0) {
        return;
      }
      for (const row of selectedRows) {
        try {
          const record = await LocalDatabaseManager.instance().modules.findOne({
            id: row._data.id,
            project_id: { $exists: true }
          });
          if (record) {
            await record.remove();
          }
        } catch (error) {
          console.error('Error while deleting record:', error);
        }
      }
      gridApiRef.current.applyTransaction({ remove: selectedRows });
      gridApiRef.current.refreshCells();
    }
  };

  return (
    <>
      <div style={{ height: '7%' }}>
        <Button
          onClick={handleDeleteSelected}
          className='m-1'
          color='danger'
          size='sm'
          disabled={isSyncing}
        >
          Delete Selected
        </Button>
        <Button
          onClick={handleForceSync}
          className='m-1'
          color='primary'
          size='sm'
          disabled={isSyncing}
        >
          {isSyncing && (
            <Spinner
              color='light'
              size='sm'
              style={{
                marginTop: 0
              }}
            >
              Loading...
            </Spinner>
          )}
          Force Sync
        </Button>
      </div>
      <div style={{ height: '93%', width: '100%' }}>
        <AgGrid
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection='multiple'
          onGridReady={handleGridReady}
        />
      </div>
    </>
  );
}

export default DebugModules;
