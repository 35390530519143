import React, { useEffect, useRef, useState } from 'react';
import { AgGrid } from '@digitalworkflow/dwreactcommon';
import {
  CellValueChangedEvent,
  ColDef,
  FirstDataRenderedEvent,
  RowSelectedEvent,
  GridReadyEvent,
  GetRowIdParams,
  RowHeightParams
} from 'ag-grid-community';
import { GridApi, GridMetaData, IGridReferences } from '../../config/types';
import { useOutsideClick } from '../HandleOutsideClick/HandleOutsideClick';
import './BuilderGrid.css';

/** Props for Builder Grid */
interface IBuilderGrid {
  id: string;
  columnDefs: ColDef[];
  masterDetail?: boolean;
  detailCellRendererParams?: any;
  data: any[];
  defaultColDef: ColDef;
  theme: string;
  pagination: boolean;
  shouldGetRowId?: boolean;
  gridReferences?: React.MutableRefObject<IGridReferences[]>;
  checkboxSelection?: boolean;
  _gridMetaData?: GridMetaData;
  getRef?: (ref: any) => void;
  setSelectedRowIndex?: (index: number) => void;
  onCellValueChanged?: (e: CellValueChangedEvent) => void;
  onUpdateAPI?: (api: GridApi) => void;
  setTabsRef?: (value: any) => void;
  setAllSelectedRows?: (rows: any[]) => void;
}

/**
 * Builder Grid component
 * @param {object} props for builder grid component
 * @return {jsx} component
 */
const BuilderGrid = ({
  id,
  columnDefs,
  data,
  defaultColDef,
  masterDetail,
  detailCellRendererParams,
  theme,
  pagination,
  shouldGetRowId = true,
  gridReferences,
  _gridMetaData,
  getRef,
  onCellValueChanged,
  onUpdateAPI,
  setTabsRef,
  setSelectedRowIndex,
  setAllSelectedRows
}: IBuilderGrid) => {
  console.log(_gridMetaData);
  const gridRef = useRef<any>(null);
  const [gridOutApi, setGridOutApi] = useState<GridApi | null>(null);
  const gridOutRef = useRef<HTMLDivElement>(null);
  const usedIds = useRef(new Set<number>());
  const lastExpandedNodeRef = useRef<any>(null);

  useOutsideClick(gridOutApi, 'ag-center-cols-container');

  useEffect(() => {
    if (gridRef && gridRef.current) {
      setTabsRef?.({
        tabId: id,
        gridRef
      });
    }
  }, [gridRef]);

  /**
   * Gets unique id
   * @return {string} new id
   */
  const getUniqueId = () => {
    let newId;
    do {
      newId = Math.floor(Math.random() * 10000);
    } while (usedIds.current.has(newId));
    return newId;
  };

  /**
   * Gets row id
   * @return {string} row id
   */
  const getRowId = (params: GetRowIdParams) => {
    const id = params.data.id || params.data.rownum;
    if (id != null) {
      usedIds.current.add(id);
      return id;
    } else {
      const uniqueId = getUniqueId();
      usedIds.current.add(uniqueId);
      return uniqueId;
    }
  };

  /**
   * Event handler for row group open
   * @param {object} event object as prop
   */
  const onRowGroupOpened = (event: { node: any }) => {
    const currentNode = event.node;
    if (
      currentNode.expanded &&
      lastExpandedNodeRef.current &&
      lastExpandedNodeRef.current !== currentNode
    ) {
      lastExpandedNodeRef.current.setExpanded(false);
    }
    if (currentNode.expanded) {
      lastExpandedNodeRef.current = currentNode;
    } else if (lastExpandedNodeRef.current === currentNode) {
      lastExpandedNodeRef.current = null;
    }
  };

  return (
    <div
      ref={gridOutRef}
      style={{ height: '100%', width: '100%', paddingBottom: '8px' }}
    >
      <AgGrid
        gridRef={gridRef}
        enableRangeSelection
        suppressCopySingleCellRanges
        stopEditingWhenCellsLoseFocus
        rowSelection='multiple'
        columnDefs={columnDefs}
        masterDetail={masterDetail}
        detailCellRendererParams={detailCellRendererParams}
        resetRowDataOnUpdate
        rowData={data}
        defaultColDef={defaultColDef}
        theme={theme}
        pagination={pagination}
        animateRows={false}
        // singleClickEdit
        getRowId={shouldGetRowId ? getRowId : undefined}
        onFirstDataRendered={(e: FirstDataRenderedEvent) =>
          e.api.sizeColumnsToFit()
        }
        onCellValueChanged={(e: CellValueChangedEvent) =>
          onCellValueChanged?.(e)
        }
        onRowSelected={(e: RowSelectedEvent) => {
          const selectedNode = e.node as any;
          const selectedRows = e.api?.getSelectedRows();
          setAllSelectedRows?.(selectedRows);
          if (selectedNode.selected) {
            setSelectedRowIndex?.(selectedNode.rowIndex + 1);
            onUpdateAPI?.(e.api);
            setGridOutApi(e.api);
          } else {
            if (selectedRows.length === 0) {
              setSelectedRowIndex?.(-1);
            }
          }
        }}
        onGridReady={(e: GridReadyEvent) => {
          getRef?.(gridRef.current);
          onUpdateAPI?.(e.api);
          gridReferences?.current.push({ tabId: id + '_tab', gridApi: e.api });
        }}
        onRowGroupOpened={onRowGroupOpened}
        getRowStyle={(params) => {
          return {
            color: params.data.textColor,
            backgroundColor: params.data.backColor
          };
        }}
        getRowHeight={(params: RowHeightParams) =>
          params.node.data.isEmptyOrTrace
            ? 25
            : Object.keys(params.node.data)[0] === 'step_type'
            ? 30
            : null
        }
        getRowClass={(params) =>
          params.data.isEmptyOrTrace ? 'cursorNotAllowed' : 'cursorPointer'
        }
      />
    </div>
  );
};

export default BuilderGrid;
