import { useEffect } from 'react';
import { GridApi } from 'ag-grid-community';
export const useOutsideClick = (gridApi: GridApi | null, className: string) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      let target = event.target as Node;
      while (target && target !== document.body) {
        if (
          target instanceof HTMLElement &&
          (target.classList.contains('ignore-outside-click') ||
            target.id.includes('current_line_tab') ||
            target.id.includes('fields_tab'))
        ) {
          return;
        }

        const parent = target.parentNode;
        if (parent) {
          target = parent;
        } else {
          break;
        }
      }

      const elements = document.getElementsByClassName(className);
      let isOutSide: boolean = true;
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        if (element && element.contains(event.target as Node)) {
          isOutSide = false;
        }
      }
      if (isOutSide) {
        if (gridApi?.getFocusedCell()) {
          gridApi.clearFocusedCell();
          gridApi.clearRangeSelection();
          gridApi.deselectAll();
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [gridApi, className]);
};
