import React from 'react';
import cls from './InfoForm.module.scss';

interface InfoFormProps {
  label: string;
  value: string;
  dateStr: string;
}

const InfoForm = ({ value, dateStr, label }: InfoFormProps) => {
  return (
    <div className={cls.wrapper}>
      <span className={cls.label}>{label}</span>
      <div className={cls.infoWrapper}>
        <input className={cls.inputInfo} value={value} disabled />
        <input className={cls.inputDate} value={dateStr} disabled />
      </div>
    </div>
  );
};
export default InfoForm;
