import { LocalDatabaseManager } from '@digitalworkflow/dwtranslateclient';
import React, { useEffect, useState, useRef } from 'react';
import { AgGrid } from '@digitalworkflow/dwreactcommon';
import {
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

interface ModalData {
  title: string;
  bodyText: string;
}
function DebugPages() {
  const [rowData, setRowData] = useState<any[] | null>(null);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState<ModalData | null>(null);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const gridApiRef = useRef<any>();
  const toggle = () => setModal(!modal);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const all = await LocalDatabaseManager.instance().pages.findAll({
          project_id: { $exists: true }
        });
        const updateData = all.map((page) => {
          const totalSteps = page.steps?.length || '';
          const totalPubSteps = page.pub_steps?.length || '';
          return {
            ...page._data,
            totalSteps,
            totalPubSteps
          };
        });
        setRowData(updateData);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const defaultColDef = {
    sortable: false,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    flex: 1
  };

  const columnDefs = [
    {
      field: 'id',
      headerName: 'id',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true
    },
    { field: 'route', headerName: 'route' },
    { field: 'title_en', headerName: 'title_en' },
    { field: 'project_id', headerName: 'project_id' },
    { field: 'totalSteps', headerName: 'steps' },
    { field: 'totalPubSteps', headerName: 'pub_steps' }
  ];

  const handleForceSync = async () => {
    try {
      setIsSyncing(true);
      const result = await LocalDatabaseManager.instance().pages.forceSync({});
      console.log(result);
      setIsSyncing(false);
    } catch (error) {
      console.error('Error while syncing:', error);
    }
  };

  const handleGridReady = (params: any) => {
    gridApiRef.current = params.api;
  };

  const handleDeleteSelected = async () => {
    if (gridApiRef.current) {
      const selectedRows = gridApiRef.current.getSelectedRows();
      if (selectedRows.length === 0) {
        return;
      }
      for (const row of selectedRows) {
        try {
          const record = await LocalDatabaseManager.instance().pages.findOne({
            id: row.id,
            project_id: { $exists: true }
          });
          if (record) {
            await record.remove();
          }
        } catch (error) {
          console.error('Error while deleting record:', error);
        }
      }
      gridApiRef.current.applyTransaction({ remove: selectedRows });
      gridApiRef.current.refreshCells();
    }
  };

  const handleCellClicked = (e: any) => {
    if (e.column.colId === 'totalSteps' && e.value !== '') {
      const bodyText = e.data.steps.map((step: any) => {
        return JSON.stringify(step) + '\n';
      });
      setModalData({ title: 'Steps', bodyText: bodyText });
      toggle();
    }
    if (e.column.colId === 'totalPubSteps' && e.value !== '') {
      const bodyText = e.data.pub_steps.map((pubStep: any) => {
        return JSON.stringify(pubStep) + '\n';
      });
      setModalData({
        title: 'Pub_steps',
        bodyText: bodyText
      });

      toggle();
    }
  };

  return (
    <>
      <div style={{ height: '7%' }}>
        <Button
          onClick={handleDeleteSelected}
          className='m-1'
          color='danger'
          size='sm'
          disabled={isSyncing}
        >
          Delete Selected
        </Button>
        <Button
          onClick={handleForceSync}
          className='m-1'
          color='primary'
          size='sm'
          disabled={isSyncing}
        >
          {isSyncing && (
            <Spinner
              color='light'
              size='sm'
              style={{
                marginTop: 0
              }}
            >
              Loading...
            </Spinner>
          )}
          Force Sync
        </Button>
      </div>
      <div style={{ height: '93%', width: '100%' }}>
        <AgGrid
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection='multiple'
          onCellClicked={handleCellClicked}
          onGridReady={handleGridReady}
        />
      </div>
      <Modal isOpen={modal} toggle={toggle} size='xl'>
        <ModalHeader toggle={toggle}>{modalData?.title}</ModalHeader>
        <ModalBody>
          <div
            style={{
              width: '100%',
              maxHeight: '500px',
              overflowY: 'scroll',
              whiteSpace: 'pre-wrap'
            }}
          >
            {modalData?.bodyText}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggle} size='sm'>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default DebugPages;
