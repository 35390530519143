import React, { useEffect, useRef, useState } from 'react';
import { RcGroup } from '../../components/RcGroup';
import {
  DockLayout,
  RCDockLayout,
  TabData
} from '@digitalworkflow/dwreactcommon';
import Documents from '../../components/Documents';
import UploadDocument from '../../components/Documents/uploadDocument';
import {
  LocalDatabaseManager,
  ProjectDocument,
  ProjectDocumentCollection,
  ProjectDocumentDocumentType,
  SyncableRecordSaveResults
} from '@digitalworkflow/dwtranslateclient';
import AddModal from '../../components/AddModal';
import { DocumentModalTypes } from '../../config/types';
import { GridApi, IRowNode } from 'ag-grid-community';
import { getDocumentActionMenuItems } from '../../config/constants/documentActionMenuItems';
import RenameModal from '../../components/Documents/RenameModal';
import DeleteModal from '../../components/Documents/DeleteModal';
import axios from 'axios';
import ReplaceModal from '../../components/Documents/ReplaceModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const ProjectDocuments = () => {
  const dockLayoutRef = useRef<DockLayout | null>(null);
  const currentDialog = useRef<() => React.JSX.Element>(() => (
    <h3>Coming Soon</h3>
  ));
  const gridRef = useRef<any>(null);

  const [documentsList, setDocumentsList] = useState<
    Partial<ProjectDocumentDocumentType>[]
  >([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const docs = useSelector(
    (state: RootState) => state.projectDocument.projectDocuments
  );
  const actionMenuItems = getDocumentActionMenuItems(handleAction);

  const getContainerHeight = (value: number) => {
    if (dockLayoutRef && dockLayoutRef.current) {
      const dockInstance = dockLayoutRef.current;

      const _tab = dockInstance.find('upload_file');
      if (_tab && _tab.parent) {
        _tab.parent.size = value;
      }
      const _documentTab = dockInstance.find('documents');
      if (_documentTab && _documentTab.parent) {
        _documentTab.parent.size = window.innerHeight / 2.5 - (value - 40);
      }
      dockInstance.loadLayout(dockInstance.saveLayout());
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [docs]);

  useEffect(() => {
    updateDocumentTab();
  }, [documentsList]);

  async function fetchDocuments() {
    console.log('here map');
    // const docs = await ProjectDocumentCollection.findAll();
    setDocumentsList(docs.map((doc: { data: any }) => doc.data));
  }

  function updateDocumentTab() {
    dockLayoutRef.current?.find('documents');
    const newTab: TabData = {
      id: 'documents',
      title: 'Documents',
      content: (
        <Documents
          getRef={(ref: any) => (gridRef.current = ref)}
          documents={documentsList}
          actionMenuItems={actionMenuItems}
        />
      )
    };
    dockLayoutRef.current?.updateTab('documents', newTab);
  }

  function updateGrid(
    res:
      | false
      | {
          saveResults: SyncableRecordSaveResults;
          record?: ProjectDocument;
        },
    type: DocumentModalTypes | 'UPLOAD',
    id?: string
  ) {
    if (!res || !res.saveResults.did_save) return;
    switch (type) {
      case 'UPLOAD':
        res.record &&
          (gridRef.current.api as GridApi).applyTransaction({
            add: [res.record.data]
          });
        break;
      case DocumentModalTypes.DELETE:
        id &&
          (gridRef.current.api as GridApi).applyTransaction({
            remove: [{ id }]
          });
        break;
      case DocumentModalTypes.REPLACE:
        console.log('here in update grid replace');
        res.record &&
          (gridRef.current.api as GridApi).applyTransaction({
            update: [res.record.data]
          });
        break;
      case DocumentModalTypes.RENAME:
        res.record &&
          (gridRef.current.api as GridApi).applyTransaction({
            update: [res.record.data]
          });
        break;
    }
  }

  async function handleUpload(file: File) {
    const res = await ProjectDocumentCollection.addProjectDocument(file);
    updateGrid(res, 'UPLOAD');
  }

  function toggleShowModal(show?: boolean) {
    setShowModal(show !== undefined ? show : !showModal);
  }

  function handleAction(actionType: DocumentModalTypes, node: IRowNode) {
    switch (actionType) {
      case DocumentModalTypes.COPY:
        handleCopy(node);
        break;
      case DocumentModalTypes.DELETE:
        handleDelete(node);
        break;
      case DocumentModalTypes.DOWNLOAD:
        handleDownload(node);
        break;
      case DocumentModalTypes.HISTORY:
        handleHistory(node);
        break;
      case DocumentModalTypes.PREVIEW:
        handlePreview(node);
        break;
      case DocumentModalTypes.RENAME:
        handleRename(node);
        break;
      case DocumentModalTypes.REPLACE:
        handleReplace(node);
        break;
    }
  }

  function cancelAction() {
    currentDialog.current = () => <h1>Coming Soon</h1>;
    toggleShowModal(false);
  }

  function handleCopy(node: IRowNode) {
    console.log('copy', node);
  }

  function handleHistory(node: IRowNode) {
    console.log('history', node);
  }

  function handlePreview(node: IRowNode) {
    console.log('preview', node);
  }

  function handleRename(node: IRowNode) {
    toggleShowModal(true);
    currentDialog.current = () => (
      <RenameModal
        documentCurrentName={node.data.name}
        onSave={(newName) => renameDocument(newName)}
        onCancel={() => cancelAction()}
      />
    );

    async function renameDocument(newName: string) {
      const res = await ProjectDocumentCollection.updateProjectDocument(
        node.data.id,
        {
          name: newName
        }
      );

      toggleShowModal(false);

      updateGrid(res, DocumentModalTypes.RENAME);
    }
  }

  function handleReplace(node: IRowNode) {
    toggleShowModal(true);
    currentDialog.current = () => (
      <ReplaceModal
        documentCurrentName={node.data.name}
        onSave={async (file: File) => await replaceDocument(file)}
        onCancel={() => cancelAction()}
      />
    );
    async function replaceDocument(file: File) {
      const res = await ProjectDocumentCollection.replaceProjectDocument(
        node.data.id,
        file
      );
      console.log('replaceDocumentRes:>>>', res);

      toggleShowModal(false);

      updateGrid(res, DocumentModalTypes.REPLACE);
    }
  }

  function handleDelete(node: IRowNode) {
    toggleShowModal(true);

    currentDialog.current = () => (
      <DeleteModal
        documentCurrentName={node.data.name}
        onSave={async () => await deleteDocument()}
        onCancel={() => cancelAction()}
      />
    );

    async function deleteDocument() {
      console.log(node.data.id);
      const res = await ProjectDocumentCollection.deleteProjectDocument(
        node.data.id
      );

      toggleShowModal(false);

      updateGrid(res, DocumentModalTypes.DELETE, node.data.id);
    }
  }

  function handleDownload(node: IRowNode) {
    const dbInstance = LocalDatabaseManager.instance();
    const filePath = `${dbInstance.internalGetServerAddress().protocol}://${
      dbInstance.internalGetServerAddress().dns
    }${extractApiPath(node.data.url)}`;
    handleDownloadFile({
      fileUrl: filePath,
      fileName: node.data.name
    });
  }

  const handleDownloadFile = async ({
    fileUrl,
    fileName
  }: {
    fileUrl: string;
    fileName: string;
  }) => {
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'arraybuffer' // Specify the responseType as 'arraybuffer' for binary data
      });

      const blob = new Blob([response.data], {
        type: response.headers['content-type']
      });
      const url = window.URL.createObjectURL(blob);

      // Create a link element and click it to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  function extractApiPath(url: string) {
    const parsedUrl = new URL(url);
    const apiPathIndex = parsedUrl.pathname.indexOf('/api');

    if (apiPathIndex !== -1) {
      return parsedUrl.pathname.substring(apiPathIndex);
    } else {
      return url; // or some default value if '/api' is not found
    }
  }

  return (
    <>
      <RCDockLayout
        dockLayoutRef={dockLayoutRef}
        defaultLayout={{
          dockbox: {
            mode: 'vertical',
            children: [
              {
                size: 18,
                mode: 'vertical',
                children: [
                  {
                    tabs: [
                      {
                        id: 'upload_file',
                        title: 'Upload Files',
                        group: 'close-all',
                        content: (
                          <UploadDocument
                            onDocumentUpload={async (file: File) =>
                              await handleUpload(file)
                            }
                            getContainerHeight={(value: number) =>
                              getContainerHeight(value)
                            }
                          />
                        )
                      }
                    ]
                  }
                ]
              },
              {
                mode: 'horizontal',
                size: 82,
                children: [
                  {
                    tabs: [
                      {
                        id: 'documents',
                        title: 'Documents',
                        group: 'close-all',
                        content: (
                          <Documents
                            getRef={(ref: any) => (gridRef.current = ref)}
                            documents={documentsList}
                            actionMenuItems={actionMenuItems}
                          />
                        )
                      }
                    ]
                  }
                ]
              }
            ]
          },
          floatbox: {
            mode: 'float',
            children: []
          }
        }}
        groups={RcGroup}
      />
      <AddModal
        show={showModal}
        toggleShow={(val?: boolean) => toggleShowModal(val)}
      >
        {currentDialog.current()}
      </AddModal>
    </>
  );
};
export default ProjectDocuments;
