import React, { useState } from 'react';
import {
  Button,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'react-bootstrap';
import { IRenameModalProps } from '../../../config/types';

/**
 * Rename Modal component
 * @param {object} props for current line component
 * @return {jsx} component
 */
export default function RenameModal({
  documentCurrentName,
  onSave,
  onCancel
}: IRenameModalProps) {
  const [newName, setNewName] = useState<string>(documentCurrentName ?? '');
  const [newNameError, setNewNameError] = useState<string>('');

  /**
   * Clears all errors
   */
  function clearErrors() {
    setNewNameError('');
  }

  /**
   * Validates form
   * @return {boolean} true or false
   */
  function validate(): boolean {
    clearErrors();
    if (newName.length === 0) {
      setNewNameError("Document's name can not be empty");
      return false;
    }
    return true;
  }

  /**
   * Event handler for submit
   */
  function handleSubmit() {
    const isValidated = validate();
    isValidated && onSave && onSave(newName);
  }

  return (
    <Form>
      <ModalHeader>Rename Document</ModalHeader>
      <ModalBody>
        <Form.Group>
          <Form.Label>Document Name</Form.Label>
          <Form.Control
            type='text'
            value={newName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewName(e.target.value)
            }
          />
          {!!newNameError.length && (
            <p className='inline-error'>{newNameError}</p>
          )}
        </Form.Group>
      </ModalBody>
      <ModalFooter>
        <Button className='btn btn-sm btn-danger' onClick={() => onCancel?.()}>
          Cancel
        </Button>
        <Button className='btn btn-sm' onClick={() => handleSubmit()}>
          Save
        </Button>
      </ModalFooter>
    </Form>
  );
}
