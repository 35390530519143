/* eslint-disable no-unused-vars */
/** Enumeration for Page Types used in DW Builder */
export enum builderPageTypes {
  pages = 'pages',
  lookups = 'lookups',
  dataDictionaries = 'data-dictionaries',
  tasks = 'tasks',
  modules = 'modules',
  moduleSections = 'module-sections',
  taskConfigs = 'task-configs',
  workflowStates = 'workflow-states'
}
