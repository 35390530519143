export function hasSpecialCharacters(
  input: string,
  extend: boolean = false
): boolean {
  // /[^\p{L}\p{N} _]/u;
  let specialCharsRegex = /[^\p{L}\p{N} _]/u; // default

  if (extend) {
    specialCharsRegex = /[^\p{L}\p{N} _:/\\-]/u;
  }
  return specialCharsRegex.test(input);
}

export function hasOnlySpecialCharacters(input: string): boolean {
  const specialCharsRegex = /[ _:/\\-]/u;

  const result = input
    .split('')
    .every((character: string) => specialCharsRegex.test(character));
  return result;
}
