import DockLayout from 'rc-dock';
import { GridMetaData } from '../config/types';
import {
  DataDictionaryTable,
  DataDictionaryTableCollection,
  LogicEngineDataDateTime,
  Lookup,
  LookupCollection,
  Module,
  ModuleCollection,
  ModuleSection,
  ModuleSectionCollection,
  Page,
  PageCollection,
  TaskConfig,
  TaskConfigCollection,
  WorkflowState,
  WorkflowStateCollection
} from '@digitalworkflow/dwtranslateclient';
import { builderPageTypes } from '../config/constants/builderPageTypes';

export function extractGridMetaFromTab(ref: DockLayout, tabId: string) {
  const foundTab: any = ref.find(tabId);

  const gridMetaData: GridMetaData =
    foundTab?.content?.props?.children?.props.children[1].props.children.props
      ._gridMetaData;

  return gridMetaData;
}

export function createGridMetaFromDocument(
  data:
    | DataDictionaryTable
    | Lookup
    | Page
    | Module
    | ModuleSection
    | TaskConfig
    | WorkflowState
): GridMetaData {
  return {
    createBy: data?.data?.create_by ?? '',
    createDt: data?.data?.create_dt ?? ({} as LogicEngineDataDateTime),
    updateBy: data?.data?.updated_by ?? '',
    updateDt: data?.data?.updated_dt ?? ({} as LogicEngineDataDateTime),
    documentId: data?.data?.id ?? '',

    lookupKey: (data as Lookup)?.data?.key,

    moduleName: (data as Module)?.data?.module_name,

    moduleSectionName: (data as ModuleSection)?.data?.section_name,

    ddTableName: (data as DataDictionaryTable)?.data?.table_name,
    ddDescription: (data as DataDictionaryTable)?.data?.description,

    taskConfigName: (data as TaskConfig)?.data?.name,
    taskConfigDescription: (data as TaskConfig)?.data?.describe,

    pageRoute: (data as Page)?.data?.route,
    pageTitle: (data as Page)?.data?.title_en,
    pageType: (data as Page)?.data?.page_type,
    pageDescription: (data as Page)?.data?.description,

    workflowCode: (data as WorkflowState)?.data?.code,
    workflowFullName: (data as WorkflowState)?.data?.full_name,
    workflowShortName: (data as WorkflowState)?.data?.name,
    workflowDescription: (data as WorkflowState)?.data?.description
  };
}

export async function createGridMetaFromDocumentIdAndType(
  documentId: string,
  type: builderPageTypes
): Promise<GridMetaData | undefined> {
  let document:
    | Page
    | Lookup
    | DataDictionaryTable
    | Module
    | ModuleSection
    | WorkflowState
    | TaskConfig
    | undefined
    | false;

  switch (type) {
    case builderPageTypes.pages:
      document = await PageCollection.getPageById(documentId);
      break;
    case builderPageTypes.lookups:
      document = await LookupCollection.getLookupById(documentId);
      break;
    case builderPageTypes.dataDictionaries:
      document = await DataDictionaryTableCollection.getTableById(documentId);
      break;
    case builderPageTypes.modules:
      document = await ModuleCollection.findById(documentId);
      break;
    case builderPageTypes.moduleSections:
      document = await ModuleSectionCollection.findById(documentId);
      break;
    case builderPageTypes.taskConfigs:
      document = await TaskConfigCollection.findById(documentId);
      break;
    case builderPageTypes.workflowStates:
      document = await WorkflowStateCollection.getWorkflowStateById(documentId);
      break;
  }
  return document ? createGridMetaFromDocument(document) : undefined;
}
