import React, { useEffect, useState } from 'react';
import CurrentLineDetail from '../CurrentLineDetail';
import CurrentLineField from '../CurrentLineField';

import style from './currentLine.module.scss';
// import { builderPageTypes as BPT } from '../../config/constants/builderPageTypes';
import { ModuleCollection } from '@digitalworkflow/dwtranslateclient';
import { GridSwitchFields } from '../../config/constants/columnDefinitions';

/** Props for Current Line */
interface ICurrentLine {
  selectedRows: any[];
  gridRef?: any;
  type?: string;
  key?: string;
}

/** Props for row data */
interface RowData {
  value: string | boolean;
  headerField: string;
}

/**
 * Current Line component
 * @param {object} props for current line component
 * @return {jsx} component
 */
const CurrentLine = ({ selectedRows, gridRef, type }: ICurrentLine) => {
  const [rows, setRows] = useState<RowData[][]>([]);
  if (type) {
  }

  useEffect(() => {
    /** Fetches data */
    const fetchData = async () => {
      if (gridRef && gridRef.current) {
        const columns = gridRef.current.columnApi.columnModel.columnDefs;

        const hasViewGroups = columns.some(
          (col: { field: string }) => col.field === 'view_groups'
        );
        const hasRownum = columns.some(
          (col: { field: string }) => col.field === 'rownum'
        );

        if (columns[1].field === 'step_type' && !hasViewGroups && !hasRownum) {
          // type === BPT.pages
          columns.push({
            field: 'view_groups',
            headerName: 'View Groups'
          });
          columns.push({
            field: 'rownum',
            headerName: 'Row num'
          });
        } // push fields for pages

        const rowDataPromises = selectedRows.map(async (item) => {
          return Promise.all(
            columns
              .filter(
                (header: { headerName: string }) => header.headerName !== '#'
              )
              .map(async (headerdata: any) => {
                let itemVal = headerdata.field ? item[headerdata.field] : '';
                if (GridSwitchFields.includes(headerdata.field)) {
                  itemVal =
                    itemVal === '' || itemVal === undefined ? false : itemVal;
                }

                if (
                  headerdata.field === 'module_id' ||
                  headerdata.field === 'parent_id'
                ) {
                  itemVal = (await ModuleCollection.findById(itemVal))?.data
                    .module_name;
                }

                if (Array.isArray(itemVal)) {
                  itemVal = itemVal.map((obj: Record<string, unknown>) => {
                    if (typeof obj === 'object') {
                      return Object.entries(obj)
                        .filter(([_, value]) => value != null && value !== '')
                        .map(([key, value]) => `${key}: ${value}`)
                        .join(', ');
                    } else if (typeof obj === 'string') {
                      return obj;
                    } else {
                      return '';
                    }
                  });
                }

                return {
                  headerField: headerdata.headerName,
                  value: itemVal
                };
              })
          );
        });

        const _rowData = await Promise.all(rowDataPromises);
        setRows(_rowData);
      }
    };

    fetchData();
  }, [selectedRows, gridRef]);

  return (
    <div className={`${style.container}  ignore-outside-click`}>
      {rows.map((row, rowIndex) => (
        <CurrentLineDetail
          className={style.currentLineDetail}
          type='lookup'
          key={`index${rowIndex}`}
        >
          {row.map((fieldData, index) => (
            <CurrentLineField
              key={index}
              value={fieldData.value}
              label={fieldData.headerField}
              onChange={() => {}}
              disabled
            />
          ))}
        </CurrentLineDetail>
      ))}
    </div>
  );
};

export default CurrentLine;
