import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import '../Forms.css';
import { SelectDropdown } from '@digitalworkflow/dwreactcommon';
import { Button } from 'reactstrap';
import { OptionType } from '../../../config/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { hasSpecialCharacters } from '../../../utils/checkSpecialCharacters';
import { FormErrorType } from '../../../pages/MasterEditor';

export interface handleModuleSectionSaveProps {
  module_id: string;
  section_name: string;
  wg_editors: string[];
  wg_monitor: string[];
  show_task_notes: boolean;
  page_name: string;
}

type ModuleSectionFormProps = {
  toggleShow?: () => void;
  handleSave?: ({
    module_id,
    section_name
  }: handleModuleSectionSaveProps) => Promise<boolean>;
  validationErrors?: FormErrorType[];
};

function ModuleSectionForm({
  toggleShow,
  handleSave,
  validationErrors
}: ModuleSectionFormProps) {
  const modules: any[] = useSelector(
    (state: RootState) => state.module.modules
  );
  const [module, setModule] = useState<OptionType | null>(null);
  const [moduleIdError, setModuleIdError] = useState<string>('');

  const [moduleOptions, setModuleOptions] = useState<OptionType[]>([]);

  const [sectionName, setSectionName] = useState<string>('');
  const [sectionNameError, setSectionNameError] = useState<string>('');

  useEffect(() => {
    setModuleIdError('');
    setSectionNameError('');
    getModuleList();
  }, []);

  useEffect(() => {
    if (validationErrors) {
      for (let index = 0; index < validationErrors.length; index++) {
        const element = validationErrors[index];
        if (element.field_id === 'section_name') {
          setSectionNameError(element.error_message);
        }
      }
    }
  }, [validationErrors]);

  async function getModuleList() {
    const modList = Object.values(modules);
    const data: OptionType[] = [];
    modList.forEach((moduleRow: any) => {
      data.push({
        id: moduleRow._data.id,
        value: moduleRow._data.module_name,
        label: moduleRow._data.module_name
      });
    });

    setModuleOptions([...data]);
  } // get module list

  function toggleModal() {
    if (!toggleShow) {
      return;
    }
    toggleShow();
  }

  function clearModuleSectionErrors() {
    setModuleIdError('');
    setSectionNameError('');
  } // clear module section errors

  async function saveModuleSection(): Promise<void> {
    clearModuleSectionErrors();
    let modSectionError = false;

    if (!module) {
      setModuleIdError('Select a module');
      modSectionError = true;
    }
    if (!sectionName || sectionName.trim() === '') {
      setSectionNameError('Section name is required');
      modSectionError = true;
    }
    if (sectionName.trim() !== sectionName) {
      setSectionNameError(
        'Section name should not have the spaces at the start or end'
      );
      modSectionError = true;
    }
    if (hasSpecialCharacters(sectionName, true)) {
      setSectionNameError('Section name contains special characters');
      modSectionError = true;
    }

    if (modSectionError) {
      return;
    } // mSection error

    clearModuleSectionErrors();
    const mSectionData = {
      module_id: module?.id ?? '',
      section_name: sectionName,
      wg_editors: [],
      wg_monitor: [],
      show_task_notes: false,
      page_name: sectionName
    };
    const result = await handleSave?.(mSectionData);
    if (result) {
      toggleModal();
    }
  }

  return (
    <div className='row'>
      <div className='modal-header h4'>New Module Section</div>
      <Form className='smallForm'>
        <div className='form-body'>
          <Form.Group controlId='moduleId' className='row'>
            <div className='col-sm-5 col-form-label'>
              <Form.Label>
                Parent Module<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-7'>
              <SelectDropdown
                placeholder='Select Parent Module'
                options={moduleOptions}
                onChange={(v) => setModule(v as any)}
                value={module}
                style={{
                  control: (baseStyle) => ({
                    ...baseStyle,
                    height: '42px',
                    borderColor: '#ced4da'
                  }),
                  singleValue: (baseStyle) => ({
                    ...baseStyle,
                    color: '#495057',
                    fontSize: '14px'
                  }),
                  placeholder: (baseStyle) => ({
                    ...baseStyle,
                    color: '#74788d',
                    fontSize: '14px'
                  })
                }}
              />
              {moduleIdError && (
                <p className=' inline-error'>{moduleIdError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group controlId='sectionName' className='form-group row'>
            <div className='col-sm-5 col-form-label'>
              <Form.Label>
                Module Section Name<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-7'>
              <Form.Control
                type='text'
                placeholder='Module Section Name'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setSectionName(e.target.value);
                }}
                value={sectionName}
              />
              {sectionNameError && (
                <p className=' inline-error'>{sectionNameError}</p>
              )}
            </div>
          </Form.Group>
        </div>

        <div className='modal-footer'>
          <Button
            className='btn btn-sm'
            color='cancel'
            type='button'
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
          <Button
            className='btn btn-sm'
            color='edit'
            type='button'
            onClick={() => saveModuleSection()}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default ModuleSectionForm;
