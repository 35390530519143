/* eslint-disable camelcase */
import React from 'react';
import TreeView from '../../GroupTreeView/GroupTreeView';
import { ITabParams } from '../../../config/types';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store';
import { openPagesTab } from './PagesUtils';
import { triggerEvent } from '../../../redux/event';
import {
  PageCollection,
  PageDocumentType,
  PageStepType
} from '@digitalworkflow/dwtranslateclient';
import { fetchPageGroups, fetchPages } from '../../../redux/page';
import { setSelectedNode } from '../../../redux/activeNode';
import {
  builderPageTypes as BPT,
  builderPageTypes
} from '../../../config/constants/builderPageTypes';
import { updateDataEverywhere } from '../../../utils';
import { ITreeViewData } from '@digitalworkflow/dwtranslateclient/lib/Models/Module/ModuleCollection';
import styles from '../Renderers.module.scss';

export const GroupsPages = ({
  ref,
  type,
  selectedRows,
  gridReferences,
  setCurrentEventAPI,
  setSelectedRowIndex,
  setSelectedRows,
  fetchData,
  setRowSelectedFlag,
  setTabsRef,
  updateData
}: ITabParams) => {
  const groups: ITreeViewData[] = useSelector(
    (state: RootState) => state.page.groups
  );
  const pagesData: any = useSelector((state: RootState) => state.page.pages);
  const dispatch = useAppDispatch();

  function openForm() {
    dispatch(triggerEvent('showModalEvent'));
  }

  function openDuplicateForm() {
    dispatch(triggerEvent('showDuplicateModalEvent'));
  }

  function reduxUpdater() {
    dispatch(fetchPages());
    dispatch(fetchPageGroups());
  }

  /**
   * callback to handle when a group is clicked in tree view
   * @param title string name of the group clicked
   */
  function handleGroupClick(title: string) {
    let currentPageSteps: PageStepType[] = [];
    let stepsFound = false;
    let currentPage: PageDocumentType | undefined;

    for (const page of pagesData) {
      if (page.data.route === title) {
        currentPage = page.data;
        currentPageSteps =
          page.data.steps && page.data.steps.length ? page.data.steps : [];
        stepsFound = true;
        break;
      }
    }

    if (!stepsFound || !currentPage) {
      return;
    }
    const rows: Partial<PageStepType>[] = [];

    Object.values(currentPageSteps).forEach((k) => {
      rows.push(k);
    });

    const tabId = title + '_tab';

    const tabAlreadyPresent = ref?.find(tabId);

    tabAlreadyPresent
      ? ref?.updateTab(tabId, null)
      : openPagesTab({
          ref,
          title,
          rows,
          currentPage,
          firstTab: false,
          type,
          selectedRows,
          gridReferences,
          fetchData,
          setSelectedRows,
          setSelectedRowIndex,
          setCurrentEventAPI,
          setRowSelectedFlag,
          setTabsRef,
          updateData,
          updateMetaData: (data) => {
            updateDataEverywhere(
              ref,
              data.id,
              data,
              builderPageTypes.pages,
              reduxUpdater
            );
          }
        });

    dispatch(setSelectedNode(title));
  }

  async function handleDrop(newData: ITreeViewData[]) {
    for (let index = 0; index < newData.length; index++) {
      if (newData[index].parent !== groups[index].parent) {
        const parentIndex = newData.findIndex(
          (v) => v.id === newData[index].parent
        );
        const tableName = newData[index].text as string;
        const destinationGroupName = newData[parentIndex].text as string;
        await PageCollection.updatePageGroup(tableName, destinationGroupName);
        dispatch(fetchPageGroups());
        break;
      }
    }
  }

  return (
    <div id={styles.treeView} className='overflow-y-auto h-100'>
      <TreeView
        pageType={BPT.pages}
        data={groups}
        setShowModal={() => openForm()}
        setShowDuplicateModal={() => openDuplicateForm()}
        handleGroupClick={(title) => handleGroupClick(title)}
        handleDrop={(newTreeData: ITreeViewData[]) => handleDrop(newTreeData)}
        tabRef={ref}
      />
    </div>
  );
};

export const HistoryPage = () => {
  return (
    <div>
      <span>History available in V2</span>
    </div>
  );
};
