import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Autocomplete from '../../Autocomplete/Autocomplete';
import { PageCollection } from '@digitalworkflow/dwtranslateclient';
import { Button } from 'reactstrap';
import { hasOnlySpecialCharacters } from '../../../utils/checkSpecialCharacters';
import { PageTypes } from '@digitalworkflow/dwtranslateclient/lib/Models/Page/PageSchema';
import { FormErrorType } from '../../../pages/MasterEditor';

export interface handlePageSaveProps {
  route: string;
  page_type: keyof typeof PageTypes | undefined;
  description?: string;
  title_en: string;
  group?: string;
}

type PageFormProps = {
  toggleShow?: () => void;
  handleSave?: ({
    route,
    page_type,
    description,
    title_en,
    group
  }: handlePageSaveProps) => Promise<boolean>;
  validationErrors?: FormErrorType[];
};

function PageForm({ toggleShow, handleSave, validationErrors }: PageFormProps) {
  const [route, setRoute] = useState<string>('');
  const [routeError, setRouteError] = useState<any>(null);
  const [pageType, setPageType] = useState<PageTypes | undefined>(undefined); //  PageTypes.page
  const [pageTypeError, setPageTypeError] = useState<any>(null);
  const [description, setDescriptiom] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<any>(null);
  const [title, setTitle] = useState<string>('');
  // const [titleError, setTitleError] = useState<any>(null);
  const [group, setGroup] = useState<string>('');
  const [groupError, setGroupError] = useState<any>(null);
  const [groupOptions, setGroupOptions] = useState<any>([]);
  // const pageTypeOptions: IGenericAnyObject[] = Object.values(
  //   PageTypes
  // ) as string[];
  const pageTypeOptions: { [key: string]: string }[] = [];
  Object.keys(PageTypes).forEach((key) => {
    pageTypeOptions.push({ title: key });
  });
  const clearPageErrors = () => {
    setRouteError(null);
    setPageTypeError(null);
    setDescriptionError(null);
    // setTitleError(null);
    setGroupError(null);
  }; // clear page errors

  useEffect(() => {
    clearPageErrors();

    getGroups();
  }, []);

  useEffect(() => {
    if (validationErrors) {
      for (let index = 0; index < validationErrors.length; index++) {
        const element = validationErrors[index];
        if (element.field_id === 'route') {
          setRouteError(element.error_message);
        }
      }
    }
  }, [validationErrors]);

  async function getGroups() {
    const res = await PageCollection.findAllGroups();
    const data: any[] = [];
    for (const group of res) {
      data.push({ title: group });
    }
    setGroupOptions([...data]);
  }

  function toggleModal() {
    if (!toggleShow) {
      return;
    }
    toggleShow();
  }

  async function savePage(): Promise<void> {
    clearPageErrors();

    let pageError = false;

    if (!route || route.trim() === '') {
      setRouteError('Page Route is required');
      pageError = true;
    }
    if (route.trim() !== route) {
      setRouteError(
        'Page Route should not have the spaces at the start or end'
      );
      pageError = true;
    }
    if (hasOnlySpecialCharacters(route)) {
      setRouteError('Page Route contains only special characters');
      pageError = true;
    }
    if (!pageType) {
      setPageTypeError('Page Type is required');
      pageError = true;
    }
    if (!description) {
      setDescriptionError('Description is required');
      pageError = true;
    }
    // if (!title) {
    //   setTitleError('Page Title is required');
    //   pageError = true;
    // }
    if (!group) {
      setGroupError('Group is required');
      pageError = true;
    }

    if (pageError) {
      return;
    }

    const result = await handleSave?.({
      route,
      page_type: pageType,
      description,
      title_en: title,
      group
    });
    if (result) {
      toggleModal();
    }
  }

  return (
    <div className='row'>
      <div className='modal-header h4'>New Page</div>
      <Form className='smallForm'>
        <div className='page-body'>
          <Form.Group controlId='title' className='form-group row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>Page Title</Form.Label>
            </div>
            <div className='col-sm-8'>
              <Form.Control
                type='text'
                placeholder='Page Title'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setTitle(e.target.value);
                }}
                value={title}
              />
              {/* {titleError && <p className='inline-error'>{titleError}</p>} */}
            </div>
          </Form.Group>
          <Form.Group controlId='route' className='form-group row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>
                Page Route<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-8'>
              <Form.Control
                type='text'
                placeholder='Page Route'
                className='form-control'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setRoute(e.target.value);
                }}
                value={route}
              />
              {routeError && <p className='inline-error'>{routeError}</p>}
            </div>
          </Form.Group>
          <Form.Group controlId='pageType' className='row'>
            <div className='col-sm-4 autoCompleteLabel'>
              Page Type<span className='text-red'>*</span>
            </div>
            <div className='col-sm-8'>
              <Autocomplete
                wrapperStyle={{ height: 'auto' }}
                onChange={(e) => {
                  console.log(e);
                }}
                onSelect={(e) => {
                  setPageType(e.title);
                }}
                data={pageTypeOptions}
              />
              {pageTypeError && (
                <p className=' inline-error'>{pageTypeError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group controlId='description' className='form-group row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>
                Description<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-8'>
              <Form.Control
                type='text'
                placeholder='Description'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setDescriptiom(e.target.value);
                }}
                value={description}
              />
              {descriptionError && (
                <p className='inline-error'>{descriptionError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group controlId='group' className='row'>
            <div className='col-sm-4 autoCompleteLabel'>
              Group<span className='text-red'>*</span>
            </div>
            <div className='col-sm-8'>
              <Autocomplete
                wrapperStyle={{ height: 'auto' }}
                onChange={(e) => {
                  setGroup(e.target.value);
                }}
                onSelect={(e) => {
                  setGroup(e.title);
                }}
                data={groupOptions}
              />
              {groupError && <p className=' inline-error'>{groupError}</p>}
            </div>
          </Form.Group>
        </div>
        <div className='modal-footer form_modal_footer'>
          <Button
            className='btn btn-sm'
            color='cancel'
            type='button'
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
          <Button
            className='btn btn-sm'
            color='edit'
            type='button'
            onClick={() => savePage()}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default PageForm;
