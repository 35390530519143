import React, {
  useState,
  useRef,
  forwardRef,
  useEffect,
  FC,
  memo
} from 'react';
import { useAppDispatch } from '../../../redux/store';
import { fetchPageGroups, fetchPages } from '../../../redux/page';
import { ICellEditor } from 'ag-grid-community';
import { addStep } from '../../MetaRenderers/Pages/PagesUtils';
import { GridApi } from '../../../config/types';
import AutocompleteEditorPortal from './AutocompleteEditorPortal';
import CustomTooltip from '../../CustomTooltip/CustomTooltip';

import './AutocompleteEditor.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

export interface OptionType {
  value: string;
  label: string;
  id: string;
}

export interface AutocompleteEditorProps {
  type: string;
  field: string;
  optionsField: string;
  value: string;
  node: any;
  updateData: (id: string, data: any) => void;
  openTab?: (tabId: string, type: string) => void;
  route?: string;
  refs?: any;
}

interface DropdownOptionProps {
  option: OptionType;
  onSelect: (option: OptionType) => void;
  isSelected: boolean;
}

const AutocompleteEditor = forwardRef<ICellEditor, AutocompleteEditorProps>(
  (props, _ref) => {
    const {
      type,
      field,
      optionsField,
      value: initialValues,
      node,
      updateData,
      openTab = () => {},
      route = '',
      refs = []
    } = props;
    const [showTooltip, setShowTooltip] = useState(false);
    const [searchWord, setSearchWord] = useState<string>('');
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [filteredOptions, setFilteredOptions] = useState<OptionType[]>([]);
    const isCustomOpenTab = 'openTab' in props;

    const dropdownRef = useRef<HTMLDivElement>(null);
    const valueRef = useRef<string>(initialValues);
    const optionssRef = useRef<HTMLInputElement>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
      const currentValue = initialValues || '';
      if (JSON.stringify(valueRef.current) !== JSON.stringify(currentValue)) {
        valueRef.current = currentValue;
      }
      const filtered = node.data[optionsField].filter((option: any) => {
        if (
          typeof option.value === 'string' &&
          typeof initialValues === 'string'
        ) {
          return option.value
            .toLowerCase()
            .includes(initialValues.toLowerCase());
        }
        return true;
      });
      setSearchWord(initialValues || '');
      setFilteredOptions(filtered);
    }, [initialValues]);

    useEffect(() => {
      const attachScrollListeners = () => {
        // Traverse up the DOM tree to find scrollable parents
        const agViews = document.getElementsByClassName('ag-body-viewport');
        const agViewsArray = Array.from(agViews);

        for (const agViews of agViewsArray) {
          agViews.addEventListener('scroll', handleScroll, true);
        }

        return agViewsArray;
      };

      const scrollableParents = attachScrollListeners();

      return () => {
        scrollableParents.forEach((parent) => {
          parent.removeEventListener('scroll', handleScroll, true);
        });
      };
    }, [isDropdownOpen]);

    useEffect(() => {
      window.addEventListener('resize', handleScroll, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
        window.removeEventListener('resize', handleScroll, true);
      };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !(event.target as Element).classList.contains('autoeditor-option')
      ) {
        updateValue(valueRef.current);
        setIsDropdownOpen(false);
      }
    };

    const handleScroll = () => {
      setIsDropdownOpen(false);
    };

    const updateValue = async (newValue: string) => {
      if (route) {
        const currentAgGrid = refs.current.filter((item: { tabId: string }) => {
          return item.tabId === route + '_tab';
        });
        const api: GridApi = currentAgGrid?.[currentAgGrid.length - 1]?.gridApi;
        if (api) {
          const rowNode = api.getDisplayedRowAtIndex(node.rowIndex);
          if (rowNode) {
            const rowData = { ...rowNode.data, [field]: newValue };
            rowNode.setData(rowData);
            api.refreshCells({
              rowNodes: [rowNode],
              columns: [field],
              force: true
            });
            console.log('Value updated successfully');
          } else {
            console.log(
              'Row node not found for id/rownum:',
              node.data.id || node.data.rownum
            );
          }
          await addStep(route, api);
          dispatch(fetchPageGroups());
          dispatch(fetchPages());
        } else {
          console.log('GridApi not found in currentAgGrid');
        }
      } else {
        node.data[field] = newValue;
        updateData(node.data.id || node.data.rownum, { [field]: newValue });
      }
    };

    const toggleDropdown = () => {
      setIsDropdownOpen(true);
      if (!isDropdownOpen) {
        document.addEventListener('click', handleClickOutside, true);
      } else {
        document.removeEventListener('click', handleClickOutside, true);
      }
    };

    const handleOptionSelect = (option: OptionType) => {
      valueRef.current = option.value;
      setSearchWord(option.value);
      setIsDropdownOpen(false);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchQuery = e.target.value;
      valueRef.current = e.target.value;
      setSearchWord(searchQuery);
      if (!isDropdownOpen) toggleDropdown();
      const filtered = node.data[optionsField].filter((option: any) => {
        if (typeof option.value === 'string' && typeof searchQuery === 'string')
          return option.value.toLowerCase().includes(searchQuery.toLowerCase());
      });

      setFilteredOptions(filtered);
    };

    const checkEmpty = () => {
      return searchWord !== '';
    };

    const openEditor = () => {
      openTab(searchWord, type);
    };

    const setAutoEditorStyle = () => {
      const dropdownElement = dropdownRef.current;
      if (dropdownElement) {
        const { top, left, bottom } = dropdownElement.getBoundingClientRect();
        const width = dropdownElement.offsetWidth;
        if (bottom + 195 > window.innerHeight) {
          return {
            bottom: window.innerHeight - top,
            left: left,
            width: width
          };
        } else {
          return {
            top: bottom,
            left: left,
            width: width
          };
        }
      }
      return {};
    };

    return (
      <div
        className='autoeditor-container'
        ref={dropdownRef}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          e.detail === 2 && optionssRef.current?.focus()
        }
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
          e.key === 'Enter' && optionssRef.current?.blur()
        }
      >
        <div className='autoeditor-selected'>
          <input
            ref={optionssRef}
            id='autoeditor-search-input'
            type='text'
            name='autoeditor-search'
            placeholder=''
            value={searchWord}
            autoComplete='off'
            className={`search-autoeditor ${
              isDropdownOpen === false && checkEmpty() ? '' : 'full-width'
            }`}
            style={node.data.isEmptyOrTrace ? { cursor: 'not-allowed' } : {}}
            disabled={node.data.isEmptyOrTrace}
            onChange={handleSearch}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onFocus={() => toggleDropdown()}
            onBlur={(e) => setTimeout(() => updateValue(e.target.value), 2000)}
            onMouseDown={(e) => e.preventDefault()}
          />
          {isCustomOpenTab && isDropdownOpen === false && checkEmpty() && (
            <button className='autoeditor-icon' onClick={openEditor}>
              <i className='fa-solid fa-up-right-from-square' />
            </button>
          )}
          {checkOverflow(optionssRef.current) && (
            <CustomTooltip show={showTooltip} target={optionssRef.current}>
              {searchWord}
            </CustomTooltip>
          )}
        </div>
        {isDropdownOpen && node.data[optionsField].length > 0 && (
          <AutocompleteEditorPortal>
            <div className='autoeditor-list' style={setAutoEditorStyle()}>
              <ul>
                {filteredOptions.map((option, index) => (
                  <DropdownOption
                    key={index}
                    option={option}
                    onSelect={handleOptionSelect}
                    isSelected={searchWord === option.value}
                  />
                ))}
              </ul>
            </div>
          </AutocompleteEditorPortal>
        )}
      </div>
    );
  }
);

const checkOverflow = (element: HTMLLIElement | HTMLInputElement | null) => {
  if (element) {
    return element.scrollWidth > element.clientWidth;
  }
  return false;
};

const DropdownOption: FC<DropdownOptionProps> = memo(
  ({ option, onSelect, isSelected }) => {
    const [showTooltips, setShowTooltips] = useState<boolean>(false);
    const optionsRef = useRef<HTMLLIElement | null>(null);
    return (
      <>
        <li
          ref={optionsRef}
          role='option'
          aria-selected={isSelected ? 'true' : 'false'}
          tabIndex={0}
          className={`autoeditor-option ${isSelected ? 'selected' : ''}`}
          onClick={() => onSelect(option)}
          onMouseEnter={() => setShowTooltips(true)}
          onMouseLeave={() => setShowTooltips(false)}
        >
          {option.value}
        </li>
        <CustomTooltip
          show={showTooltips && checkOverflow(optionsRef.current)}
          target={optionsRef.current}
        >
          {option.label}
        </CustomTooltip>
      </>
    );
  }
);

export default AutocompleteEditor;
