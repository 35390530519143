import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DataDictionaryTableCollection } from '@digitalworkflow/dwtranslateclient';
// import { ITreeViewData } from '../config/types';
import { AppConfig } from '../config/constants/AppConfig';
import { ITreeViewData } from '@digitalworkflow/dwtranslateclient/lib/Models/Module/ModuleCollection';

export interface DataDictionaryInitialState {
  groups: ITreeViewData[];
  dataDictionary: any;
  openedKeys: string[];
  selectedRows: any;
  loading: boolean;
  error: string | null;
}

const initialState: DataDictionaryInitialState = {
  groups: [],
  dataDictionary: [],
  selectedRows: [],
  openedKeys: [],
  loading: false,
  error: null
};

export const fetchDataDictionaryGroups = createAsyncThunk<
  ITreeViewData[],
  void,
  { rejectValue: string }
>('lookup/fetchDataDictionaryGroups', async (_, thunkAPI) => {
  try {
    const data: ITreeViewData[] =
      await DataDictionaryTableCollection.getTreeViewDataDictionary();

    if (AppConfig.evReduxLogs) {
      console.log(
        '%credux::groups::data-dictionary',
        'font-size:20px;color:brown'
      );
      console.log(data);
    }

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch Data Dictionary data.');
  }
});

export const fetchDataDictionary = createAsyncThunk<
  any,
  void,
  { rejectValue: string }
>('lookup/fetchDataDictionary', async (_, thunkAPI) => {
  try {
    const dataDictionary =
      await DataDictionaryTableCollection.getAllTablesWithFields();

    if (AppConfig.evReduxLogs) {
      console.log(
        '%credux::documents::data-dictionary',
        'font-size:20px;color:brown'
      );
      console.log(dataDictionary);
    }

    return dataDictionary;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch dictionary data.');
  }
});

export const dataDictionarySlice = createSlice({
  name: 'dataDictionary',
  initialState,
  reducers: {
    updateDataDictionaryGroups: (state, action) => {
      return {
        ...state,
        groups: action.payload
      };
    },
    pushOpenedKey: (state, action) => {
      return {
        ...state,
        openedKeys: [...state.openedKeys, action.payload]
      };
    },
    updateDictionarySelectedRow: (state, action) => {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchDataDictionaryGroups.pending || fetchDataDictionary.pending,
        (state) => ({
          ...state,
          loading: true,
          error: null
        })
      )
      .addCase(fetchDataDictionaryGroups.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        groups: action.payload
      }))
      .addCase(fetchDataDictionary.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        dataDictionary: action.payload
      }))
      .addCase(
        fetchDataDictionaryGroups.rejected || fetchDataDictionary.rejected,
        (state, action) => ({
          ...state,
          loading: false,
          error: action.error.message || 'Something went wrong'
        })
      );
  }
});

export const {
  updateDataDictionaryGroups,
  pushOpenedKey,
  updateDictionarySelectedRow
} = dataDictionarySlice.actions;
export default dataDictionarySlice.reducer;
