import { createSlice } from '@reduxjs/toolkit';
import { LayoutBase } from 'rc-dock';

export interface LookupInitialState {
  layout: LayoutBase | undefined;
}

const initialState: LookupInitialState = {
  layout: undefined
};

export const dockLayoutSlice = createSlice({
  name: 'dockLayout',
  initialState,
  reducers: {
    setDockLayout: (_, action) => {
      return {
        layout: action.payload
      };
    }
  }
});

export const { setDockLayout } = dockLayoutSlice.actions;
export default dockLayoutSlice.reducer;
