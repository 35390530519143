/** Library for Authentication related functions */
import { portalInfo } from '../config/constants/portalInfo';
import { storageKeys } from '../config/constants/storageKeys';

import commonLib from './common-lib';

const authLib: any = {};

/**
 * Asynchronous function for logout - it clears user data from storage and redirects him to login page
 * @return void
 */
authLib.logoutFunc = async function (): Promise<void> {
  await commonLib.authService().authLogout();
  localStorage.removeItem(storageKeys.tokenKey);
  localStorage.removeItem(storageKeys.userKey);
  commonLib.redirect(portalInfo.loginPortalUrl + portalInfo.loginUri);
}; // logout function

/**
 * Gets user token from storage and returns it
 * @return {string} token
 */
authLib.getUserToken = function (): string | null {
  return localStorage.getItem(storageKeys.tokenKey);
};

export default authLib;
