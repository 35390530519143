import React from 'react';
import {
  Button,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'react-bootstrap';
import { IDeleteModalProps } from '../../../config/types';

/** Delete Modal component
 * @param {object} props for delete modal component
 * @return {jsx} component
 */
export default function DeleteModal({
  documentCurrentName,
  onCancel,
  onSave
}: IDeleteModalProps) {
  return (
    <Form>
      <ModalHeader>Delete Document</ModalHeader>
      <ModalBody>
        <span style={{ wordBreak: 'break-word' }}>
          Are you sure you want to delete <strong>{documentCurrentName}</strong>{' '}
          ?
        </span>
      </ModalBody>
      <ModalFooter>
        <Button className='btn btn-sm btn-danger' onClick={() => onCancel?.()}>
          Cancel
        </Button>
        <Button className='btn btn-sm' onClick={() => onSave?.()}>
          Delete
        </Button>
      </ModalFooter>
    </Form>
  );
}
