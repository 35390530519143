import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Autocomplete from '../../Autocomplete/Autocomplete';
import {
  PageCollection,
  ProjectCollection
} from '@digitalworkflow/dwtranslateclient';
import { Button } from 'reactstrap';
import { hasOnlySpecialCharacters } from '../../../utils/checkSpecialCharacters';
// import { PageTypes } from '@digitalworkflow/dwtranslateclient/lib/Models/Page/PageSchema';
import { FormErrorType } from '../../../pages/MasterEditor';
import { IGenericAnyObject } from '../../../config/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

export interface handleDuplicatePageSaveProps {
  route: string;
  title: string;
  projectId: string;
  group: string;
}

type DuplicatePageFormProps = {
  toggleShow?: () => void;
  handleSave?: ({
    route,
    title,
    projectId,
    group
  }: handleDuplicatePageSaveProps) => Promise<void>;
  validationErrors?: FormErrorType[];
};

function DuplicatePageForm({
  toggleShow,
  handleSave,
  validationErrors
}: DuplicatePageFormProps) {
  const [route, setRoute] = useState<string>('');
  const [routeError, setRouteError] = useState<any>(null);
  const [title, setTitle] = useState<string>('');
  const [titleError, setTitleError] = useState<any>(null);
  const [projectId, setProjectId] = useState<string>('');
  const [projectIdError, setProjectIdError] = useState<any>(null);
  const [group, setGroup] = useState<string>('');
  const [groupError, setGroupError] = useState<any>(null);
  const [groupOptions, setGroupOptions] = useState<any>([]);
  const [projectOptions, setProjectOptions] = useState<
    IGenericAnyObject[] | undefined
  >(undefined);
  const node2Duplicate = useSelector(
    (state: RootState) => state.event.node2Duplicate
  );

  console.log('node to duplicate: ', node2Duplicate);

  const clearPageErrors = () => {
    setRouteError(null);
    setTitleError(null);
    setProjectIdError(null);
    setGroupError(null);
  }; // clear page errors

  useEffect(() => {
    clearPageErrors();
    getProjectsList();
    getGroups();
  }, []);

  useEffect(() => {
    if (validationErrors) {
      for (let index = 0; index < validationErrors.length; index++) {
        const element = validationErrors[index];
        if (element.field_id === 'route') {
          setRouteError(element.error_message);
        }
      }
    }
  }, [validationErrors]);

  async function getGroups() {
    const res = await PageCollection.findAllGroups();
    const data: any[] = [];
    for (const group of res) {
      data.push({ title: group });
    }
    setGroupOptions([...data]);
  }

  async function getProjectsList() {
    const projects = await ProjectCollection.getAvailableProjectsList();
    const allProjects = Object.values(projects).map(
      (project) => project[0]._data
    );

    const projList = Object.values(allProjects);
    const data: IGenericAnyObject[] = [];
    projList.forEach((projRow: any) => {
      data.push({
        id: projRow.project_id,
        name: projRow.name_en
      });
    });

    setProjectOptions([...data]);
  } // get projects list

  function toggleModal() {
    if (!toggleShow) {
      return;
    }
    toggleShow();
  }

  function saveDuplicatePage(): void {
    clearPageErrors();

    let pageError = false;

    if (!route || route.trim() === '') {
      setRouteError('Page Route is required');
      pageError = true;
    }
    if (route.trim() !== route) {
      setRouteError(
        'Page Route should not have the spaces at the start or end'
      );
      pageError = true;
    }
    if (hasOnlySpecialCharacters(route)) {
      setRouteError('Page Route contains only special characters');
      pageError = true;
    }
    if (!title) {
      setTitleError('Page Title is required');
      pageError = true;
    }
    if (!projectId) {
      setProjectIdError('Project is required');
      pageError = true;
    }
    if (!group) {
      setGroupError('Group is required');
      pageError = true;
    }

    if (pageError) {
      return;
    }

    const result = handleSave?.({
      route,
      title,
      projectId: '',
      group
    });
    if (result) {
      toggleModal();
    }
  }
  const duplicatePageTitle = node2Duplicate
    ? `Duplicate [${node2Duplicate}] Page`
    : 'Duplicate Page';

  return (
    <div className='row'>
      <div className='modal-header h4'>{duplicatePageTitle}</div>
      <Form className='smallForm'>
        <div className='page-body'>
          <Form.Group controlId='title' className='form-group row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>
                Page Title<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-8'>
              <Form.Control
                type='text'
                placeholder='Page Title'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setTitle(e.target.value);
                }}
                value={title}
              />
              {titleError && <p className='inline-error'>{titleError}</p>}
            </div>
          </Form.Group>
          <Form.Group controlId='route' className='form-group row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>
                Page Route<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-8'>
              <Form.Control
                type='text'
                placeholder='Page Route'
                className='form-control'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setRoute(e.target.value);
                }}
                value={route}
              />
              {routeError && <p className='inline-error'>{routeError}</p>}
            </div>
          </Form.Group>
          <Form.Group controlId='projectId' className='row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>
                Project<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-8'>
              <select
                id='projectId'
                name='projectId'
                className='form-select form-control'
                value={projectId}
                onChange={(e) => {
                  console.log('proj val: ', e.target.value);
                  setProjectId(e.target.value);
                }}
                onFocus={() => {
                  console.log('focus project');
                }}
                onBlur={() => {
                  console.log('on blur project');
                }}
              >
                <option value=''>Select Project</option>
                {projectOptions?.map((option, index) => (
                  <option
                    key={index}
                    value={option.id}
                    onClick={(e) => {
                      console.log('click project option: ', e, option, index);
                    }}
                  >
                    {option.name}
                  </option>
                ))}
              </select>
              {projectIdError && (
                <p className=' inline-error'>{projectIdError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group controlId='group' className='row'>
            <div className='col-sm-4 autoCompleteLabel'>
              Group<span className='text-red'>*</span>
            </div>
            <div className='col-sm-8'>
              <Autocomplete
                wrapperStyle={{ height: 'auto' }}
                onChange={(e) => {
                  setGroup(e.target.value);
                }}
                onSelect={(e) => {
                  setGroup(e.title);
                }}
                data={groupOptions}
              />
              {groupError && <p className=' inline-error'>{groupError}</p>}
            </div>
          </Form.Group>
        </div>
        <div className='modal-footer form_modal_footer'>
          <Button
            className='btn btn-sm'
            color='cancel'
            type='button'
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
          <Button
            className='btn btn-sm'
            color='edit'
            type='button'
            onClick={() => {
              console.log('save duplicate page call handler');
              saveDuplicatePage();
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default DuplicatePageForm;
