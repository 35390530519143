import React, { useState, useRef, useCallback, useMemo } from 'react';
import { DockLayout, RCDockLayout } from '@digitalworkflow/dwreactcommon';
import { RcGroup } from '../../components/RcGroup';
import './Publish.css';
import { pageTypeMapping } from '../../config/constants/pageTypeMapping';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'react-bootstrap';
import { PageCollection } from '@digitalworkflow/dwtranslateclient';
import { useAppDispatch } from '../../redux/store';
import { fetchPageGroups, fetchPages } from '../../redux/page';

interface CheckboxItemProps {
  item: { label: string; checked: boolean };
  onChange: (checked: boolean) => void;
}

const CheckboxItem: React.FC<CheckboxItemProps> = ({ item, onChange }) => {
  return (
    <div className='checkbox-item'>
      <input
        type='checkbox'
        checked={item.checked}
        id={`checkbox-${item.label}`}
        onChange={(e) => onChange(e.target.checked)}
        className='custom-checkbox'
        disabled={item.label !== 'Publish Page'}
      />
      <label htmlFor={`checkbox-${item.label}`} className='checkbox-label'>
        {item.label}
      </label>
    </div>
  );
};

const PublishToDevelopment: React.FC = () => {
  const dispatch = useAppDispatch();

  const [items, setItems] = useState(
    Object.values(pageTypeMapping).map((item) => ({
      label: `Publish ${item}`,
      checked: false
    }))
  );
  const [showModal, setShowModal] = useState(false);

  const handleCheckboxChange = useCallback(
    (index: number, checked: boolean) => {
      setItems((currentItems) =>
        currentItems.map((item, i) =>
          i === index ? { ...item, checked } : item
        )
      );
    },
    []
  );

  const handlePublish = useCallback(() => {
    setShowModal(true);
  }, []);

  const confirmPublish = useCallback(async () => {
    console.log(
      'Publishing items:',
      items.filter((item) => item.checked)
    );
    setShowModal(false);

    if (items.some((item) => item.checked && item.label === 'Publish Page')) {
      await publishAllPages();
    }
  }, [items]);

  const publishAllPages = useCallback(async () => {
    const pages = await PageCollection.getAllPages();
    await Promise.all(
      pages.map(async (page) => {
        page.data.pub_steps =
          page.data.steps && page.data.steps.length > 0
            ? handlePubStepsSave(page.data)
            : [];
        if (page.data.steps === undefined) {
          page.data.steps = [];
        }
        await page.save();
      })
    );
    dispatch(fetchPageGroups());
    dispatch(fetchPages());
  }, []);

  const handlePubStepsSave = useCallback((data: any) => {
    return data.steps.map((step: any, idx: number) => ({
      ...step,
      rownum: idx + 1
    }));
  }, []);

  const isPublishButtonDisabled = useMemo(
    () => !items.some((item) => item.checked),
    [items]
  );

  return (
    <>
      <div className='checkbox-list'>
        <div className='checkbox-items'>
          {items.map((item, index) => (
            <CheckboxItem
              key={index}
              item={item}
              onChange={(checked) => handleCheckboxChange(index, checked)}
            />
          ))}
        </div>
        <Button
          type='button'
          className='btn-sm mt-2'
          color='add'
          disabled={isPublishButtonDisabled}
          onClick={handlePublish}
        >
          Publish
        </Button>
      </div>
      {showModal && (
        <Modal show onHide={() => setShowModal(false)}>
          <ModalHeader closeButton>
            <Modal.Title>Confirm Publish</Modal.Title>
          </ModalHeader>
          <ModalBody>
            <p>Are you sure you want to publish the selected items?</p>
          </ModalBody>
          <ModalFooter className='modal-footer-publish'>
            <Button variant='secondary' onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={confirmPublish}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

const CheckboxListPage: React.FC = () => {
  const dockLayoutRef = useRef<DockLayout | null>(null);

  const defaultLayout: any = useMemo(
    () => ({
      dockbox: {
        mode: 'horizontal',
        id: 'master_dock',
        children: [
          {
            tabs: [
              {
                id: 'pubdev_list',
                title: 'Publish to Development',
                group: 'close-all',
                content: <PublishToDevelopment />
              }
            ]
          }
        ]
      },
      floatbox: { children: [] }
    }),
    []
  );

  return (
    <RCDockLayout
      dockLayoutRef={dockLayoutRef}
      defaultLayout={defaultLayout}
      groups={RcGroup}
    />
  );
};

export default CheckboxListPage;
