/* eslint-disable no-unused-vars */
/** Enumeration for items having links allowed */
export const hashAllowLinks = [
  'Project Settings',
  'Pages',
  'Data Dictionaries',
  'Lookups',
  'Modules',
  'Module Sections',
  'Workflow States',
  'Task Configs',
  'Publish',
  'Admin Tools',
  'Permissions',
  'External Connections'
];
