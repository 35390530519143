import {
  LintPostProcessor,
  PageStepType
} from '@digitalworkflow/dwtranslateclient';

export function lintProcessor(steps: PageStepType[]) {
  const processor: LintPostProcessor = new LintPostProcessor();
  const rows = processor.processCommands(steps);

  const updatedRows = rows.map((row, i: number) => {
    const { step, backColor, ...rest } = row;
    return {
      ...step,
      ...rest,
      isEmptyOrTrace: i < rows.length - 4 && step.rownum === undefined,
      rownum: i + 1,
      backColor:
        i < rows.length - 4 &&
        step.rownum === undefined &&
        backColor === undefined
          ? '#d3d3d3'
          : backColor
    };
  });

  return updatedRows;
}
