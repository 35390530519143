import React from 'react';
import styles from './CurrentGridDetail.module.scss';
import { IGridDetailProps } from '../../config/types';
import { builderPageTypes } from '../../config/constants/builderPageTypes';
import { formatDate } from '../../utils';
import { LogicEngineDataDateTime } from '@digitalworkflow/dwtranslateclient';
import InfoForm from '../InfoForm/InforForm';

/**
 * Current Grid Detail component
 * @param {object} props for current grid detail component
 * @return {jsx} component
 */
export default function CurrentGridDetail({ data, type }: IGridDetailProps) {
  console.log('CurrentGridDetail', data);
  return (
    <div className={styles.wrapper}>
      <div className={styles.inputGroup}>
        <div className='d-flex w-100 justify-content-center'>
          <h5>
            {type === builderPageTypes.dataDictionaries
              ? 'Data Dictionary'
              : type === builderPageTypes.lookups
              ? 'Lookup'
              : type === builderPageTypes.pages
              ? 'Page'
              : type === builderPageTypes.modules
              ? 'Module'
              : type === builderPageTypes.moduleSections
              ? 'Module Section'
              : type === builderPageTypes.workflowStates
              ? 'Workflow State'
              : 'Task Config'}
          </h5>
        </div>
        <>
          <InfoForm
            label='Pub Version'
            value='11 (static)'
            dateStr={formatDate(
              data?.createDt ?? ({} as LogicEngineDataDateTime)
            )}
          />
          <InfoForm
            label='Last Save'
            value={data?.updateBy ?? ''}
            dateStr={formatDate(
              data?.updateDt ?? ({} as LogicEngineDataDateTime)
            )}
          />
          <InfoForm
            label='Created'
            value={data?.createBy ?? ''}
            dateStr={formatDate(
              data?.createDt ?? ({} as LogicEngineDataDateTime)
            )}
          />
        </>
      </div>
    </div>
  );
}
