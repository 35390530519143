import React from 'react';
import { Modal } from 'react-bootstrap'; // , Button
import './AddModal.css';

/** Props type for Add Modal popup */
type AddModalProps = {
  show: boolean;
  toggleShow: () => void;
  children?: React.ReactNode;
};

/**
 * Shows Add Modal popup
 * @param {object} props for add modal popup
 * @return {jsx} component
 */
function AddModal({ show = false, toggleShow, children }: AddModalProps) {
  function toggleModal() {
    toggleShow();
  }

  return (
    <Modal
      show={show}
      onHide={() => toggleModal()}
      keyboard
      className='modal fade'
    >
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

export default AddModal;
