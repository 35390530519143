import React, { useEffect } from 'react';
import dbLib from '../../lib/db-lib';
import {
  CommonHelper,
  LocalDatabaseManager,
  ProjectCollection,
  DataTools,
  StringOptions
} from '@digitalworkflow/dwtranslateclient';
import LoadingScreen from '../../components/LoadingScreen';
import { storageKeys } from '../../config/constants/storageKeys';
import { IGenericAnyObject } from '../../config/types';
import logoSmImage from '../../assets/images/bgis-logo-sm.png';
import { useNavigate } from 'react-router';

interface ISyncProps {
  setIsSynced: React.Dispatch<React.SetStateAction<boolean>>;
  setProjects: React.Dispatch<React.SetStateAction<IGenericAnyObject[] | []>>;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  setProjectSmallLogo: React.Dispatch<React.SetStateAction<string>>;
  setHasDropdownMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setDropdownMenuItems: React.Dispatch<React.SetStateAction<string[] | []>>;
  setDropdownValue: React.Dispatch<React.SetStateAction<string>>;
  fetchAllGroups: () => void;
  fetchAllData: () => void;
  redirectToUrl: string;
  initialRedirectUrl: string;
}

function Sync({
  setIsSynced,
  setProjects,
  setDropdownMenuItems,
  setHasDropdownMenu,
  setProjectSmallLogo,
  setUsername,
  setDropdownValue,
  fetchAllData,
  fetchAllGroups,
  redirectToUrl,
  initialRedirectUrl
}: ISyncProps) {
  const navigate = useNavigate();

  useEffect(() => {
    initializeLocalDatabase();
  }, []);

  async function initializeLocalDatabase(): Promise<boolean> {
    const dbCreated = await dbLib.startDb();
    const instance = LocalDatabaseManager.instance();
    const subscription = instance.evDatabaseSyncProgress();
    subscription.initialPullComplete$.subscribe(async (isComplete: boolean) => {
      if (isComplete) {
        const hash = localStorage.getItem('dwbuilder_hash') ?? null;
        let hashProjectId: string | null = null;
        let hashType: string | null = null;
        let hashTab: string | null = null;
        if (hash) {
          const existing = DataTools.internalValidateString(hash).replace(
            '#',
            ''
          );
          const opt = new StringOptions(existing);
          hashProjectId = opt.checkOption('project', true);
          hashType = opt.checkOption('type', true);
          hashTab = opt.checkOption('tab', true);
        }
        await getProjects(hashProjectId);
        fetchAllGroups();
        fetchAllData();
        setIsSynced(true);
        redirectToUrl !== '/sync' &&
        redirectToUrl !== '/authenticate' &&
        redirectToUrl !== '/'
          ? navigate(
              hashTab && hashType && hashProjectId
                ? `/${redirectToUrl.split('/')[1]}/${hashType}${hash}`
                : redirectToUrl
            )
          : navigate(initialRedirectUrl);
      }
    });

    return dbCreated;
  } // init local db ends

  const getProjects = async (hashProjectId: string | null) => {
    try {
      const userStr = localStorage.getItem(storageKeys.userKey);
      if (!userStr) throw new Error('User not found in localStorage');

      const userObj = JSON.parse(userStr);
      const projects = await ProjectCollection.getAvailableProjectsList();
      const allProjects = Object.values(projects).map(
        (project) => project[0]._data
      );

      if (allProjects.length > 0) {
        setProjects(allProjects);
        userObj.all_active_projects = allProjects;

        /**
         * @description this is for development and QA
         */
        const lastActiveProjectString =
          localStorage.getItem('lastActiveProject');
        let lastActiveProjectObj = lastActiveProjectString
          ? JSON.parse(lastActiveProjectString)
          : null;
        if (hashProjectId) {
          const hashProject = allProjects.find(
            (project) => project.project_id === hashProjectId
          );
          if (hashProject) {
            lastActiveProjectObj = {
              project_id: hashProject.project_id,
              project_name: hashProject.name_en
            };
            localStorage.setItem(
              'lastActiveProject',
              JSON.stringify(lastActiveProjectObj)
            );
          }
        } else {
          if (
            !lastActiveProjectObj ||
            (lastActiveProjectObj.project_id === '' &&
              lastActiveProjectObj.project_name === '')
          ) {
            lastActiveProjectObj = {
              project_id: 'test2',
              project_name: 'DW Test 2'
            };
            localStorage.setItem(
              'lastActiveProject',
              JSON.stringify(lastActiveProjectObj)
            );
          }
        }

        CommonHelper.setProjectName(lastActiveProjectObj.project_id);
        setDropdownValue(lastActiveProjectObj.project_name);

        /**
         * @description this is the original and correct implementation
         */
        // CommonHelper.setProjectName(allProjects[0].project_id ?? '');
        // setDropdownValue(allProjects[0].name_en)

        localStorage.setItem(storageKeys.userKey, JSON.stringify(userObj));
      }

      const projectNames = userObj.all_active_projects.map(
        (project: { name_en: any }) => project.name_en
      );
      setDropdownMenuItems(projectNames);
      if (userObj.first_name) {
        setUsername(userObj.first_name);
        setProjectSmallLogo(logoSmImage);
      }
      setHasDropdownMenu(true);
    } catch (e) {
      console.error('Error in getProjects:', e);
    }
    return true;
  };

  return <LoadingScreen comment='Syncing' />;
}

export default Sync;
