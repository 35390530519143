import React from 'react';
import { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import { TabData } from 'rc-dock';
import BuilderGrid from '../components/BuilderGrid';
import {
  DockLayout,
  GridApi,
  GridMetaData,
  IGridReferences
} from '../config/types';
import { builderPageTypes } from '../config/constants/builderPageTypes';
import BuilderForm from '../components/BuilderForm';
import { getGroupInfo } from './getGroupInfo';
interface IGenerateTabProps {
  id: string;
  title: string;
  group: builderPageTypes;
  columnDefs: ColDef[];
  masterDetail?: boolean;
  detailCellRendererParams?: any;
  data: any[];
  defaultColDef: ColDef;
  theme: string;
  pagination: boolean;
  gridReferences: React.MutableRefObject<IGridReferences[]>;
  gridMetaData?: GridMetaData;
  metaGridCols?: ColDef[];
  metaGridDefaultCol?: ColDef;
  onMetaCellValueChanged?: (e: CellValueChangedEvent) => void;
  setSelectedRowIndex: (index: number) => void;
  onCellValueChanged: (e: CellValueChangedEvent) => void;
  onUpdateAPI: (api: GridApi) => void;
  setTabsRef: (value: any) => void;
  dockRef?: DockLayout | null;
}

export function generateTab({
  id,
  title,
  group,
  columnDefs,
  detailCellRendererParams = {},
  masterDetail = false,
  data,
  defaultColDef,
  theme,
  pagination,
  gridReferences,
  gridMetaData,
  metaGridCols,
  metaGridDefaultCol,
  onMetaCellValueChanged,
  onCellValueChanged,
  onUpdateAPI,
  setTabsRef,
  setSelectedRowIndex,
  dockRef
}: IGenerateTabProps): TabData {
  const metaDataRows = [
    {
      id: gridMetaData?.documentId,
      description:
        gridMetaData?.pageDescription ??
        gridMetaData?.ddDescription ??
        gridMetaData?.workflowDescription ??
        gridMetaData?.taskConfigDescription,
      name:
        gridMetaData?.moduleName ??
        gridMetaData?.moduleSectionName ??
        gridMetaData?.taskConfigName ??
        gridMetaData?.workflowShortName,

      key: gridMetaData?.lookupKey,

      table_name: gridMetaData?.ddTableName,

      code: gridMetaData?.workflowCode,
      full_name: gridMetaData?.workflowFullName,

      route: gridMetaData?.pageRoute,
      title_en: gridMetaData?.pageTitle,
      page_type: gridMetaData?.pageType
    }
  ];

  return {
    id: id + '_tab',
    title: title,
    group: getGroupInfo(group),
    closable: true,
    content: (
      <div style={{ height: '100%' }}>
        {group === builderPageTypes.workflowStates ||
        group === builderPageTypes.modules ||
        group === builderPageTypes.moduleSections ||
        group === builderPageTypes.taskConfigs ? (
          <>
            <div style={{ height: 90 }}>
              <BuilderGrid
                id={id + '_metaData'}
                columnDefs={metaGridCols ?? []}
                data={metaDataRows ?? []}
                defaultColDef={metaGridDefaultCol ?? {}}
                theme={theme}
                pagination={pagination}
                onCellValueChanged={onMetaCellValueChanged}
              />
            </div>
            <div style={{ height: 'calc(100% - 90px)' }}>
              <BuilderForm
                id={id}
                columnDefs={columnDefs}
                masterDetail={masterDetail}
                detailCellRendererParams={detailCellRendererParams}
                data={data}
                defaultColDef={defaultColDef}
                theme={theme}
                pagination={pagination}
                gridReferences={gridReferences}
                _gridMetaData={gridMetaData}
                onUpdateAPI={onUpdateAPI}
                setTabsRef={setTabsRef}
                setSelectedRowIndex={setSelectedRowIndex}
                type={group}
                dockRef={dockRef}
              />
            </div>
          </>
        ) : (
          <>
            <div style={{ height: 75 }}>
              <BuilderGrid
                id={id + '_metaData'}
                columnDefs={metaGridCols ?? []}
                data={metaDataRows ?? []}
                defaultColDef={metaGridDefaultCol ?? {}}
                theme={theme}
                pagination={pagination}
                onCellValueChanged={onMetaCellValueChanged}
              />
            </div>
            <div style={{ height: 'calc(100% - 75px)' }}>
              <BuilderGrid
                id={id}
                columnDefs={columnDefs}
                masterDetail={masterDetail}
                detailCellRendererParams={detailCellRendererParams}
                data={data}
                defaultColDef={defaultColDef}
                theme={theme}
                pagination={pagination}
                gridReferences={gridReferences}
                _gridMetaData={gridMetaData}
                onCellValueChanged={onCellValueChanged}
                onUpdateAPI={onUpdateAPI}
                setTabsRef={setTabsRef}
                setSelectedRowIndex={setSelectedRowIndex}
              />
            </div>
          </>
        )}
      </div>
    )
  } as TabData;
}
