import DockLayout from 'rc-dock';
import { GridMetaData, ILookupGridUpdateData } from '../../config/types';
import {
  LogicEngineDataDateTime,
  Lookup,
  LookupCollection,
  ValidationResults
} from '@digitalworkflow/dwtranslateclient';
import { generateTab } from '../generateTab';
import { builderPageTypes } from '../../config/constants/builderPageTypes';
import { createLookupRowData } from '../../components/MetaRenderers/Lookups/LookupsUtils';

export async function handleUpdateLookup(
  ref: DockLayout | null | undefined,
  id: string,
  data: ILookupGridUpdateData,
  reduxUpdater: () => void
) {
  let saveResults: ValidationResults = {} as ValidationResults;

  // update document in db
  const { oldLookup, isUpdated, newLookup } = await _updateLookupInDB(id, data);

  saveResults = isUpdated.validationResults as ValidationResults;

  if (!isUpdated.validationResults.has_errors) {
    // if document is updated in db, update redux
    reduxUpdater();

    // if ref provided, find and update currently opened editor tab and grid
    if (ref) {
      // find tab
      const tab: any = ref.find(oldLookup?.data.key + '_tab');

      if (tab) {
        // update grid meta, data(if applicable), and tab title(if applicable)
        const oldTabProps = (tab as any).content.props.children.props.children;
        newLookup &&
          _updateLookupTabAndGrid(newLookup, oldTabProps, ref, saveResults);
      }
    }
  }

  return saveResults;
}

// internal function
async function _updateLookupInDB(id: string, data: ILookupGridUpdateData) {
  const oldLookup = await LookupCollection.getLookupById(id);

  let isUpdated;
  if (data.group) {
    const temp = await LookupCollection.updateKeyGroup(
      oldLookup.data.key ?? '',
      data.group
    );
    isUpdated = temp.length
      ? { validationResults: { has_errors: false } }
      : {
          validationResults: {
            has_errors: true,
            results: [
              {
                error_message: 'Error while updating',
                field_id: 'group',
                type: 'error'
              }
            ]
          }
        };
  } else {
    isUpdated = await LookupCollection.updateLookupKey(
      oldLookup.data.key ?? '',
      data.key ?? '',
      oldLookup.data.group ?? ''
    );
  }

  const newLookup = await LookupCollection.findLastKey(data.key ?? '');

  return { oldLookup, isUpdated, newLookup };
}

// internal function
async function _updateLookupTabAndGrid(
  newLookup: Lookup,
  tabProps: any,
  ref: DockLayout,
  saveResults: ValidationResults
) {
  const newGridMeta: GridMetaData = {
    createBy: newLookup?.data.create_by ?? '',
    updateBy: newLookup?.data.updated_by ?? '',
    createDt: newLookup?.data.create_dt ?? ({} as LogicEngineDataDateTime),
    updateDt: newLookup?.data.updated_dt ?? ({} as LogicEngineDataDateTime),
    lookupKey: newLookup?.data.key,
    documentId: newLookup?.data.id ?? ''
  };

  const newData = await LookupCollection.findAllByKey(
    newLookup?.data.key ?? ''
  );

  if (!newData.length) {
    saveResults.addError('newDataFetch', 'failed to fetch new data');
  }

  const extractedData = newData.map((lookup) => lookup.data);
  extractedData.push(
    createLookupRowData(newLookup.data.key ?? '', newLookup.data.group ?? '')
  );

  const tabUpdated = ref.updateTab(
    tabProps[1].props.children.props.id + '_tab',
    generateTab({
      ...tabProps[1].props.children.props,
      gridMetaData: newGridMeta,
      id: newLookup?.data.key,
      title: newLookup?.data.key ?? '',
      data: extractedData,
      group: builderPageTypes.lookups,
      metaGridCols: tabProps[0].props.children.props.columnDefs,
      metaGridDefaultCol: tabProps[0].props.children.props.defaultColDef,
      onMetaCellValueChanged:
        tabProps[0].props.children.props.onCellValueChanged
    })
  );

  if (!tabUpdated) {
    saveResults.addError('updateTab', 'failed to update opened tab');
  }
}
