import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import '../Forms.css';
// import Autocomplete from '../../Autocomplete/Autocomplete';
import { Button } from 'reactstrap';
import { SelectDropdown } from '@digitalworkflow/dwreactcommon';
import { OptionType } from '../../../config/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { hasSpecialCharacters } from '../../../utils/checkSpecialCharacters';
import { FormErrorType } from '../../../pages/MasterEditor';

type TaskConfigFormProps = {
  toggleShow?: () => void;
  handleSave?: (data: any) => Promise<boolean>;
  validationErrors?: FormErrorType[];
};

function TaskConfigForm({
  toggleShow,
  handleSave,
  validationErrors
}: TaskConfigFormProps) {
  const modules: any[] = useSelector(
    (state: RootState) => state.module.modules
  );
  console.log('modules list: ', modules);
  const [module, setModule] = useState<OptionType | null>(null);
  const [moduleIdError, setModuleIdError] = useState<string>('');
  const [moduleOptions, setModuleOptions] = useState<OptionType[]>([]);
  const [taskConfigName, setTaskConfigName] = useState<string>('');
  const [taskConfigNameError, setTaskConfigNameError] = useState<string>('');
  const [taskConfigDescription, setTaskConfigDescription] = useState('');
  const [taskConfigDescriptionError, setTaskConfigDescriptionError] =
    useState(null);
  useEffect(() => {
    clearErrors();
    getModuleList();
  }, []);

  useEffect(() => {
    if (validationErrors) {
      for (let index = 0; index < validationErrors.length; index++) {
        const element = validationErrors[index];
        if (element.field_id === 'name') {
          setTaskConfigNameError(element.error_message);
        }
      }
    }
  }, [validationErrors]);

  async function getModuleList() {
    const modList = Object.values(modules);
    const data: OptionType[] = [];
    modList.forEach((moduleRow: any) => {
      data.push({
        id: moduleRow._data.id,
        value: moduleRow._data.module_name,
        label: moduleRow._data.module_name
      });
    });

    setModuleOptions([...data]);
  } // get module list
  function toggleModal() {
    if (!toggleShow) {
      return;
    }
    toggleShow();
  }
  function clearErrors() {
    setModuleIdError('');
    setTaskConfigNameError('');
    setTaskConfigDescriptionError(null);
  }
  async function saveTaskConfig(): Promise<void> {
    clearErrors();
    let Error = false;

    if (!module) {
      setModuleIdError('Select a module');
      Error = true;
    }
    if (!taskConfigName || taskConfigName.trim() === '') {
      setTaskConfigNameError('Task Config name is required');
      Error = true;
    }
    if (taskConfigName.trim() !== taskConfigName) {
      setTaskConfigNameError(
        'Task Config name should not have the spaces at the start or end'
      );
      Error = true;
    }
    if (hasSpecialCharacters(taskConfigName)) {
      setTaskConfigNameError('Task Config name contains special characters');
      Error = true;
    }

    if (Error) {
      return;
    }
    const tasConfigData = {
      module_id: module?.id ?? '',
      name: taskConfigName,
      describe: taskConfigDescription
    };
    const result = await handleSave?.(tasConfigData);
    if (result) {
      toggleModal();
    }
  }

  return (
    <div className='row'>
      <div className='modal-header h4'>New Task Config</div>
      <Form className='smallForm'>
        <div className='form-body'>
          <Form.Group controlId='moduleId' className='row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>
                Module<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-8'>
              <SelectDropdown
                placeholder='Select Parent Module'
                options={moduleOptions}
                onChange={(v) => setModule(v as any)}
                value={module}
                style={{
                  control: (baseStyle) => ({
                    ...baseStyle,
                    height: '42px',
                    borderColor: '#ced4da'
                  }),
                  singleValue: (baseStyle) => ({
                    ...baseStyle,
                    color: '#495057',
                    fontSize: '14px'
                  }),
                  placeholder: (baseStyle) => ({
                    ...baseStyle,
                    color: '#74788d',
                    fontSize: '14px'
                  })
                }}
              />
              {moduleIdError && (
                <p className=' inline-error'>{moduleIdError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group controlId='taskConfigName' className='form-group row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>
                Task config name<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-8'>
              <Form.Control
                type='text'
                placeholder='Task config name'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setTaskConfigName(e.target.value);
                }}
                value={taskConfigName}
              />
              {taskConfigNameError && (
                <p className='inline-error'>{taskConfigNameError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group
            controlId='taskConfigDescription'
            className='form-group row'
          >
            <div className='col-sm-4 col-form-label'>
              <Form.Label>Description:</Form.Label>
            </div>
            <div className='col-sm-8'>
              <Form.Control
                type='text'
                placeholder='Page Description'
                onChange={(e) => {
                  setTaskConfigDescription(e.target.value);
                }}
                value={taskConfigDescription}
              />
            </div>

            {taskConfigDescriptionError ?? (
              <Form.Text className='text-muted'>
                {taskConfigDescriptionError}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        <div className='modal-footer'>
          <Button
            className='btn btn-sm'
            color='cancel'
            type='button'
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
          <Button
            className='btn btn-sm'
            color='edit'
            type='button'
            onClick={() => saveTaskConfig()}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default TaskConfigForm;
