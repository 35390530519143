import ComingSoon from '../pages/ComingSoon';
import MasterEditor from '../pages/MasterEditor';
import ProjectDocuments from '../pages/ProjectDocuments';
import DebugModules from '../pages/Debugging/modules';
import DebugTasks from '../pages/Debugging/tasks';
import DebugPages from '../pages/Debugging/pages';
import Publish from '../pages/Publish';

export const adminPortalLayoutRoutes = [
  { path: '*', element: ComingSoon },
  { path: 'editor/:pageType', element: MasterEditor },
  { path: 'project-documents', element: ProjectDocuments },
  { path: 'pub_dev', element: Publish },
  { path: 'admin/dump/modules', element: DebugModules },
  { path: 'admin/dump/tasks', element: DebugTasks },
  { path: 'admin/dump/pages', element: DebugPages }
];
