import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Autocomplete from '../../Autocomplete/Autocomplete';
import {
  DataDictionaryTableCollection,
  ProjectCollection
} from '@digitalworkflow/dwtranslateclient';
import { Button } from 'reactstrap';
import { hasSpecialCharacters } from '../../../utils/checkSpecialCharacters';
import { useSelector } from 'react-redux';
import { IGenericAnyObject } from '../../../config/types';
import { RootState } from '../../../redux/store';

export type DuplicateDataTableType = {
  tableName: string;
  projectId: string;
  group: string;
};

type DuplicateDataDictionaryFormProps = {
  toggleShow?: () => void;
  handleSave?: ({
    tableName,
    projectId,
    group
  }: DuplicateDataTableType) => void;
};

function DuplicateDataDictionaryForm({
  toggleShow,
  handleSave
}: DuplicateDataDictionaryFormProps) {
  const [duplicateDDTableTitle, setDuplicateDDTableTitle] = useState<string>(
    'Duplicate Data Dictionary'
  );
  const [tableName, setTableName] = useState<string>('');
  const [tableNameError, setTableNameError] = useState<any>(null);
  const [projectId, setProjectId] = useState<string>('');
  const [projectIdError, setProjectIdError] = useState<any>(null);

  const [group, setGroup] = useState<string>('');
  const [groupError, setGroupError] = useState<any>(null);
  const [groupOptions, setGroupOptions] = useState<any>([]);
  const [projectOptions, setProjectOptions] = useState<
    IGenericAnyObject[] | undefined
  >(undefined);
  const node2Duplicate = useSelector(
    (state: RootState) => state.event.node2Duplicate
  );

  const clearDataDictionaryErrors = () => {
    setTableNameError(null);
    setProjectIdError(null);
    setGroupError(null);
  }; // clear data dictionary errors

  useEffect(() => {
    clearDataDictionaryErrors();
    getProjectsList();
    getGroups();

    const popupCaption = node2Duplicate
      ? `Duplicate [${node2Duplicate}] Data Dictionary`
      : duplicateDDTableTitle;
    setDuplicateDDTableTitle(popupCaption);
  }, []);

  async function getGroups() {
    const groups = await DataDictionaryTableCollection.findAllGroups();
    const data: any[] = [];
    for (const group of groups) {
      data.push({ title: group });
    }
    setGroupOptions([...data]);
  }

  async function getProjectsList() {
    const projects = await ProjectCollection.getAvailableProjectsList();
    const allProjects = Object.values(projects).map(
      (project) => project[0]._data
    );

    const projList = Object.values(allProjects);
    const data: IGenericAnyObject[] = [];
    projList.forEach((projRow: any) => {
      data.push({
        id: projRow.project_id,
        name: projRow.name_en
      });
    });

    setProjectOptions([...data]);
  } // get projets list

  function toggleModal() {
    if (!toggleShow) {
      return;
    }
    toggleShow();
  }

  function saveDuplicateDataTable(): void {
    clearDataDictionaryErrors();
    let dderror = false;

    if (!tableName || tableName.trim() === '') {
      setTableNameError('Table name is required');
      dderror = true;
    }
    if (tableName.trim() !== tableName) {
      setTableNameError(
        'Table name should not have the spaces at the start or end'
      );
      dderror = true;
    }
    if (hasSpecialCharacters(tableName)) {
      setTableNameError('Table name contains special characters');
      dderror = true;
    }
    if (!projectId) {
      setProjectIdError('Project is required');
      dderror = true;
    }
    if (!group) {
      setGroupError('Group is required');
      dderror = true;
    }

    if (dderror) {
      return;
    }

    handleSave?.({
      tableName,
      projectId,
      group
    });
    toggleModal();
  }

  return (
    <div className='row'>
      <div className='modal-header h4'>{duplicateDDTableTitle}</div>
      <Form className='smallForm'>
        <div className='dictionary-body'>
          <Form.Group controlId='codedValue' className='form-group row'>
            <div className='col-sm-3 col-form-label'>
              <Form.Label>
                Table Name<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-9'>
              <Form.Control
                type='text'
                placeholder='Table Name'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setTableName(e.target.value);
                }}
                value={tableName}
              />
              {tableNameError && (
                <p className='inline-error'>{tableNameError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group controlId='projectId' className='row'>
            <div className='col-sm-3 col-form-label'>
              <Form.Label>
                Project<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-9'>
              <select
                id='projectId'
                name='projectId'
                className='form-select form-control'
                value={projectId}
                onChange={(e) => {
                  console.log('proj val: ', e.target.value);
                  setProjectId(e.target.value);
                }}
                onFocus={() => {
                  console.log('focus project');
                }}
                onBlur={() => {
                  console.log('on blur project');
                }}
              >
                <option value=''>Select Project</option>
                {projectOptions?.map((option, index) => (
                  <option
                    key={index}
                    value={option.id}
                    onClick={(e) => {
                      console.log('click project option: ', e, option, index);
                    }}
                  >
                    {option.name}
                  </option>
                ))}
              </select>
              {projectIdError && (
                <p className=' inline-error'>{projectIdError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group controlId='group' className='row'>
            <div className='col-sm-3 autoCompleteLabel'>
              Group<span className='text-red'>*</span>
            </div>
            <div className='col-sm-9'>
              <Autocomplete
                wrapperStyle={{ height: 'auto' }}
                onChange={(e) => {
                  setGroup(e.target.value);
                }}
                onSelect={(e) => {
                  setGroup(e.title);
                }}
                data={groupOptions}
              />
              {groupError && <p className='inline-error'>{groupError}</p>}
            </div>
          </Form.Group>
        </div>
        <div className='modal-footer form_modal_footer'>
          <Button
            className='btn btn-sm'
            color='cancel'
            type='button'
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
          <Button
            className='btn btn-sm'
            color='edit'
            type='button'
            onClick={() => saveDuplicateDataTable()}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default DuplicateDataDictionaryForm;
