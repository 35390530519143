import React, { useRef, useState, useEffect } from 'react';
// import Dropzone from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { Button } from 'reactstrap';

import style from './index.module.scss';
import { IUploadDocumentProps } from '../../config/types';

/**
 * Upload document component
 * @param {object} props for upload document component
 * @return {jsx} component
 */
const UploadDocument = ({
  showUpload = true,
  onDocumentUpload,
  onFileInput,
  getContainerHeight
}: IUploadDocumentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFile(acceptedFiles)
  });

  const [file, setFile] = useState<File>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  useEffect(() => {
    if (ref && ref.current) {
      getContainerHeight?.(ref.current.clientHeight);
    }
  }, [ref.current && ref.current.clientHeight]);

  /**
   * Select file handler
   * @param {object} button, paragraph or mouse event
   */
  const selectFile = (
    e: React.MouseEvent<HTMLButtonElement | HTMLParagraphElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (fileInput && fileInput.current) {
      fileInput.current.click();
    }
  };

  /**
   * File handler
   * @param {object} file array or list or null
   */
  const handleFile = (file: File[] | FileList | null) => {
    file && setFile(file[0]);
    file && onFileInput && onFileInput(file[0]);
  };

  /** Handles submit event */
  async function handleSubmit() {
    setIsUploading(true);
    file && (await onDocumentUpload(file));
    setIsUploading(false);
    clearSelf();
  }

  /** Set file value */
  function clearSelf() {
    setFile(undefined);
  }

  return (
    <div className='mt-2' ref={ref}>
      <section>
        <div {...getRootProps()}>
          <input
            {...getInputProps()}
            ref={fileInput}
            multiple={false}
            onChange={(e) => handleFile(e.target.files)}
          />
          <div className={style.dropContainer}>
            <p
              onClick={(
                e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
              ) => selectFile(e)}
            >
              {file ? file.name : 'Drop a file here...'}
            </p>
            <Button
              type='button'
              className='btn-sm'
              color='light'
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                selectFile(e)
              }
            >
              Browse
            </Button>
          </div>
        </div>
      </section>

      {showUpload && (
        <Button
          type='button'
          className='btn-sm mt-2'
          color='add'
          onClick={async () => await handleSubmit()}
          disabled={!file || isUploading}
        >
          Upload
          <i className='fa-regular fa-arrow-up-from-bracket ms-2' />
        </Button>
      )}
    </div>
  );
};

export default UploadDocument;
