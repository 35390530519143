/* eslint-disable camelcase */
// import React from 'react';
import {
  createGridMetaFromDocument,
  extractGridMetaFromTab,
  generateTab
} from '../../../utils';
import {
  getDefaultColumns,
  getMetaGridDefaultCol,
  getModuleSectionColumns,
  getModuleSectionMetaGridCols
} from '../../../config/constants/columnDefinitions';
import {
  ModuleSection,
  ModuleSectionCollection,
  ModuleSectionDocumentType
} from '@digitalworkflow/dwtranslateclient';
import { portalInfo } from '../../../config/constants/portalInfo';
import {
  ITabParams,
  CellValueChangedEvent,
  ColDef,
  ISaveModuleSectionProps,
  GridApi,
  GridMetaData
} from '../../../config/types';
import { builderPageTypes } from '../../../config/constants/builderPageTypes';
import { updateCurrentGridDetail } from '../../CurrentGridDetail/updateCurrentGridDetail';
import { GlobalHashHelper } from '../../../utils/HashHelper';

/**
 * @return Promise<boolean>
 * @returns true - if ModuleSection is added successfully
 * @returns false - if ModuleSection is not added successfully
 */
export async function addModuleSection(
  data?: ISaveModuleSectionProps,
  e?: CellValueChangedEvent
): Promise<any | undefined> {
  let response: any = {};
  if (e) {
    response = await ModuleSectionCollection.addModuleSection({
      module_id: e.data.module_id,
      section_name: e.data.section_name,
      section_icon: e.data.section_icon,
      wg_canseesection: e.data.wg_canseesection,
      status_cansee: e.data.status_cansee,
      wg_editors: e.data.wg_editors,
      wg_monitor: e.data.wg_monitor,
      show_task_notes: e.data.show_task_notes,
      page_name: e.data.page_name
    });
    e.data.id = response.record.data.id;
  } else if (data) {
    response = await ModuleSectionCollection.addModuleSection({
      module_id: data.module_id,
      section_name: data.section_name,
      section_icon: data?.section_icon,
      wg_canseesection: data?.wg_canseesection,
      status_cansee: data?.status_cansee,
      wg_editors: data?.wg_editors,
      wg_monitor: data?.wg_monitor,
      show_task_notes: data?.show_task_notes,
      page_name: data?.page_name
    });
  } else {
    console.log('neither event nor form called addModuleSection()');
  }
  return response;
}

/**
 * @return Promise<boolean>
 * @returns true - if modulesection is updated successfully
 * @returns false - if modulesection is not updated successfully
 */
async function updateModuleSection(
  e: CellValueChangedEvent
): Promise<ModuleSection | false> {
  const {
    section_name,
    section_icon,
    wg_canseesection,
    status_cansee,
    wg_editors,
    wg_monitor,
    show_task_notes,
    page_name
  } = e.data;
  // await ModuleSectionCollection.deleteModuleSection
  const response2 = deleteModuleSection(e.data.id);
  const response = await ModuleSectionCollection.updateModuleSection(
    e.data.id,
    {
      section_name,
      section_icon,
      wg_canseesection,
      status_cansee,
      wg_editors,
      wg_monitor,
      show_task_notes,
      page_name
    }
  );
  console.log('update module section: ', e, response2, response);
  return response && response.saveResults.did_save && response.record;
}

/**
 * @return Promise<boolean>
 * @returns true - if module section is deleted successfully
 * @returns false - if module section is not deleted successfully
 */
async function deleteModuleSection(e: CellValueChangedEvent): Promise<boolean> {
  // const     = await ModuleSectionCollection.deleteModuleSection(e.data.id);
  // return modulesection && true;
  console.log('delete module section: ', e);
  return true;
}

export async function openModuleSectionsTab({
  ref,
  data: moduleSections,
  groups,
  rows,
  title,
  firstTab = true,
  gridReferences,
  updateMetaData,
  fetchData,
  setSelectedRows,
  setSelectedRowIndex,
  setCurrentEventAPI,
  setRowSelectedFlag,
  setTabsRef,
  updateData
}: ITabParams) {
  if (!ref) {
    console.log('no ref provided, returning...');
    return;
  }

  const defaultColDef: ColDef = getDefaultColumns(
    portalInfo.agGridConfig.sortableColumn
  );

  const columnDefs: ColDef[] = getModuleSectionColumns(updateData);
  let data: Partial<ModuleSectionDocumentType>[] = [];
  // let key: string = '';

  // console.log('module sections utils: ', moduleSections, groups);

  if (firstTab) {
    if (!moduleSections && !groups?.length) {
      console.log('moduleSections || groups empty, returning...');
      return;
    }

    moduleSections.forEach((modSection: any) => {
      if (modSection.data) {
        console.log('first tab: ', modSection.data);
        data.push(modSection.data);
        // eslint-disable-next-line no-useless-return
        return;
      }
    });
    title = GlobalHashHelper.hasOption('tab')
      ? GlobalHashHelper.getOption('tab')
      : data[0].section_name;
  } else {
    if (!rows || !title) {
      console.log('rows||title empty, returning...');
      return;
    }

    // key = title;
    data = [...rows];
  }

  function onUpdateAPI(api: GridApi) {
    if (api.getSelectedRows() && api.getSelectedRows().length > 0) {
      setSelectedRows?.(api.getSelectedRows());
      setRowSelectedFlag(true);
    }
    setCurrentEventAPI(api);
  }
  /**
   * last row edit - creates new row, add lookup in DB
   * other row edit - edits current row, edit lookup in DB
   * delete all cells data - deletes object from DB
   * @param e event triggred on cell value change
   */
  async function onCellValueChanged(
    e: CellValueChangedEvent
  ): Promise<false | ModuleSection> {
    console.log('-- mod section change: ', e.data);

    let isDeleted: false | ModuleSection = false;
    setSelectedRows([e.data]);
    if (!e.data.id) {
      // insertEmptyRow(
      //   builderPageTypes.moduleSections,
      //   e.data.section_name,
      //   e.data.module_id,
      //   e.api
      // );
      await addModuleSection(undefined, e);
    } else {
      const { section_name, section_icon, page_name } = e.data;
      if (
        !(page_name?.length || section_name?.length || section_icon?.length)
      ) {
        // await deleteModuleSection(e);
        // isDeleted = true;
      } else isDeleted = await updateModuleSection(e);
    }
    return isDeleted;
  }

  async function localCellValueChanged(e: CellValueChangedEvent) {
    const isUpdated = await onCellValueChanged(e);
    if (!isUpdated || !ref) return;

    fetchData();

    const newGridMeta: GridMetaData = createGridMetaFromDocument(isUpdated);

    updateCurrentGridDetail({
      type: builderPageTypes.moduleSections,
      ref,
      data: newGridMeta
    });
  }

  const moduleSectionData = await ModuleSectionCollection.findById(
    data[0].id ?? ''
  );
  const gridMetaData: GridMetaData | undefined = !moduleSectionData
    ? undefined
    : createGridMetaFromDocument(moduleSectionData);

  const metaGridCols = getModuleSectionMetaGridCols();
  const metaGridDefaultCol = getMetaGridDefaultCol();

  const tab = generateTab({
    id: title ?? '',
    title: title ?? '',
    data: rows ?? [],
    group: builderPageTypes.moduleSections,
    theme: portalInfo.agGridConfig.theme,
    pagination: portalInfo.agGridConfig.pagination,
    defaultColDef,
    columnDefs,
    gridReferences,
    gridMetaData,
    metaGridCols,
    metaGridDefaultCol,
    onCellValueChanged: (e: CellValueChangedEvent) => localCellValueChanged(e),
    setSelectedRowIndex,
    onUpdateAPI: (api: GridApi) => onUpdateAPI(api),
    setTabsRef,
    onMetaCellValueChanged: (e: CellValueChangedEvent) => {
      updateMetaData && updateMetaData(e.data);
    },
    dockRef: ref
  });

  console.log(groups, rows, title);

  if (firstTab) {
    ref.updateTab('Loading', tab, true);

    updateCurrentGridDetail({
      type: builderPageTypes.moduleSections,
      ref,
      data: extractGridMetaFromTab(ref, title + '_tab')
    });
  } else {
    ref.dockMove(tab, 'grid_dock', 'middle');
  }
}
