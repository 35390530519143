import React, { useRef, useEffect, useState } from 'react';
import cls from './CurrentLineField.module.scss';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

/** Props for Current Line Field component */
interface CurrentLineFieldProps {
  value: string | boolean;
  label: string;
  onChange?: (s: string) => void;
  onBlur?: (s: string) => void;
  onChangeFlag?: (s: boolean) => void;
  inputStyle?: React.CSSProperties;
  disabled?: boolean;
  showIcon?: boolean;
  isLast?: boolean;
}

/**
 * Current Line Field component
 * @param {object} props for current line field component
 * @return {jsx} component
 */
const CurrentLineField: React.FC<CurrentLineFieldProps> = ({
  value,
  label,
  onChange,
  onBlur,
  onChangeFlag,
  inputStyle,
  disabled = false,
  showIcon = true,
  isLast = false
}) => {
  const labelRef = useRef<any>(null);
  const [hasOver, setHasOver] = useState<boolean>(false);
  const [fieldValue, setFieldValue] = useState(value);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (labelRef.current.scrollWidth > labelRef.current.clientWidth)
      setHasOver(true);
  }, []);

  return (
    <div className={cls.wrapper}>
      <CustomTooltip
        target={labelRef.current}
        show={hasOver && showTooltip}
        placement={isLast ? 'top' : 'bottom'}
      >
        {label}
      </CustomTooltip>
      <span
        ref={labelRef}
        className={cls.label}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        style={{ ...(hasOver && showTooltip ? { cursor: 'pointer' } : {}) }}
      >
        {label}
      </span>
      <div className={cls.inputWrapper}>
        {typeof fieldValue === 'boolean' ? (
          <div className='grid-switch-wrapper' style={{ height: '30px' }}>
            <label className='switch'>
              <input
                type='checkbox'
                checked={fieldValue}
                onChange={(e) => {
                  setFieldValue(e.target.checked);
                  onChangeFlag && onChangeFlag(e.target.checked);
                }}
                disabled={disabled}
              />
              <span className='slider round' />
            </label>
          </div>
        ) : (
          <>
            {showIcon && <i className={`${cls.icon} fa fa-border-all`} />}
            <input
              className={cls.input}
              style={inputStyle}
              value={fieldValue}
              onBlur={() => onBlur && onBlur(fieldValue)}
              disabled={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(e.target.value);
                console.log(onChange);
                onChange && onChange(e.target.value);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                e.key === 'Escape' && setFieldValue(value);
                (e.key === 'Enter' || e.key === 'Escape') &&
                  e.currentTarget.blur();
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default CurrentLineField;
