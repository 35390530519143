// DropdownPortal.tsx
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import '.';

interface DropdownPortalProps {
  children: ReactNode; // Defines the type for children
}

const DropdownPortal: React.FC<DropdownPortalProps> = ({ children }) => {
  const portalRoot = document.getElementById('portal-root') as HTMLElement;
  return ReactDOM.createPortal(children, portalRoot);
};

export default DropdownPortal;
