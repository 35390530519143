import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  FC,
  memo
} from 'react';
import { ICellEditor } from 'ag-grid-community';
import DropdownPortal from './DropdownPortal';
import CustomTooltip from '../../CustomTooltip/CustomTooltip';

import 'bootstrap/dist/css/bootstrap.min.css';
import './DropdownEditor.scss';

export interface OptionType {
  value: string;
  label: string;
  id: string;
}

export interface DropdownEditorProps {
  field: string;
  value: string | string[];
  options: OptionType[];
  multiSelect?: boolean;
  node: any;
  updateData: (id: string, data: any, rowIndex: number) => void;
}

interface DropdownOptionProps {
  option: OptionType;
  onSelect: (option: OptionType) => void;
  isSelected: boolean;
  multiSelect: boolean;
}

const DropdownEditor = forwardRef<ICellEditor, DropdownEditorProps>(
  (
    {
      field,
      value: initialValues,
      options,
      node,
      updateData,
      multiSelect = false
    },
    ref
  ) => {
    const [value, setValue] = useState<string | string[]>(
      multiSelect ? initialValues || [] : initialValues || ''
    );
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [filteredOptions, setFilteredOptions] =
      useState<OptionType[]>(options);
    const [searchWord, setSearchWord] = useState<string>('');
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const optionssRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const IsCurrentlyOpened = useRef<boolean>(false);
    const valueRef = useRef<string | string[]>(initialValues);

    useEffect(() => {
      const currentValue = multiSelect
        ? initialValues || []
        : initialValues || '';
      if (JSON.stringify(valueRef.current) !== JSON.stringify(currentValue)) {
        valueRef.current = currentValue;
      }
    }, [initialValues, multiSelect]);

    useEffect(() => {
      const attachScrollListeners = () => {
        // Traverse up the DOM tree to find scrollable parents
        const agViews = document.getElementsByClassName('ag-body-viewport');
        const agViewsArray = Array.from(agViews);

        for (const agViews of agViewsArray) {
          agViews.addEventListener('scroll', handleScroll, true);
        }

        return agViewsArray;
      };

      const scrollableParents = attachScrollListeners();

      return () => {
        scrollableParents.forEach((parent) => {
          parent.removeEventListener('scroll', handleScroll, true);
        });
      };
    }, [isDropdownOpen]);

    useEffect(() => {
      window.addEventListener('resize', handleResize, true);
      return () => {
        document.removeEventListener('click', handleDocumentClick, true);
        window.removeEventListener('resize', handleResize, true);
      };
    }, []);

    useImperativeHandle(ref, () => ({
      getValue: () => value,
      isPopup() {
        return true;
      }
    }));

    const closeDropdown = () => {
      node.data[field] = valueRef.current;
      updateData(
        node.data.id,
        {
          [field]: valueRef.current
        },
        node.rowIndex
      );
      IsCurrentlyOpened.current = false;
      setIsDropdownOpen(false);
    };

    const handleScroll = () => {
      if (isDropdownOpen) {
        closeDropdown();
      }
    };

    const handleResize = () => {
      setIsDropdownOpen(false);
    };

    const handleDocumentClick = async (event: MouseEvent) => {
      const target = event.target as HTMLInputElement;
      if (
        target.name !== 'search' &&
        target.name !== 'multi-checkbox' &&
        target.name !== 'select-all' &&
        target.innerText !== '(Select All)' &&
        IsCurrentlyOpened.current &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !target.classList.contains('dropdown-option')
      ) {
        console.log('here');
        closeDropdown();
      }
    };

    const handleOptionSelect = (option: OptionType) => {
      let newValue;
      if (multiSelect) {
        newValue = Array.isArray(valueRef.current) ? [...valueRef.current] : [];
        if (newValue.includes(option.value)) {
          newValue = newValue.filter((v) => v !== option.value);
        } else {
          newValue.push(option.value);
        }
        setIsSelectAll(newValue.length === filteredOptions.length);
      } else {
        newValue = value === option.value ? '' : option.value;
      }
      setValue(newValue);
      valueRef.current = newValue;
      !multiSelect && isDropdownOpen && closeDropdown();
    };

    const toggleDropdown = () => {
      setSearchWord('');
      setFilteredOptions(options);
      if (multiSelect) {
        setIsSelectAll(value.length === filteredOptions.length);
      }
      if (!isDropdownOpen) {
        document.addEventListener('click', handleDocumentClick, true);
      } else {
        document.removeEventListener('click', handleDocumentClick, true);
      }
      if (isDropdownOpen) {
        closeDropdown();
      } else {
        IsCurrentlyOpened.current = !isDropdownOpen;
        setIsDropdownOpen(!isDropdownOpen);
      }
    };

    const renderSelectedValue = () => {
      if (multiSelect) {
        if (Array.isArray(value) && value.length > 0) {
          return (
            value
              .map((v) => options.find((option) => option.value === v)?.label)
              .filter((label) => label)
              .join(', ') || 'Select'
          );
        } else {
          return 'Select';
        }
      } else {
        return value
          ? options.find((option) => option.value === value)?.label
          : 'Select';
      }
    };

    const isOptionSelected = (optionValue: string) => {
      return multiSelect
        ? Array.isArray(value) && value.includes(optionValue)
        : value === optionValue;
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const search = e.target.value;
      setSearchWord(search);
      const result = search
        ? options.filter((option: OptionType) =>
            option.label
              .toLowerCase()
              .trim()
              .includes(search.toLowerCase().trim())
          )
        : options;
      if (Array.isArray(value)) {
        setIsSelectAll(result.every((option) => value.includes(option.value)));
      }
      setFilteredOptions(result);
    };

    const handleSelectAll = () => {
      const selected = filteredOptions.map(
        (option: OptionType) => option.value
      );
      if (Array.isArray(value)) {
        if (!isSelectAll) {
          setIsSelectAll(true);
          const addValues = selected.filter(
            (optionValue) => !value.includes(optionValue)
          );
          valueRef.current = [...value, ...addValues];
          setValue((prev) => [...prev, ...addValues]);
        } else {
          setIsSelectAll(false);
          const removedValues = value.filter(
            (optionValue) => !selected.includes(optionValue)
          );
          valueRef.current = removedValues;
          setValue(removedValues);
        }
      }
    };

    const checkEmpty = () => {
      if (multiSelect) {
        return value.length !== 0;
      } else {
        return value !== '';
      }
    };

    const checkOverflow = () => {
      return (
        (optionssRef.current?.scrollWidth ?? 0) >
        (optionssRef.current?.clientWidth ?? 0)
      );
    };

    const setAutoEditorStyle = () => {
      const dropdownElement = dropdownRef.current;
      if (dropdownElement) {
        const { top, left, bottom } = dropdownElement.getBoundingClientRect();
        const width = dropdownElement.offsetWidth;
        if (bottom + 200 > window.innerHeight) {
          return {
            bottom: window.innerHeight - top,
            left: left,
            width: width
          };
        } else {
          return {
            top: bottom,
            left: left,
            width: width
          };
        }
      }
      return {};
    };

    return (
      <div className='dropdown-container' ref={dropdownRef}>
        {options.length > 0 ? (
          <>
            <div
              ref={optionssRef}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onClick={toggleDropdown}
              className='dropdown-selected'
            >
              {renderSelectedValue()}
            </div>
            <CustomTooltip
              show={showTooltip && checkEmpty() && checkOverflow()}
              target={optionssRef.current}
            >
              {isSelectAll ? 'All' : renderSelectedValue()}
            </CustomTooltip>
          </>
        ) : (
          <div className='dropdown-selected dropdown-selected-disabled'>
            No values to show
          </div>
        )}
        {isDropdownOpen && options.length > 0 && (
          <DropdownPortal>
            <div className='dropdown-list' style={setAutoEditorStyle()}>
              <div className='dropdown-header'>
                <input
                  type='text'
                  name='search'
                  placeholder='Search...'
                  className='search-input'
                  value={searchWord}
                  onChange={handleSearch}
                  autoComplete='off'
                />
                {multiSelect && filteredOptions.length > 0 && (
                  <div className='select-all' onClick={handleSelectAll}>
                    <input
                      type='checkbox'
                      name='select-all'
                      checked={isSelectAll}
                      readOnly
                    />
                    <span className='m-1'>(Select All)</span>
                  </div>
                )}
              </div>
              <ul className='option-container'>
                {filteredOptions.map((option) => (
                  <DropdownOption
                    key={option.id || option.value}
                    option={option}
                    onSelect={handleOptionSelect}
                    isSelected={isOptionSelected(option.value)}
                    multiSelect={multiSelect}
                  />
                ))}
              </ul>
            </div>
          </DropdownPortal>
        )}
      </div>
    );
  }
);

const DropdownOption: FC<DropdownOptionProps> = memo(
  ({ option, onSelect, isSelected, multiSelect }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const optionsRef = useRef<HTMLLIElement>(null);
    const checkOverFlow = () => {
      return (
        (optionsRef.current?.scrollWidth ?? 0) >
        (optionsRef.current?.clientWidth ?? 0)
      );
    };
    return (
      <>
        <li
          onClick={() => onSelect(option)}
          role='option'
          ref={optionsRef}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          aria-selected={isSelected ? 'true' : 'false'}
          tabIndex={0}
          className={`dropdown-option ${isSelected ? 'selected' : ''}`}
        >
          {multiSelect && (
            <input
              type='checkbox'
              name='multi-checkbox'
              checked={isSelected}
              readOnly
            />
          )}{' '}
          {option.label}
        </li>
        <CustomTooltip
          show={showTooltip && checkOverFlow()}
          target={optionsRef.current}
        >
          {option.label}
        </CustomTooltip>
      </>
    );
  }
);

export default DropdownEditor;
