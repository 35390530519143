import React from 'react';
// import { ICellEditor } from 'ag-grid-community';
import './GridSwitch.scss';
// import DropdownPortal from './DropdownPortal';
import {
  ModuleDocumentType,
  ModuleSectionDocumentType,
  TaskConfigDocumentType,
  WorkflowStateDocumentType
} from '@digitalworkflow/dwtranslateclient';

export interface GridSwitchProps {
  field: string;
  node: any;
  label?: string;
  updateData: (
    id: string,
    data: Partial<
      | ModuleDocumentType
      | WorkflowStateDocumentType
      | ModuleSectionDocumentType
      | TaskConfigDocumentType
    >
  ) => void;
}

function GridSwitch({ field, node, label, updateData }: GridSwitchProps) {
  function handleSwitchChange(e: React.ChangeEvent<HTMLInputElement>) {
    updateData(node.data.id, { [field]: e.target.checked });
  }

  return (
    <div className='grid-switch-wrapper'>
      <label className='label-switch'>{label}</label>

      <label className='switch'>
        <input
          type='checkbox'
          defaultChecked={node.data[field]}
          onChange={(e) => handleSwitchChange(e)}
        />
        <span className='slider round' />
      </label>
    </div>
  );
}

export default GridSwitch;
