import DockLayout from 'rc-dock';
import { GridMetaData, IModuleSectionGridUpdateData } from '../../config/types';
import {
  LogicEngineDataDateTime,
  ModuleSection,
  ModuleSectionCollection,
  ValidationResults
} from '@digitalworkflow/dwtranslateclient';
import { generateTab } from '../generateTab';
import { builderPageTypes } from '../../config/constants/builderPageTypes';

export async function handleUpdateModuleSection(
  ref: DockLayout | null | undefined,
  id: string,
  data: IModuleSectionGridUpdateData,
  reduxUpdater: () => void
) {
  let saveResults: ValidationResults = {} as ValidationResults;

  // update document in db
  const { oldModuleSection, isUpdated, newModuleSection } =
    await _updateModuleSectionInDB(id, data);

  saveResults =
    (isUpdated && isUpdated.saveResults.validation) ||
    ({} as ValidationResults);

  if (isUpdated && isUpdated.saveResults.did_save) {
    // if document is updated in db, update redux
    reduxUpdater();

    // if ref provided, find and update currently opened editor tab and grid
    if (ref) {
      // find tab
      const tab: any = oldModuleSection
        ? ref.find(oldModuleSection.data.section_name + '_tab')
        : false;
      if (tab) {
        // update grid meta, data(if applicable), and tab title(if applicable)
        const oldTabProps = (tab as any).content.props.children.props.children;
        newModuleSection &&
          _updateModuleSectionTabAndGrid(
            newModuleSection,
            oldTabProps,
            ref,
            saveResults
          );
      }
    }
  }

  return saveResults;
}

async function _updateModuleSectionInDB(
  id: string,
  data: IModuleSectionGridUpdateData
) {
  const oldModuleSection = await ModuleSectionCollection.findById(id);

  const isUpdated = await ModuleSectionCollection.updateModuleSection(id, {
    section_name: data.name
  });

  const newModuleSection = await ModuleSectionCollection.findById(id);

  return { oldModuleSection, isUpdated, newModuleSection };
}

async function _updateModuleSectionTabAndGrid(
  newModuleSection: ModuleSection,
  tabProps: any,
  ref: DockLayout,
  saveResults: ValidationResults
) {
  const newGridMeta: GridMetaData = {
    createBy: newModuleSection?.data.create_by ?? '',
    updateBy: newModuleSection?.data.updated_by ?? '',
    createDt:
      newModuleSection?.data.create_dt ?? ({} as LogicEngineDataDateTime),
    updateDt:
      newModuleSection?.data.updated_dt ?? ({} as LogicEngineDataDateTime),
    moduleSectionName: newModuleSection?.data.section_name,
    documentId: newModuleSection?.data.id ?? ''
  };

  const tabUpdated = ref.updateTab(
    tabProps[1].props.children.props.id + '_tab',
    generateTab({
      ...tabProps[1].props.children.props,
      gridMetaData: newGridMeta,
      id: newModuleSection?.data.section_name,
      title: newModuleSection?.data.section_name ?? '',
      data: [newModuleSection.data],
      group: builderPageTypes.moduleSections,
      metaGridCols: tabProps[0].props.children.props.columnDefs,
      metaGridDefaultCol: tabProps[0].props.children.props.defaultColDef,
      onMetaCellValueChanged:
        tabProps[0].props.children.props.onCellValueChanged
    })
  );

  if (!tabUpdated) {
    saveResults.addError('updateTab', 'failed to update opened tab');
  }
}
