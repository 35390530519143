import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import '../Forms.css';
import { Button } from 'reactstrap';
import {
  ModuleCollection,
  WorkflowStateDocumentType
} from '@digitalworkflow/dwtranslateclient';
import { hasSpecialCharacters } from '../../../utils/checkSpecialCharacters';
import { FormErrorType } from '../../../pages/MasterEditor';
import { OptionType } from '../../../config/types';
import { SelectDropdown } from '@digitalworkflow/dwreactcommon';

type WorkflowStatesFormProps = {
  toggleShow?: () => void;
  handleSave?: (data: Partial<WorkflowStateDocumentType>) => Promise<boolean>;
  validationErrors?: FormErrorType[];
};

function WorkflowStatesForm({
  toggleShow,
  handleSave,
  validationErrors
}: WorkflowStatesFormProps) {
  const [workflowStateName, setWorkflowStateName] = useState<string>('');
  const [workflowStateNameError, setWorkflowStateNameError] =
    useState<any>(null);

  const [workflowStateFullName, setWorkflowStateFullName] =
    useState<string>('');
  const [workflowStateFullNameError, setWorkflowStateFullNameError] =
    useState<any>(null);

  const [workflowStateCode, setWorkflowStateCode] = useState<string>('');
  const [workflowStateCodeError, setWorkflowStateCodeError] =
    useState<any>(null);

  const [workflowStateDescription, setWorkflowStateDescription] =
    useState<string>('');
  const [workflowStateDescriptionError, setWorkflowStateDescriptionError] =
    useState<any>(null);

  const [parent, setParent] = useState<OptionType | null>(null);
  const [parentError, setParentError] = useState<any>(null);

  const [parentOptions, setParentOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    setWorkflowStateNameError(null);
    setWorkflowStateFullNameError(null);
    setWorkflowStateCodeError(null);
    setWorkflowStateDescriptionError(null);
    setParentError(null);
    getParents();
  }, []);

  useEffect(() => {
    if (validationErrors) {
      for (let index = 0; index < validationErrors.length; index++) {
        const element = validationErrors[index];
        if (element.field_id === 'name') {
          setWorkflowStateNameError(element.error_message);
        }
      }
    }
  }, [validationErrors]);

  async function getParents() {
    const modules = await ModuleCollection.findAllModules(undefined, false);
    const data: any[] = [];
    for (const module of modules) {
      data.push({
        value: module.data.module_name,
        label: module.data.module_name,
        id: module.data.id
      });
    }
    setParentOptions([...data]);
  }

  function toggleModal() {
    if (!toggleShow) {
      return;
    }
    toggleShow();
  }

  const clearErrors = () => {
    setWorkflowStateNameError(null);
    setWorkflowStateFullNameError(null);
    setWorkflowStateCodeError(null);
    setWorkflowStateDescriptionError(null);
    setParentError(null);
  }; // clear errors

  async function saveModule(): Promise<void> {
    clearErrors();
    let hasError = false;

    if (!workflowStateCode || workflowStateCode.trim() === '') {
      setWorkflowStateCodeError('Field Required');
      hasError = true;
    }
    if (workflowStateCode.trim() !== workflowStateCode) {
      setWorkflowStateCodeError(
        'Field should not have the spaces at the start or end'
      );
      hasError = true;
    }
    if (!workflowStateDescription) {
      setWorkflowStateDescriptionError('Field Required');
      hasError = true;
    }
    if (!workflowStateName || workflowStateName.trim() === '') {
      setWorkflowStateNameError('Field Required');
      hasError = true;
    }
    if (hasSpecialCharacters(workflowStateName)) {
      setWorkflowStateNameError('Field contains special characters');
      hasError = true;
    }
    if (!workflowStateFullName || workflowStateFullName.trim() === '') {
      setWorkflowStateFullNameError('Field Required');
      hasError = true;
    }
    if (!parent) {
      setParentError('Option Required');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const result = await handleSave?.({
      name: workflowStateName,
      full_name: workflowStateFullName,
      code: workflowStateCode,
      description: workflowStateDescription,
      module_id: parent?.id ?? ''
    });

    if (result) {
      toggleModal();
    }
  }

  return (
    <div className='row'>
      <div className='modal-header h4'>New Workflow State</div>
      <Form className='smallForm'>
        <div className='form-body'>
          <Form.Group controlId='code' className='form-group row'>
            <div className='col-sm-3 col-form-label'>
              <Form.Label>
                Code<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-9'>
              <Form.Control
                type='text'
                placeholder='ps_review'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setWorkflowStateCode(e.target.value);
                }}
                value={workflowStateCode}
              />
              {!!workflowStateCodeError?.length && (
                <p className='inline-error'>{workflowStateCodeError}</p>
              )}
            </div>
          </Form.Group>

          <Form.Group controlId='name' className='form-group row'>
            <div className='col-sm-3 col-form-label'>
              <Form.Label>
                Short Name<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-9'>
              <Form.Control
                type='text'
                placeholder='PS Review'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setWorkflowStateName(e.target.value);
                }}
                value={workflowStateName}
              />
              {!!workflowStateNameError?.length && (
                <p className='inline-error'>{workflowStateNameError}</p>
              )}
            </div>
          </Form.Group>

          <Form.Group controlId='fullName' className='form-group row'>
            <div className='col-sm-3 col-form-label'>
              <Form.Label>
                Full Name<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-9'>
              <Form.Control
                type='text'
                placeholder='Property Services Review'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setWorkflowStateFullName(e.target.value);
                }}
                value={workflowStateFullName}
              />
              {!!workflowStateFullNameError?.length && (
                <p className='inline-error'>{workflowStateFullNameError}</p>
              )}
            </div>
          </Form.Group>

          <Form.Group controlId='description' className='form-group row'>
            <div className='col-sm-3 col-form-label'>
              <Form.Label>
                Description<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-9'>
              <Form.Control
                type='text'
                placeholder='What to do during this state'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setWorkflowStateDescription(e.target.value);
                }}
                value={workflowStateDescription}
              />
              {!!workflowStateDescriptionError?.length && (
                <p className='inline-error'>{workflowStateDescriptionError}</p>
              )}
            </div>
          </Form.Group>

          <Form.Group controlId='parent' className='row'>
            <div className='col-sm-3 autoCompleteLabel'>
              Parent Module<span className='text-red'>*</span>
            </div>
            <div className='col-sm-9'>
              <SelectDropdown
                placeholder='Select Parent Module'
                options={parentOptions}
                onChange={(v) => setParent(v as any)}
                value={parent}
                style={{
                  control: (baseStyle) => ({
                    ...baseStyle,
                    height: '42px',
                    borderColor: '#ced4da'
                  }),
                  singleValue: (baseStyle) => ({
                    ...baseStyle,
                    color: '#495057',
                    fontSize: '14px'
                  }),
                  placeholder: (baseStyle) => ({
                    ...baseStyle,
                    color: '#74788d',
                    fontSize: '14px'
                  })
                }}
              />
              {!!parentError?.length && (
                <p className='inline-error'>{parentError}</p>
              )}
            </div>
          </Form.Group>
        </div>

        <div className='modal-footer'>
          <Button
            className='btn btn-sm'
            color='cancel'
            type='button'
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
          <Button
            className='btn btn-sm'
            color='edit'
            type='button'
            onClick={() => saveModule()}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default WorkflowStatesForm;
