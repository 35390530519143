import React, { useRef, useState } from 'react';
import cls from './NormalCell.module.scss';
import CustomTooltip from '../../CustomTooltip/CustomTooltip';

interface NormalCellProps {
  value: string;
}
const NormalCell = ({ value }: NormalCellProps) => {
  const cellRef = useRef<HTMLDivElement>(null);
  const [setShowTooltip, setSetShowTooltip] = useState<boolean>(false);
  const checkOverflow = () => {
    return (
      (cellRef.current?.scrollWidth ?? 0) > (cellRef.current?.clientWidth ?? 0)
    );
  };
  return (
    <div>
      <div
        ref={cellRef}
        className={cls.cell}
        onMouseEnter={() => setSetShowTooltip(true)}
        onMouseLeave={() => setSetShowTooltip(false)}
      >
        {value}
      </div>
      <CustomTooltip
        target={cellRef.current}
        show={setShowTooltip && checkOverflow()}
      >
        {value}
      </CustomTooltip>
    </div>
  );
};

export default NormalCell;
