import React from 'react';
import { BoxData } from 'rc-dock';
import LoadingScreen from '../../components/LoadingScreen';

export const Browser: BoxData = {
  id: 'meta_dock',
  size: 25,
  mode: 'vertical',
  children: [
    {
      id: 'browser_dock',
      panelLock: { panelStyle: 'main' },
      tabs: [
        {
          id: 'groups_tab',
          title: 'Loading',
          content: <LoadingScreen comment='Loading' />
        },
        {
          id: 'current_tab',
          title: 'Loading',
          content: <LoadingScreen comment='Loading' />
        },
        {
          id: 'history_tab',
          title: 'Loading',
          content: <LoadingScreen comment='Loading' />
        }
      ]
    },
    {
      id: 'line_dock',
      panelLock: { panelStyle: 'main' },
      tabs: [
        {
          id: 'current_line_tab',
          title: 'Loading',
          content: <LoadingScreen comment='Loading' />
        }
      ]
    }
  ]
};
