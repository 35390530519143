import React from 'react';
import cls from './CurrentLineDetail.module.scss';

/** Props for Current Line detail */
interface CurrentLineDetailProps {
  type?: 'lookup' | 'dataDictionary' | 'page';
  children: React.ReactNode;
  className?: string;
}

/**
 * Current Line Detail component
 * @param {object} props for current line detail component
 * @return {jsx} component
 */
const CurrentLineDetail: React.FC<CurrentLineDetailProps> = ({
  className,
  children
}) => {
  return (
    <div className={`${cls.container} ${className}`}>
      <div className={cls.wrapper}>{children}</div>
    </div>
  );
};
export default CurrentLineDetail;
