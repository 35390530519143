/* eslint-disable camelcase */
// import React from 'react';
import {
  createGridMetaFromDocument,
  extractGridMetaFromTab,
  generateTab
} from '../../../utils';
import {
  getDefaultColumns,
  getMetaGridDefaultCol,
  getTaskConfigColumns,
  // getTaskConfigDetailsCol,
  getTaskConfigMetaGridCols
} from '../../../config/constants/columnDefinitions';
import {
  TaskConfig,
  TaskConfigCollection,
  TaskConfigDocumentType
} from '@digitalworkflow/dwtranslateclient';
import { portalInfo } from '../../../config/constants/portalInfo';
import {
  ITabParams,
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridMetaData
} from '../../../config/types';
import { builderPageTypes } from '../../../config/constants/builderPageTypes';
import { updateCurrentGridDetail } from '../../CurrentGridDetail/updateCurrentGridDetail';
import { GlobalHashHelper } from '../../../utils/HashHelper';

/**
 * @return Promise<boolean>
 * @returns true - if lookup is updated successfully
 * @returns false - if lookup is not updated successfully
 */
async function updateTaskConfigs(
  id: string,
  data: TaskConfigDocumentType
): Promise<TaskConfig | false> {
  const res = await TaskConfigCollection.updateTaskConfig(id, data);
  return res && res.saveResults.did_save && res.record;
}

export async function openTaskConfigsTab({
  ref,
  data: taskConfigs,
  // groups,
  rows,
  title,
  firstTab = true,
  gridReferences,
  updateMetaData,
  fetchData,
  setSelectedRows,
  setSelectedRowIndex,
  setCurrentEventAPI,
  setRowSelectedFlag,
  setTabsRef
}: ITabParams) {
  if (!ref) {
    console.log('no ref provided, returning...');
    return;
  }

  const defaultColDef: ColDef = getDefaultColumns(
    portalInfo.agGridConfig.sortableColumn
  );

  const columnDefs: ColDef[] = getTaskConfigColumns();

  let key = '';
  let data: any[] = [];

  if (firstTab) {
    if (!taskConfigs.length) {
      console.log('taskConfigs empty, returning...');
      return;
    }

    const currentTaskConfig: any = GlobalHashHelper.hasOption('tab')
      ? taskConfigs.find(
          (item: TaskConfig) =>
            item._data.name ===
            GlobalHashHelper.getOption('tab').replaceAll('%20', ' ')
        )
      : taskConfigs[0];

    data = [currentTaskConfig._data];
    key = currentTaskConfig._data.name;
  } else {
    if (!rows || !title) {
      console.log('rows||title taskConfigs empty, returning...');
      return;
    }

    key = title;
    data = [...rows];
  }

  function onUpdateAPI(api: GridApi) {
    if (api.getSelectedRows() && api.getSelectedRows().length > 0) {
      setSelectedRows?.(api.getSelectedRows());
      setRowSelectedFlag(true);
    }
    setCurrentEventAPI(api);
  }
  /**
   * last row edit - creates new row, add lookup in DB
   * other row edit - edits current row, edit lookup in DB
   * delete all cells data - deletes object from DB
   * @param e event triggred on cell value change
   */
  async function onCellValueChanged(e: CellValueChangedEvent) {
    setSelectedRows([e.data]);
    const isUpdated = await updateTaskConfigs(e.data.id, e.data);

    if (!isUpdated || !ref) return;

    fetchData();

    const newGridMeta: GridMetaData = createGridMetaFromDocument(isUpdated);

    updateCurrentGridDetail({
      type: builderPageTypes.taskConfigs,
      ref,
      data: newGridMeta
    });
  }

  const taskConfigData = await TaskConfigCollection.findById(data[0].id ?? '');
  const gridMetaData: GridMetaData | undefined = !taskConfigData
    ? undefined
    : createGridMetaFromDocument(taskConfigData);

  const metaGridCols = getTaskConfigMetaGridCols();
  const metaGridDefaultCol = getMetaGridDefaultCol();

  const tab = generateTab({
    id: key,
    title: key,
    group: builderPageTypes.taskConfigs,
    data: data,
    theme: portalInfo.agGridConfig.theme,
    pagination: portalInfo.agGridConfig.pagination,
    defaultColDef,
    columnDefs,
    masterDetail: true,
    // detailCellRendererParams,
    gridReferences,
    gridMetaData,
    metaGridCols,
    metaGridDefaultCol,
    onCellValueChanged: (e: CellValueChangedEvent) => onCellValueChanged(e),
    setSelectedRowIndex,
    onUpdateAPI: (api: GridApi) => onUpdateAPI(api),
    setTabsRef,
    onMetaCellValueChanged: (e: CellValueChangedEvent) => {
      updateMetaData && updateMetaData(e.data);
    },
    dockRef: ref
  });

  if (firstTab) {
    ref.updateTab('Loading', tab, true);

    updateCurrentGridDetail({
      type: builderPageTypes.taskConfigs,
      ref,
      data: extractGridMetaFromTab(ref, key + '_tab')
    });
  } else {
    ref.dockMove(tab, 'grid_dock', 'middle');
  }
}
