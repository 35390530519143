import React from 'react';
import style from './FieldsRenderer.module.scss';

/** Field Props */
interface Field {
  field: string | undefined;
}

/** Props for Field Renderer component */
interface IFieldsRenderer {
  fields: Field[];
  handleFieldClick: (value: string) => void;
}

/**
 * Fields Renderer component
 * @param {object} props for fields renderer component
 * @return {jsx} component
 */
const FieldsRenderer: React.FC<IFieldsRenderer> = ({
  fields,
  handleFieldClick
}) => {
  return (
    <div className={`${style.container} ignore-outside-click`}>
      <div className={style.wrapper}>
        {!!fields &&
          fields.map((item, index) => (
            <span
              key={index}
              onClick={() => handleFieldClick(item.field ?? '')}
            >
              + {item.field}
            </span>
          ))}
      </div>
    </div>
  );
};

export default FieldsRenderer;
