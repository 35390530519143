import { IGenericAnyObject } from '../config/types';

export function removeUndefinedKeyFromObject(
  obj: IGenericAnyObject
): IGenericAnyObject {
  const newObject: IGenericAnyObject = {};
  for (const key of Object.keys(obj)) {
    if (obj[key] !== undefined) {
      newObject[key] = obj[key];
    }
  }
  return newObject;
}
