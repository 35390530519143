import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  selectedNode: string;
  // selectedNodeId: string;
}

const initialState: InitialState = {
  selectedNode: '' // ,
  // selectedNodeId: ''
};

export const selectedNodeSlice = createSlice({
  name: 'selectedNode',
  initialState,
  reducers: {
    setSelectedNode: (_, action) => {
      return {
        selectedNode: action.payload
      };
    }
  }
});

export const { setSelectedNode } = selectedNodeSlice.actions;
export default selectedNodeSlice.reducer;
