/** Database Library */
import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb';
import { LocalDatabaseManager } from '@digitalworkflow/dwtranslateclient';
import authLib from './auth-lib';

const dbLib: any = {};
dbLib.instance = null as unknown as LocalDatabaseManager;

/**
 * Starts the database and returns its object
 * @return {object} database object
 */
dbLib.startDb = async function (): Promise<boolean> {
  if (dbLib.instance && dbLib.instance.initialized) return true;

  dbLib.instance = LocalDatabaseManager.instance();
  const dbCreated = await LocalDatabaseManager.startDb(
    getRxStorageIndexedDB() as any,
    authLib.getUserToken()
  );

  return dbCreated;
}; // start db

export default dbLib;
