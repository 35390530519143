import { ProfileMenuItem } from '@digitalworkflow/dwreactcommon';
import authLib from '../../lib/auth-lib';

/** Menu items definition for Pofile */
export const profileMenuItems: Array<ProfileMenuItem> = [
  {
    type: 'Link',
    text_en: 'Change Password',
    icon: ' fa fa-regular fa-user ',
    link: '/changepassword'
  },
  {
    type: 'Divider',
    text_en: ''
  },
  {
    type: 'Action',
    text_en: 'Logout',
    icon: 'fa fa-power-off',
    action: async () => {
      await authLib.logoutFunc();
    }
  },
  {
    type: 'Action',
    text_en: 'Return to Digital Workflow Hub',
    icon: 'fa fa-power-off',
    action: () => {}
  }
];
