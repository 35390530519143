/** Steps type definition */
export const STEPTYPE = {
  beginChart: 'Begin Chart',
  endChart: 'End Chart',
  beginForm: 'Begin Form',
  endForm: 'End Form',
  beginGroup: 'Begin Group',
  endGroup: 'End Group',
  beginTabs: 'Begin Tabs',
  endTabs: 'End Tabs',
  beginList: 'Begin List',
  endList: 'End List',
  if: 'If',
  endIf: 'End If',
  beginLoop: 'Begin Loop',
  endLoop: 'End Loop',
  beginMacro: 'Begin Macro',
  endMacro: 'End Macro',
  beginSection: 'Begin Section',
  endSection: 'End Section',
  beginTable: 'Begin Table',
  endTable: 'End Table',
  beginTest: 'Begin Test',
  endTest: 'End Test'
};
