// AutocompletePortal.tsx
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import '.';

interface AutocompletePortalProps {
  children: ReactNode; // Defines the type for children
}

const AutocompleteEditorPortal: React.FC<AutocompletePortalProps> = ({
  children
}) => {
  const portalRoot = document.getElementById('portal-root') as HTMLElement;
  return ReactDOM.createPortal(children, portalRoot);
};

export default AutocompleteEditorPortal;
