import {
  LocalDatabaseManager,
  TaskCollection,
  LogicEngineDataDateTime
} from '@digitalworkflow/dwtranslateclient';
import React, { useEffect, useState, useRef } from 'react';
import { AgGrid } from '@digitalworkflow/dwreactcommon';
import { storageKeys } from '../../config/constants/storageKeys';
import {
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
interface ModalData {
  title: string;
  bodyText: string;
}
function DebugTasks() {
  const [rowData, setRowData] = useState<any[] | null>(null);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState<ModalData | null>(null);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const gridApiRef = useRef<any>();
  const toggle = () => setModal(!modal);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const all = await LocalDatabaseManager.instance().tasks.findAll({
          project_id: { $exists: true }
        });
        const newAll = all.map((task) => {
          const totalDocs = task.doc?.length || '';
          return {
            ...task._data,
            totalDocs
          };
        });
        setRowData(newAll);
        console.log(newAll);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    minWidth: 150
  };

  const columnDefs = [
    {
      field: 'id',
      headerName: 'ID',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      width: 150,
      flex: 1
    },
    { field: 'project_id', headerName: 'Project ID', flex: 1 },
    { field: 'title', headerName: 'TItle', flex: 2 },
    { field: 'totalDocs', headerName: 'Documents', flex: 1 },
    { field: 'auser', headerName: 'Assigned User', flex: 2 },
    { field: 'awg', headerName: 'Assigned Workgroup', flex: 2 },
    { field: 'workflow_id', headerName: 'Workflow', flex: 1 },
    { field: 'task_status', headerName: 'Status', flex: 1 },
    { field: 'priority', headerName: 'Priority', flex: 1 },
    { field: 'expire_start_dt', headerName: 'Expire Start', flex: 1 },
    { field: 'expire_end_dt', headerName: 'Expire End', flex: 1 },
    { field: 'open_query_count', headerName: 'Queries', flex: 1 },
    { field: 'workflow_sla_date', headerName: 'Workflow SLA', flex: 1 },
    { field: 'parent_id', headerName: 'Parent ID', flex: 1 }
  ];

  const handleForceSync = async () => {
    try {
      setIsSyncing(true);
      const result = await LocalDatabaseManager.instance().tasks.forceSync({});
      console.log(result);
      setIsSyncing(false);
    } catch (error) {
      console.error('Error while syncing:', error);
    }
  };

  const handleGridReady = (params: any) => {
    gridApiRef.current = params.api;
  };

  const handleDeleteSelected = async () => {
    if (gridApiRef.current) {
      const selectedRows = gridApiRef.current.getSelectedRows();
      if (selectedRows.length === 0) {
        return;
      }
      for (const row of selectedRows) {
        try {
          const record = await LocalDatabaseManager.instance().tasks.findOne({
            id: row.id,
            project_id: { $exists: true }
          });
          if (record) {
            await record.remove();
          }
        } catch (error) {
          console.error('Error while deleting record:', error);
        }
      }
      gridApiRef.current.applyTransaction({ remove: selectedRows });
      gridApiRef.current.refreshCells();
    }
  };

  const handleCellClicked = (e: any) => {
    if (e.column.colId === 'totalDocs' && e.value !== '') {
      const bodyText = e.data.doc.map((el: any) => {
        return JSON.stringify(el) + '\n';
      });
      setSelectedRowId(e.data.id);
      setModalData({ title: 'Docs', bodyText: bodyText });
      toggle();
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleEditOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (modalData) {
      setModalData({ ...modalData, bodyText: e.target.value });
    }
  };

  const handleSubmit = async () => {
    setIsEditing(false);
    const userStr = localStorage.getItem(storageKeys.userKey);
    const userObj = userStr ? JSON.parse(userStr) : null;
    if (!selectedRowId || !userObj) return;
    try {
      const _record = await TaskCollection.findTaskById(selectedRowId);

      if (_record) {
        const bodyTextArr = modalData?.bodyText?.includes('\n')
          ? modalData.bodyText.split('\n')
          : [modalData?.bodyText ?? ''];
        const newData = bodyTextArr.map((el: string) => JSON.parse(el));
        _record._data.doc = newData;
        _record._data.updated_by = userObj.email;
        _record._data.updated_dt = new LogicEngineDataDateTime(new Date());
        const _result = await _record.save();
        console.log(_result);
      }
    } catch (error) {
      console.error('Error while editing record:', error);
    }
    toggle();
  };

  return (
    <>
      <div style={{ height: '7%' }}>
        <Button
          onClick={handleDeleteSelected}
          className='m-1'
          color='danger'
          size='sm'
          disabled={isSyncing}
        >
          Delete Selected
        </Button>
        <Button
          onClick={handleForceSync}
          className='m-1'
          color='primary'
          size='sm'
          disabled={isSyncing}
        >
          {isSyncing && (
            <Spinner
              color='light'
              size='sm'
              style={{
                marginTop: 0
              }}
            >
              Loading...
            </Spinner>
          )}
          Force Sync
        </Button>
      </div>
      <div style={{ height: '93%', width: '100%' }}>
        <AgGrid
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection='multiple'
          onGridReady={handleGridReady}
          onCellClicked={handleCellClicked}
        />
      </div>
      <Modal isOpen={modal} toggle={toggle} size='lg'>
        <ModalHeader toggle={toggle}>{modalData?.title}</ModalHeader>
        <ModalBody>
          <textarea
            style={{
              width: '100%',
              padding: '10px',
              height: '500px',
              overflowY: 'scroll',
              whiteSpace: 'pre-wrap'
            }}
            disabled={!isEditing}
            value={modalData?.bodyText ?? ''}
            onChange={handleEditOnChange}
          />
        </ModalBody>
        <ModalFooter>
          {isEditing ? (
            <Button color='success' onClick={handleSubmit} size='sm'>
              Save
            </Button>
          ) : (
            <Button color='primary' onClick={handleEdit} size='sm'>
              Edit
            </Button>
          )}

          <Button color='secondary' onClick={toggle} size='sm'>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default DebugTasks;
