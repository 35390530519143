import React, { useEffect, useRef, useState } from 'react';
import {
  CellValueChangedEvent,
  ColDef,
  ColumnResizedEvent,
  FirstDataRenderedEvent
} from 'ag-grid-community';
import { AgGrid, SelectDropdown } from '@digitalworkflow/dwreactcommon';
import {
  GridApi,
  GridMetaData,
  IGridReferences,
  DockLayout,
  IUpdateCurrentGridDetailProps
} from '../../config/types';
import cls from './BuilderForm.module.scss';
import CurrentLineField from '../CurrentLineField';
import { builderPageTypes } from '../../config/constants/builderPageTypes';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import {
  ModuleCollection,
  ModuleSectionCollection,
  PageCollection,
  TaskConfigCollection,
  WorkflowStateCollection
} from '@digitalworkflow/dwtranslateclient';
import { storageKeys } from '../../config/constants/storageKeys';
import { WorkgroupType } from '@digitalworkflow/dwloginclient';
import DropdownEditor, { OptionType } from '../GridCellTypes/DropdownEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchModuleGroups, fetchModules } from '../../redux/module';
import { useAppDispatch } from '../../redux/store';
import {
  fetchModuleSectionGroups,
  fetchModuleSections
} from '../../redux/moduleSection';
import {
  fetchWorkflowStateGroups,
  fetchWorkflowStates
} from '../../redux/workflowState';
import {
  fetchTaskConfigGroups,
  fetchTaskConfigs
} from '../../redux/taskConfig';
import { createGridMetaFromDocument } from '../../utils';
import { updateCurrentGridDetail } from '../CurrentGridDetail/updateCurrentGridDetail';
import { toast } from 'react-toastify';
import { SelectDropdownStyle } from '../../config/constants/SelectDropdownStyle';
import GridSwitch from '../GridCellTypes/GridSwitch';

/** Props for Dropdown Field */
interface DropDownFieldProps {
  label: string;
  value: OptionType | OptionType[];
  options: OptionType[];
  onChange: (option: OptionType | OptionType[]) => Promise<void>;
  onBlur?: () => void;
  isMulti?: boolean;
}

/**
 * Shows Dropdown Field component
 * @param {object} props for dropdown field component
 * @return {jsx} component
 */
const DropDownField = ({
  value: initVale,
  label,
  options,
  onChange,
  onBlur,
  isMulti = false
}: DropDownFieldProps) => {
  const labelRef = useRef<any>(null);
  const [hasOver, setHasOver] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [value, setValue] = useState(initVale);

  useEffect(() => {
    setValue(initVale);
  }, [initVale]);

  useEffect(() => {
    if (labelRef.current.scrollWidth > labelRef.current.clientWidth)
      setHasOver(true);
  }, []);

  return (
    <div className={cls.dropdown}>
      <CustomTooltip
        target={labelRef.current}
        show={hasOver && showTooltip}
        placement='bottom'
      >
        {label}
      </CustomTooltip>
      <span
        ref={labelRef}
        className={cls.label}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        style={{ ...(hasOver && showTooltip ? { cursor: 'pointer' } : {}) }}
      >
        {label}
      </span>
      <SelectDropdown
        options={options}
        placeholder=''
        isSearchable
        isClearable={false}
        menuPosition='fixed'
        isMulti={isMulti}
        onChange={(val) => {
          onChange(val as any);
        }}
        onBlur={() => {
          if (!!onBlur && isMulti) onBlur();
        }}
        value={value}
        style={SelectDropdownStyle}
      />
    </div>
  );
};

/** Props for Builder form component */
interface IBuilderForm {
  id: string;
  columnDefs: ColDef[];
  masterDetail?: boolean;
  detailCellRendererParams?: any;
  data: any[];
  defaultColDef: ColDef;
  theme: string;
  pagination: boolean;
  shouldGetRowId?: boolean;
  gridReferences?: React.MutableRefObject<IGridReferences[]>;
  checkboxSelection?: boolean;
  _gridMetaData?: GridMetaData;
  type: builderPageTypes;
  getRef?: (ref: any) => void;
  setSelectedRowIndex?: (index: number) => void;
  onUpdateAPI?: (api: GridApi) => void;
  setTabsRef?: (value: any) => void;
  setAllSelectedRows?: (rows: any[]) => void;
  dockRef?: DockLayout | null;
}

/**
 * Shows Builder form component
 * @param {object} props for builder form component
 * @return {jsx} component
 */
const BuilderForm = ({
  id,
  columnDefs,
  data,
  defaultColDef,
  theme,
  type,
  setTabsRef,
  dockRef
}: IBuilderForm) => {
  const dispatch = useAppDispatch();

  const gridRef = useRef<any>(null);
  const slaGridContainerRef = useRef<HTMLDivElement>(null);
  const dataGridContainerRef = useRef<HTMLDivElement>(null);
  const [parentModules, setParentModules] = useState<OptionType[]>([]);
  const [workGroups, setWorkGroups] = useState<OptionType[]>([]);
  const [workflow, setWorkflow] = useState<OptionType[]>([]);
  const [pageNames, setPageNames] = useState<OptionType[]>([]);
  const [formData, setFormData] = useState<any>(data);

  const [slaOpened, setSlaOpened] = useState<boolean>(false);
  const [cardLabelOpened, setCardLabelOpened] = useState<boolean>(false);
  const [datafieldOpened, setDatafieldOpened] = useState<boolean>(false);

  useEffect(() => {
    const _data = [...data];
    const d = { ..._data[0] };

    if (type === builderPageTypes.workflowStates) {
      const _sla = Array.isArray(d.sla) ? [...d.sla] : [];
      _sla.push({ sla_time: '', sla_action: 'none', sla_value: '' });
      d.sla = _sla;
    }

    if (type === builderPageTypes.taskConfigs) {
      const _cardLabels = Array.isArray(d.card_labels)
        ? [...d.card_labels]
        : [];
      _cardLabels.push({
        label: '',
        display_template: '',
        display_condition: ''
      });
      d.card_labels = _cardLabels;

      const _dataField = Array.isArray(d.data_fields) ? [...d.data_fields] : [];
      _dataField.push({
        label: '',
        display_template: '',
        display_condition: ''
      });
      d.data_fields = _dataField;
    }

    _data[0] = d;
    setFormData(_data);
  }, []);

  /**
   * Initializes data for builder form
   */
  const init = async () => {
    const ParentModuleNames = (
      await ModuleCollection.findAllModules(undefined, false)
    ).map((module) => ({
      id: module.data.id || '',
      value: module.data.id || '',
      label: module.data.module_name || ''
    }));
    setParentModules(ParentModuleNames);
    const workGroups = JSON.parse(
      localStorage.getItem(storageKeys.userKey) || '{}'
    )?.work_groups;
    const workGroupsNames = workGroups?.map((workgroup: WorkgroupType) => ({
      id: workgroup.id || '',
      value: workgroup.formatted_workgroup_name || '',
      label: workgroup.formatted_workgroup_name || ''
    }));
    setWorkGroups(workGroupsNames);
    const workflowNames = (
      await WorkflowStateCollection.findAllWorkflowStates(
        formData?.[0]?.data?.module_id
      )
    ).map((workflow) => ({
      id: workflow._data.name || '',
      value: workflow._data.name || '',
      label: workflow._data.name || ''
    }));
    setWorkflow(workflowNames);
    const _pageNames = (await PageCollection.getAllPages()).map((module) => ({
      id: module.data.route || '',
      value: module.data.route || '',
      label: module.data.route || ''
    }));
    setPageNames(_pageNames);
  };

  useEffect(() => {
    init();
    if (gridRef && gridRef.current) {
      setTabsRef?.({
        tabId: id,
        gridRef
      });
    }
  }, [gridRef]);

  /**
   * Updates modules data in redux
   */
  function updateModulesRedux() {
    dispatch(fetchModules());
    dispatch(fetchModuleGroups());
  }

  /**
   * Updates module sections data in redux
   */
  function updateModuleSectionRedux() {
    dispatch(fetchModuleSections());
    dispatch(fetchModuleSectionGroups());
  }

  /**
   * Updates workflow states data in redux
   */
  function updateWorkflowStateRedux() {
    dispatch(fetchWorkflowStates());
    dispatch(fetchWorkflowStateGroups());
  }

  /**
   * Updates task config data in redux
   */
  function updateTaskConfigRedux() {
    dispatch(fetchTaskConfigs());
    dispatch(fetchTaskConfigGroups());
  }

  type CollectionFetchFunction = (_id: string) => Promise<any>;

  /**
   * Even handler for on blur event to update data
   */
  const onBlurUpdate = async (_id: string): Promise<void> => {
    try {
      if (!dockRef) return;

      const typeToCollectionMap: Record<string, CollectionFetchFunction> = {
        [builderPageTypes.modules]:
          ModuleCollection.findById.bind(ModuleCollection),
        [builderPageTypes.moduleSections]:
          ModuleSectionCollection.findById.bind(ModuleSectionCollection),
        [builderPageTypes.workflowStates]:
          WorkflowStateCollection.getWorkflowStateById.bind(
            WorkflowStateCollection
          ),
        [builderPageTypes.taskConfigs]:
          TaskConfigCollection.findById.bind(TaskConfigCollection)
      };

      const fetchFunction = typeToCollectionMap[type];
      if (!fetchFunction) return;

      const data = await fetchFunction(_id);
      if (!data) return;

      const newGridMeta: GridMetaData = createGridMetaFromDocument(data);

      const update: IUpdateCurrentGridDetailProps = {
        type,
        ref: dockRef,
        data: newGridMeta
      };

      updateCurrentGridDetail(update);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Async Event handler for on update event
   * @param {string} _id
   * @param {string} field
   * @param {string} value
   * @param {string} isMulti
   */
  const onUpdate = async (
    _id: string,
    field: string,
    value: any,
    isMulti?: boolean
  ) => {
    if (dockRef) {
      let data;
      switch (type) {
        case builderPageTypes.modules:
          data = await ModuleCollection.updateModule(_id, {
            [field]: value
          });
          updateModulesRedux();
          break;
        case builderPageTypes.moduleSections:
          data = await ModuleSectionCollection.updateModuleSection(_id, {
            [field]: value
          });
          updateModuleSectionRedux();
          break;
        case builderPageTypes.workflowStates:
          data = await WorkflowStateCollection.updateWorkflowState(_id, {
            [field]: value
          });
          updateWorkflowStateRedux();
          break;
        case builderPageTypes.taskConfigs:
          data = await TaskConfigCollection.updateTaskConfig(_id, {
            [field]: value
          });
          updateTaskConfigRedux();
          break;
        default:
          break;
      }

      if (!data || data.saveResults.validation.has_errors) {
        toast.error(
          data
            ? data.saveResults.validation.results[0].error_message
            : 'An error occured',
          {
            containerId: 'main-toast'
          }
        );
        return;
      }

      setFormData([data.record.data]);

      const newGridMeta: GridMetaData = createGridMetaFromDocument(data.record);

      if (!isMulti) {
        updateCurrentGridDetail({
          type: type,
          ref: dockRef,
          data: newGridMeta
        });
      }
    }
  }; // on update ends

  let encounteredBoolean = false;

  useEffect(() => {
    const slaDetailGridContainer = slaGridContainerRef.current as HTMLElement;
    const dataDetailGridContainer = dataGridContainerRef.current as HTMLElement;
    if (slaDetailGridContainer && slaOpened) {
      slaDetailGridContainer.tabIndex = -1;
      slaDetailGridContainer.focus();
    }
    if (dataDetailGridContainer && datafieldOpened) {
      dataDetailGridContainer.tabIndex = -1;
      dataDetailGridContainer.focus();
    }
  }, [slaOpened, datafieldOpened]);

  if (formData.length > 0) {
    return (
      <div className={cls.formOut}>
        {columnDefs.map((def: any, index: number) => {
          const isBooleanValue = [
            'show_web',
            'show_mobile',
            'show_webmobile',
            'anonymous_allowed',
            'notes_enabled',
            'postpone_enabled',
            'show_task_notes'
          ].includes(def.field);

          if (def.headerName === '#' || def.field === 'type') {
            return null;
          }

          if (def.field === 'module_id' || def.field === 'parent_id') {
            const currentIndex = parentModules.findIndex(
              (option) => option.id === formData[0][def.field]
            );
            return (
              <DropDownField
                label={def.headerName}
                options={parentModules}
                key={index}
                value={parentModules[currentIndex]}
                onChange={async (option) => {
                  onUpdate(
                    formData[0].id,
                    def.field,
                    (option as OptionType).id
                  );
                }}
              />
            );
          }
          if (
            [
              'wg_approver',
              'initial_workgroup',
              'initial_workflow',
              'page_name'
            ].includes(def.field)
          ) {
            const _group =
              def.field === 'initial_workflow'
                ? workflow
                : def.field === 'page_name'
                ? pageNames
                : workGroups;
            const currentIndex = _group.findIndex(
              (option) => option.id === formData[0][def.field]
            );
            return (
              <DropDownField
                label={def.headerName}
                options={_group}
                key={index}
                value={_group[currentIndex]}
                onChange={async (option) => {
                  onUpdate(
                    formData[0].id,
                    def.field,
                    (option as OptionType).id
                  );
                }}
              />
            );
          }
          if (
            [
              'wg_cansee',
              'wg_canaction',
              'wg_canmanage',
              'wg_canseemodule',
              'wg_canusemodule',
              'wg_leavenotes',
              'wg_editors',
              'wg_monitors',
              'status_cansee',
              'wg_canseesection',
              'wg_monitor'
            ].includes(def.field)
          ) {
            const _formData = JSON.parse(JSON.stringify(formData));
            const currentValues = [];
            for (let i = 0; i < workGroups.length; i++) {
              const element = workGroups[i];
              if (typeof _formData[0][def.field] === 'string') {
                return null;
              }
              if (
                _formData[0][def.field] &&
                _formData[0][def.field]
                  .map((d: any) => d.id)
                  .includes(element.id)
              ) {
                currentValues.push(element);
              }
            }
            return (
              <DropDownField
                label={def.headerName}
                options={workGroups}
                key={index}
                value={currentValues}
                isMulti
                onChange={async (option) => {
                  onUpdate(
                    formData[0].id,
                    def.field,
                    [...(option as OptionType[])],
                    true
                  );
                }}
                onBlur={() => {
                  onBlurUpdate(formData[0].id);
                }}
              />
            );
          }
          if (def.field === 'note_type') {
            const options: OptionType[] = [
              { value: 'system', label: 'system', id: '' },
              { value: 'user', label: 'user', id: '' }
            ];
            return (
              <DropDownField
                label={def.headerName}
                options={options}
                key={index}
                value={
                  formData[0][def.field] === 'system' ? options[0] : options[1]
                }
                onChange={async (option) => {
                  if (type === builderPageTypes.taskConfigs) {
                    onUpdate(
                      formData[0].id,
                      def.field,
                      (option as OptionType).value
                    );
                  }
                }}
              />
            );
          }
          if (def.field === 'sla') {
            return (
              <div key={index} className={cls.detailView}>
                <span className={cls.label} style={{ marginTop: '10px' }}>
                  {def.headerName}
                </span>
                <div className={cls.detailMain}>
                  <div
                    className={cls.deatialHeader}
                    onClick={() => {
                      setSlaOpened((prevState) => !prevState);
                    }}
                  >
                    <div className={cls.headerLabel}>SLA Details</div>
                    {slaOpened ? (
                      <FontAwesomeIcon
                        icon='chevron-down'
                        className={cls.dropdownIcon}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon='chevron-right'
                        className={cls.dropdownIcon}
                      />
                    )}
                  </div>
                  {slaOpened && (
                    <div className={cls.detailTable} ref={slaGridContainerRef}>
                      <AgGrid
                        rowData={formData[0][def.field]}
                        onFirstDataRendered={(e: FirstDataRenderedEvent) => {
                          e.api.sizeColumnsToFit();
                        }}
                        columnDefs={[
                          {
                            field: 'sla_time',
                            headerName: 'Time',
                            flex: 1,
                            filterParams: {
                              maxNumConditions: 1,
                              filterOptions: ['contains', 'equals']
                            }
                          },
                          {
                            field: 'sla_action',
                            headerName: 'Action',
                            flex: 1,
                            filterParams: {
                              maxNumConditions: 1,
                              filterOptions: ['contains', 'equals']
                            },
                            autoHeight: true,
                            cellRenderer: DropdownEditor,
                            cellRendererParams: function (params: {
                              node: any;
                            }) {
                              return {
                                node: params.node,
                                options: [
                                  { value: 'page', label: 'Page' },
                                  { value: 'none', label: 'None' }
                                ],
                                field: 'sla_action',
                                updateData: (
                                  id: string,
                                  data: any,
                                  rowIndex: number
                                ) => {
                                  console.log('###', id);
                                  const _sla = [...formData[0][def.field]];
                                  _sla[rowIndex] = {
                                    ...formData[0][def.field][rowIndex],
                                    ...data
                                  };
                                  const newSla = [..._sla];
                                  if (rowIndex === formData[0].sla.length - 1) {
                                    newSla.pop();
                                  }
                                  onUpdate(formData[0].id, def.field, newSla);
                                  _sla.push({
                                    sla_time: '',
                                    sla_action: 'none',
                                    sla_value: ''
                                  });
                                  formData[0].sla = _sla;
                                  setFormData([...formData]);
                                }
                              };
                            },
                            editable: false
                          },

                          {
                            field: 'sla_value',
                            headerName: 'Value',
                            flex: 1,
                            filterParams: {
                              maxNumConditions: 1,
                              filterOptions: ['contains', 'equals']
                            }
                          }
                        ]}
                        defaultColDef={defaultColDef}
                        theme={theme}
                        onColumnResized={(e: ColumnResizedEvent) => {
                          if (
                            e.columns &&
                            e.columns?.length > 1 &&
                            e.source === 'autosizeColumns'
                          ) {
                            e.columnApi.resetColumnState();
                          }
                        }}
                        onCellValueChanged={(e: CellValueChangedEvent) => {
                          const _sla = [...formData[0][def.field]];
                          if (e.rowIndex !== null && e.rowIndex > -1) {
                            _sla[e.rowIndex] = e.data;
                            const newSla = [..._sla];
                            if (formData[0].sla.length - 1 !== e.rowIndex) {
                              newSla.pop();
                            }
                            onUpdate(formData[0].id, def.field, newSla);
                            newSla.push({
                              sla_time: '',
                              sla_action: 'none',
                              sla_value: ''
                            });
                            formData[0].sla = newSla;
                            setFormData([...formData]);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }
          if (['card_labels', 'data_fields'].includes(def.field)) {
            const opened =
              def.field === 'card_labels'
                ? cardLabelOpened
                : def.field === 'data_fields'
                ? datafieldOpened
                : false;
            const setOpended =
              def.field === 'card_labels'
                ? setCardLabelOpened
                : def.field === 'data_fields'
                ? setDatafieldOpened
                : () => {};
            return (
              <div key={index} className={cls.detailView}>
                <span className={cls.label} style={{ marginTop: '10px' }}>
                  {def.headerName}
                </span>
                <div className={cls.detailMain}>
                  <div
                    className={cls.deatialHeader}
                    onClick={() => setOpended(!opened)}
                  >
                    <div className={cls.headerLabel}>
                      {def.headerName} Details
                    </div>
                    {opened ? (
                      <FontAwesomeIcon
                        icon='chevron-down'
                        className={cls.dropdownIcon}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon='chevron-right'
                        className={cls.dropdownIcon}
                      />
                    )}
                  </div>
                  {opened && (
                    <div className={cls.detailTable} ref={dataGridContainerRef}>
                      <AgGrid
                        rowData={formData[0][def.field]}
                        onFirstDataRendered={(e: FirstDataRenderedEvent) =>
                          e.api.sizeColumnsToFit()
                        }
                        columnDefs={[
                          {
                            field: 'label',
                            headerName: 'Label',
                            flex: 1,
                            filterParams: {
                              maxNumConditions: 1,
                              filterOptions: ['contains', 'equals']
                            }
                          },
                          {
                            field: 'display_template',
                            headerName: 'Display Template',
                            flex: 1,
                            filterParams: {
                              maxNumConditions: 1,
                              filterOptions: ['contains', 'equals']
                            }
                          },

                          {
                            field: 'display_condition',
                            headerName: 'Display Condition',
                            flex: 1,
                            filterParams: {
                              maxNumConditions: 1,
                              filterOptions: ['contains', 'equals']
                            }
                          }
                        ]}
                        defaultColDef={defaultColDef}
                        theme={theme}
                        onColumnResized={(e: ColumnResizedEvent) => {
                          if (
                            e.columns &&
                            e.columns?.length > 1 &&
                            e.source === 'autosizeColumns'
                          ) {
                            e.columnApi.resetColumnState();
                          }
                        }}
                        onCellValueChanged={(e: CellValueChangedEvent) => {
                          const field = formData[0][def.field];
                          if (e.rowIndex !== null && e.rowIndex > -1) {
                            field[e.rowIndex] = e.data;
                            const _field = [...field];
                            if (
                              e.rowIndex !==
                              formData[0][def.field].length - 1
                            ) {
                              _field.pop();
                            }
                            onUpdate(formData[0].id, def.field, _field);
                            _field.push({
                              label: '',
                              display_template: '',
                              display_condition: ''
                            });
                            formData[0][def.field] = _field;
                            setFormData([...formData]);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }

          if (encounteredBoolean) return;

          encounteredBoolean = isBooleanValue;

          const currentType = columnDefs[0].headerName;

          return isBooleanValue ? (
            <div className={cls.switches} key={index}>
              {currentType === builderPageTypes.workflowStates
                ? columnDefs.slice(columnDefs.length - 2).map((def, index) => (
                    <div key={index} className={cls.childSwitch}>
                      <GridSwitch
                        field={def.field ?? ''}
                        node={{ data: formData[0] }}
                        updateData={(def as any).updateData}
                        label={def.headerName}
                      />
                    </div>
                  ))
                : currentType === builderPageTypes.modules
                ? columnDefs.slice(columnDefs.length - 4).map((def, index) => (
                    <div key={index} className={cls.childSwitch}>
                      <GridSwitch
                        field={def.field ?? ''}
                        node={{ data: formData[0] }}
                        updateData={(def as any).updateData}
                        label={def.headerName}
                      />
                    </div>
                  ))
                : currentType === builderPageTypes.moduleSections
                ? columnDefs.slice(columnDefs.length - 1).map((def, index) => (
                    <div key={index} className={cls.childSwitch}>
                      <GridSwitch
                        field={def.field ?? ''}
                        node={{ data: formData[0] }}
                        updateData={(def as any).updateData}
                        label={def.headerName}
                      />
                    </div>
                  ))
                : null}
            </div>
          ) : (
            <div style={{ marginBottom: '10px' }} key={index}>
              <CurrentLineField
                value={
                  isBooleanValue
                    ? !!formData[0][def.field]
                    : formData[0][def.field]
                }
                label={def.headerName}
                onBlur={async (str) => {
                  onUpdate(formData[0].id, def.field, str);
                }}
                onChangeFlag={async (flg) => {
                  onUpdate(formData[0].id, def.field, flg);
                }}
                inputStyle={{
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  borderRadius: '3px',
                  paddingLeft: '10px'
                }}
                showIcon={false}
              />
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

export default BuilderForm;
