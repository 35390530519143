import React from 'react';
import { TabData } from 'rc-dock';
import { IUpdateCurrentGridDetailProps } from '../../config/types';
import CurrentGridDetail from './CurrentGridDetails';

/**
 * Update Current Grid Detail component
 * @param {object} props for update current grid detail component
 * @return {jsx} component
 */
export function updateCurrentGridDetail({
  type,
  ref,
  data
}: IUpdateCurrentGridDetailProps) {
  const newTab: TabData = {
    title: 'Current Grid Details',
    id: 'current_tab',
    content: data ? <CurrentGridDetail data={data} type={type} /> : <></>,
    closable: false
  };

  ref.updateTab('current_tab', newTab, false);
}
