import React, { useRef, useState } from 'react';

import style from './index.module.scss';
import { IActionMenuProps } from '../../config/types';
import { Button, Overlay, Popover } from 'react-bootstrap';

/**
 * Action menu component
 * @param {object} props for current line component
 * @return {jsx} component
 */
function ActionMenu({ actionMenuItems, node }: IActionMenuProps) {
  const [show, setShow] = useState(false);

  const target = useRef<any>(null);

  /**
   * Toggles show value
   * @param {boolean} new show
   */
  function toggleShow(newShow?: boolean) {
    setShow(newShow !== undefined ? newShow : !show);
  }

  return (
    <div className={style.menuContainer}>
      <Button
        ref={target}
        type='button'
        className={`btn-sm ${style.button}`}
        onClick={() => toggleShow()}
      >
        <i className='fa-solid fa-ellipsis-vertical cursor-pointer' />
      </Button>
      <Overlay
        show={show}
        placement='top'
        target={target.current}
        rootClose
        onHide={() => toggleShow(false)}
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <Popover {...props}>
            <div className={style.container}>
              {actionMenuItems.map((item, index) => (
                <div
                  key={index}
                  className={`d-flex gap-3 align-items-center ${
                    style.actionContainer
                  } ${item.class && style[item.class]}`}
                  onClick={() => {
                    toggleShow(false);
                    item.onClick && item.onClick(node);
                  }}
                >
                  <i className={item.icon + ' ' + style.iconSide} />
                  <p>{item.title}</p>
                </div>
              ))}
            </div>
          </Popover>
        )}
      </Overlay>
    </div>
  );
}

export default ActionMenu;
