/** Column Definitions for different grids used */
import {
  DataDictionaryFieldTypes,
  DataDictionaryFieldValidationTypes
} from '@digitalworkflow/dwtranslateclient/lib/Models/DataDictionaryField/DataDictionaryFieldSchema';
import DropdownEditor from '../../components/GridCellTypes/DropdownEditor';
import { CommandLinter } from '@digitalworkflow/dwtranslateclient';
import { CellValueChangedEvent, IActionMenuItem, ColDef } from '../types';
import React, { useEffect, useRef, useState } from 'react';
import {
  ICellRendererParams,
  ValueSetterParams,
  EditableCallbackParams,
  ColumnResizedEvent
} from 'ag-grid-community';
import { convertToThumbnailImageUrl, formatDate } from '../../utils';
import style from './cellRenderers.module.scss';
import ActionMenu from '../../components/Documents/actionMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import CustomTooltip from '../../components/CustomTooltip/CustomTooltip';
import AutocompleteEditor from '../../components/GridCellTypes/AutocompleteEditor';
import NormalCell from '../../components/GridCellTypes/NormalCell';
import commonLib from '../../lib/common-lib';
import IconOfFileType from '../../components/IconOfFileType';
import ImageCell from '../../components/GridCellTypes/ImageCell';
import { builderPageTypes } from './builderPageTypes';

/**
 * default columns defintion for meta grid
 * @return {object} default columns definition for meta grid
 * */
export const getMetaGridDefaultCol = () => ({
  flex: 1,
  suppressSizeToFit: false,
  suppressAutoSize: false,
  editable: true,
  floatingFilter: false,
  suppressMenu: true,
  unSortIcon: false,
  sortable: false,
  filter: 'none'
});

/**
 * Gets columns defintion of page meta grid
 * @return {object} columns definition for page meta grid
 * */
export const getPageMetaGridCols = () => [
  { field: 'route', headerName: 'Route' },
  { field: 'description', headerName: 'Description' },
  { field: 'title_en', headerName: 'Title EN' },
  { field: 'page_type', headerName: 'Page Type' }
];

/**
 * Get columns defintion of lookup meta grid
 * @return {object} columns definition for lookup meta grid
 * */
export const getLookupMetaGridCols = () => [
  { field: 'key', headerName: 'Key' }
];

/**
 * Gets columns defintion of data dictionary meta grid
 * @return {object} columns definition for data dictionary meta grid
 * */
export const getDdMetaGridCols = () => [
  { field: 'table_name', headerName: 'Table Name' },
  { field: 'description', headerName: 'Description' }
];

/**
 * Get columns defintion of module meta grid
 * @return {object} columns definition for module meta grid
 * */
export const getModuleMetaGridCols = () => [
  { field: 'name', headerName: 'Name' }
];

/**
 * Gets columns defintion of module section meta grid
 * @return {object} columns definition for module section meta grid
 * */
export const getModuleSectionMetaGridCols = () => [
  { field: 'name', headerName: 'Name' }
];

/**
 * Gets columns defintion of workflow state meta grid
 * @return {object} columns definition for workflow state meta grid
 * */
export const getWorkflowStateMetaGridCols = () => [
  { field: 'code', headerName: 'Code' },
  { field: 'name', headerName: 'Short Name' },
  { field: 'full_name', headerName: 'Full Name' },
  { field: 'description', headerName: 'Description' }
];

/**
 * Gets columns defintion of task config meta grid
 * @return {object} columns definition for task config meta grid
 * */
export const getTaskConfigMetaGridCols = () => [
  { field: 'name', headerName: 'Name' },
  { field: 'description', headerName: 'Description' }
];

/**
 * Gets default columns defintion of grid
 * @return {object} default columns definition for grid
 * */
export const getDefaultColumns = (sortableColumn: boolean = false) => {
  return {
    sortable: sortableColumn,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    editable: true,
    resizable: true,
    minWidth: 140
  };
};

/**
 * Gets columns defintion for lookups
 * @return {object} columns definition for lookups
 * */
export const getLookupColumns = () => {
  return [
    {
      headerName: '#',
      valueGetter: 'node.rowIndex+1',
      floatingFilter: false,
      editable: false,
      filter: 'none',
      suppressSizeToFit: true,
      maxWidth: 55,
      suppressMenu: true,
      resizable: false,
      unSortIcon: false
    },
    {
      field: 'coded_value',
      headerName: 'Coded Value',
      cellStyle: (params: any) => {
        if (params.value === 'Coded values are duplicated') {
          return { backgroundColor: '#FF9999' };
        }
        return undefined;
      }
    },
    {
      field: 'text',
      headerName: 'Text EN'
    },
    {
      field: 'export_value',
      headerName: 'Export Name'
    },
    {
      field: 'meta',
      headerName: 'Meta',
      valueFormatter: (params: any) => {
        return `Meta (${(params?.data?.doc?.length || 1) - 1})`;
      },
      cellRendererSelector: (params: any) => {
        if (!params.data.id) {
          return;
        }
        return { component: 'agGroupCellRenderer' };
      },
      editable: false,
      maxWidth: 120,
      floatingFilter: false,
      filter: 'none',
      suppressSizeToFit: true,
      suppressMenu: true,
      resizable: false,
      unSortIcon: false
    }
  ];
};

/**
 * Gets columns defintion for lookups meta
 * @return {object} columns definition for lookups meta
 * */
export const getLookupMetaColumns = (
  handleDetailGridCellValueChanged: (
    e: CellValueChangedEvent<any, any>,
    params: any
  ) => Promise<void>
) => {
  return {
    refreshStrategy: 'rows',
    detailGridOptions: {
      defaultColDef: {
        sortable: false,
        unSortIcon: true,
        floatingFilter: true,
        editable: true,
        resizable: true,
        minWidth: 140,
        flex: 1,
        singleClickEdit: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          maxNumConditions: 1,
          filterOptions: ['contains', 'equals']
        }
      },

      columnDefs: [
        {
          field: 'fid',
          headerName: 'Key'
        },
        {
          field: 'val',
          headerName: 'Value',
          cellRendererSelector: function (params: ICellRendererParams) {
            if (params.node.data.fid === 'image') {
              return {
                component: ImageCell as any,
                params: {
                  src: params.node.data.val
                }
              };
            } else {
              return null;
            }
          },
          editable: (params: EditableCallbackParams) =>
            params.node.data.fid !== 'image'
        }
      ],

      stopEditingWhenCellsLoseFocus: true,
      onColumnResized: (e: ColumnResizedEvent) => {
        if (
          e.columns &&
          e.columns.length > 1 &&
          e.source === 'autosizeColumns'
        ) {
          e.columnApi.resetColumnState();
        }
      }
    },
    getDetailRowData: function (params: any) {
      params.successCallback(params.data.doc);
      setTimeout(() => {
        if (params.node.detailGridInfo.api) {
          params.node.detailGridInfo.api.addEventListener(
            'cellValueChanged',
            (e: CellValueChangedEvent<any, any>) =>
              handleDetailGridCellValueChanged(e, params)
          );
        }
      }, 0);
    }
  };
};

/**
 * Gets columns defintion for data dictionary
 * @return {object} columns definition for data dictionary
 * */
export const getDataDictionaryColumns = (
  updateData: (id: string, data: any) => void,
  lookupData: any,
  dataDictionaryData: any,
  openTab?: (tabId: string, type: string) => void
) => {
  return [
    {
      headerName: '#',
      valueGetter: 'node.rowIndex+1',
      floatingFilter: false,
      editable: false,
      filter: 'none',
      suppressSizeToFit: true,
      maxWidth: 55,
      suppressMenu: true,
      resizable: false,
      unSortIcon: false
    },
    {
      field: 'field',
      headerName: 'Field',
      cellStyle: (params: any) => {
        if (params.value === 'Fields are duplicated') {
          return { backgroundColor: '#FF9999' };
        }
        return undefined;
      }
    },
    {
      field: 'field_label_en',
      headerName: 'Label EN'
    },
    {
      field: 'field_type',
      headerName: 'Field Type',
      cellRenderer: DropdownEditor,
      cellRendererParams: function (params: { node: any }) {
        return {
          node: params.node,
          field: 'field_type',
          updateData,
          options: Object.values(DataDictionaryFieldTypes).map((type) => ({
            value: type,
            label: type
          }))
        };
      },
      wrapText: true,
      autoHeight: true,
      editable: false
    },
    {
      field: 'validations',
      headerName: 'Validations',
      valueFormatter: (_params: any) => {
        return 'Validations';
      },
      cellRenderer: 'agGroupCellRenderer',
      editable: false
    },
    {
      field: 'related_table',
      headerName: 'Related Table',
      cellRendererSelector: function (params: {
        data: {
          field_type: string;
          related_table_options: any;
          related_table: string;
        };
        node: any;
      }) {
        let options = [];
        if (params.data.field_type === 'Lookup') {
          options = Object.keys(lookupData).map((type) => ({
            value: type,
            label: type
          }));
        } else if (
          params.data.field_type === 'Collection' ||
          params.data.field_type === 'Document'
        ) {
          options = dataDictionaryData.map(
            (dd: { table: { _data: { table_name: any } } }) => ({
              value: dd.table._data.table_name,
              label: dd.table._data.table_name
            })
          );
        } else {
          return {
            component: () => {
              return <NormalCell value={params.data.related_table} />;
            }
          };
        }
        params.data.related_table_options = options;
        return {
          component: AutocompleteEditor,
          params: {
            node: params.node,
            field: 'related_table',
            optionsField: 'related_table_options',
            updateData,
            type: params.data.field_type,
            openTab
          }
        };
      },
      wrapText: true,
      autoHeight: true,
      editable: function (params: { data: { field_type: string } }) {
        if (
          params.data.field_type === 'Collection' ||
          params.data.field_type === 'Document' ||
          params.data.field_type === 'Lookup'
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    {
      field: 'related_id',
      headerName: 'Related Id'
    },
    {
      field: 'default_value',
      headerName: 'Default Value',
      valueSetter: (params: ValueSetterParams) => {
        const field = params.colDef.field;
        if (field && params.oldValue !== params.newValue) {
          params.data[field] = params.newValue;
          return true;
        }
        return false;
      }
    },
    {
      field: 'options',
      headerName: 'Options'
    },
    {
      field: 'export_name',
      headerName: 'Export Name'
    }
  ];
};

/**
 * Gets columns defintion for data dictionary validations
 * @return {object} columns definition for data dictionary valications
 * */
export const getDDValidationsColumns = (
  handleDetailGridCellValueChanged: (
    e: CellValueChangedEvent<any, any>,
    params: any
  ) => Promise<void>,
  updateData: (id: string, data: any) => void
) => {
  const ddTypesArr = Object.values(DataDictionaryFieldValidationTypes);
  let ddTypesOptions: any[] = [];

  if (ddTypesArr) {
    ddTypesOptions = ddTypesArr.map((type) => {
      return {
        value: type,
        label: commonLib.capitalize(type)
      };
    });
  }

  return {
    refreshStrategy: 'rows',
    detailGridOptions: {
      columnDefs: [
        {
          field: 'type',
          headerName: 'Type',
          filterParams: {
            maxNumConditions: 1,
            filterOptions: ['contains', 'equals']
          },
          autoHeight: true,
          cellRenderer: DropdownEditor,
          cellRendererParams: function (params: { node: any }) {
            return {
              node: params.node,
              options: ddTypesOptions,
              field: 'type',
              updateData
            };
          },
          editable: false
        },
        {
          field: 'enabled',
          headerName: 'Enabled',
          filterParams: {
            maxNumConditions: 1,
            filterOptions: ['contains', 'equals']
          }
        },
        {
          field: 'condition',
          headerName: 'Condition',
          filterParams: {
            maxNumConditions: 1,
            filterOptions: ['contains', 'equals']
          }
        },
        {
          field: 'message',
          headerName: 'Message',
          filterParams: {
            maxNumConditions: 1,
            filterOptions: ['contains', 'equals']
          }
        }
      ],
      defaultColDef: {
        sortable: false,
        unSortIcon: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        editable: true,
        resizable: true,
        minWidth: 140,
        flex: 1,
        singleClickEdit: true
      },
      stopEditingWhenCellsLoseFocus: true
    },
    getDetailRowData: function (params: any) {
      params.successCallback(params.data.validations);
      setTimeout(() => {
        if (params.node.detailGridInfo.api) {
          params.node.detailGridInfo.api.addEventListener(
            'cellValueChanged',
            (e: CellValueChangedEvent<any, any>) =>
              handleDetailGridCellValueChanged(e, params)
          );
        }
      }, 0);
    }
  };
};

/**
 * Gets columns defintion for page step
 * @return {object} columns definition for page steps
 * */
export const getPageStepColumns = (
  route: string,
  refs: any,
  updateData: (id: string, data: any) => void
) => {
  return [
    {
      headerName: '#',
      valueGetter: 'node.rowIndex+1',
      floatingFilter: false,
      editable: false,
      filter: 'none',
      suppressSizeToFit: true,
      maxWidth: 55,
      suppressMenu: true,
      resizable: false,
      unSortIcon: false
    },
    {
      field: 'step_type',
      headerName: 'Step Type',
      cellStyle: (params: any) => {
        const indentSize = 15;
        const indentLevel = params.data.indent_level + 1;
        const paddingLeft = indentLevel * indentSize;
        if (paddingLeft) {
          return {
            paddingLeft: `${paddingLeft}px`
          };
        } else {
          return null;
        }
      },
      editable: (params: EditableCallbackParams) =>
        params.node.data.isEmptyOrTrace !== true
    },
    {
      field: 'step_option',
      headerName: 'Step Option',
      cellRendererSelector: function (params: {
        data: {
          step_type: any;
          step_option: any;
          step_option_options: { value: string; label: string }[];
        };
        node: any;
      }) {
        let options: { value: string; label: string }[] = [];
        const commandLinter = new CommandLinter();
        const result = commandLinter.possibleMatches({
          step_type: params.data.step_type || '',
          step_option: params.data.step_option || ''
        });
        if (result) {
          options = result
            .map((type) => {
              if (!type.options.options) {
                return {
                  value: type.options.commandText,
                  label: type.options.description
                };
              } else {
                return type.options.options.map((option) => {
                  return {
                    value: option.commandText,
                    label: option.description
                  };
                });
              }
            })
            .flat();
        }
        if (!options?.length || params.node.data.isEmptyOrTrace === true) {
          return undefined;
        }

        params.data.step_option_options = options;
        return {
          component: AutocompleteEditor,
          params: {
            node: params.node,
            field: 'step_option',
            optionsField: 'step_option_options',
            updateData,
            type: params.data.step_type,
            route,
            refs
          }
        };
      },
      // editable: true,
      editable: function (params: EditableCallbackParams) {
        if (params.node.data.isEmptyOrTrace === true) {
          return false;
        }
        if (!params.node.data.step_option_options?.length) {
          return true;
        } else {
          return false;
        }
      },
      wrapText: true,
      autoHeight: true,
      suppressKeyboardEvent: (params) => {
        const key = params.event.key;
        const input = document.getElementById('autoeditor-search-input');
        const activeElement = document.activeElement;
        return (
          input?.id &&
          activeElement?.id &&
          input.id === activeElement.id &&
          (key === 'ArrowLeft' || key === 'ArrowRight')
        );
      }
    } as ColDef,
    {
      field: 'text_en',
      headerName: 'Text EN',
      minWidth: 400,
      editable: (params: EditableCallbackParams) =>
        params.node.data.isEmptyOrTrace !== true
    },
    {
      field: 'field_id',
      headerName: 'Field Id',
      editable: (params: EditableCallbackParams) =>
        params.node.data.isEmptyOrTrace !== true
    },
    {
      field: 'option_1',
      headerName: 'Option 1',
      editable: (params: EditableCallbackParams) =>
        params.node.data.isEmptyOrTrace !== true
    },
    {
      field: 'option_2',
      headerName: 'Option 2',
      editable: (params: EditableCallbackParams) =>
        params.node.data.isEmptyOrTrace !== true
    },
    {
      field: 'option_3',
      headerName: 'Option 3',
      editable: (params: EditableCallbackParams) =>
        params.node.data.isEmptyOrTrace !== true
    },
    {
      field: 'execute_if',
      headerName: 'Execute If',
      editable: (params: EditableCallbackParams) =>
        params.node.data.isEmptyOrTrace !== true
    },
    {
      field: 'style',
      headerName: 'Style',
      editable: (params: EditableCallbackParams) =>
        params.node.data.isEmptyOrTrace !== true
    }
  ];
};

/**
 * Gets columns defintion for module
 * @return {object} columns definition for module
 * */
export const getModuleColumns = (
  updateData: (id: string, data: any) => void
) => {
  return [
    {
      field: 'type',
      headerName: builderPageTypes.modules
    },
    {
      field: 'parent_id',
      headerName: 'Parent Module'
    },
    // {
    //   field: 'module_name',
    //   headerName: 'Name'
    // },
    {
      field: 'wg_approver',
      headerName: 'WG Approver'
    },
    {
      field: 'wg_canseemodule',
      headerName: 'WG Can See'
    },
    {
      field: 'wg_canusemodule',
      headerName: 'WG Can Use'
    },
    {
      field: 'wg_leavenotes',
      headerName: 'WG Can Leave Notes'
    },
    {
      field: 'wg_editors',
      headerName: 'WG Editors'
    },
    {
      field: 'wg_monitors',
      headerName: 'WG Monitors'
    },
    {
      field: 'help_text',
      headerName: 'Help Text'
    },
    {
      field: 'display_style',
      headerName: 'Display Style'
    },
    {
      field: 'icon',
      headerName: 'Icon'
    },
    {
      field: 'pdf_export_page',
      headerName: 'PDF Export Page'
    },
    {
      field: 'start_page',
      headerName: 'Start Page'
    },
    {
      field: 'default_status',
      headerName: 'Default Status'
    },
    {
      field: 'primary_data_table',
      headerName: 'Primary Data Table'
    },
    {
      field: 'show_web',
      headerName: 'Show Web',
      updateData
    },
    {
      field: 'show_mobile',
      headerName: 'Show Mobile',
      updateData
    },
    {
      field: 'show_webmobile',
      headerName: 'Show Web Mobile',
      updateData
    },
    {
      field: 'anonymous_allowed',
      headerName: 'Allow Anonymous',
      updateData
    }
  ];
};

/**
 * Gets columns defintion for module sections
 * @return {object} columns definition for module sections
 * */
export const getModuleSectionColumns = (
  updateData: (id: string, data: any) => void
) => {
  return [
    {
      field: 'type',
      headerName: builderPageTypes.moduleSections
    },
    {
      field: 'module_id',
      headerName: 'Parent Module'
    },
    {
      field: 'page_name',
      headerName: 'Page Name'
    },
    {
      field: 'status_cansee',
      headerName: 'Status Can See'
    },
    {
      field: 'wg_canseesection',
      headerName: 'WG Can See'
    },
    {
      field: 'wg_editors',
      headerName: 'WG Editors'
    },
    {
      field: 'wg_monitor',
      headerName: 'WG Monitors'
    },
    {
      field: 'section_icon',
      headerName: 'Section Icon'
    },
    {
      field: 'show_task_notes',
      headerName: 'Show Notes',
      updateData
    }
  ];
};

/**
 * Gets columns defintion for workflow states
 * @return {object} columns definition for workflow states
 * */
export const getWorkflowStateColumns = (
  updateData: (id: string, data: any) => void
) => {
  return [
    {
      field: 'type',
      headerName: builderPageTypes.workflowStates
    },
    {
      field: 'module_id',
      headerName: 'Parent Module'
    },
    {
      field: 'wg_cansee',
      headerName: 'WG Can See'
    },
    {
      field: 'wg_canaction',
      headerName: 'WG Can Action'
    },
    {
      field: 'wg_canmanage',
      headerName: 'WG Can Manage'
    },
    {
      field: 'display_order',
      headerName: 'Display Order'
    },
    {
      field: 'route',
      headerName: 'Route'
    },
    {
      field: 'sla',
      headerName: 'SLA'
    },
    {
      field: 'postpone_enabled',
      headerName: 'Can Be Postponed',
      updateData
    },
    {
      field: 'notes_enabled',
      headerName: 'Show Notes',
      updateData
    }
  ];
};

/**
 * Gets columns defintion for task config
 * @return {object} columns definition for task config
 * */
export const getTaskConfigColumns = () => {
  return [
    {
      field: 'module_id',
      headerName: 'Parent Module'
    },
    // {
    //   field: 'name',
    //   headerName: 'Name'
    // },
    // {
    //   field: 'describe',
    //   headerName: 'Description'
    // },
    {
      field: 'card_labels',
      headerName: 'Card Labels'
    },
    {
      field: 'data_fields',
      headerName: 'Data Fields'
    },
    {
      field: 'initial_workgroup',
      headerName: 'Initial Workgroup'
    },
    {
      field: 'initial_workflow',
      headerName: 'Initial Workflow'
    },
    {
      field: 'note_type',
      headerName: 'Note Type'
    },
    {
      field: 'task_open_page',
      headerName: 'Task Open Page'
    },
    {
      field: 'default_priority',
      headerName: 'Default Priority'
    }
  ];
};

/**
 * Gets columns defintion for documents
 * @return {object} columns definition for documents
 * */
export const getDocumentGridColumns = (actionMenuItems: IActionMenuItem[]) => {
  const fileNameRenderer = (params: ICellRendererParams) => {
    const imageSrc = useRef('');
    const couldFetch = useRef(false);

    useEffect(() => {
      fetchImage();
    }, []);

    async function fetchImage() {
      const thumbnailUrl = convertToThumbnailImageUrl(params.data.url, 30);
      try {
        await fetch(thumbnailUrl).then((res) => {
          if (res.status === 200) {
            couldFetch.current = true;
            imageSrc.current = thumbnailUrl;
          } else {
            throw new Error(
              `image not found against the URL: ${thumbnailUrl}, falling back to predefined image`
            );
          }
        });
      } catch (err) {
        console.log(err);
      }
    }

    return (
      <div className='d-flex align-items-center justify-content-between'>
        {!couldFetch.current ? (
          <IconOfFileType url={params.data.url} />
        ) : (
          <img src={imageSrc.current} width={30} height={30} />
        )}
        <div className={style.nameCol}>
          <span className={style.title}>{params.data.name}</span>
          {/* {params.data.updated_by !== params.data.create_by && (
            <span className={style.updated}>
              Updated by {params.data.updated_by}
            </span>
          )} */}
          <span className={style.updated}>
            {params.data.updated_by !== params.data.create_by
              ? 'Updated by ' + params.data.updated_by
              : 'No Updates'}
          </span>
          {/* params.data.updated_by !== params.data.create_by */}
        </div>
      </div>
    );
  };
  const detailsRenderer = (params: ICellRendererParams) => {
    return (
      <div className={style.updateCol}>
        <span className={style.title}>Uploaded by {params.data.create_by}</span>
        <span className={style.updated}>
          {formatDate(params.data.create_dt)}
        </span>
      </div>
    );
  };
  const urlRenderer = (params: ICellRendererParams) => {
    const [tooltipText, setTooltipText] = useState('Copy Link');
    const [tooltipShow, setTooltipShow] = useState(false);
    const triggerRef = useRef<any>(undefined);
    return (
      <div className='h-100 d-flex align-items-center gap-1'>
        <span
          className={style.urlSpan}
          onMouseEnter={() => {
            setTooltipShow(true);
            setTooltipText(params.data.url);
          }}
          onMouseLeave={() => {
            setTooltipShow(false);
            setTooltipText('Copy Link');
          }}
        >
          {params.data.url}
        </span>
        <div
          className='h-100 d-flex justify-content-center align-items-center'
          ref={triggerRef}
          onClick={() => {
            params.node.setSelected(false);
            navigator.clipboard.writeText(params.data.url);
            setTooltipText('Link Copied');
            setTimeout(() => {
              setTooltipText('Copy Link');
              setTooltipShow(false);
            }, 1000);
          }}
          onMouseEnter={() => setTooltipShow(true)}
          onMouseLeave={() => setTooltipShow(false)}
        >
          <FontAwesomeIcon
            className={style.copyIcon}
            icon={faCopy}
            // color='blue'
          />
        </div>
        <CustomTooltip
          show={tooltipShow}
          target={triggerRef.current}
          placement='top'
          moveLeft='100%'
        >
          {tooltipText}
        </CustomTooltip>
      </div>
    );
  };
  return [
    {
      headerName: 'File Name',
      cellRenderer: fileNameRenderer,
      checkboxSelection: true,
      flex: 2,
      cellClass: ['document-cell']
    },
    {
      headerName: 'Upload Details',
      cellRenderer: detailsRenderer,
      flex: 1.5
    },
    {
      headerName: 'URL',
      cellClass: ['document-cell'],
      cellRenderer: urlRenderer,
      flex: 1.5
    } as ColDef,
    {
      headerName: 'Action',
      sortable: false,
      unSortIcon: false,
      width: 20,
      floatingFilter: false,
      cellClass: ['document-cell'],
      cellRenderer: ActionMenu,
      cellRendererParams: function (params: ICellRendererParams) {
        return {
          node: params.node,
          actionMenuItems
        };
      },
      flex: 0.5
    }
  ];
};

/**
 * Grid swich fields type definition
 * */
export const GridSwitchFields = [
  'show_web',
  'show_mobile',
  'show_webmobile',
  'anonymous_allowed',
  'notes_enabled',
  'postpone_enabled',
  'show_task_notes'
];
