import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Autocomplete from '../../Autocomplete/Autocomplete';
import {
  LookupCollection,
  ProjectCollection
} from '@digitalworkflow/dwtranslateclient';
import { Button } from 'reactstrap';
import { hasSpecialCharacters } from '../../../utils/checkSpecialCharacters';
import { FormErrorType } from '../../../pages/MasterEditor';
import { IGenericAnyObject } from '../../../config/types';
import { useSelector } from 'react-redux';
// import event, { IEventInitialState } from '../../../redux/event';
import { RootState } from '../../../redux/store';

type handleSaveProps = {
  key: string;
  projectId: string;
  group: string;
};

type DuplicateLookupFormProps = {
  toggleShow?: () => void;
  handleSave?: ({ key, projectId, group }: handleSaveProps) => Promise<boolean>;
  validationErrors?: FormErrorType[];
};

function DuplicateLookupForm({
  toggleShow,
  handleSave,
  validationErrors
}: DuplicateLookupFormProps) {
  const [key, setKey] = useState<string>('');
  const [keyError, setKeyError] = useState<any>(null);
  const [projectId, setProjectId] = useState<string>('');
  const [projectIdError, setProjectIdError] = useState<any>(null);
  const [group, setGroup] = useState<string>('');
  const [groupError, setGroupError] = useState<any>(null);
  const [groupOptions, setGroupOptions] = useState<any>([]);
  const [projectOptions, setProjectOptions] = useState<
    IGenericAnyObject[] | undefined
  >(undefined);
  const node2Duplicate = useSelector(
    (state: RootState) => state.event.node2Duplicate
  );

  console.log('node to duplicate: ', node2Duplicate);

  const clearLookupErrors = () => {
    setKeyError(null);

    setGroupError(null);
  }; // clear lookup errors

  useEffect(() => {
    clearLookupErrors();
    getProjectsList();
    getGroups();
  }, []);

  useEffect(() => {
    if (validationErrors) {
      for (let index = 0; index < validationErrors.length; index++) {
        const element = validationErrors[index];
        if (element.field_id === 'key') {
          setKeyError(element.error_message);
        }
      }
    }
  }, [validationErrors]);

  async function getGroups() {
    const res = await LookupCollection.findAllGroups();
    const data: any[] = [];
    for (const group of res) {
      data.push({ title: group });
    }
    setGroupOptions([...data]);
  }

  async function getProjectsList() {
    const projects = await ProjectCollection.getAvailableProjectsList();
    const allProjects = Object.values(projects).map(
      (project) => project[0]._data
    );

    const projList = Object.values(allProjects);
    const data: IGenericAnyObject[] = [];
    projList.forEach((projRow: any) => {
      data.push({
        id: projRow.project_id,
        name: projRow.name_en
      });
    });

    setProjectOptions([...data]);
  } // get projects list

  function toggleModal() {
    if (!toggleShow) {
      return;
    }
    toggleShow();
  }

  async function saveDuplicateLookup(): Promise<void> {
    clearLookupErrors();
    let lookupError = false;

    if (!key || key.trim() === '') {
      setKeyError('Key/Lookup name is required');
      lookupError = true;
    }
    if (key.trim() !== key) {
      setKeyError(
        'Key/Lookup name should not have the spaces at the start or end'
      );
      lookupError = true;
    }
    if (hasSpecialCharacters(key)) {
      setKeyError('Key contains special characters');
      lookupError = true;
    }

    if (!projectId) {
      setProjectIdError('Project is required');
      lookupError = true;
    }

    if (!group) {
      setGroupError('Group is required');
      lookupError = true;
    }

    if (lookupError) {
      return;
    }

    const result = await handleSave?.({
      key,
      projectId: '',
      group
    });
    if (result) {
      toggleModal();
    }
  }
  const duplicateLookupTitle = node2Duplicate
    ? `Duplicate [${node2Duplicate}] Lookup`
    : 'Duplicate Lookup';
  return (
    <div className='row'>
      <div className='modal-header h4'>{duplicateLookupTitle}</div>
      <Form className='smallForm'>
        <div className='lookup-body'>
          <Form.Group controlId='lookupkey' className='form-group row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>
                Key/Lookup Name<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-8'>
              <Form.Control
                type='text'
                placeholder='Enter Key or Lookup Name'
                // className='form-control'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setKey(e.target.value);
                }}
                value={key}
              />
              {keyError && <p className='inline-error'>{keyError}</p>}
            </div>
          </Form.Group>
          <Form.Group controlId='projectId' className='row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>
                Project<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-8'>
              <select
                id='projectId'
                name='projectId'
                className='form-select form-control'
                value={projectId}
                onChange={(e) => {
                  console.log('proj val: ', e.target.value);
                  setProjectId(e.target.value);
                }}
                onFocus={() => {
                  console.log('focus project');
                }}
                onBlur={() => {
                  console.log('on blur project');
                }}
              >
                <option value=''>Select Project</option>
                {projectOptions?.map((option, index) => (
                  <option
                    key={index}
                    value={option.id}
                    onClick={(e) => {
                      console.log('click project option: ', e, option, index);
                    }}
                  >
                    {option.name}
                  </option>
                ))}
              </select>
              {projectIdError && (
                <p className=' inline-error'>{projectIdError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group controlId='group' className='row'>
            <div className='col-sm-4 autoCompleteLabel'>
              Group<span className='text-red'>*</span>
            </div>
            <div className='col-sm-8'>
              <Autocomplete
                wrapperStyle={{ height: 'auto' }}
                onChange={(e) => {
                  setGroup(e.target.value);
                }}
                onSelect={(e) => {
                  setGroup(e.title);
                }}
                data={groupOptions}
              />
              {groupError && <p className='inline-error'>{groupError}</p>}
            </div>
          </Form.Group>
        </div>
        <div className='modal-footer form_modal_footer'>
          <Button
            className='btn btn-sm'
            color='cancel'
            type='button'
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
          <Button
            className='btn btn-sm'
            color='edit'
            type='button'
            onClick={() => saveDuplicateLookup()}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default DuplicateLookupForm;
