import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LookupCollection } from '@digitalworkflow/dwtranslateclient';
// import { ITreeViewData } from '../config/types';
import { AppConfig } from '../config/constants/AppConfig';
import { ITreeViewData } from '@digitalworkflow/dwtranslateclient/lib/Models/Module/ModuleCollection';

export interface LookupInitialState {
  groups: ITreeViewData[];
  lookups: any;
  openedKeys: string[];
  selectedRows: any;
  loading: boolean;
  error: string | null;
}

const initialState: LookupInitialState = {
  groups: [],
  lookups: [],
  openedKeys: [],
  selectedRows: [],
  loading: false,
  error: null
};

export const fetchLookupGroups = createAsyncThunk<
  ITreeViewData[],
  void,
  { rejectValue: string }
>('lookup/fetchLookupGroups', async (_, thunkAPI) => {
  try {
    const data: ITreeViewData[] = await LookupCollection.getTreeViewLookup();

    if (AppConfig.evReduxLogs) {
      console.log('%credux::groups::lookup', 'font-size:20px;color:brown');
      console.log(data);
    }

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch Lookup data.');
  }
});

export const fetchLookups = createAsyncThunk<
  any,
  void,
  { rejectValue: string }
>('lookup/fetchLookups', async (_, thunkAPI) => {
  try {
    const lookups = await LookupCollection.getAllKeys();

    if (AppConfig.evReduxLogs) {
      console.log('%credux::documents::lookup', 'font-size:20px;color:brown');
      console.log(lookups);
    }

    return lookups;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch Lookup data.');
  }
});

export const addLookup = createAsyncThunk<
  boolean,
  any,
  { rejectValue: string }
>('lookup/addLookup', async (data: any) => {
  try {
    const response = await LookupCollection.addLookup(
      data.key,
      data.codedValue,
      data.text,
      data.export_value,
      data.group
    );
    return !!response;
  } catch (error) {
    console.log(error, 'add lookup failed');
    return false;
  }
});

export const lookupSlice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    updateLookupGroups: (state, action) => {
      return {
        ...state,
        groups: action.payload
      };
    },
    pushOpenedKey: (state, action) => {
      return {
        ...state,
        openedKeys: [...state.openedKeys, action.payload]
      };
    },
    updateLookupSelectedRow: (state, action) => {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchLookupGroups.pending || fetchLookups.pending || addLookup.pending,
        (state) => ({
          ...state,
          loading: true,
          error: null
        })
      )
      .addCase(addLookup.fulfilled, (state) => {
        return {
          ...state,
          loading: false
        };
      })
      .addCase(fetchLookupGroups.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        groups: action.payload
      }))
      .addCase(fetchLookups.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        lookups: action.payload,
        openedKeys: [Object.keys(action.payload)[0]]
      }))
      .addCase(
        fetchLookupGroups.rejected || fetchLookups.rejected,
        (state, action) => ({
          ...state,
          loading: false,
          error: action.error.message || 'Something went wrong'
        })
      );
  }
});

export const { updateLookupGroups, pushOpenedKey, updateLookupSelectedRow } =
  lookupSlice.actions;
export default lookupSlice.reducer;
