import React, { useEffect, useState, useCallback } from 'react';
import { IGenericAnyObject } from '../../config/types';
import style from './Autocomplete.module.scss';
import SearchInput from '../SearchInput/SearchInput';

/** Props type for Autocomplete component */
type AutocompleteProps = {
  wrapperStyle?: React.CSSProperties;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect: (d: IGenericAnyObject, i: number) => void;
  onInputFocus?: () => void;
  onInputBlur?: () => void;
  data?: IGenericAnyObject[];
  isDisabled?: boolean;
  listHeight?: number | string;
};

/**
 * Shows Autocomplete component
 * @param {object} props for autocomplete component
 * @return {jsx} component
 */
function Autocomplete({
  wrapperStyle,
  onChange,
  onSelect,
  onInputBlur,
  onInputFocus,
  data,
  listHeight,
  isDisabled = false
}: AutocompleteProps) {
  const [showList, setShowList] = useState<boolean>(false);
  const [optionsData, setOptionsData] = useState<IGenericAnyObject[]>(
    data ?? []
  );
  const [value, setValue] = useState<string>('');
  const [isMouseOverList, setIsMouseOverList] = useState(false);

  function handleMouseEnter() {
    setIsMouseOverList(true);
  }

  function handleMouseLeave() {
    setIsMouseOverList(false);
  }

  function inputFocus() {
    setShowList(true);
    onInputFocus?.();
  }
  function inputBlur() {
    setTimeout(() => {
      if (!isMouseOverList) {
        setShowList(false);
        onInputBlur?.();
      }
    }, 250);
  }

  function inputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
    onChange(e);
  }

  const optionClick = useCallback(
    (d: IGenericAnyObject, i: number) => {
      setValue(d.title ?? '');
      onSelect(d, i);
      setShowList(false); // Close the dropdown list
    },
    [onSelect]
  );

  useEffect(() => {
    if (!data) return;

    if (value === '') {
      setOptionsData(data);
    } else {
      const filterData = data.filter((d: IGenericAnyObject) =>
        d.title.toLowerCase().includes(value.toLowerCase())
      );
      setOptionsData(filterData);
    }
  }, [value, data]);

  const searchInputStyle: React.CSSProperties = {};

  const searchWrapperStyle: React.CSSProperties = {
    borderWidth: '1px',
    borderColor: '#ced4da',
    borderRadius: '4px',
    height: '41px'
  };

  return (
    <div className={style.customAutocomplete} style={wrapperStyle}>
      <SearchInput
        id='keysInput'
        name='autocomplete-datalist'
        placeholder='Filter...'
        autoComplete='off'
        role='combobox'
        list=''
        value={value}
        onFocus={() => inputFocus()}
        onBlur={() => {
          inputBlur();
        }}
        onChange={(e: any) => inputChange(e)}
        wrapperStyle={searchWrapperStyle}
        searchInputStyle={searchInputStyle}
        disabled={isDisabled}
      />

      {optionsData && optionsData.length > 0 && (
        <datalist
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
          style={{
            display: showList ? 'block' : 'none',
            maxHeight: listHeight ?? 100
          }}
          role='listbox'
          id='autocomplete-datalist'
          className={style.autocompleteDatalist}
        >
          {optionsData.map((d: IGenericAnyObject, i: number) => (
            <option
              key={i}
              value={d.title ?? ''}
              onClick={() => optionClick(d, i)}
              className={style.autocompleteOptions}
            >
              {d.title ?? ''}
            </option>
          ))}
        </datalist>
      )}
    </div>
  );
}

export default Autocomplete;
