import { CollectionEvents } from '@digitalworkflow/dwtranslateclient/lib/Database/Types/CollectionEvents';
import dbLib from '../lib/db-lib';
import {
  IGenericAnyObject,
  ICollectionChangedEventSubscriber,
  IGridReferences
} from '../config/types';
import { updateOpenedEditorsOfAType } from './updateOpenedEditorsOfAType';
import { builderPageTypes } from '../config/constants/builderPageTypes';
import { AppConfig } from '../config/constants/AppConfig';

export function collectionChangedEventSubscriber({
  gridReferences,
  dictionariesFetcher,
  loopkupsFetcher,
  modulesFetcher,
  moduleSectionsFetcher,
  pagesFetcher,
  taskConfigsFetcher,
  workflowStatesFetcher
}: ICollectionChangedEventSubscriber) {
  const lookupEvents: CollectionEvents<any> =
    dbLib.instance.lookups.evCollectionEvents();

  const pageEvents: CollectionEvents<any> =
    dbLib.instance.pages.evCollectionEvents();

  const dataDictionaryFieldEvents: CollectionEvents<any> =
    dbLib.instance.datadictionaryfields.evCollectionEvents();

  const dataDictionaryTableEvents: CollectionEvents<any> =
    dbLib.instance.datadictionarytables.evCollectionEvents();

  const moduleEvents: CollectionEvents<any> =
    dbLib.instance.modules.evCollectionEvents();

  const moduleSectionEvents: CollectionEvents<any> =
    dbLib.instance.module_sections.evCollectionEvents();

  const workflowStateEvents: CollectionEvents<any> =
    dbLib.instance.workflow_states.evCollectionEvents();

  const taskConfigEvents: CollectionEvents<any> =
    dbLib.instance.task_configs.evCollectionEvents();

  lookupEvents.remoteReceived$.subscribe((changeEvent: IGenericAnyObject) => {
    if (changeEvent)
      callReduxFetcherAndEditorUpdator(
        loopkupsFetcher,
        builderPageTypes.lookups,
        changeEvent,
        gridReferences
      );
  });

  pageEvents.remoteReceived$.subscribe((changeEvent: IGenericAnyObject) => {
    if (changeEvent)
      callReduxFetcherAndEditorUpdator(
        pagesFetcher,
        builderPageTypes.pages,
        changeEvent,
        gridReferences
      );
  });

  moduleEvents.remoteReceived$.subscribe((changeEvent: IGenericAnyObject) => {
    if (changeEvent)
      callReduxFetcherAndEditorUpdator(
        modulesFetcher,
        builderPageTypes.modules,
        changeEvent,
        gridReferences
      );
  });

  dataDictionaryFieldEvents.remoteReceived$.subscribe(
    (changeEvent: IGenericAnyObject) => {
      if (changeEvent)
        callReduxFetcherAndEditorUpdator(
          dictionariesFetcher,
          builderPageTypes.dataDictionaries,
          changeEvent,
          gridReferences
        );
    }
  );

  dataDictionaryTableEvents.remoteReceived$.subscribe(
    (changeEvent: IGenericAnyObject) => {
      if (changeEvent)
        callReduxFetcherAndEditorUpdator(
          dictionariesFetcher,
          builderPageTypes.dataDictionaries,
          changeEvent,
          gridReferences
        );
    }
  );

  moduleSectionEvents.remoteReceived$.subscribe(
    (changeEvent: IGenericAnyObject) => {
      if (changeEvent)
        callReduxFetcherAndEditorUpdator(
          moduleSectionsFetcher,
          builderPageTypes.moduleSections,
          changeEvent,
          gridReferences
        );
    }
  );

  workflowStateEvents.remoteReceived$.subscribe(
    (changeEvent: IGenericAnyObject) => {
      if (changeEvent)
        callReduxFetcherAndEditorUpdator(
          workflowStatesFetcher,
          builderPageTypes.workflowStates,
          changeEvent,
          gridReferences
        );
    }
  );

  taskConfigEvents.remoteReceived$.subscribe(
    (changeEvent: IGenericAnyObject) => {
      if (changeEvent)
        callReduxFetcherAndEditorUpdator(
          taskConfigsFetcher,
          builderPageTypes.taskConfigs,
          changeEvent,
          gridReferences
        );
    }
  );
}

function callReduxFetcherAndEditorUpdator(
  fetcher: () => void,
  builderPageTypes: builderPageTypes,
  changeEvent: IGenericAnyObject,
  gridReferences: React.MutableRefObject<IGridReferences[]>
) {
  !!AppConfig.evLiveSyncLogs &&
    console.log('%cchange event', 'color:orange; font-size:15px;', changeEvent);

  fetcher();

  updateOpenedEditorsOfAType({
    type: builderPageTypes,
    changeEvent,
    gridReferences
  });
}
