import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ModuleSectionCollection } from '@digitalworkflow/dwtranslateclient';
import { AppConfig } from '../config/constants/AppConfig';
import { ITreeViewData } from '@digitalworkflow/dwtranslateclient/lib/Models/Module/ModuleCollection';

export interface ModuleSectionInitialState {
  groups: ITreeViewData[];
  moduleSections: any;
  selectedRows: any;
  loading: boolean;
  error: string | null;
}

const initialState: ModuleSectionInitialState = {
  groups: [],
  moduleSections: [],
  selectedRows: [],
  loading: false,
  error: null
};

export const fetchModuleSectionGroups = createAsyncThunk<
  ITreeViewData[],
  void,
  { rejectValue: string }
>('moduleSection/getAllGroupedSectionsByModules', async (_, thunkAPI) => {
  try {
    const data: ITreeViewData[] =
      await ModuleSectionCollection.getTreeViewModuleSections();

    if (AppConfig.evReduxLogs) {
      console.log(
        '%credux::groups::module-section',
        'font-size:20px;color:brown'
      );
      console.log(data);
    }

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      'Failed to fetch grouped module sections data.'
    );
  }
});

export const fetchModuleSections = createAsyncThunk<
  any,
  void,
  { rejectValue: string }
>('moduleSection/fetchModuleSections', async (_, thunkAPI) => {
  try {
    const moduleSections = await ModuleSectionCollection.getAllSections();

    if (AppConfig.evReduxLogs) {
      console.log(
        '%credux::documents::module-section',
        'font-size:20px;color:brown'
      );
      console.log(moduleSections);
    }

    return moduleSections;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch module sections data.');
  }
});

export const moduleSectionSlice = createSlice({
  name: 'moduleSection',
  initialState,
  reducers: {
    updateModuleSectionGroups: (state, action) => {
      return {
        ...state,
        groups: action.payload
      };
    },
    updateModuleSectionSelectedRow: (state, action) => {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchModuleSectionGroups.pending || fetchModuleSections.pending,
        (state) => ({
          ...state,
          loading: true,
          error: null
        })
      )
      .addCase(fetchModuleSectionGroups.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        groups: action.payload
      }))
      .addCase(fetchModuleSections.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        moduleSections: action.payload
      }))
      .addCase(
        fetchModuleSectionGroups.rejected || fetchModuleSections.rejected,
        (state, action) => ({
          ...state,
          loading: false,
          error: action.error.message || 'Something went wrong'
        })
      );
  }
});

export const { updateModuleSectionGroups, updateModuleSectionSelectedRow } =
  moduleSectionSlice.actions;
export default moduleSectionSlice.reducer;
