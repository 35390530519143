import { IRowNode } from 'ag-grid-community';
import { DocumentModalTypes, IActionMenuItem } from '../types';

/**
 * Get action menu items for documents
 * @return {object} columns definition for workflow states
 * */
export const getDocumentActionMenuItems = (
  onItemClick: (actionType: DocumentModalTypes, node: IRowNode) => void
): IActionMenuItem[] => [
  {
    title: 'Preview',
    icon: 'fa-light fa-eye',
    class: 'disabled',
    onClick: (node: IRowNode) => onItemClick(DocumentModalTypes.PREVIEW, node)
  },
  {
    title: 'History',
    icon: 'fa-light fa-clock-rotate-left',
    class: 'disabled',
    onClick: (node: IRowNode) => onItemClick(DocumentModalTypes.HISTORY, node)
  },
  {
    title: 'Duplicate',
    icon: 'fa-light fa-copy',
    class: 'disabled',
    onClick: (node: IRowNode) => onItemClick(DocumentModalTypes.COPY, node)
  },
  {
    title: 'Rename',
    icon: 'fa-solid fa-keyboard',
    class: 'positiveAction',
    onClick: (node: IRowNode) => onItemClick(DocumentModalTypes.RENAME, node)
  },
  {
    title: 'Replace',
    icon: 'fa-light fa-file-import',
    class: 'warningAction',
    onClick: (node: IRowNode) => onItemClick(DocumentModalTypes.REPLACE, node)
  },
  {
    title: 'Download',
    icon: 'fa-solid fa-download',
    class: 'successAction',
    onClick: (node: IRowNode) => onItemClick(DocumentModalTypes.DOWNLOAD, node)
  },
  {
    title: 'Delete',
    icon: 'fa-solid fa-trash',
    class: 'negativeAction',
    onClick: (node: IRowNode) => onItemClick(DocumentModalTypes.DELETE, node)
  }
];
