import lookup from './lookup';
import dataDictionary from './dataDictionary';
import dockLayout from './dockLayout';
import event from './event';
import page from './page';
import module from './module';
import moduleSection from './moduleSection';
import workflowState from './workflowState';
import taskConfig from './taskConfig';
import activeNode from './activeNode';
import projectDocument from './projectDocument';

const rootReducer = {
  lookup,
  dockLayout,
  event,
  dataDictionary,
  page,
  module,
  moduleSection,
  workflowState,
  taskConfig,
  projectDocument,
  activeNode
};

export default rootReducer;
