import { builderPageTypes as BPT } from './builderPageTypes';

/** Page types mapping to their actual names */
export const pageTypeMapping: Record<string, string> = {
  [BPT.pages]: 'Page',
  [BPT.lookups]: 'Lookup',
  [BPT.dataDictionaries]: 'Data Dictionary',
  [BPT.modules]: 'Module',
  [BPT.moduleSections]: 'Module Section',
  [BPT.workflowStates]: 'Workflow State',
  [BPT.taskConfigs]: 'Task Config'
};
