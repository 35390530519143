import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import '../Forms.css';
import { SelectDropdown } from '@digitalworkflow/dwreactcommon';
import { OptionType } from '../../../config/types';
import { Button } from 'reactstrap';
import {
  ModuleCollection,
  ModuleDocumentType
} from '@digitalworkflow/dwtranslateclient';
import { hasSpecialCharacters } from '../../../utils/checkSpecialCharacters';
import { FormErrorType } from '../../../pages/MasterEditor';

type ModuleFormProps = {
  toggleShow?: () => void;
  handleSave?: (data: Partial<ModuleDocumentType>) => Promise<boolean>;
  validationErrors?: FormErrorType[];
};

function ModuleForm({
  toggleShow,
  handleSave,
  validationErrors
}: ModuleFormProps) {
  const [moduleName, setModuleName] = useState<string>('');
  const [moduleNameError, setModuleNameError] = useState<any>(null);

  const [parent, setParent] = useState<OptionType | null>(null);
  const [parentError, setParentError] = useState<any>(null);
  const [parentOptions, setParentOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    setModuleNameError(null);
    setParentError(null);
    getParents();
  }, []);

  useEffect(() => {
    if (validationErrors) {
      for (let index = 0; index < validationErrors.length; index++) {
        const element = validationErrors[index];
        if (element.field_id === 'module_name') {
          setModuleNameError(element.error_message);
        }
      }
    }
  }, [validationErrors]);

  async function getParents() {
    const modules = await ModuleCollection.findAllModules(undefined, true);
    const data: any[] = [];
    for (const module of modules) {
      data.push({
        value: module.data.module_name,
        label: module.data.module_name,
        id: module.data.id
      });
    }
    setParentOptions([...data]);
  }

  function toggleModal() {
    if (!toggleShow) {
      return;
    }
    toggleShow();
  }

  const clearModuleErrors = () => {
    setModuleNameError(null);
    setParentError(null);
  }; // clear module errors

  async function saveModule(): Promise<void> {
    clearModuleErrors();
    let hasError = false;

    if (!moduleName || moduleName.trim() === '') {
      setModuleNameError('Module Name is required');
      hasError = true;
    }
    if (moduleName.trim() !== moduleName) {
      setModuleNameError(
        'Module Name should not have the spaces at the start or end'
      );
      hasError = true;
    }
    if (hasSpecialCharacters(moduleName)) {
      setModuleNameError('Table name contains special characters');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const result = await handleSave?.({
      module_name: moduleName,
      parent_id: parent?.id
    });
    if (result) {
      toggleModal();
    }
  }

  return (
    <div className='row'>
      <div className='modal-header h4'>New Module</div>
      <Form className='smallForm'>
        <div className='form-body'>
          <Form.Group controlId='basicField' className='form-group row'>
            <div className='col-sm-3 col-form-label'>
              <Form.Label>
                Module Name<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-9'>
              <Form.Control
                type='text'
                placeholder='Module Name'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setModuleName(e.target.value);
                }}
                value={moduleName}
              />
              {moduleNameError && (
                <p className='inline-error'>{moduleNameError}</p>
              )}
            </div>
          </Form.Group>

          <Form.Group controlId='parent' className='row'>
            <div className='col-sm-3 autoCompleteLabel'>Parent Module</div>
            <div className='col-sm-9'>
              <SelectDropdown
                placeholder='Select Parent Module'
                options={parentOptions}
                onChange={(v) => setParent(v as any)}
                value={parent}
                style={{
                  control: (baseStyle) => ({
                    ...baseStyle,
                    height: '42px',
                    borderColor: '#ced4da'
                  }),
                  singleValue: (baseStyle) => ({
                    ...baseStyle,
                    color: '#495057',
                    fontSize: '14px'
                  }),
                  placeholder: (baseStyle) => ({
                    ...baseStyle,
                    color: '#74788d',
                    fontSize: '14px'
                  })
                }}
              />
            </div>
            {parentError ?? (
              <Form.Text className='text-muted'>{parentError}</Form.Text>
            )}
          </Form.Group>
        </div>

        <div className='modal-footer'>
          <Button
            className='btn btn-sm'
            color='cancel'
            type='button'
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
          <Button
            className='btn btn-sm'
            color='edit'
            type='button'
            onClick={() => saveModule()}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default ModuleForm;
