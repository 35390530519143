import commonLib from '../../lib/common-lib';

const env = commonLib.getEnvironment();

/** Portal info definition */
export const portalInfo = {
  loginProfileId: 'wr3YP2DR',
  projectId: 'dwbuilder',
  id: '6528f959fcf7834e0cac8877',
  projectName: 'Digital Workflow Builder',
  projectLogo: '38lzj4jc.png',
  loginPortalUrl:
    env === 'staging'
      ? 'https://staging.logindev.dw.protovate.com/'
      : 'https://logindev.dw.protovate.com/',
  loginUri: 'login/wr3YP2DR',
  logoUri: 'api/v1/projects/logo/',
  agGridConfig: {
    theme: 'balham',
    pagination: false,
    sortableColumn: false
  },
  pagesCols4CurrentLine: ['view_groups', 'rownum'],
  enableDuplicate: ['lookups', 'data-dictionaries', 'pages'] // BPT.lookups, BPT.dataDictionaries
};
