import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Autocomplete from '../../Autocomplete/Autocomplete';
import { DataDictionaryTableCollection } from '@digitalworkflow/dwtranslateclient';
import { Button } from 'reactstrap';
import { hasSpecialCharacters } from '../../../utils/checkSpecialCharacters';
import { FormErrorType } from '../../../pages/MasterEditor';

/** Type definition for Data Table Type */
export type DataTableType = {
  tableName: string;
  description: string;
  group: string;
};

/** Props for Data Dictionary Form */
type DataDictionaryFormProps = {
  toggleShow?: () => void;
  handleSave?: ({
    tableName,
    description,
    group
  }: DataTableType) => Promise<boolean>;
  validationErrors?: FormErrorType[];
};

/**
 * Data Ditionary Form Component
 * @param {object} props for Data Dictionary Form component
 * @return {jsx} component
 */
function DataDictionaryForm({
  toggleShow,
  handleSave,
  validationErrors
}: DataDictionaryFormProps) {
  const [tableName, setTableName] = useState<string>('');
  const [tableNameError, settableNameError] = useState<any>(null);
  const [description, setDescription] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<any>(null);

  const [group, setGroup] = useState<string>('');
  const [groupError, setGroupError] = useState<any>(null);
  const [groupOptions, setGroupOptions] = useState<any>([]);

  /**
   * Clears errors related to Data Dictionary
   */
  const clearDataDictionaryErrors = () => {
    settableNameError(null);
    setDescriptionError(null);
    setGroupError(null);
  }; // clear data dictionary errors

  useEffect(() => {
    clearDataDictionaryErrors();
    getGroups();
  }, []);
  useEffect(() => {
    if (validationErrors) {
      for (let index = 0; index < validationErrors.length; index++) {
        const element = validationErrors[index];
        if (element.field_id === 'table_name') {
          settableNameError(element.error_message);
        }
      }
    }
  }, [validationErrors]);

  /**
   * Gets groups
   * @param {object} props for upload document component
   * @return {jsx} component
   */
  async function getGroups() {
    const groups = await DataDictionaryTableCollection.findAllGroups();
    const data: any[] = [];
    for (const group of groups) {
      data.push({ title: group });
    }
    setGroupOptions([...data]);
  }

  function toggleModal() {
    if (!toggleShow) {
      return;
    }
    toggleShow();
  }

  async function saveDataTable(): Promise<void> {
    clearDataDictionaryErrors();
    let dderror = false;

    if (!tableName || tableName.trim() === '') {
      settableNameError('Table name is required');
      dderror = true;
    }
    if (tableName.trim() !== tableName) {
      settableNameError(
        'Table name should not have the spaces at the start or end'
      );
      dderror = true;
    }
    if (hasSpecialCharacters(tableName)) {
      settableNameError('Table name contains special characters');
      dderror = true;
    }
    if (!description) {
      setDescriptionError('Description is required');
      dderror = true;
    }
    if (!group) {
      setGroupError('Group is required');
      dderror = true;
    }

    if (dderror) {
      return;
    }

    const result = await handleSave?.({
      tableName,
      description,
      group
    });
    if (result) {
      toggleModal();
    }
  }

  return (
    <div className='row'>
      <div className='modal-header h4'>New Data Dictionary Table</div>
      <Form className='smallForm'>
        <div className='dictionary-body'>
          <Form.Group controlId='codedValue' className='form-group row'>
            <div className='col-sm-3 col-form-label'>
              <Form.Label>
                Table Name<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-9'>
              <Form.Control
                type='text'
                placeholder='Table Name'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setTableName(e.target.value);
                }}
                value={tableName}
              />
              {tableNameError && (
                <p className='inline-error'>{tableNameError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group controlId='text' className='form-group row'>
            <div className='col-sm-3 col-form-label'>
              <Form.Label>
                Description<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-9'>
              <Form.Control
                type='text'
                placeholder='Description'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setDescription(e.target.value);
                }}
                value={description}
              />
              {descriptionError && (
                <p className='inline-error'>{descriptionError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group controlId='group' className='row'>
            <div className='col-sm-3 autoCompleteLabel'>
              Group<span className='text-red'>*</span>
            </div>
            <div className='col-sm-9'>
              <Autocomplete
                wrapperStyle={{ height: 'auto' }}
                onChange={(e) => {
                  setGroup(e.target.value);
                }}
                onSelect={(e) => {
                  setGroup(e.title);
                }}
                data={groupOptions}
              />
              {groupError && <p className='inline-error'>{groupError}</p>}
            </div>
          </Form.Group>
        </div>
        <div className='modal-footer form_modal_footer'>
          <Button
            className='btn btn-sm'
            color='cancel'
            type='button'
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
          <Button
            className='btn btn-sm'
            color='edit'
            type='button'
            onClick={() => saveDataTable()}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default DataDictionaryForm;
