import React from 'react';
export const RcGroup = {
  headless: {
    floatable: false,
    maximizable: false
  },
  'close-all': {
    floatable: true,
    closable: true,
    newWindow: true,
    panelExtra: (panelData: any, context: any) => {
      const buttons = [];
      if (panelData.parent.mode !== 'window') {
        buttons.push(
          <span
            className='my-panel-extra-btn'
            key='maximize'
            title={
              panelData.parent.mode === 'maximize' ? 'Restore' : 'Maximize'
            }
            onClick={() => {
              context.dockMove(panelData, null, 'maximize');
            }}
          >
            {panelData.parent.mode === 'maximize' ? '▬' : '▣'}
          </span>
        );
        buttons.push(
          <span
            className='my-panel-extra-btn'
            key='new-window'
            title='Open in new window'
            onClick={() => context.dockMove(panelData, null, 'new-window')}
          >
            ⇪
          </span>
        );
      }

      return <div>{buttons}</div>;
    }
  }
};
