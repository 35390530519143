import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

interface ILoadingScreenProps {
  comment?: string;
}

function LoadingScreen({ comment }: ILoadingScreenProps) {
  return (
    <div className='h-100 w-100 d-flex flex-column justify-content-center align-items-center'>
      <Spinner animation='border' role='status' />
      <span>{comment ?? 'Sync in Progress'}</span>
    </div>
  );
}

export default LoadingScreen;
