import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PageCollection } from '@digitalworkflow/dwtranslateclient';
import { AppConfig } from '../config/constants/AppConfig';
import { ITreeViewData } from '@digitalworkflow/dwtranslateclient/lib/Models/Module/ModuleCollection';

function areArraysNotEqual<T>(
  array1: T[] | undefined,
  array2: T[] | undefined
): boolean {
  if (array1 === undefined && array2 === undefined) return false;

  if (array1 === undefined || array2 === undefined) return true;

  if (array1.length !== array2.length) return true;

  for (let i = 0; i < array1.length; i++) {
    if (!isObjectEqual(array1?.[i], array2?.[i])) return true;
  }

  return false;
}

function isObjectEqual(obj1: any, obj2: any): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (
      key === 'execute_if' ||
      key === 'field_id' ||
      key === 'id' ||
      key === 'option_1' ||
      key === 'option_2' ||
      key === 'option_3' ||
      key === 'rownum' ||
      key === 'style' ||
      key === 'text_en' ||
      key === 'view_groups'
    ) {
      if (obj1[key] !== obj2[key]) {
        if (
          !(
            key === 'rownum' &&
            (obj1[key]?.unknown === obj2[key] ||
              obj1[key] === obj2[key]?.unknown ||
              obj1[key]?.unknown === obj2[key]?.unknown)
          )
        )
          return false;
      }
    }
  }

  return true;
}

export interface PageInitialState {
  groups: ITreeViewData[];
  pages: any;
  selectedRows: any;
  loading: boolean;
  error: string | null;
}

const initialState: PageInitialState = {
  groups: [],
  pages: [],
  selectedRows: [],
  loading: false,
  error: null
};

export const fetchPageGroups = createAsyncThunk<
  ITreeViewData[],
  void,
  { rejectValue: string }
>('page/fetchPageGroups', async (_, thunkAPI) => {
  try {
    const data = await PageCollection.getTreeViewPage();

    for (const group of data) {
      if (group.parent !== 0) {
        const obj = await PageCollection.getPageById(group.id as string);
        group.data = {
          isUnPublished: areArraysNotEqual(obj?.data.steps, obj?.data.pub_steps)
        };
      }
    }

    if (AppConfig.evReduxLogs) {
      console.log('%credux::groups::page', 'font-size:20px;color:brown');
      console.log(data);
    }

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch Page data.');
  }
});

export const fetchPages = createAsyncThunk<any, void, { rejectValue: string }>(
  'pages/fetchPages',
  async (_, thunkAPI) => {
    try {
      const pages = await PageCollection.getAllPages();

      const newPages = pages.map((page) => {
        return {
          ...page,
          data: {
            ...page.data,
            steps: Array.isArray(page.data.steps)
              ? page.data.steps.map((step, index) => {
                  if ('id' in step) delete step?.id;
                  return {
                    ...step,
                    rownum: index + 1
                  };
                })
              : page.data.steps
          }
        };
      });

      if (AppConfig.evReduxLogs) {
        console.log('%credux::documents::page', 'font-size:20px;color:brown');
        console.log(newPages);
      }

      return newPages;
    } catch (error) {
      return thunkAPI.rejectWithValue('Failed to fetch Page data.');
    }
  }
);

export const addPage = createAsyncThunk<boolean, any, { rejectValue: string }>(
  'page/addPage',
  async (data: any) => {
    try {
      const page = PageCollection.createPage();
      page.data = data;
      const response = await page.save();
      return !!response;
    } catch (error) {
      console.log(error, 'add page failed');
      return false;
    }
  }
);

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    updatePageGroups: (state, action) => {
      return {
        ...state,
        groups: action.payload
      };
    },
    updatePageSelectedRow: (state, action) => {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchPageGroups.pending || fetchPages.pending || addPage.pending,
        (state) => ({
          ...state,
          loading: true,
          error: null
        })
      )
      .addCase(addPage.fulfilled, (state) => {
        return {
          ...state,
          loading: false
        };
      })
      .addCase(fetchPageGroups.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        groups: action.payload
      }))
      .addCase(fetchPages.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        pages: action.payload,
        openedKeys: [Object.keys(action.payload)[0]]
      }))
      .addCase(
        fetchPageGroups.rejected || fetchPages.rejected,
        (state, action) => ({
          ...state,
          loading: false,
          error: action.error.message || 'Something went wrong'
        })
      );
  }
});

export const { updatePageGroups, updatePageSelectedRow } = pageSlice.actions;
export default pageSlice.reducer;
