import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './CustomTooltip.scss';

/** Props for Custom Tooltip component */
interface CustomTooltipProps {
  children: React.ReactNode;
  show: boolean;
  target: HTMLElement | null;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  moveLeft?: string;
}

/**
 * Custom Tooltip component
 * @param {object} props for custom tooltip component
 * @return {jsx} component
 */
const CustomTooltip: FC<CustomTooltipProps> = ({
  children,
  show,
  target,
  placement,
  moveLeft
}) => {
  if (!show || !target) {
    return null;
  }

  const { top, left, width, height } = target.getBoundingClientRect();
  const tooltipStyle = {
    top: `${top}px`,
    left: `${left + width + 10}px`, // Adjust as needed
    transform:
      placement === 'top'
        ? `translate(calc(${'-' + (moveLeft ?? '50%')} - 10px),-110%)`
        : placement === 'right'
        ? `translate(0px,0px)`
        : placement === 'bottom'
        ? `translate(calc(-50% - 10px),calc(${height}px + 10px))`
        : placement === 'left'
        ? `translate(calc(calc(-${width}px + -20px) + -110%),0px)`
        : ''
  };

  const tooltipTopStyle = {
    top: `${top}px`,
    left: `${left + width + 10}px`, // Adjust as needed
    transform: `translate(calc(${'-' + (moveLeft ?? '50%')} - 10px),-110%)`
  };

  const [isTop, setIsTop] = useState<boolean>(false);

  useEffect(() => {
    setIsTop(top + 75 > window.innerHeight);
  }, []);

  return ReactDOM.createPortal(
    <div
      style={isTop ? tooltipTopStyle : tooltipStyle}
      className={`custom-tooltip ${show ? 'show' : ''}`}
    >
      {children}
    </div>,
    document.body
  );
};

export default CustomTooltip;
