import { LogicEngineDataDateTime } from '@digitalworkflow/dwtranslateclient';

export const formatDate = (dateObj: LogicEngineDataDateTime) => {
  if (dateObj && dateObj.value) {
    const dateValue = new Date(dateObj.value);
    if (!isNaN(dateValue.getTime())) {
      return dateValue.toUTCString();
    }
  }
  return '';
};
