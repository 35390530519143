/** Common library for general purpose functions */
import { AuthService, WorkgroupType } from '@digitalworkflow/dwloginclient';
import { portalInfo } from '../config/constants/portalInfo';
import {
  CommonHelper,
  LogicEngineProcessor
} from '@digitalworkflow/dwtranslateclient';
import { storageKeys } from '../config/constants/storageKeys';
const commonLib: any = {};

/**
 * Gets and returns base url of portal
 * @return {string} baseURL of
 */
commonLib.getBaseUrl = function (): string {
  return window.location.origin + '/';
}; // get base url end

/**
 * Gets and returns complete url with given path i.e. uri
 * @params {string} path
 * @return {string} url = baseURL + path
 */
commonLib.getUrl = function (path: string): string {
  path = path ?? '';
  const baseUrl = commonLib.getBaseUrl();
  return new URL(path, baseUrl).toString();
}; // get url ends

/**
 * Checks if the current host is localhost or not
 * @return {boolean}
 */
commonLib.isLocalhost = function (): boolean {
  // Check if the hostname is "localhost" or starts with "127." (IPv4 loopback)
  return (
    window.location.hostname === 'localhost' ||
    window.location.hostname.startsWith('127.')
  );
};

/**
 * Redirects user to the given url if redirection is enabled for active environment
 * @params {string} url
 * @params {boolean} asLink
 * @return void
 */
commonLib.redirect = function (url: string, asLink = true) {
  if (commonLib.isLocalhost()) {
    if (
      process.env.REACT_APP_REDIRECT_TO_LOGIN &&
      !+process.env.REACT_APP_REDIRECT_TO_LOGIN
    ) {
      // Do not redirect if marked as 0
      return;
    }
  }

  asLink ? (window.location.href = url) : window.location.replace(url);
}; // redirect ends

/**
 * Returns Login APIs URL from poral information
 * @return {string}
 */
commonLib.loginApisUrl = function () {
  return portalInfo.loginPortalUrl;
}; // login apis url ends

/**
 * Sets project name / ID from portal info for Auth Service and DWTranslateClient
 */
commonLib.setProjectName = function () {
  AuthService.setProjectName(portalInfo.loginProfileId); // dwbuilder
  CommonHelper.setProjectName(portalInfo.projectId);
}; // set project name ends

/**
 * Returns auth service instance
 * @return {boolean}
 */
commonLib.authService = function () {
  return AuthService.instance();
};

/**
 * Returns workgroups array after getting from storage
 * @return {object} array workgroups array with each item having id, value and label keys
 */
commonLib.getWorkgroups = function () {
  const workGroups = JSON.parse(
    localStorage.getItem(storageKeys.userKey) || '{}'
  )?.work_groups;
  const workGroupsNames = workGroups?.map((workgroup: WorkgroupType) => ({
    id: workgroup.id || '',
    value: workgroup.formatted_workgroup_name || '',
    label: workgroup.formatted_workgroup_name || ''
  }));

  return workGroupsNames;
};

/**
 * Gets and validates code, returns true or false after validation
 * @params {string} code
 * @return {boolean}
 */
commonLib.validateLogicString = function (code: string) {
  const val = code?.trim();
  const logicLinter = new LogicEngineProcessor();

  return logicLinter.configureLogicCell(val);
}; // validate logic string

/**
 * Formats given if condition and returns formatted string
 * @param {string} code
 * @return {boolean}
 */
commonLib.formatIfCommand = function (code: string) {
  let ifCommand = code;
  const startBraceIndex = ifCommand.indexOf('{{');
  const endBraceIndex = ifCommand.indexOf('}}');
  const valRes = commonLib.validateLogicString(code);

  if (valRes?.isValid) {
    ifCommand = valRes.formatted; // valRes.formatted ?? ifCommand;

    if (
      ifCommand &&
      ifCommand.trim() !== '' &&
      startBraceIndex !== -1 &&
      endBraceIndex !== -1 &&
      startBraceIndex < endBraceIndex
    ) {
      return ifCommand;
    } // valid string with {{ and }}

    if (ifCommand && ifCommand.trim() !== '' && startBraceIndex === -1) {
      ifCommand = `{{ ${ifCommand} }}`;
      return ifCommand;
    }
  }

  ifCommand = commonLib.ensureDoubleCurlyBrackets(ifCommand);

  return ifCommand;
}; // format if command

/**
 * Ensures the input is enclosed in double curly braces and return the string after adding the missing ones
 * @param {string} input
 * @return {string} formatted string
 */
commonLib.ensureDoubleCurlyBrackets = function (input: string): string {
  if (input === undefined || input === '') {
    return '';
  }

  let str = input?.trim();

  if (str === '') {
    return str;
  }

  if (str.startsWith('{{') && !str.endsWith('}}')) {
    str = str + ' }}';
    return str;
  } // string starts with {{ but not ending with }}

  const startPattern = /^{{+/;
  const endPattern = /}}+$/;

  // Ensure there are no more than double brackets at the start or end
  const sanitizedStart = str.replace(startPattern, '');
  const sanitizedEnd = sanitizedStart.replace(endPattern, '');
  str = sanitizedEnd.trim();

  if (str === '') {
    return '';
  }

  str = commonLib.ensureParentheses(str); // ensure parentheses

  // Concatenate {{ at the start and }} at the end
  str = '{{ ' + str + ' }}';

  return str;
}; // ensure double curly brackets

/**
 * Ensures the input is enclosed in double parentheses and return the string after adding the missing ones
 * @param {string} input
 * @return {string} formatted string
 */
commonLib.ensureParentheses = function (str: string): string {
  // Ensure parentheses are matched
  const stack = [];
  const parenthesesRegex = /[()]/g;

  let match;
  while ((match = parenthesesRegex.exec(str)) !== null) {
    const [char] = match;

    if (char === '(') {
      stack.push(char);
    } else if (char === ')') {
      if (stack.length === 0) {
        str += ')';
      } else {
        stack.pop();
      }
    }
  }

  // Add missing parentheses
  while (stack.length > 0) {
    str += ')';
    stack.pop();
  }

  return str;
}; // Ensure parentheses ends

/**
 * Ensures there is a closing brace for each opening brace and returns formatted string
 * @param {string} string
 * @return {string} formatted string
 */
commonLib.ensureBraces = function (str: string): string {
  // Ensure braces are matched
  const stack = [];
  const bracesRegex = /[{}]/g;

  let match;
  while ((match = bracesRegex.exec(str)) !== null) {
    const [char] = match;

    if (char === '{') {
      stack.push(char);
    } else if (char === '}') {
      if (stack.length === 0) {
        str += '}';
      } else {
        stack.pop();
      }
    }
  }

  // Add missing braces
  while (stack.length > 0) {
    str += '}';
    stack.pop();
  }

  return str;
}; // Ensure braces ends

/**
 * Ensures the input string is enclosed in double braces and return the string after adding the missing ones
 * @param {string} str
 * @return {string} formatted string
 */
commonLib.ensureDoubleBraces = function (str: string): string {
  // Ensure double braces are matched
  const stack = [];
  const bracesRegex = /[{{}}]/g;

  let match;
  while ((match = bracesRegex.exec(str)) !== null) {
    const [char] = match;

    if (char === '{{') {
      stack.push(char);
    } else if (char === '}}') {
      if (stack.length === 0) {
        str += ' }}';
      } else {
        stack.pop();
      }
    }
  }

  // Add missing braces
  while (stack.length > 0) {
    str += ' }}';
    stack.pop();
  }

  return str;
}; // Ensure double braces ends

/**
 * Gets the environment from env file and returns it
 * @return {string} environment
 */
commonLib.getEnvironment = function (): string {
  return process.env.REACT_APP_ENV || 'dev';
}; // get env

/**
 * Capitalizes the given string and returns it
 * @param {string} str
 * @return {string} formatted string
 */
commonLib.capitalize = function (str: string = ''): string {
  str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });

  return str;
};

export default commonLib;
