import React, { useRef, useState } from 'react'; //
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AdminPortalLayout } from '@digitalworkflow/dwreactcommon';
import { menuItems } from './config/constants/menuItems';
import { profileMenuItems } from './config/constants/profileMenuItems';
import logoImage from './assets/images/bgis-logo.png';
import logoSmImage from './assets/images/bgis-logo-sm.png';
import { useAppDispatch } from './redux/store'; // RootState,
import './App.css';
import { adminPortalLayoutRoutes } from './router/routes';
import { portalInfo } from './config/constants/portalInfo';
import { CommonHelper } from '@digitalworkflow/dwtranslateclient';
import { batch } from 'react-redux';
import { fetchLookupGroups, fetchLookups } from './redux/lookup';
import {
  fetchDataDictionary,
  fetchDataDictionaryGroups
} from './redux/dataDictionary';
import { fetchPageGroups, fetchPages } from './redux/page';
import { fetchModuleGroups, fetchModules } from './redux/module';
import {
  fetchModuleSectionGroups,
  fetchModuleSections
} from './redux/moduleSection';
import {
  fetchWorkflowStateGroups,
  fetchWorkflowStates
} from './redux/workflowState';
import { fetchTaskConfigGroups, fetchTaskConfigs } from './redux/taskConfig';
import { IGenericAnyObject } from './config/types';
import Sync from './pages/Sync';
import Authenticate from './pages/Authenticate';
import { storageKeys } from './config/constants/storageKeys';
import { fetchProjectDocuments } from './redux/projectDocument';

export interface DropdownMenuItem {
  text_en: string;
  icon?: string;
  action?: Function;
}

function App() {
  const apiUrl = portalInfo.loginPortalUrl;
  const dwbProjectLogo = portalInfo.projectLogo;
  const logoUri = portalInfo.logoUri;
  const logoUrlPrefix = apiUrl + logoUri;

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSynced, setIsSynced] = useState(false);
  const [username, setUsername] = useState<string>('Guest');
  const [projects, setProjects] = useState<IGenericAnyObject[] | []>([]);
  const [projectLogo] = useState<string>(
    dwbProjectLogo ? logoUrlPrefix + dwbProjectLogo : logoImage
  );
  const [projectSmallLogo, setProjectSmallLogo] = useState<string>(logoSmImage);
  const [hasDropdownMenu, setHasDropdownMenu] = useState<boolean>(true);
  const [dropdownValue, setDropdownValue] = useState<string>('');
  const [dropdownMenuItems, setDropdownMenuItems] = useState<string[] | []>([]); // { text_en: 'BGIS DW Builder', action: console.log }

  const redirectToUrl = useRef('');
  const initialRedirectUrl = useRef('/editor/pages');
  const dispatch = useAppDispatch();

  function setProjectInLibrary(name: string) {
    let isProjectAvailable = false;
    let commanHelperProjectName = '';
    for (const project of projects) {
      if (project.name_en === name) {
        CommonHelper.setProjectName(project.project_id);
        commanHelperProjectName = project.project_id;
        isProjectAvailable = true;
        break;
      }
    }
    if (isProjectAvailable === false) {
      console.error(`Project:${name} Not Found`);
    }

    fetchAllGroups();
    fetchAllData();
    setDropdownValue(name);
    const lastActiveProject = {
      project_id: commanHelperProjectName,
      project_name: name
    };
    localStorage.setItem(
      'lastActiveProject',
      JSON.stringify(lastActiveProject)
    );
  }

  function fetchAllGroups() {
    batch(() => {
      dispatch(fetchLookupGroups());
      dispatch(fetchDataDictionaryGroups());
      dispatch(fetchPageGroups());
      dispatch(fetchModuleGroups());
      dispatch(fetchModuleSectionGroups());
      dispatch(fetchWorkflowStateGroups());
      dispatch(fetchTaskConfigGroups());
    });
  }

  function fetchAllData() {
    batch(() => {
      dispatch(fetchLookups());
      dispatch(fetchDataDictionary());
      dispatch(fetchPages());
      dispatch(fetchModules());
      dispatch(fetchModuleSections());
      dispatch(fetchWorkflowStates());
      dispatch(fetchTaskConfigs());
      dispatch(fetchProjectDocuments());
    });
  }

  function storeTokenLocally() {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      localStorage.setItem(storageKeys.tokenKey, token);
    } // token received
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path='/authenticate'
            element={<Authenticate setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
            path='/sync'
            element={
              !isAuthenticated ? (
                ((redirectToUrl.current = location.pathname),
                (<Navigate replace to='authenticate' />))
              ) : (
                <Sync
                  fetchAllData={() => fetchAllData()}
                  fetchAllGroups={() => fetchAllGroups()}
                  setDropdownMenuItems={setDropdownMenuItems}
                  setHasDropdownMenu={setHasDropdownMenu}
                  setProjectSmallLogo={setProjectSmallLogo}
                  setProjects={setProjects}
                  setUsername={setUsername}
                  setIsSynced={setIsSynced}
                  setDropdownValue={setDropdownValue}
                  redirectToUrl={redirectToUrl.current}
                  initialRedirectUrl={initialRedirectUrl.current}
                />
              )
            }
          />
          <Route
            path='/'
            element={
              !isAuthenticated ? (
                ((redirectToUrl.current = location.pathname),
                storeTokenLocally(),
                localStorage.setItem('dwbuilder_hash', document.location.hash),
                (<Navigate replace to='authenticate' />))
              ) : !isSynced ? (
                ((redirectToUrl.current = location.pathname),
                (<Navigate replace to='sync' />))
              ) : (
                <AdminPortalLayout
                  menuItems={menuItems}
                  profileMenuItems={profileMenuItems}
                  logoImage={projectLogo}
                  logoSmImage={projectSmallLogo}
                  hasDropdownMenu={
                    dropdownMenuItems.length > 0 ? hasDropdownMenu : false
                  }
                  dropdownOptions={dropdownMenuItems}
                  dropdownValue={dropdownValue}
                  dropdownChangeHandler={(t: string) => {
                    console.log('123DD Change: ', t);
                    setProjectInLibrary(t);
                  }}
                  username={username}
                  showWorldClock={false}
                />
              )
            }
          >
            {adminPortalLayoutRoutes.map((route, id) => (
              <Route key={id} path={route.path} element={<route.element />} />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
