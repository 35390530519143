import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ModuleCollection } from '@digitalworkflow/dwtranslateclient';
import { AppConfig } from '../config/constants/AppConfig';
import { ITreeViewData } from '@digitalworkflow/dwtranslateclient/lib/Models/Module/ModuleCollection';

export interface ModuleInitialState {
  groups: ITreeViewData[];
  modules: any;
  selectedRows: any;
  loading: boolean;
  error: string | null;
}

const initialState: ModuleInitialState = {
  groups: [],
  modules: [],
  selectedRows: [],
  loading: false,
  error: null
};

export const fetchModuleGroups = createAsyncThunk<
  ITreeViewData[],
  void,
  { rejectValue: string }
>('module/fetchModuleGroups', async (_, thunkAPI) => {
  try {
    const data: ITreeViewData[] = await ModuleCollection.getTreeViewModules();

    if (AppConfig.evReduxLogs) {
      console.log('%credux::groups::module', 'font-size:20px;color:brown');
      console.log(data);
    }

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch module data.');
  }
});

export const fetchModules = createAsyncThunk<
  any,
  void,
  { rejectValue: string }
>('module/fetchModules', async (_, thunkAPI) => {
  try {
    const modules = await ModuleCollection.getAllModules();

    if (AppConfig.evReduxLogs) {
      console.log('%credux::documents::module', 'font-size:20px;color:brown');
      console.log(modules);
    }

    return modules;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch module data.');
  }
});

export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    updateModuleGroups: (state, action) => {
      return {
        ...state,
        groups: action.payload
      };
    },
    updateModuleSelectedRow: (state, action) => {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchModuleGroups.pending || fetchModules.pending, (state) => ({
        ...state,
        loading: true,
        error: null
      }))
      .addCase(fetchModuleGroups.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        groups: action.payload
      }))
      .addCase(fetchModules.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        modules: action.payload
      }))
      .addCase(
        fetchModuleGroups.rejected || fetchModules.rejected,
        (state, action) => ({
          ...state,
          loading: false,
          error: action.error.message || 'Something went wrong'
        })
      );
  }
});

export const { updateModuleGroups, updateModuleSelectedRow } =
  moduleSlice.actions;
export default moduleSlice.reducer;
