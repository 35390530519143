import DockLayout from 'rc-dock';
import { GridMetaData, IPageGridUpdateData } from '../../config/types';
import {
  LogicEngineDataDateTime,
  Page,
  PageCollection,
  PageDocumentType,
  ValidationResults
} from '@digitalworkflow/dwtranslateclient';
import { generateTab } from '../generateTab';
import { builderPageTypes } from '../../config/constants/builderPageTypes';
import { removeUndefinedKeyFromObject } from '../removeUndefinedKeyFromObject';

export async function handleUpdatePage(
  ref: DockLayout | null | undefined,
  id: string,
  data: IPageGridUpdateData,
  reduxUpdater: () => void
) {
  let saveResults: ValidationResults = {} as ValidationResults;

  // update document in db
  const { oldPage, isUpdated, newPage } = await _updatePageInDB(id, data);

  saveResults =
    (isUpdated && isUpdated.saveResults.validation) ||
    ({} as ValidationResults);

  if (isUpdated && isUpdated.saveResults.did_save) {
    // if document is updated in db, update redux
    reduxUpdater();

    // if ref provided, find and update currently opened editor tab and grid
    if (ref) {
      // find tab
      const tab: any = ref.find(oldPage?.data.route + '_tab');
      if (tab) {
        // update grid meta, data(if applicable), and tab title(if applicable)
        const oldTabProps = (tab as any).content.props.children.props.children;
        newPage &&
          _updatePageTabAndGrid(newPage, oldTabProps, ref, saveResults);
      }
    }
  }

  return saveResults;
}

async function _updatePageInDB(id: string, data: IPageGridUpdateData) {
  const oldPage = await PageCollection.getPageById(id);

  const isUpdated = await PageCollection.updatePage(
    oldPage?.data.route ?? '',
    removeUndefinedKeyFromObject({
      route: data?.route,
      title_en: data?.title_en,
      // page_type: data?.page_type,
      description: data?.description,
      group: data?.group
    }) as Partial<PageDocumentType>
  );

  const newPage = await PageCollection.getPageById(id);

  return { oldPage, isUpdated, newPage };
}

async function _updatePageTabAndGrid(
  newPage: Page,
  tabProps: any,
  ref: DockLayout,
  saveResults: ValidationResults
) {
  const newGridMeta: GridMetaData = {
    createBy: newPage?.data.create_by ?? '',
    updateBy: newPage?.data.updated_by ?? '',
    createDt: newPage?.data.create_dt ?? ({} as LogicEngineDataDateTime),
    updateDt: newPage?.data.updated_dt ?? ({} as LogicEngineDataDateTime),
    pageRoute: newPage?.data.route,
    pageTitle: newPage?.data.title_en,
    pageDescription: newPage?.data.description,
    pageType: newPage?.data.page_type,
    documentId: newPage?.data.id ?? ''
  };

  const tabUpdated = ref.updateTab(
    tabProps[1].props.children.props.id + '_tab',
    generateTab({
      ...tabProps[1].props.children.props,
      gridMetaData: newGridMeta,
      id: newPage?.data.route,
      title: newPage?.data.route ?? '',
      group: builderPageTypes.pages,
      metaGridCols: tabProps[0].props.children.props.columnDefs,
      metaGridDefaultCol: tabProps[0].props.children.props.defaultColDef,
      onMetaCellValueChanged:
        tabProps[0].props.children.props.onCellValueChanged
    })
  );

  if (!tabUpdated) {
    saveResults.addError('updateTab', 'failed to update opened tab');
  }
}
