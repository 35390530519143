import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { TaskConfigCollection } from '@digitalworkflow/dwtranslateclient';
import { AppConfig } from '../config/constants/AppConfig';
import { ITreeViewData } from '@digitalworkflow/dwtranslateclient/lib/Models/Module/ModuleCollection';

export interface TaskConfigInitialState {
  groups: ITreeViewData[];
  taskConfigs: any;
  selectedRows: any;
  loading: boolean;
  error: string | null;
}

const initialState: TaskConfigInitialState = {
  groups: [],
  taskConfigs: [],
  selectedRows: [],
  loading: false,
  error: null
};

export const fetchTaskConfigGroups = createAsyncThunk<
  ITreeViewData[],
  void,
  { rejectValue: string }
>('taskConfig/fetchTaskConfigGroups', async (_, thunkAPI) => {
  try {
    const groups: ITreeViewData[] =
      await TaskConfigCollection.getTreeViewTaskConfig();

    console.log(
      'AppConfig.evReduxLogs',
      AppConfig.evReduxLogs,
      process.env.REACT_APP_EV_REDUX_LOGS
    );
    if (AppConfig.evReduxLogs) {
      console.log('%credux::groups::task-config', 'font-size:20px;color:brown');
      console.log(groups);
    }

    return groups;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch taskConfig data.');
  }
});

export const fetchTaskConfigs = createAsyncThunk<
  any,
  void,
  { rejectValue: string }
>('taskConfig/fetchTaskConfigs', async (_, thunkAPI) => {
  try {
    const data = await TaskConfigCollection.getAllTaskConfig();

    if (AppConfig.evReduxLogs) {
      console.log(
        '%credux::documents::task-config',
        'font-size:20px;color:brown'
      );
      console.log(data);
    }

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch taskConfig data.');
  }
});

export const taskConfigSlice = createSlice({
  name: 'taskConfig',
  initialState,
  reducers: {
    updateTaskConfigGroups: (state, action) => {
      return {
        ...state,
        groups: action.payload
      };
    },
    updateTaskConfigSelectedRow: (state, action) => {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchTaskConfigGroups.pending || fetchTaskConfigs.pending,
        (state) => ({
          ...state,
          loading: true,
          error: null
        })
      )
      .addCase(fetchTaskConfigGroups.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        groups: action.payload
      }))
      .addCase(fetchTaskConfigs.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        taskConfigs: action.payload
      }))
      .addCase(
        fetchTaskConfigGroups.rejected || fetchTaskConfigs.rejected,
        (state, action) => ({
          ...state,
          loading: false,
          error: action.error.message || 'Something went wrong'
        })
      );
  }
});

export const { updateTaskConfigGroups, updateTaskConfigSelectedRow } =
  taskConfigSlice.actions;
export default taskConfigSlice.reducer;
