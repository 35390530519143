import React, { useState, useRef, useEffect } from 'react';
import {
  Tree,
  getBackendOptions,
  MultiBackend,
  NodeModel
} from '@minoru/react-dnd-treeview';
import { DndProvider } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   // faArrowDown,
//   // faArrowRight,
//   faChevronDown,
//   faChevronRight
// } from '@fortawesome/free-solid-svg-icons';
import {
  faFolder,
  faFolderOpen
  // faSquareChevronDown,
  // faSquareChevronRight
  // faSquareChevronRight,
  // faSquareChevronDown
} from '@fortawesome/pro-light-svg-icons';
import { faFile } from '@fortawesome/pro-duotone-svg-icons';
import { RootState, useAppDispatch } from '../../redux/store';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import styles from './GroupTreeView.module.css';
import SearchInput from '../SearchInput/SearchInput';
import { Button } from 'reactstrap';
import {
  // PageCollection,
  // DataDictionaryTableCollection,
  // LookupCollection,
  // ModuleCollection,
  // ModuleSectionCollection,
  // TaskConfigCollection,
  // WorkflowStateCollection,
  ValidationResults
} from '@digitalworkflow/dwtranslateclient';
import { fetchLookups, fetchLookupGroups } from '../../redux/lookup';
import {
  fetchDataDictionary,
  fetchDataDictionaryGroups
} from '../../redux/dataDictionary';
import { fetchPageGroups, fetchPages } from '../../redux/page';
import { fetchModuleGroups, fetchModules } from '../../redux/module';
import {
  fetchModuleSectionGroups,
  fetchModuleSections
} from '../../redux/moduleSection';
import { useSelector } from 'react-redux';
import {
  fetchTaskConfigGroups,
  fetchTaskConfigs
} from '../../redux/taskConfig';
import {
  fetchWorkflowStateGroups,
  fetchWorkflowStates
} from '../../redux/workflowState';
import { builderPageTypes as BPT } from '../../config/constants/builderPageTypes';
import {
  ContextMenuTrigger,
  ContextMenu,
  ContextMenuItem
} from 'rctx-contextmenu';
import { portalInfo } from '../../config/constants/portalInfo';
import { setNode2Duplicate } from '../../redux/event';
import { toast } from 'react-toastify';
import {
  hasSpecialCharacters,
  hasOnlySpecialCharacters
} from '../../utils/checkSpecialCharacters';
import { updateDataEverywhere } from '../../utils';
// import { LookupCollection } from '@digitalworkflow/dwtranslateclient';

// type TreeViewProps = {
//   treeData: NodeModel[];
// };

// function TreeView({treeData}:TreeViewProps) {

type CustomNodeProps = {
  node: NodeModel<any>;
  depth: number;
  isOpen: boolean;
  isEdit: boolean;
  setIsEdit: (value: boolean) => void;
  pageType: string;
  selectedNode: string;
  handleGroupClick?: (value: string) => void;
  onToggle: (id: NodeModel['id']) => void;
  rightClickedNodeId: number | string | null;
  setRightClickedNodeId: (id: number | string | null) => void;
  updatedNode: (node: NodeModel<any>) => Promise<ValidationResults | false>;
  isActiveGroup?: boolean;
};

const CustomNode: React.FC<CustomNodeProps> = ({
  node,
  depth,
  selectedNode,
  pageType,
  handleGroupClick,
  onToggle,
  rightClickedNodeId,
  setRightClickedNodeId,
  isOpen,
  isEdit,
  setIsEdit,
  updatedNode,
  isActiveGroup = false
}) => {
  const [inputValue, setInputValue] = useState<string>(node.text);
  const [inputError, setInputError] = useState<string | null>(null);
  const [inputProcessing, setInputProcessing] = useState<boolean>(false);

  const validationMessage = 'Input should be alpha-numeric with symbol(s) ';
  const charactersAllowed = '_';
  const charactersAllowedExtended = '_ / \\ : - ';

  useEffect(() => {
    if (isActiveGroup && !isOpen) {
      onToggle(node.id);
    }
  }, [isActiveGroup, selectedNode]);

  const indent = depth * 30;

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    onToggle(node.id);
  };

  const handleClick = () => {
    handleGroupClick && !isEdit && handleGroupClick(node.text);
  };
  let CaretShow = true;
  if (node.data && node.data.noChildren) {
    CaretShow = false;
  }

  const inputValidation = (param: string) => {
    if (!param || param.trim() === '') {
      setInputError('Input value is required');
      return false;
    }
    if (param.trim() !== param) {
      setInputError('Input value should not have spaces at the start or end');
      return false;
    }
    if (hasOnlySpecialCharacters(param)) {
      setInputError(
        validationMessage +
          (pageType === BPT.pages
            ? charactersAllowedExtended
            : charactersAllowed)
      );
      return false;
    }
    if (pageType === BPT.pages && hasSpecialCharacters(param, true)) {
      setInputError(validationMessage + charactersAllowedExtended);
      return false;
    }
    if (pageType !== BPT.pages && hasSpecialCharacters(param)) {
      setInputError(validationMessage + charactersAllowed);
      return false;
    }

    return true;
  };

  async function handleUpdate(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    setInputProcessing(true);

    if (inputValidation(inputValue)) {
      const newNode = { ...node, text: inputValue };
      const isUpdated = await updatedNode(newNode);

      if (isUpdated && isUpdated.has_errors)
        setInputError(isUpdated.results[0].error_message);
      else {
        e?.stopPropagation();
        setIsEdit(false);
        setRightClickedNodeId(null);
        setInputValue(newNode.text);
      }
    }

    setInputProcessing(false);
  }

  function handleCancel(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e?.stopPropagation();
    setIsEdit(false);
    setRightClickedNodeId(null);
    setInputValue(node.text);
    setInputError(null);
  }

  return (
    <div
      className={`tree-node ${styles.root}`}
      style={{ paddingInlineStart: indent }}
      onClick={handleToggle}
    >
      <div>
        {node.droppable && (
          <div className={styles.expandIconWrapper}>
            {CaretShow && (
              <FontAwesomeIcon
                size='xs'
                icon={isOpen ? faCaretDown : faCaretRight}
                style={{ marginRight: 5 }}
              />
            )}

            <FontAwesomeIcon
              size='sm'
              icon={isOpen && CaretShow ? faFolderOpen : faFolder}
            />
          </div>
        )}
      </div>
      <div className={styles.labelGridItem} onClick={handleClick}>
        {isEdit && node.id === rightClickedNodeId ? (
          <div className={styles.editGroup} style={{ flexWrap: 'wrap' }}>
            <input
              className={styles.input}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                setInputError(null);
              }}
              onClick={(e) => e.stopPropagation()}
            />
            {inputError && <div className='inline-error'>{inputError}</div>}
            <div className={styles.editGroup}>
              <Button
                size='sm'
                className='btn-edit'
                onClick={(e) => handleUpdate(e)}
                disabled={
                  inputValue.length === 0 ||
                  inputValue === node.text ||
                  inputProcessing
                }
              >
                Update
              </Button>
              <Button
                size='sm'
                className='btn-cancel'
                onClick={(e) => handleCancel(e)}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <ContextMenuTrigger id='contextmenu'>
            <div
              onContextMenu={(e) => {
                e.preventDefault();
                setIsEdit(false);
                setRightClickedNodeId(node.id);
              }}
            >
              {!node.droppable && (
                <FontAwesomeIcon
                  size='sm'
                  icon={faFile}
                  style={{ marginRight: 5 }}
                />
              )}
              <span
                className={
                  selectedNode === node.text &&
                  (node.parent !== 0 || pageType === 'modules')
                    ? styles.selectedText
                    : node?.data?.isUnPublished
                    ? styles.unpublished
                    : ''
                }
              >
                {node.text}
              </span>
            </div>
          </ContextMenuTrigger>
        )}
      </div>
    </div>
  );
};

interface ITreeView {
  pageType: string;
  data?: any[];
  handleGroupClick?: (value: string) => void;
  setShowModal: () => void;
  setShowDuplicateModal?: () => void;
  handleDrop: (newTreeData: any) => void;
  tabRef: any;
}
function TreeView({
  pageType,
  data,
  handleGroupClick,
  setShowModal,
  setShowDuplicateModal,
  handleDrop,
  tabRef
}: ITreeView) {
  const dispatch = useAppDispatch();
  const [treeData, setTreeData] = useState<any[]>([]);
  // require('../../config/constants/sampleGroups.json')
  const selectedNodeRedux = useSelector(
    (state: RootState) => state.activeNode.selectedNode
  );
  const [filteredData, setFilteredData] = useState<any[]>(treeData);
  const [value, setValue] = useState('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [enableDuplicate, setEnableDuplicate] = useState<boolean>(false);
  const [isRootNode, setIsRootNode] = useState<boolean>(false);
  const [rightClickedNodeId, setRightClickedNodeId] = useState<
    number | string | null
  >(null);
  const [node2Change, setNode2Change] = useState<string | null>(null);
  const [activeParent, setActiveParent] = useState<number | string | null>(
    null
  );
  const treeRef = useRef<any>(null);
  // const handleDrop = (newTreeData: any) => setFilteredData(newTreeData);

  useEffect(() => {
    if (data) {
      setTreeData(data);
      setFilteredData(data);

      if (portalInfo.enableDuplicate.includes(pageType)) {
        // BPT[pageType])
        setEnableDuplicate(true);
      }
    }
  }, [data]);

  useEffect(() => {
    const filteredDatatemp = [...filteredData];
    const indexActive = filteredDatatemp.findIndex(
      (d: any) => d.text === selectedNodeRedux
    );
    if (indexActive >= 0) {
      const parentIndex = filteredDatatemp.findIndex(
        (d: any) => filteredDatatemp[indexActive].parent === d.id
      );
      if (parentIndex >= 0) {
        setActiveParent(filteredDatatemp[parentIndex].id);
      }
    }
  }, [selectedNodeRedux]);

  /**
   * @TODO @Refactor
   * all these should be passed as prop
   */
  function fetchLookupsData(fetchGroups: boolean = false) {
    dispatch(fetchLookups());
    fetchGroups && dispatch(fetchLookupGroups());
  }

  function fetchDDData(fetchGroups: boolean = false) {
    dispatch(fetchDataDictionary());
    fetchGroups && dispatch(fetchDataDictionaryGroups());
  }

  function fetchPagesData(fetchGroups: boolean = false) {
    dispatch(fetchPages());
    fetchGroups && dispatch(fetchPageGroups());
  }

  function fetchModulesData(fetchGroups: boolean = false) {
    dispatch(fetchModules());
    fetchGroups && dispatch(fetchModuleGroups());
  }

  function fetchModuleSectionsData(fetchGroups: boolean = false) {
    dispatch(fetchModuleSections());
    fetchGroups && dispatch(fetchModuleSectionGroups());
  }

  function fetchTaskConfigsData(fetchGroups: boolean = false) {
    dispatch(fetchTaskConfigs());
    fetchGroups && dispatch(fetchTaskConfigGroups());
  }

  function fetchWorkflowStatesData(fetchGroups: boolean = false) {
    dispatch(fetchWorkflowStates());
    fetchGroups && dispatch(fetchWorkflowStateGroups());
  }

  function callDataAccordingToPage(pageType: string) {
    switch (pageType) {
      case BPT.pages:
        fetchPagesData(true);
        break;
      case BPT.lookups:
        fetchLookupsData(true);
        break;
      case BPT.dataDictionaries:
        fetchDDData(true);
        break;
      case BPT.modules:
        fetchModulesData(true);
        fetchModuleSectionsData(true);
        fetchTaskConfigsData(true);
        fetchWorkflowStatesData(true);
        break;
      case BPT.moduleSections:
        fetchModuleSectionsData(true);
        break;
      case BPT.taskConfigs:
        fetchTaskConfigsData(true);
        break;
      case BPT.workflowStates:
        fetchWorkflowStatesData(true);
        break;
      default:
        console.log('on invalid route');
        break;
    }
  }
  /**
   * @TODO @Refactor
   * ends
   */

  function inputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
    if (e.target.value === '') return setFilteredData(treeData);
    let newTree;
    if (e.target.value.length < value.length) {
      newTree = treeData.filter(
        // (node) =>
        (node: NodeModel) =>
          node.parent === 0 ||
          node.text?.toLowerCase().includes(e.target.value.toLowerCase())
      );
    } else {
      newTree = filteredData.filter(
        // (node) =>
        (node: NodeModel) =>
          node.parent === 0 ||
          node.text?.toLowerCase().includes(e.target.value.toLowerCase())
      );
    }
    setFilteredData(newTree);
  }

  function inputFocus() {
    treeRef.current?.openAll();
  }

  function inputBlur() {
    if (value.length) return;
    console.log('closing');
    // treeRef.current?.closeAll();
  }

  /**
   * @TODO @Refactor
   * should be fixed on translate client
   */
  function checkEmpty(node: NodeModel<any>) {
    if (
      pageType === BPT.pages ||
      pageType === BPT.lookups ||
      pageType === BPT.dataDictionaries
    ) {
      if (node.droppable !== true) {
        return true;
      }
      const currentNodeId = node.id;
      return filteredData.some((el) => el.parent === currentNodeId);
    } else {
      return true;
    }
  }

  /**
   * @TODO @Refactor
   */
  const setRightClickedNodeIdOnRClick = async (nodeKey: any) => {
    let sourceNode = nodeKey;
    let currentObj = nodeKey;

    if (data?.find((v) => v.id === nodeKey)?.parent === 0) {
      setIsRootNode(true);
    } else {
      // not group or root node
      setIsRootNode(false);
      if (pageType === BPT.dataDictionaries) {
        if (data?.find((v) => v.id === nodeKey)) {
          currentObj = data?.find((v) => v.id === nodeKey);
          sourceNode = currentObj?.text;
        }
      }
    }
    setRightClickedNodeId(nodeKey);
    dispatch(setNode2Duplicate(sourceNode));
    setNode2Change(sourceNode);
  };

  // async function checkIfAlreadyExist(node: NodeModel<any>) {
  //   let exists = true;

  //   function setExists(value: boolean) {
  //     exists = value;
  //   }

  //   switch (pageType) {
  //     case BPT.dataDictionaries:
  //       (await DataDictionaryTableCollection.getTableByName(node.text)) !==
  //         undefined || setExists(false);
  //       break;
  //     case BPT.lookups:
  //       (await LookupCollection.findAllByKey(node.text)).length > 0 ||
  //         setExists(false);
  //       break;
  //     case BPT.pages:
  //       (await PageCollection.findRoute(node.text)) !== undefined ||
  //         setExists(false);
  //       break;
  //     case BPT.modules:
  //       (await ModuleCollection.getModuleByName(node.text)) !== undefined ||
  //         setExists(false);
  //       break;
  //     case BPT.moduleSections:
  //       (await ModuleSectionCollection.findByName(
  //         node.text,
  //         node.parent as string
  //       )) !== undefined || setExists(false);
  //       break;
  //     case BPT.workflowStates:
  //       (await WorkflowStateCollection.findByName(
  //         node.text,
  //         node.parent as string
  //       )) !== undefined || setExists(false);
  //       break;
  //     default:
  //       (await TaskConfigCollection.findByName(
  //         node.text,
  //         node.parent as string
  //       )) !== undefined || setExists(false);
  //       break;
  //   }
  //   if (exists)
  //     toast.error('Duplicate name is not supported', {
  //       containerId: 'main-toast'
  //     });
  //   return exists;
  // }

  async function handleUpdatedNode(node: NodeModel) {
    if (data) {
      let result: ValidationResults | false = false;
      switch (pageType) {
        case BPT.pages:
          result = await updateDataEverywhere(
            tabRef,
            node.id as string,
            {
              group: node.parent === 0 ? node.text : undefined,
              route: node.parent !== 0 ? node.text : undefined
            },
            BPT.pages,
            () => callDataAccordingToPage(BPT.pages)
          );
          break;

        case BPT.dataDictionaries:
          result = await updateDataEverywhere(
            tabRef,
            node.id as string,
            {
              group: node.parent === 0 ? node.text : undefined,
              table_name: node.parent !== 0 ? node.text : undefined
            },
            BPT.dataDictionaries,
            () => callDataAccordingToPage(BPT.dataDictionaries)
          );
          break;

        case BPT.lookups:
          result = await updateDataEverywhere(
            tabRef,
            node.id as string,
            {
              group: node.parent === 0 ? node.text : undefined,
              key: node.parent !== 0 ? node.text : undefined
            },
            BPT.lookups,
            () => callDataAccordingToPage(BPT.lookups)
          );
          break;

        case BPT.modules:
          result = await updateDataEverywhere(
            tabRef,
            node.id as string,
            { name: node.text },
            BPT.modules,
            () => callDataAccordingToPage(BPT.modules)
          );
          break;

        case BPT.moduleSections:
          if (node.parent !== 0) {
            result = await updateDataEverywhere(
              tabRef,
              node.id as string,
              { name: node.text },
              BPT.moduleSections,
              () => callDataAccordingToPage(BPT.moduleSections)
            );
          } else {
            toast.error('Can not edit Module name', {
              containerId: 'main-toast'
            });
          }
          break;

        case BPT.taskConfigs:
          if (node.parent !== 0) {
            result = await updateDataEverywhere(
              tabRef,
              node.id as string,
              { name: node.text },
              BPT.taskConfigs,
              () => callDataAccordingToPage(BPT.taskConfigs)
            );
          } else {
            toast.error('Can not edit Module name', {
              containerId: 'main-toast'
            });
          }
          break;

        case BPT.workflowStates:
          if (node.parent !== 0) {
            result = await updateDataEverywhere(
              tabRef,
              node.id as string,
              { name: node.text },
              BPT.workflowStates,
              () => callDataAccordingToPage(BPT.workflowStates)
            );
          } else {
            toast.error('Can not edit Module name', {
              containerId: 'main-toast'
            });
          }
          break;
      }

      // if (result !== true && result !== undefined) {
      //   const { saveResults } = result as { saveResults: any };
      //   console.log(saveResults);
      //   if (
      //     saveResults &&
      //     saveResults.validation &&
      //     saveResults.validation.results[0] &&
      //     saveResults.validation.results[0].error_message
      //   ) {
      //     toast.error(
      //       saveResults.validation.results[0].error_message ??
      //         'An error occured!'
      //     );
      //   }
      // }

      // if ((result as ValidationResults)?.has_errors) {
      //   toast.error(
      //     (result as ValidationResults).results[0]?.error_message ??
      //       'An Error Occured',
      //     {
      //       containerId: 'main-toast'
      //     }
      //   );
      // }

      // callDataAccordingToPage(pageType);
      return result;
    }
    return false;
  }

  return (
    <div>
      <div className={styles.searchContainer}>
        <div className='custom-autocomplete flex-1'>
          <SearchInput
            className='autocomplete-input'
            id='keysInput'
            name='autocomplete-datalist'
            placeholder='Filter...'
            value={value}
            onChange={(e: any) => inputChange(e)}
            onFocus={() => inputFocus()}
            onBlur={() => inputBlur()}
          />
        </div>
        <div>
          <Button
            color='add'
            className='btn btn-sm'
            onClick={() => setShowModal()}
          >
            + New
          </Button>
        </div>
      </div>
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <Tree
          ref={treeRef}
          tree={filteredData}
          rootId={0}
          onDrop={handleDrop}
          canDrag={(node: any) => node.droppable === false}
          render={(node, { depth, isOpen, onToggle }) =>
            checkEmpty(node) ? (
              <CustomNode
                pageType={pageType}
                node={node}
                depth={depth}
                isOpen={isOpen}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                selectedNode={selectedNodeRedux}
                onToggle={onToggle}
                handleGroupClick={handleGroupClick}
                rightClickedNodeId={rightClickedNodeId}
                setRightClickedNodeId={setRightClickedNodeIdOnRClick} // setRightClickedNodeIds
                isActiveGroup={node.id === activeParent}
                updatedNode={(node) => handleUpdatedNode(node)}
              />
            ) : (
              <></>
            )
          }
          classes={{
            root: styles.treeRoot,
            draggingSource: styles.draggingSource,
            dropTarget: styles.dropTarget
          }}
        />
      </DndProvider>
      <ContextMenu id='contextmenu' className={styles.contextmenu}>
        <ContextMenuItem
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            setIsEdit(true);
          }}
        >
          <i className='fa fa-pencil fa-sm' />
          <span className={styles.contextmenuText}>Edit</span>
        </ContextMenuItem>
        {enableDuplicate && !isRootNode ? (
          <ContextMenuItem
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setShowDuplicateModal && setShowDuplicateModal();

              if (node2Change) {
                dispatch(setNode2Duplicate(node2Change)); // rightClickedNodeId
              }
            }}
          >
            <i className='fa fa-files fa-sm' />
            <span className={styles.contextmenuText}>Duplicate</span>
          </ContextMenuItem>
        ) : (
          <></>
        )}
      </ContextMenu>
    </div>
  );
}

export default TreeView;
