/* eslint-disable camelcase */
import { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import {
  createGridMetaFromDocument,
  extractGridMetaFromTab,
  generateTab,
  insertEmptyRow
} from '../../../utils';
import {
  getDefaultColumns,
  getDataDictionaryColumns,
  getDdMetaGridCols,
  getMetaGridDefaultCol,
  getDDValidationsColumns
} from '../../../config/constants/columnDefinitions';
import {
  // DataDictionaryField,
  DataDictionaryFieldCollection,
  DataDictionaryFieldDocumentType,
  DataDictionaryTableCollection
} from '@digitalworkflow/dwtranslateclient';
import { portalInfo } from '../../../config/constants/portalInfo';
import {
  ITabParams,
  GridApi,
  GridMetaData,
  RowDataTransaction,
  IGenericAnyObject
} from '../../../config/types';
import { builderPageTypes as BPT } from '../../../config/constants/builderPageTypes';
import { updateCurrentGridDetail } from '../../CurrentGridDetail/updateCurrentGridDetail';
import { GlobalHashHelper } from '../../../utils/HashHelper';
import { DataDictionaryFieldValidation } from '@digitalworkflow/dwtranslateclient/lib/Models/DataDictionaryField/DataDictionaryFieldSchema';
import { toast } from 'react-toastify';

interface IHandleSaveProps {
  field: string;
  field_label_en: string;
  field_type: string;
  validations: DataDictionaryFieldValidation[];
  related_table: string;
  related_id: string;
  default_value: string;
  options: string;
  export_name: string;
  table_name: string;
}

/**
 * @return Promise<boolean>
 * @returns true - if lookup is added successfully
 * @returns false - if lookup is not added successfully
 */
export async function addDataDictionaryField(
  data?: IHandleSaveProps,
  e?: CellValueChangedEvent,
  destination?: string
): Promise<any> {
  let res;
  if (e) {
    console.log('add e:>>', e);
    res = await DataDictionaryFieldCollection.addField({
      field: e.data.field ?? '',
      field_label_en: e.data.field_label_en ?? '',
      field_type: e.data.field_type ?? '',
      validations: e.data.validations ?? [],
      related_table: e.data.related_table ?? '',
      related_id: e.data.related_id ?? '',
      default_value: e.data.default_value ?? '',
      options: e.data.options ?? '',
      export_name: e.data.export_name ?? '',
      table_name: destination || (e.data.table_name ?? '')
    });
    if (res.saveResults?.did_save) {
      e.data.id = res.record._data.id;
    } else {
      e.data.field = 'Fields are duplicated';
      e.api.refreshCells({ columns: ['field'] });
    }
  } else if (data) {
    res = await DataDictionaryFieldCollection.addField({
      field: data.field ?? '',
      field_label_en: data.field_label_en ?? '',
      field_type: (data.field_type as any) ?? '',
      validations: data.validations ?? ([] as DataDictionaryFieldValidation[]),
      related_table: data.related_table ?? '',
      related_id: data.related_id ?? '',
      default_value: data.default_value ?? '',
      options: data.options ?? '',
      export_name: data.export_name ?? '',
      table_name: destination || (data.table_name ?? '')
    });
  }
  console.log('res:>>>', res);
  return res;
}

/**
 * @return Promise<boolean>
 * @returns true - if lookup is updated successfully
 * @returns false - if lookup is not updated successfully
 */
async function updateDDField(e: CellValueChangedEvent): Promise<void> {
  const updateResult = await DataDictionaryFieldCollection.updateField(
    e.data.id,
    {
      field: e.data.field ?? '',
      field_label_en: e.data.field_label_en ?? '',
      field_type: e.data.field_type ?? '',
      validations: e.data.validations ?? [],
      related_table: e.data.related_table ?? '',
      related_id: e.data.related_id ?? '',
      default_value: e.data.default_value ?? '',
      options: e.data.options ?? '',
      export_name: e.data.export_name ?? ''
    }
  );
  if (updateResult && updateResult?.saveResults?.did_save) {
    toast.success('DataDictionary was updated successfully.', {
      containerId: 'main-toast'
    });
  } else if (
    updateResult &&
    updateResult?.saveResults?.validation?.results &&
    updateResult?.saveResults?.validation?.results.length > 0
  ) {
    const { results } = updateResult?.saveResults?.validation;
    for (let i = 0; i < results.length; i++) {
      toast.error(results[i].error_message, {
        containerId: 'main-toast'
      });
    }
  }
}

// /**
//  * @return Promise<boolean>
//  * @returns true - if dataDictionaryField is deleted successfully
//  * @returns false - if dataDictionaryField is not deleted successfully
//  */
async function delDataDictionaryField(
  e: CellValueChangedEvent
): Promise<boolean> {
  console.log('delete DD:>>>', e);
  const dataDictionaryField = await DataDictionaryFieldCollection.deleteDDField(
    e.data.id,
    true
  );

  const transaction = {
    remove: [e.data]
  };
  e.api.applyTransaction(transaction);

  return dataDictionaryField && true;
}

function areAllValuesEmpty(obj: IHandleSaveProps): boolean {
  const keysOfIHandleSaveProps: Array<keyof IHandleSaveProps> = [
    'field',
    'field_label_en',
    // 'field_type',
    'validations',
    'related_table',
    'related_id',
    'default_value',
    'options',
    'export_name'
  ];
  for (const key of keysOfIHandleSaveProps) {
    if (
      (key === 'validations' &&
        obj[key].length === 1 &&
        obj[key][0].enabled !== '' &&
        obj[key][0].message !== '' &&
        obj[key][0].condition !== '') ||
      (key === 'validations' && obj[key].length > 1)
    ) {
      return false;
    } else if (
      obj[key] !== undefined &&
      typeof obj[key] === 'string' &&
      (obj[key] as string).trim() !== ''
    ) {
      return false;
    }
  }
  return true;
}

/**
 * creates a new Lookup object (Partial<DataDictionaryFieldDocumentType> type) with empty string values
 * @returns new Lookup object
 */
export function createDataDictionaryFieldRowData(
  key: string
): Partial<DataDictionaryFieldDocumentType> {
  const newDD: Partial<DataDictionaryFieldDocumentType> = {
    table_name: key
  };
  return newDD;
}

export async function openDataDictionaryTab({
  ref,
  data: dataDictionaryFields,
  groups,
  rows,
  title,
  firstTab = true,
  gridReferences,
  fetchData,
  setCurrentEventAPI,
  setSelectedRowIndex,
  setSelectedRows,
  setRowSelectedFlag,
  setTabsRef,
  updateData = () => console.log('updateDataFunctionNotFound'),
  openTab,
  updateMetaData,
  lookupData,
  dataDictionaryData
}: ITabParams) {
  if (!ref) {
    console.log('no ref provided, returning...');
    return;
  }

  const defaultColDef: ColDef = getDefaultColumns(
    portalInfo.agGridConfig.sortableColumn
  );
  const columnDefs: ColDef[] = getDataDictionaryColumns(
    updateData,
    lookupData,
    dataDictionaryData,
    openTab
  );

  function insertEmptyRowSub(currentEventApi?: GridApi) {
    if (!currentEventApi) {
      console.log('no event');
      return;
    }
    const colDef: ColDef[] = currentEventApi.getColumnDefs() as ColDef[];
    const data: IGenericAnyObject = {};

    colDef?.splice(0, 1);
    colDef?.forEach((col) => {
      data[col.field as string] = '';
    });

    data.type = 'warning';

    const transaction: RowDataTransaction = {
      add: [data],
      addIndex: -1
    };
    currentEventApi.applyTransaction(transaction);
  }

  function removeFirstEmptyRow(currentEventApi: GridApi) {
    if (!currentEventApi) {
      console.log('Grid API is not available');
      return;
    }

    const rowData: any[] = [];
    currentEventApi.forEachNode((node) => rowData.push(node.data));

    let emptyRowIndex = -1; // Initialize with -1 to indicate "not found" by default
    rowData.some(function (row, indx) {
      if (
        row.enabled?.trim() === '' &&
        row.condition?.trim() === '' &&
        row.message?.trim() === ''
      ) {
        console.log('Empty row index: ', indx, row);
        emptyRowIndex = indx;
        return true; // Stop iteration once the first match is found
      }
      return false; // Continue iteration if not a match
    });

    if (emptyRowIndex !== -1) {
      const transaction = {
        remove: [rowData[emptyRowIndex]]
      };
      currentEventApi.applyTransaction(transaction);
    } else {
      console.log('No empty row found');
    }
  }

  async function handleDetailGridCellValueChanged(
    e: CellValueChangedEvent,
    params: any
  ) {
    await onSubCellValueChanged(e, params);
    fetchData();
  }

  const detailCellRendererParams = getDDValidationsColumns(
    handleDetailGridCellValueChanged,
    updateData
  );

  let data: Partial<DataDictionaryFieldDocumentType>[] = [];
  let key: string = '';

  if (firstTab) {
    if (!groups?.length) {
      console.log('groups empty, returning...');
      return;
    }
    if (!dataDictionaryFields?.length) {
      console.log('ddData empty, returning...');
      return;
    }

    key = GlobalHashHelper.hasOption('tab')
      ? GlobalHashHelper.getOption('tab')
      : dataDictionaryFields[0].table._data.table_name;

    dataDictionaryFields[0].fields.forEach((ddRow: any) => {
      data.push(ddRow._data);
    });

    data.push(createDataDictionaryFieldRowData(key));
  } else {
    if (!rows || !title) {
      console.log('rows||title empty, returning...');
      return;
    }

    key = title;
    data = [...rows, createDataDictionaryFieldRowData(title)];
  }

  function onUpdateAPI(api: GridApi) {
    if (api.getSelectedRows() && api.getSelectedRows().length > 0) {
      // Sort the selected rows.
      const selectedNodes = api.getSelectedNodes();
      const selectedIndices = selectedNodes.map((node) => node.rowIndex);
      selectedIndices.sort((a: any, b: any) => a - b);
      const selectedRows = selectedIndices
        .filter((index) => index !== null)
        .map((index) => api.getDisplayedRowAtIndex(index as number)?.data);
      // --------------------------------
      setSelectedRows?.(selectedRows);
      setRowSelectedFlag(true);
    }
    setCurrentEventAPI(api);
  }

  /**
   * last row edit - creates new row, add dataDictionaryField in DB
   * other row edit - edits current row, edit dataDictionaryField in DB
   * delete all cells data - deletes object from DB
   * @param e event triggred on cell value change
   */
  async function onCellValueChanged(e: CellValueChangedEvent) {
    const allEmpty = areAllValuesEmpty(e.data);

    /**
     * @reason
     * Line commented as it was causing issues in ag-grid.
     * It will out focus ag grid cell after edit
     */
    // setSelectedRows([e.data]);

    const currentRowIndex = e.api.getFocusedCell()?.rowIndex;

    if (!e.data.id) {
      if (currentRowIndex === e.api.getDisplayedRowCount() - 1) {
        insertEmptyRow(
          BPT.dataDictionaries,
          e.api.getSelectedNodes()[0].data.table_name,
          undefined,
          e.api
        );
      }
      await addDataDictionaryField(undefined, e);
    } else {
      if (allEmpty) {
        await delDataDictionaryField(e);
      } else {
        await updateDDField(e);
      }
    }
  }

  async function localCellValueChanged(e: CellValueChangedEvent) {
    await onCellValueChanged(e);

    fetchData();

    /**
     * @reason
     * Code block commented as it was causing issues in ag-grid
     */

    /*
    const ddTable = await DataDictionaryTableCollection.getTableByName(
      e.data.table_name
    );

    if (!ddTable || !ref) return;

    const newGridMeta: GridMetaData = createGridMetaFromDocument(ddTable);

    updateCurrentGridDetail({
      type: BPT.dataDictionaries,
      ref,
      data: newGridMeta
    });
    */
  }

  async function onSubCellValueChanged(
    e: CellValueChangedEvent<any, any>,
    params: CellValueChangedEvent<any, any>
  ) {
    const rowIndex =
      e.rowIndex !== null ? e.rowIndex : params.data.validations.length;

    if (
      e.data.enabled?.trim() === '' &&
      e.data.condition?.trim() === '' &&
      e.data.message?.trim() === ''
    ) {
      if (rowIndex < params.data.validations.length - 1) {
        removeFirstEmptyRow(e.api);
        params.data.validations.splice(rowIndex, 1);
      }
    } else {
      const currentRowIndex = e.api.getFocusedCell()?.rowIndex;

      if (currentRowIndex === e.api.getDisplayedRowCount() - 1) {
        insertEmptyRowSub(e.api);
        params.data.validations.push({
          type: 'warning',
          enabled: '',
          condition: '',
          message: ''
        });
      }
      params.data.validations[rowIndex] = e.data;
    }
    await updateDDField(params);
  }

  const ddTable = await DataDictionaryTableCollection.getTableByName(
    data[0].table_name as string
  );

  const gridMetaData: GridMetaData | undefined =
    ddTable && createGridMetaFromDocument(ddTable);

  const metaGridCols = getDdMetaGridCols();
  const metaGridDefaultCol = getMetaGridDefaultCol();

  data.forEach((row) => {
    if (
      !row.validations ||
      (typeof row.validations === 'string' && row.validations === 'NA')
    ) {
      row.validations = [];
    }
    if (Array.isArray(row.validations)) {
      if (
        row.validations[row.validations?.length - 1]?.enabled !== '' &&
        row.validations[row.validations?.length - 1]?.message !== '' &&
        row.validations[row.validations?.length - 1]?.condition !== ''
      )
        row.validations.push({
          type: 'warning',
          enabled: '',
          condition: '',
          message: ''
        });
    }
  });

  const tab = generateTab({
    id: key,
    title: key,
    group: BPT.dataDictionaries,
    data: data,
    theme: portalInfo.agGridConfig.theme,
    pagination: portalInfo.agGridConfig.pagination,
    defaultColDef,
    columnDefs,
    masterDetail: true,
    detailCellRendererParams,
    gridReferences,
    gridMetaData,
    metaGridCols,
    metaGridDefaultCol,
    onCellValueChanged: async (e: CellValueChangedEvent) =>
      localCellValueChanged(e),
    onUpdateAPI: (api: GridApi) => onUpdateAPI(api),
    setTabsRef,
    setSelectedRowIndex,
    onMetaCellValueChanged: (e: CellValueChangedEvent) => {
      updateMetaData && updateMetaData(e.data);
    }
  });

  if (firstTab) {
    ref.updateTab('Loading', tab, true);

    updateCurrentGridDetail({
      type: BPT.dataDictionaries,
      ref,
      data: extractGridMetaFromTab(ref, key + '_tab')
    });
  } else {
    ref.dockMove(tab, 'grid_dock', 'middle');
  }
}
