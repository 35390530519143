/* eslint-disable camelcase */
import React from 'react';
import TreeView from '../../GroupTreeView/GroupTreeView';
import { ITabParams } from '../../../config/types';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store';
import { openTaskConfigsTab } from './TaskConfigsUtils';
import { triggerEvent } from '../../../redux/event';
import {
  TaskConfig,
  TaskConfigCollection
} from '@digitalworkflow/dwtranslateclient';
import { setSelectedNode } from '../../../redux/activeNode';
import {
  fetchTaskConfigGroups,
  fetchTaskConfigs
} from '../../../redux/taskConfig';
import {
  builderPageTypes as BPT,
  builderPageTypes
} from '../../../config/constants/builderPageTypes';
import { updateDataEverywhere } from '../../../utils';
import { ITreeViewData } from '@digitalworkflow/dwtranslateclient/lib/Models/Module/ModuleCollection';
import styles from '../Renderers.module.scss';

export const GroupsTaskConfigs = ({
  ref,
  type,
  selectedRows,
  gridReferences,
  setCurrentEventAPI,
  setSelectedRowIndex,
  setSelectedRows,
  fetchData,
  setRowSelectedFlag,
  setTabsRef,
  updateData
}: ITabParams) => {
  const groups: ITreeViewData[] = useSelector(
    (state: RootState) => state.taskConfig.groups
  );
  const taskConfigs: any = useSelector(
    (state: RootState) => state.taskConfig.taskConfigs
  );

  const dispatch = useAppDispatch();

  function reduxUpdater() {
    dispatch(fetchTaskConfigs());
    dispatch(fetchTaskConfigGroups());
  }

  function openForm() {
    dispatch(triggerEvent('showModalEvent'));
  }

  /**
   * callback to handle when a group is clicked in tree view
   * @param title string name of the group clicked
   */
  function handleGroupClick(title: string) {
    const currentTaskConfig: TaskConfig | null = taskConfigs.filter(
      (state: TaskConfig) => state._data.name === title
    )?.[0];

    if (!currentTaskConfig?._data.id?.length) {
      console.log('TC not found, returning...');
      return;
    }

    const rows = [currentTaskConfig._data];

    const tabId = title + '_tab';

    const tabAlreadyPresent = ref?.find(tabId);

    tabAlreadyPresent
      ? ref?.updateTab(tabId, null)
      : openTaskConfigsTab({
          ref,
          title,
          rows,
          firstTab: false,
          type,
          selectedRows,
          gridReferences,
          fetchData,
          setSelectedRows,
          setSelectedRowIndex,
          setCurrentEventAPI,
          setRowSelectedFlag,
          setTabsRef,
          updateData,
          updateMetaData: (data) => {
            updateDataEverywhere(
              ref,
              data.id,
              data,
              builderPageTypes.taskConfigs,
              reduxUpdater
            );
          }
        });

    dispatch(setSelectedNode(title));
  }

  async function handleDrop(newData: ITreeViewData[]) {
    for (let index = 0; index < newData.length; index++) {
      if (newData[index].parent !== groups[index].parent) {
        const parentIndex = newData.findIndex(
          (v) => v.id === newData[index].parent
        );
        await TaskConfigCollection.updateTaskconfigModule(
          newData[index].text as string,
          newData[parentIndex].text as string
        );
        dispatch(fetchTaskConfigGroups());
        break;
      }
    }
  }

  return (
    <div id={styles.treeView} className='overflow-y-auto h-100'>
      <TreeView
        pageType={BPT.taskConfigs}
        data={groups}
        setShowModal={() => openForm()}
        handleGroupClick={(title) => handleGroupClick(title)}
        handleDrop={(newTreeData: ITreeViewData[]) => handleDrop(newTreeData)}
        tabRef={ref}
      />
    </div>
  );
};

export const HistoryTaskConfigs = () => {
  return (
    <div>
      <span>History available in V2</span>
    </div>
  );
};
