import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Autocomplete from '../../Autocomplete/Autocomplete';
import { LookupCollection } from '@digitalworkflow/dwtranslateclient';
import { Button } from 'reactstrap';
import { hasSpecialCharacters } from '../../../utils/checkSpecialCharacters';
import { FormErrorType } from '../../../pages/MasterEditor';

type handleSaveProps = {
  key: string;
  codedValue: string;
  text: string;
  export_value: string;
  group: string;
};

type LookupFormProps = {
  toggleShow?: () => void;
  handleSave?: ({
    key,
    codedValue,
    text,
    export_value,
    group
  }: handleSaveProps) => Promise<boolean>;
  validationErrors?: FormErrorType[];
};

function LookupForm({
  toggleShow,
  handleSave,
  validationErrors
}: LookupFormProps) {
  const [key, setKey] = useState<string>('');
  const [keyError, setKeyError] = useState<any>(null);
  const [codedValue, setCodedValue] = useState<string>('');
  const [codedValueError, setCodedValueError] = useState<any>(null);
  const [exportValue, setExportValue] = useState<string>('');
  const [exportValueError, setExportValueError] = useState<any>(null);
  const [text, setText] = useState<string>('');
  const [textError, setTextError] = useState<any>(null);
  const [group, setGroup] = useState<string>('');
  const [groupError, setGroupError] = useState<any>(null);
  const [groupOptions, setGroupOptions] = useState<any>([]);

  const clearLookupErrors = () => {
    setKeyError(null);
    setCodedValueError(null);
    setExportValueError(null);
    setTextError(null);
    setGroupError(null);
  }; // clear lookup errors

  useEffect(() => {
    clearLookupErrors();

    getGroups();
  }, []);
  useEffect(() => {
    if (validationErrors) {
      for (let index = 0; index < validationErrors.length; index++) {
        const element = validationErrors[index];
        if (element.field_id === 'key') {
          setKeyError(element.error_message);
        }
      }
    }
  }, [validationErrors]);

  async function getGroups() {
    const res = await LookupCollection.findAllGroups();
    const data: any[] = [];
    for (const group of res) {
      data.push({ title: group });
    }
    setGroupOptions([...data]);
  }

  function toggleModal() {
    if (!toggleShow) {
      return;
    }
    toggleShow();
  }

  async function saveLookup(): Promise<void> {
    clearLookupErrors();
    let lookupError = false;

    if (!key || key.trim() === '') {
      setKeyError('Key/Lookup name is required');
      lookupError = true;
    }
    if (key.trim() !== key) {
      setKeyError(
        'Key/Lookup name should not have the spaces at the start or end'
      );
      lookupError = true;
    }
    if (hasSpecialCharacters(key)) {
      setKeyError('Key contains special characters');
      lookupError = true;
    }
    if (!codedValue) {
      setCodedValueError('Coded value is required');
      lookupError = true;
    }
    if (!text) {
      setTextError('Text En is required');
      lookupError = true;
    }
    if (!group) {
      setGroupError('Group is required');
      lookupError = true;
    }

    if (lookupError) {
      return;
    }

    const result = await handleSave?.({
      key,
      codedValue,
      text,
      export_value: exportValue,
      group
    });
    if (result) {
      toggleModal();
    }
  }

  return (
    <div className='row'>
      <div className='modal-header h4'>New Lookup</div>
      <Form className='smallForm'>
        <div className='lookup-body'>
          <Form.Group controlId='lookupkey' className='form-group row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>
                Key/Lookup Name<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-8'>
              <Form.Control
                type='text'
                placeholder='Enter Key or Lookup Name'
                // className='form-control'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setKey(e.target.value);
                }}
                value={key}
              />
              {keyError && <p className='inline-error'>{keyError}</p>}
            </div>
          </Form.Group>
          <Form.Group controlId='codedValue' className='form-group row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>
                Coded Value<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-8'>
              <Form.Control
                type='text'
                placeholder='Coded Value'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setCodedValue(e.target.value);
                }}
                value={codedValue}
              />
              {codedValueError && (
                <p className=' inline-error'>{codedValueError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group controlId='text' className='form-group row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>
                Text En<span className='text-red'>*</span>
              </Form.Label>
            </div>
            <div className='col-sm-8'>
              <Form.Control
                type='text'
                placeholder='English Text'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setText(e.target.value);
                }}
                value={text}
              />
              {textError && <p className='inline-error'>{textError}</p>}
            </div>
          </Form.Group>
          <Form.Group controlId='exportValue' className='form-group row'>
            <div className='col-sm-4 col-form-label'>
              <Form.Label>Export Name</Form.Label>
            </div>
            <div className='col-sm-8'>
              <Form.Control
                type='text'
                placeholder='Export Name'
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setExportValue(e.target.value);
                }}
                value={exportValue}
              />
              {exportValueError && (
                <p className='inline-error'>{exportValueError}</p>
              )}
            </div>
          </Form.Group>
          <Form.Group controlId='group' className='row'>
            <div className='col-sm-4 autoCompleteLabel'>
              Group<span className='text-red'>*</span>
            </div>
            <div className='col-sm-8'>
              <Autocomplete
                wrapperStyle={{ height: 'auto' }}
                onChange={(e) => {
                  setGroup(e.target.value);
                }}
                onSelect={(e) => {
                  setGroup(e.title);
                }}
                data={groupOptions}
              />
              {groupError && <p className='inline-error'>{groupError}</p>}
            </div>
          </Form.Group>
        </div>
        <div className='modal-footer form_modal_footer'>
          <Button
            className='btn btn-sm'
            color='cancel'
            type='button'
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
          <Button
            className='btn btn-sm'
            color='edit'
            type='button'
            onClick={() => saveLookup()}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default LookupForm;
