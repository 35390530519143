import React from 'react';
import { DockLayout, ITabParams } from '../../config/types';
import {
  GroupsLookup,
  HistoryLookup
} from '../../components/MetaRenderers/Lookups';
import {
  GroupDataDictionary,
  HistoryDataDictionary
} from '../../components/MetaRenderers/DataDictionary';
import { GroupsPages, HistoryPage } from '../../components/MetaRenderers/Pages';
// import CurrentGridDetail from '../../components/CurrentGridDetail';
// import CurrentLine from '../../components/CurrentLine';
import {
  GroupsModules,
  HistoryModules
} from '../../components/MetaRenderers/Modules';
import {
  GroupsModuleSections,
  HistoryModuleSections
} from '../../components/MetaRenderers/ModuleSections';
import {
  GroupsWorkflowStates,
  HistoryWorkflowStates
} from '../../components/MetaRenderers/WorkflowStates';
import {
  GroupsTaskConfigs,
  HistoryTaskConfigs
} from '../../components/MetaRenderers/TaskConfigs';
import { builderPageTypes as BPT } from '../../config/constants/builderPageTypes';

export function openBrowserTabs(props: ITabParams): boolean {
  let returnedBoolean = false;

  if (!props.ref) {
    console.log('dock ref is undefined');
  } else {
    switch (props.type) {
      case BPT.pages:
        returnedBoolean = true;
        getPagesBrowserTab(props);
        break;

      case BPT.lookups:
        returnedBoolean = true;
        getLookupsBrowserTab(props);
        break;

      case BPT.dataDictionaries:
        returnedBoolean = true;
        getDataDictionaryBrowserTab(props);
        break;

      case BPT.modules:
        returnedBoolean = true;
        getModulesBrowserTab(props);
        break;

      case BPT.moduleSections:
        returnedBoolean = true;
        getModuleSectionsBrowserTab(props);
        break;

      case BPT.workflowStates:
        returnedBoolean = true;
        getWorkflowStatesBrowserTab(props);
        break;

      case BPT.taskConfigs:
        returnedBoolean = true;
        getTaskConfigsBrowserTab(props);
        break;

      default:
        break;
    }
  }

  return returnedBoolean;

  function getDataDictionaryBrowserTab(props: ITabParams) {
    returnedBoolean = true;
    browserTabUpdater(
      props.ref,
      'Data Dictionary',
      () => GroupDataDictionary(props),
      // () => (
      //   <CurrentGridDetail type='Data Dictionary' detailData={detailData} />
      // ),
      HistoryDataDictionary
      // () => (
      //   <CurrentLine type={BPT.dataDictionaries} selectedRows={props.selectedRows} />
      // )
    );
  }

  function getLookupsBrowserTab(props: ITabParams) {
    returnedBoolean = true;
    browserTabUpdater(
      props.ref,
      'Lookup',
      () => GroupsLookup(props),
      HistoryLookup
      // () => <CurrentLine type={BPT.lookups} selectedRows={props.selectedRows} />
    );
  }

  function getPagesBrowserTab(props: ITabParams) {
    returnedBoolean = true;
    browserTabUpdater(
      props.ref,
      'Page',
      () => GroupsPages(props),
      HistoryPage
      // () => <CurrentLine type={BPT.pages} selectedRows={props.selectedRows} />
    );
  }

  function getModulesBrowserTab(props: ITabParams) {
    returnedBoolean = true;
    browserTabUpdater(
      props.ref,
      'Module',
      () => GroupsModules(props),
      HistoryModules
      // () => <CurrentLine type={BPT.modules} selectedRows={props.selectedRows} />
    );
  }

  function getModuleSectionsBrowserTab(props: ITabParams) {
    returnedBoolean = true;
    browserTabUpdater(
      props.ref,
      'Module Section',
      () => GroupsModuleSections(props),
      HistoryModuleSections
      // () => (
      // <CurrentLine type={BPT.moduleSections} selectedRows={props.selectedRows} />
      // )
    );
  }

  function getWorkflowStatesBrowserTab(props: ITabParams) {
    returnedBoolean = true;
    browserTabUpdater(
      props.ref,
      'Workflow State',
      () => GroupsWorkflowStates(props),
      HistoryWorkflowStates
      // () => (
      // <CurrentLine type={BPT.workflowStates} selectedRows={props.selectedRows} />
      // )
    );
  }

  function getTaskConfigsBrowserTab(props: ITabParams) {
    returnedBoolean = true;
    browserTabUpdater(
      props.ref,
      'Task Config',
      () => GroupsTaskConfigs(props),
      HistoryTaskConfigs
      // () => (
      // <CurrentLine type={BPT.workflowStates} selectedRows={props.selectedRows} />
      // )
    );
  }
}

function browserTabUpdater(
  ref: DockLayout | null,
  type:
    | 'Page'
    | 'Lookup'
    | 'Data Dictionary'
    | 'Module'
    | 'Module Section'
    | 'Task Config'
    | 'Workflow State',
  Group: () => React.JSX.Element,
  History: () => React.JSX.Element
) {
  if (!ref) return;

  ref.updateTab(
    'groups_tab',
    {
      id: 'groups_tab',
      title: `${type === 'Data Dictionary' ? 'Data Dictionaries' : type + 's'}`, // `${type + 's'}`,
      content: <Group />,
      group: 'close-all'
    },
    true
  );
  // ref.updateTab(
  //   'current_tab',
  //   {
  //     id: 'current_tab',
  //     title: `${'Current ' + type}`,
  //     content: <Current />
  //   },
  //   false
  // );
  ref.updateTab(
    'history_tab',
    {
      id: 'history_tab',
      title: `${type + ' History'}`,
      content: <History />,
      group: 'close-all'
    },
    false
  );
  // ref.updateTab(
  //   'current_line_tab',
  //   {
  //     id: 'current_line_tab',
  //     title: 'Current Line',
  //     content: <CurrentLine />,
  //     group: 'close-all'
  //   },
  //   true
  // );
}

// export function browserCurrentUpdate(
//   type: string,
//   ref: DockLayout | null,
//   CurrentGridDetail: () => React.JSX.Element
// ) {
//   ref?.updateTab(
//     'current_tab',
//     {
//       id: 'current_tab',
//       title: `${'Current ' + type}`,
//       content: <CurrentGridDetail />
//     },
//     true
//   );
// }

export function browserCurrentLineUpdate(
  ref: DockLayout | null,
  CurrentLine: () => React.JSX.Element
) {
  ref?.updateTab(
    'current_line_tab',
    {
      id: 'current_line_tab',
      title: 'Current Line',
      content: <CurrentLine />
    },
    true
  );
}

export function addFieldTab(
  ref: DockLayout | null,
  FieldLine: () => React.JSX.Element
) {
  ref?.dockMove(
    {
      id: 'fields_tab',
      title: 'Fields',
      closable: true,
      content: <FieldLine />
    },
    'current_line_tab',
    'after-tab'
  );
}

export function removeFieldTab(ref: DockLayout | null) {
  const _container = ref?.find('fields_tab');
  if (_container) {
    ref?.dockMove(_container as any, null, 'remove');
  }
}
