import React, { useEffect } from 'react';
import LoadingScreen from '../../components/LoadingScreen';
import { storageKeys } from '../../config/constants/storageKeys';
import authLib from '../../lib/auth-lib';
import commonLib from '../../lib/common-lib';
import { portalInfo } from '../../config/constants/portalInfo';
import {
  AuthResultType,
  AuthService,
  WorkgroupService
} from '@digitalworkflow/dwloginclient';
import { useNavigate } from 'react-router';

interface IAuthenticateProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

function Authenticate({ setIsAuthenticated }: IAuthenticateProps) {
  const loginPortalUrl = portalInfo.loginPortalUrl + portalInfo.loginUri;
  const navigate = useNavigate();

  useEffect(() => {
    authenticateToken();
  }, []);

  const authenticateToken = async () => {
    try {
      const authService = AuthService.instance();
      const env = commonLib.getEnvironment();
      AuthService.setEnvironment(env);

      // Get token from local storage
      const localToken = authLib.getUserToken();

      if (!localToken) {
        // no token in local storage
        commonLib.redirect(loginPortalUrl);
        return;
      } else {
        AuthService.setPortalLoginId(portalInfo.loginProfileId); // using Auth Service set Project Name...
        // Token found in local storage
        const authUserRes: AuthResultType = await authService.authUserFromToken(
          localToken
        );
        console.log(`auth user response: `, authUserRes);

        if (authUserRes && authUserRes?.is_error === false) {
          // valid user - continue
          authUserRes.data.user.all_active_projects = [];

          localStorage.setItem(
            storageKeys.userKey,
            JSON.stringify(authUserRes.data.user)
          );
          setWorkgroups();
        } else {
          console.log(`dwbuilder --- redirect to login `, loginPortalUrl);
          commonLib.redirect(loginPortalUrl);
          return;
        } // else - valid user
      } // token found in local storage

      setIsAuthenticated(true);
      navigate('/sync');
    } catch (e) {
      console.log('Exception on authenticate ', e);
    }
  };

  async function setWorkgroups() {
    try {
      const userStr = localStorage.getItem(storageKeys.userKey);
      const userObj = userStr ? JSON.parse(userStr) : null;
      const instance = AuthService.instance();
      WorkgroupService.setAuthServiceInstance(instance);
      const workgroupService = new WorkgroupService();
      const workGroups = await workgroupService.getAllWorkgroup();
      userObj.work_groups = workGroups.data;
      localStorage.setItem(storageKeys.userKey, JSON.stringify(userObj));
    } catch (error) {
      console.error('Error while setting workgroups', error);
    }
  }

  return <LoadingScreen comment='Authenticating' />;
}

export default Authenticate;
