import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ProjectDocumentCollection } from '@digitalworkflow/dwtranslateclient';
import { AppConfig } from '../config/constants/AppConfig';

export interface ProjectDocumentInitialState {
  projectDocuments: any;
  selectedRows: any;
  loading: boolean;
  error: string | null;
}

const initialState: ProjectDocumentInitialState = {
  projectDocuments: [],
  selectedRows: [],
  loading: false,
  error: null
};

export const fetchProjectDocuments = createAsyncThunk<
  any,
  void,
  { rejectValue: string }
>('projectDocument/fetchProjectDocuments', async (_, thunkAPI) => {
  try {
    const data = await ProjectDocumentCollection.findAll();

    if (AppConfig.evReduxLogs) {
      console.log(
        '%credux::documents::project-document',
        'font-size:20px;color:brown'
      );
      console.log(data);
    }

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch projectDocument data.');
  }
});

export const projectDocumentSlice = createSlice({
  name: 'projectDocument',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProjectDocuments.fulfilled, (state, action) => ({
      ...state,
      loading: false,
      projectDocuments: action.payload
    }));
  }
});

export default projectDocumentSlice.reducer;
