/* eslint-disable camelcase */
// import React from 'react';
import {
  createGridMetaFromDocument,
  extractGridMetaFromTab,
  generateTab,
  insertEmptyRow
} from '../../../utils';
import {
  getDefaultColumns,
  getLookupColumns,
  getLookupMetaColumns,
  getLookupMetaGridCols,
  getMetaGridDefaultCol
} from '../../../config/constants/columnDefinitions';
import { Lookup, LookupCollection } from '@digitalworkflow/dwtranslateclient';
import { portalInfo } from '../../../config/constants/portalInfo';
import {
  ITabParams,
  CellValueChangedEvent,
  ColDef,
  LookupDocumentType,
  ISaveLookupProps,
  GridApi,
  ISaveDuplicateLookupProps,
  IGenericAnyObject,
  RowDataTransaction,
  GridMetaData
  // IGenericAnyObject,
  // ITreeViewData,
  // DockLayout
} from '../../../config/types';
import { builderPageTypes as BPT } from '../../../config/constants/builderPageTypes';
import { updateCurrentGridDetail } from '../../CurrentGridDetail/updateCurrentGridDetail';
import { GlobalHashHelper } from '../../../utils/HashHelper';
import { toast } from 'react-toastify';

/**
 * @return Promise<boolean>
 * @returns true - if lookup is added successfully
 * @returns false - if lookup is not added successfully
 */
export async function addLookup(
  data?: ISaveLookupProps,
  e?: CellValueChangedEvent,
  shouldValidateKey: boolean = false,
  destination?: string
): Promise<any | undefined> {
  let response: any = {};

  if (e) {
    console.log(e);

    response = await LookupCollection.addLookup(
      destination || e.data.key,
      e.data.coded_value,
      e.data.text,
      e.data.export_value,
      e.data.group,
      shouldValidateKey
    );

    if (response?.saveResults?.did_save) {
      e.data.id = response.record.data.id;
      e.api.refreshCells({
        columns: ['meta'],
        force: true,
        rowNodes: [e.node]
      });
    } else {
      e.data.coded_value = 'Coded values are duplicated';
      e.api.refreshCells({ columns: ['coded_value'] });
    }
  } else if (data) {
    response = await LookupCollection.addLookup(
      destination || data.key,
      data.codedValue,
      data.text,
      data.export_value,
      data.group,
      shouldValidateKey
    );
    // response = response?.saveResults?.did_save;
  } else {
    console.log('neither event nor form called add Lookup()');
  }
  return response;
}

/**
 * @return Promise<boolean>
 * @returns true - if lookup is added successfully
 * @returns false - if lookup is not added successfully
 */
export async function addDuplicateLookup(
  node2Duplicate: string,
  data?: ISaveDuplicateLookupProps,
  e?: CellValueChangedEvent
): Promise<any | undefined> {
  const copyFromKey = node2Duplicate ?? 'smlookup';
  let response: any = {};

  if (e) {
    response = await LookupCollection.duplicateLookup(
      copyFromKey,
      e.data.key,
      e.data.group,
      e.data.projectId
    );
    if (response?.saveResults?.did_save) {
      e.data.id = response.record.data.id;
    }
  } else if (data) {
    response = await LookupCollection.duplicateLookup(
      copyFromKey,
      data.key,
      data.group,
      data.projectId
    );
  } else {
    console.log('neither event nor form called duplicate lookup()');
  }
  return response;
}

/**
 * @return Promise<boolean>
 * @returns true - if lookup is deleted successfully
 * @returns false - if lookup is not deleted successfully
 */
async function delLookup(e: CellValueChangedEvent): Promise<boolean> {
  const lookup = await LookupCollection.deleteLookup(e.data.id);
  return lookup && true;
}

/**
 * creates a new Lookup object (Partial<LookupDocumentType> type) with empty string values
 * @returns new Lookup object
 */
export function createLookupRowData(
  key: string,
  group: string
): Partial<LookupDocumentType> {
  const newLookup: Partial<LookupDocumentType> = {
    key,
    group,
    coded_value: '',
    export_value: '',
    text: ''
  };
  return newLookup;
}

export async function openLookupsTab({
  ref,
  data: lookups,
  groups,
  rows,
  title,
  firstTab = true,
  gridReferences,
  updateMetaData,
  fetchData,
  setSelectedRows,
  setSelectedRowIndex,
  setCurrentEventAPI,
  setRowSelectedFlag,
  setTabsRef
}: ITabParams) {
  if (!ref) {
    console.log('no ref provided, returning...');
    return;
  }

  const defaultColDef: ColDef = getDefaultColumns(
    portalInfo.agGridConfig.sortableColumn
  );
  const columnDefs: ColDef[] = getLookupColumns();
  const detailCellRendererParams = getLookupMetaColumns(
    handleDetailGridCellValueChanged
  );

  let data: Partial<LookupDocumentType>[] = [];
  let key: string = '';

  if (firstTab) {
    if (!lookups) {
      console.log('lookups in redux empty, returning...');
      return;
    }

    const lookupKeys = Object.keys(lookups);

    if (!lookupKeys.length || !groups?.length) {
      console.log('lookups keys || groups empty, returning...');
      return;
    }

    key = GlobalHashHelper.hasOption('tab')
      ? GlobalHashHelper.getOption('tab')
      : lookupKeys[0];

    if (!key || key === 'undefined') {
      console.log('lookups first key empty, returning...');
      return;
    }

    for (const k of Object.keys(lookups[key])) {
      data.push(lookups[key][k].data);
    }

    data.push(createLookupRowData(key, data[0].group ?? ''));
  } else {
    if (!rows || !title) {
      console.log('rows||title empty, returning...');
      return;
    }

    key = title;
    data = [...rows, createLookupRowData(title, rows[0].group ?? '')];
  }

  function onUpdateAPI(api: GridApi) {
    if (api.getSelectedRows() && api.getSelectedRows().length > 0) {
      // Sort the selected rows.
      const selectedNodes = api.getSelectedNodes();
      const selectedIndices = selectedNodes.map((node) => node.rowIndex);
      selectedIndices.sort((a: any, b: any) => a - b);
      const selectedRows = selectedIndices
        .filter((index) => index !== null)
        .map((index) => api.getDisplayedRowAtIndex(index as number)?.data);
      // --------------------------------
      setSelectedRows?.(selectedRows);
      setRowSelectedFlag(true);
    }
    setCurrentEventAPI(api);
  }

  /**
   * last row edit - creates new row, add lookup in DB
   * other row edit - edits current row, edit lookup in DB
   * delete all cells data - deletes object from DB
   * @param e event triggred on cell value change
   */
  async function onCellValueChanged(
    e: CellValueChangedEvent
  ): Promise<Lookup | false> {
    let isDeleted: false | Lookup = false;

    /**
     * @reason
     * Line commented as it was causing issues in ag-grid.
     * It will out focus ag grid cell after edit
     */
    // setSelectedRows([e.data]);

    if (!e.data.id) {
      const currentRowIndex = e.api.getFocusedCell()?.rowIndex;
      if (currentRowIndex === e.api.getDisplayedRowCount() - 1) {
        insertEmptyRow(
          BPT.lookups,
          e.api.getDisplayedRowAtIndex(0)?.data.key,
          e.api.getDisplayedRowAtIndex(0)?.data.group,
          e.api
        );
      }
      const result = await addLookup(undefined, e);
      if (result && result?.saveResults?.did_save) {
        toast.success('Lookup was added successfully.', {
          containerId: 'main-toast'
        });
      } else if (
        result?.saveResults?.validation?.results &&
        result?.saveResults?.validation?.results.length > 0
      ) {
        const { results } = result?.saveResults?.validation;
        for (let i = 0; i < results.length; i++) {
          toast.error(results[i].error_message, { containerId: 'main-toast' });
        }
      }
    } else {
      const { coded_value, text, export_value } = e.data;
      if (!(export_value?.length || coded_value?.length || text?.length)) {
        await delLookup(e);
      } else {
        await LookupCollection.deleteLookup(e.data.id, false);
        const response = await LookupCollection.updateLookup(e.data.id, e.data);
        if (response && response?.saveResults?.did_save) {
          toast.success('Lookup was updated successfully.', {
            containerId: 'main-toast'
          });
        } else if (
          response &&
          response?.saveResults?.validation?.results &&
          response?.saveResults?.validation?.results.length > 0
        ) {
          const { results } = response?.saveResults?.validation;
          for (let i = 0; i < results.length; i++) {
            if (results[i].field_id === 'key') {
              e.data.coded_value = 'Coded values are duplicated';
              e.api.refreshCells({ columns: ['coded_value'] });
            }
            toast.error(results[i].error_message, {
              containerId: 'main-toast'
            });
          }
        } else {
          toast.error('An error occured updating lookup.', {
            containerId: 'main-toast'
          });
        }
        isDeleted =
          response && response.saveResults.did_save && response.record;
      }
    }
    return isDeleted;
  }

  async function handleDetailGridCellValueChanged(
    e: CellValueChangedEvent,
    params: any
  ) {
    await onSubCellValueChanged(e, params);
    fetchData();
  }

  async function onSubCellValueChanged(
    e: CellValueChangedEvent<any, any>,
    params: CellValueChangedEvent<any, any>
  ) {
    try {
      const theLookUpCollection = LookupCollection.getCollection();

      const record = await theLookUpCollection.findOne({
        id: params.data.id
      });

      if (!record) return false;

      const lookup = new Lookup(theLookUpCollection, record);
      const rowIndex =
        e.rowIndex !== null ? e.rowIndex : params.data.doc.length;
      if (!lookup) return false;

      if (e.data.fid?.trim() === '') {
        if (rowIndex < params.data.doc.length - 1) {
          removeFirstEmptyRow(e.api);
          params.data.doc.splice(rowIndex, 1);
          delete lookup.doc[e.oldValue];
        }
      } else {
        const currentRowIndex = e.api.getFocusedCell()?.rowIndex;

        if (currentRowIndex === e.api.getDisplayedRowCount() - 1) {
          insertEmptyRowSub(e.api);
          params.data.doc.push({ fid: '', val: '', tid: 0 });
        }
        if (e.oldValue && e.oldValue !== e.data.fid) {
          delete lookup.doc[e.oldValue];
        }
        if (e.data.fid !== '') {
          lookup.doc[e.data.fid] = e.data.val;
          params.data.doc[rowIndex] = {
            fid: e.data.fid,
            val: e.data.val
          };
        }
      }
      await lookup.save();
      const currentAgGrid = gridReferences.current.filter((item) => {
        return item.tabId === params.data.key + '_tab';
      });
      currentAgGrid?.[currentAgGrid.length - 1]?.gridApi.refreshCells({
        columns: ['meta'],
        force: true
      });

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  function insertEmptyRowSub(currentEventApi?: GridApi) {
    if (!currentEventApi) {
      console.log('no event');
      return;
    }
    const colDef: ColDef[] = currentEventApi.getColumnDefs() as ColDef[];
    const data: IGenericAnyObject = {};

    colDef?.splice(0, 1);
    colDef?.forEach((col) => {
      data[col.field as string] = '';
    });

    const transaction: RowDataTransaction = {
      add: [data],
      addIndex: -1
    };
    currentEventApi.applyTransaction(transaction);
  }

  function removeFirstEmptyRow(currentEventApi: GridApi) {
    if (!currentEventApi) {
      console.log('Grid API is not available');
      return;
    }

    const rowData: any[] = [];
    currentEventApi.forEachNode((node) => rowData.push(node.data));

    // const emptyRowIndex = rowData.findIndex((row) => {
    //   const rIndex =
    //     (row.fid === '' || row.fid === null || row.fid === undefined) ?? -1;
    //   return rIndex;
    // });

    let emptyRowIndex = -1; // Initialize with -1 to indicate "not found" by default
    rowData.some(function (row, indx) {
      if (row.fid?.trim() === '' || row.fid === null || row.fid === undefined) {
        console.log('Empty row index: ', indx, row);
        emptyRowIndex = indx;
        return true; // Stop iteration once the first match is found
      }
      return false; // Continue iteration if not a match
    });

    if (emptyRowIndex !== -1) {
      const transaction = {
        remove: [rowData[emptyRowIndex]]
      };
      currentEventApi.applyTransaction(transaction);
    } else {
      console.log('No empty row found');
    }
  }

  async function localCellValueChanged(e: CellValueChangedEvent) {
    const isUpdated = await onCellValueChanged(e);
    isUpdated && fetchData();

    /**
     * @reason
     * Code block commented as it was causing issues in ag-grid
     */

    /*
    if (!isUpdated || !ref) return;
    const newGridMeta: GridMetaData = createGridMetaFromDocument(isUpdated);
    updateCurrentGridDetail({
      type: BPT.lookups,
      ref,
      data: newGridMeta
    });
    */
  }

  data.forEach((item) => {
    item.doc = Array.isArray(item.doc) ? item.doc : [];
    const lastDocItem = item.doc[item.doc.length - 1];
    if (
      !lastDocItem ||
      (lastDocItem.fid && lastDocItem.fid.toString().trim() !== '')
    ) {
      item.doc.push({ fid: '', val: '', tid: 0 });
    }
  });

  const lookupData = await LookupCollection.getLookUpByKeyValue(
    data[0].key ?? '',
    data[0].coded_value ?? ''
  );
  const gridMetaData: GridMetaData | undefined =
    lookupData && createGridMetaFromDocument(lookupData);

  const metaGridCols = getLookupMetaGridCols();
  const metaGridDefaultCol = getMetaGridDefaultCol();

  const tab = generateTab({
    id: key,
    title: key,
    group: BPT.lookups,
    data: data,
    theme: portalInfo.agGridConfig.theme,
    pagination: portalInfo.agGridConfig.pagination,
    defaultColDef,
    columnDefs,
    masterDetail: true,
    detailCellRendererParams,
    gridReferences: gridReferences,
    gridMetaData,
    metaGridCols,
    metaGridDefaultCol,
    onCellValueChanged: (e: CellValueChangedEvent) => localCellValueChanged(e),
    setSelectedRowIndex,
    onUpdateAPI: (api: GridApi) => onUpdateAPI(api),
    setTabsRef,
    onMetaCellValueChanged: (e: CellValueChangedEvent) => {
      updateMetaData && updateMetaData(e.data);
    }
  });

  if (firstTab) {
    ref.updateTab('Loading', tab, true);

    updateCurrentGridDetail({
      type: BPT.lookups,
      ref,
      data: extractGridMetaFromTab(ref, key + '_tab')
    });
  } else {
    ref.dockMove(tab, 'grid_dock', 'middle');
  }
}
