import DockLayout from 'rc-dock';
import { GridMetaData, ITaskConfigGridUpdateData } from '../../config/types';
import {
  LogicEngineDataDateTime,
  TaskConfig,
  TaskConfigCollection,
  ValidationResults
} from '@digitalworkflow/dwtranslateclient';
import { generateTab } from '../generateTab';
import { builderPageTypes } from '../../config/constants/builderPageTypes';
import { removeUndefinedKeyFromObject } from '../removeUndefinedKeyFromObject';

export async function handleUpdateTaskConfig(
  ref: DockLayout | null | undefined,
  id: string,
  data: ITaskConfigGridUpdateData,
  reduxUpdater: () => void
) {
  let saveResults: ValidationResults = {} as ValidationResults;

  // update document in db
  const { oldTaskConfig, isUpdated, newTaskConfig } =
    await _updateTaskConfigInDB(id, data);

  saveResults =
    (isUpdated && isUpdated.saveResults.validation) ||
    ({} as ValidationResults);

  if (isUpdated && isUpdated.saveResults.did_save) {
    // if document is updated in db, update redux
    reduxUpdater();

    // if ref provided, find and update currently opened editor tab and grid
    if (ref) {
      // find tab
      const tab: any =
        oldTaskConfig && ref.find(oldTaskConfig?.data.name + '_tab');
      if (tab) {
        // update grid meta, data(if applicable), and tab title(if applicable)
        const oldTabProps = (tab as any).content.props.children.props.children;
        newTaskConfig &&
          _updateTaskConfigTabAndGrid(
            newTaskConfig,
            oldTabProps,
            ref,
            saveResults
          );
      }
    }
  }

  return saveResults;
}

async function _updateTaskConfigInDB(
  id: string,
  data: ITaskConfigGridUpdateData
) {
  const oldTaskConfig = await TaskConfigCollection.findById(id);

  const isUpdated = await TaskConfigCollection.updateTaskConfig(
    id,
    removeUndefinedKeyFromObject({
      name: data?.name,
      describe: data?.description
    })
  );

  const newTaskConfig = await TaskConfigCollection.findById(id);

  return { oldTaskConfig, isUpdated, newTaskConfig };
}

async function _updateTaskConfigTabAndGrid(
  newTaskConfig: TaskConfig,
  tabProps: any,
  ref: DockLayout,
  saveResults: ValidationResults
) {
  const newGridMeta: GridMetaData = {
    createBy: newTaskConfig?.data.create_by ?? '',
    updateBy: newTaskConfig?.data.updated_by ?? '',
    createDt: newTaskConfig?.data.create_dt ?? ({} as LogicEngineDataDateTime),
    updateDt: newTaskConfig?.data.updated_dt ?? ({} as LogicEngineDataDateTime),
    taskConfigDescription: newTaskConfig?.data.describe,
    taskConfigName: newTaskConfig?.data.name,
    documentId: newTaskConfig?.data.id ?? ''
  };

  const tabUpdated = ref.updateTab(
    tabProps[1].props.children.props.id + '_tab',
    generateTab({
      ...tabProps[1].props.children.props,
      gridMetaData: newGridMeta,
      id: newTaskConfig.data?.name,
      title: newTaskConfig.data?.name ?? '',
      data: [{ ...newTaskConfig.data, clickedColumn: '' }],
      group: builderPageTypes.taskConfigs,
      metaGridCols: tabProps[0].props.children.props.columnDefs,
      metaGridDefaultCol: tabProps[0].props.children.props.defaultColDef,
      onMetaCellValueChanged:
        tabProps[0].props.children.props.onCellValueChanged
    })
  );

  if (!tabUpdated) {
    saveResults.addError('updateTab', 'failed to update opened tab');
  }
}
