import {
  IGenericAnyObject,
  ColDef,
  GridApi,
  RowDataTransaction
} from '../config/types';
import { builderPageTypes as BPT } from '../config/constants/builderPageTypes';

export function insertDataRow(
  type: string,
  currentEventApi: GridApi,
  selectedRowIndex: number,
  rowData: any,
  selectedRows: any[]
) {
  if (!currentEventApi || !rowData) {
    console.log('no event');
    return;
  }
  currentEventApi.forEachNode((node) => {
    node.setExpanded(false);
  });
  const colDef: ColDef[] = currentEventApi.getColumnDefs() as ColDef[];
  colDef?.splice(0, 1);
  const data: IGenericAnyObject = {};
  colDef?.forEach((col) => {
    data[col.field as string] = rowData[col.field as string];
  });
  if (rowData.group) {
    data.group = rowData.group;
  }
  if (rowData.table_name) {
    data.table_name = rowData.table_name;
  }
  if (type === BPT.lookups) data.key = selectedRows?.[0].key;
  const lastRowIndex =
    selectedRows[selectedRows?.length - 1].id ||
    selectedRows[selectedRows?.length - 1].rownum
      ? selectedRowIndex
      : selectedRowIndex - 1;

  const transaction: RowDataTransaction = {
    add: [data],
    addIndex: lastRowIndex
  };
  currentEventApi.applyTransaction(transaction);
  currentEventApi.ensureIndexVisible(lastRowIndex);
}
