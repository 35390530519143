import DockLayout from 'rc-dock';
import { GridMetaData, IDdGridUpdateData } from '../../config/types';
import {
  DataDictionaryTable,
  DataDictionaryTableCollection,
  LogicEngineDataDateTime,
  ValidationResults
} from '@digitalworkflow/dwtranslateclient';
import { generateTab } from '../generateTab';
import { builderPageTypes } from '../../config/constants/builderPageTypes';
import { removeUndefinedKeyFromObject } from '../removeUndefinedKeyFromObject';

export async function handleUpdateDD(
  ref: DockLayout | null | undefined,
  id: string,
  data: IDdGridUpdateData,
  reduxUpdater: () => void
) {
  let saveResults: ValidationResults | boolean = {} as ValidationResults;

  // update document in db
  const { oldTable, isUpdated, newTable } = await _updateDDInDB(id, data);

  if (isUpdated && isUpdated?.did_save) {
    // if document is updated in db, update redux
    reduxUpdater();

    // if ref provided, find and update currently opened editor tab and grid
    if (ref) {
      // find tab
      const tab: any = ref.find(oldTable?.data.table_name + '_tab');

      if (tab) {
        // update grid meta, data(if applicable), and tab title(if applicable)
        const oldTabProps = (tab as any).content.props.children.props.children;
        newTable &&
          _updateDDTabAndGrid(newTable, oldTabProps, ref, saveResults);
      }
    }
  } else {
    // saveResults = (isUpdated && 'validation' in isUpdated) ?? saveResults;
    saveResults =
      isUpdated && 'validation' in isUpdated
        ? isUpdated.validation
        : saveResults; // Correctly assign validation results
  }

  return saveResults;
}

async function _updateDDInDB(id: string, data: IDdGridUpdateData) {
  const oldTable = await DataDictionaryTableCollection.getTableById(id);

  const isUpdated = await DataDictionaryTableCollection.updateDataTable(
    id,
    removeUndefinedKeyFromObject({
      table_name: data?.table_name,
      description: data?.description,
      group: data?.group
    })
  );

  const newTable = await DataDictionaryTableCollection.getTableById(id);

  return { oldTable, isUpdated, newTable };
}

async function _updateDDTabAndGrid(
  newTable: DataDictionaryTable,
  tabProps: any,
  ref: DockLayout,
  saveResults: ValidationResults
) {
  const newGridMeta: GridMetaData = {
    createBy: newTable?.data.create_by ?? '',
    updateBy: newTable?.data.updated_by ?? '',
    createDt: newTable?.data.create_dt ?? ({} as LogicEngineDataDateTime),
    updateDt: newTable?.data.updated_dt ?? ({} as LogicEngineDataDateTime),
    ddTableName: newTable?.data.table_name,
    ddDescription: newTable?.data.description,
    documentId: newTable?.data.id ?? ''
  };

  const tabUpdated = ref.updateTab(
    tabProps[1].props.children.props.id + '_tab',
    generateTab({
      ...tabProps[1].props.children.props,
      gridMetaData: newGridMeta,
      id: newTable?.data.table_name,
      title: newTable?.data.table_name ?? '',
      group: builderPageTypes.dataDictionaries,
      metaGridCols: tabProps[0].props.children.props.columnDefs,
      metaGridDefaultCol: tabProps[0].props.children.props.defaultColDef,
      onMetaCellValueChanged:
        tabProps[0].props.children.props.onCellValueChanged
    })
  );

  if (!tabUpdated) {
    saveResults.addError('updateTab', 'failed to update opened tab');
  }
}
