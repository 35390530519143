/* eslint-disable camelcase */
import React from 'react';
import TreeView from '../../GroupTreeView/GroupTreeView';
import {
  ITabParams,
  DataDictionaryFieldDocumentType
} from '../../../config/types';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store';
import { DataDictionaryTableCollection } from '@digitalworkflow/dwtranslateclient';
import { openDataDictionaryTab } from './DataDictionaryUtils';
import { triggerEvent } from '../../../redux/event';
import { setSelectedNode } from '../../../redux/activeNode';
import {
  builderPageTypes as BPT,
  builderPageTypes
} from '../../../config/constants/builderPageTypes';
import {
  fetchDataDictionary,
  fetchDataDictionaryGroups
} from '../../../redux/dataDictionary';
import { updateDataEverywhere } from '../../../utils';
import { ITreeViewData } from '@digitalworkflow/dwtranslateclient/lib/Models/Module/ModuleCollection';
// import { updateDataDictionaryGroups } from '../../../redux/dataDictionary';
import styles from '../Renderers.module.scss';

// export const CurrentLineDataDictionary = () => {
//   const [field, setField] = useState<string>('');
//   const [codedValue, setCodedValue] = useState<string>('');
//   const [exportName, setExportName] = useState<string>('');
//   return (
//     <CurrentLineDetail type='dataDictionary'>
//       <CurrentLineField value={field} label='Field' onChange={setField} />
//       <CurrentLineField
//         value={codedValue}
//         label='Coded Value'
//         onChange={setCodedValue}
//       />
//       <CurrentLineField
//         value={exportName}
//         label='Export Name'
//         onChange={setExportName}
//       />
//     </CurrentLineDetail>
//   );
// };

// interface IGroupDataDictionaryProps {
//   dockLayoutRef: DockLayout;
//   setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
//   setDictionaryRowSelected: (value: boolean) => void;
// }

export const GroupDataDictionary = ({
  ref: dockLayoutRef,
  type,
  selectedRows,
  gridReferences,
  setSelectedRowIndex,
  setCurrentEventAPI,
  setSelectedRows,
  fetchData,
  setRowSelectedFlag,
  setTabsRef,
  updateData,
  ...rest
}: ITabParams) => {
  const groups: ITreeViewData[] = useSelector(
    (state: RootState) => state.dataDictionary.groups
  );
  const dataDictionaryFields: any[] = useSelector(
    (state: RootState) => state.dataDictionary.dataDictionary
  );
  const lookupData: any[] = useSelector(
    (state: RootState) => state.lookup.lookups
  );

  const dispatch = useAppDispatch();

  function reduxUpdater() {
    dispatch(fetchDataDictionary());
    dispatch(fetchDataDictionaryGroups());
  }

  function openForm() {
    dispatch(triggerEvent('showModalEvent'));
  }

  function openDuplicateForm() {
    dispatch(triggerEvent('showDuplicateModalEvent'));
  }

  // function updateSelectedRowsData(_rows: any[]) {
  //   setSelectedRows(_rows);
  //   setRowSelectedFlag(true);
  // }

  /**
   * callback to handle when a group is clicked in tree view
   * @param title string name of the group clicked
   */
  async function handleGroupClick(title: string) {
    // if (!ddFields.length) return;

    const rows: Partial<DataDictionaryFieldDocumentType>[] = [];

    // Object.keys(ddFields).forEach((k) => {
    //   rows.push(ddFields[k].data);
    // });
    if (!dataDictionaryFields.length) {
      console.log('empty DD in redux, returning...');
      return;
    }

    const openId = dataDictionaryFields.findIndex(
      (ddField) => ddField.table._data.table_name === title
    );
    if (openId === -1) {
      console.log('cant find DD Index, returning...');
      return;
    }

    dataDictionaryFields[openId].fields.forEach((ddRow: any) => {
      rows.push(ddRow._data);
    });

    const tabId = title + '_tab';

    const tabAlreadyPresent = dockLayoutRef?.find(tabId);

    tabAlreadyPresent
      ? dockLayoutRef?.updateTab(tabId, null)
      : openDataDictionaryTab({
          ref: dockLayoutRef,
          title,
          type,
          selectedRows,
          rows,
          firstTab: false,
          gridReferences,
          fetchData,
          setCurrentEventAPI,
          setSelectedRows,
          setSelectedRowIndex,
          setRowSelectedFlag,
          setTabsRef,
          updateData,
          openTab: rest.openTab,
          lookupData,
          dataDictionaryData: dataDictionaryFields,
          updateMetaData: (data) => {
            updateDataEverywhere(
              dockLayoutRef,
              data.id,
              data,
              builderPageTypes.dataDictionaries,
              reduxUpdater
            );
          }
        });
    dispatch(setSelectedNode(title));
  }

  async function handleDrop(newData: ITreeViewData[]) {
    for (let index = 0; index < newData.length; index++) {
      if (newData[index].parent !== groups[index].parent) {
        const parentIndex = newData.findIndex(
          (v) => v.id === newData[index].parent
        );
        await DataDictionaryTableCollection.updateTableGroup(
          newData[index].text as string,
          newData[parentIndex].text as string
        );
        // dispatch(updateDataDictionaryGroups(newData));
        fetchData();
        break;
      }
    }
  }

  return (
    <div id={styles.treeView} className='overflow-y-auto h-100'>
      <TreeView
        pageType={BPT.dataDictionaries}
        data={groups}
        setShowModal={() => openForm()}
        setShowDuplicateModal={() => openDuplicateForm()}
        handleGroupClick={async (title) => {
          return await handleGroupClick(title);
        }}
        handleDrop={(newTreeData: ITreeViewData[]) => handleDrop(newTreeData)}
        tabRef={dockLayoutRef}
      />
    </div>
  );
};

export const HistoryDataDictionary = () => {
  return (
    <div>
      <span>History available in V2</span>
    </div>
  );
};
