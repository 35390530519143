import DockLayout from 'rc-dock';
import {
  GridMetaData,
  // GridMetaData,
  // IDdGridUpdateData,
  IModuleGridUpdateData
} from '../../config/types';
import {
  LogicEngineDataDateTime,
  Module,
  ModuleCollection,
  ValidationResults
} from '@digitalworkflow/dwtranslateclient';
import { generateTab } from '../generateTab';
import { builderPageTypes } from '../../config/constants/builderPageTypes';

export async function handleUpdateModule(
  ref: DockLayout | null | undefined,
  id: string,
  data: IModuleGridUpdateData,
  reduxUpdater: () => void
) {
  let saveResults: ValidationResults = {} as ValidationResults;

  // update document in db
  const { oldModule, isUpdated, newModule } = await _updateModuleInDB(id, data);

  saveResults =
    (isUpdated && isUpdated.saveResults.validation) ||
    ({} as ValidationResults);

  if (isUpdated && isUpdated.saveResults.did_save) {
    // if document is updated in db, update redux
    reduxUpdater();

    // if ref provided, find and update currently opened editor tab and grid
    if (ref) {
      // find tab
      const tab: any = ref.find(oldModule?.data.module_name + '_tab');
      if (tab) {
        // update grid meta, data(if applicable), and tab title(if applicable)
        const oldTabProps = (tab as any).content.props.children.props.children;
        newModule &&
          _updateModuleTabAndGrid(newModule, oldTabProps, ref, saveResults);
      }
    }
  }

  return saveResults;
}

async function _updateModuleInDB(id: string, data: IModuleGridUpdateData) {
  const oldModule = await ModuleCollection.findById(id);

  const isUpdated = await ModuleCollection.updateModule(id, {
    module_name: data?.name
  });

  const newModule = await ModuleCollection.findById(id);

  return { oldModule, isUpdated, newModule };
}

async function _updateModuleTabAndGrid(
  newModule: Module,
  tabProps: any,
  ref: DockLayout,
  saveResults: ValidationResults
) {
  const newGridMeta: GridMetaData = {
    createBy: newModule?.data.create_by ?? '',
    updateBy: newModule?.data.updated_by ?? '',
    createDt: newModule?.data.create_dt ?? ({} as LogicEngineDataDateTime),
    updateDt: newModule?.data.updated_dt ?? ({} as LogicEngineDataDateTime),
    moduleName: newModule?.data.module_name,
    documentId: newModule?.data.id ?? ''
  };

  const tabUpdated = ref.updateTab(
    tabProps[1].props.children.props.id + '_tab',
    generateTab({
      ...tabProps[1].props.children.props,
      gridMetaData: newGridMeta,
      id: newModule?.data.module_name,
      title: newModule?.data.module_name ?? '',
      data: [newModule.data],
      group: builderPageTypes.modules,
      metaGridCols: tabProps[0].props.children.props.columnDefs,
      metaGridDefaultCol: tabProps[0].props.children.props.defaultColDef,
      onMetaCellValueChanged:
        tabProps[0].props.children.props.onCellValueChanged
    })
  );

  if (!tabUpdated) {
    saveResults.addError('updateTab', 'failed to update opened tab');
  }
}
