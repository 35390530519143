/* eslint-disable react/jsx-no-bind */
/* eslint-disable camelcase */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { RCDockLayout, TabData } from '@digitalworkflow/dwreactcommon';
import { useParams } from 'react-router';
import {
  DockLayout,
  ISaveLookupProps,
  ISaveDuplicateLookupProps,
  IInitialTabDefaultParams,
  GridApi,
  ISaveModuleSectionProps,
  IGridReferences
} from '../../config/types';
import { Browser } from './Browser';
import {
  addFieldTab,
  // addFieldTab,
  browserCurrentLineUpdate,
  // browserCurrentUpdate,
  openBrowserTabs,
  removeFieldTab
} from './BrowserTabsHelper';
import { openInitialEditorTab } from './EditorTabsHelper';
import LoadingScreen from '../../components/LoadingScreen';
import { batch, useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store';
import AddModal from '../../components/AddModal';
import LookupForm from '../../components/Forms/LookupForm';
import { triggerEvent } from '../../redux/event';
import {
  addDuplicateLookup,
  addLookup,
  openLookupsTab
} from '../../components/MetaRenderers/Lookups/LookupsUtils';
import { fetchLookupGroups, fetchLookups } from '../../redux/lookup';
import DataDictionaryForm, {
  DataTableType
} from '../../components/Forms/DataDictionaryForm';
import {
  DataDictionaryTableCollection,
  LookupDocumentType,
  GridServices,
  DataDictionaryFieldCollection,
  PageCollection,
  ModuleDocumentType,
  ModuleSectionDocumentType,
  ModuleCollection,
  WorkflowStateDocumentType,
  WorkflowStateCollection,
  ModuleSectionCollection,
  TaskConfigCollection,
  TaskConfigDocumentType,
  PageDocumentType,
  // CommandLinter,
  Page,
  LookupCollection
  // CommonHelper
} from '@digitalworkflow/dwtranslateclient';
import GridShortcutButtons from '../../components/GridShortcutButtons';
import {
  insertEmptyRow,
  insertDataRow,
  extractGridMetaFromTab,
  updateDataEverywhere,
  lintProcessor
} from '../../utils';
import { RcGroup } from '../../components/RcGroup';
import CurrentLine from '../../components/CurrentLine';
import {
  addDataDictionaryField,
  openDataDictionaryTab
} from '../../components/MetaRenderers/DataDictionary/DataDictionaryUtils';
import {
  addStep,
  openPagesTab
} from '../../components/MetaRenderers/Pages/PagesUtils';
import { fetchPageGroups, fetchPages } from '../../redux/page';
import PageForm, { handlePageSaveProps } from '../../components/Forms/PageForm';
import ModuleForm from '../../components/Forms/ModuleForm';
import ModuleSectionForm from '../../components/Forms/ModuleSectionForm';
import WorkflowStatesForm from '../../components/Forms/WorkflowStatesForm';
import TaskConfigForm from '../../components/Forms/TaskConfigForm';
import {
  fetchDataDictionary,
  fetchDataDictionaryGroups
} from '../../redux/dataDictionary';
import { fetchModuleGroups, fetchModules } from '../../redux/module';
import {
  fetchModuleSectionGroups,
  fetchModuleSections
} from '../../redux/moduleSection';
import {
  fetchWorkflowStateGroups,
  fetchWorkflowStates
} from '../../redux/workflowState';
import {
  fetchTaskConfigGroups,
  fetchTaskConfigs
} from '../../redux/taskConfig';
import {
  addModule,
  openModulesTab
} from '../../components/MetaRenderers/Modules/ModulesUtils';
import {
  addModuleSection,
  openModuleSectionsTab
} from '../../components/MetaRenderers/ModuleSections/ModuleSectionsUtils';
import { openWorkflowStatesTab } from '../../components/MetaRenderers/WorkflowStates/WorkflowStatesUtils';
import { setSelectedNode } from '../../redux/activeNode';
import { openTaskConfigsTab } from '../../components/MetaRenderers/TaskConfigs/TaskConfigsUtils';
import { collectionChangedEventSubscriber } from '../../utils/collectionChangedEventSubscriber';
import {
  builderPageTypes as BPT,
  builderPageTypes
} from '../../config/constants/builderPageTypes';
import CustomTooltip from '../../components/CustomTooltip/CustomTooltip';
import { AsyncThunk } from '@reduxjs/toolkit';
import FieldsRenderer from '../../components/FieldsRenderer/FieldsRenderer';
import DuplicateLookupForm from '../../components/Forms/DuplicateLookupForm';
import { updateCurrentGridDetail } from '../../components/CurrentGridDetail/updateCurrentGridDetail';
import DuplicateDataDictionaryForm, {
  DuplicateDataTableType
} from '../../components/Forms/DuplicateDataDictionaryForm';
import DuplicatePageForm, {
  handleDuplicatePageSaveProps
} from '../../components/Forms/DuplicatePageForm';
import { GlobalHashHelper } from '../../utils/HashHelper';
import { hashAllowLinks } from '../../config/constants/hashAllowLinks';
import { ITreeViewData } from '@digitalworkflow/dwtranslateclient/lib/Models/Module/ModuleCollection';
import { toast } from 'react-toastify';
import { createGridMetaFromDocumentIdAndType } from '../../utils/gridMetaManipulate';

interface PageTypeHandler {
  data?: any[];
  groups?: any[];
  fetchData: () => void;
  setRowSelectedFlag: (val: boolean) => void;
  updateData: (id: string, data: any) => void;
  openTab?: (tabId: string, type: string) => void;
}

export type FormErrorType = {
  error_message: string;
  field_id: string;
  type: string;
};

function MasterEditor() {
  const { pageType } = useParams<{ pageType: BPT }>();
  const [currentAgGridRef, setCurrentAgGridRef] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);
  const [currentEventAPI, setCurrentEventAPI] = useState<GridApi>(
    {} as GridApi
  );
  const [copiedRows, setCopiedRows] = useState<any[]>([]);
  const [tabsRef, setTabsRef] = useState<any[]>([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [currentTabType, setCurrentTabType] = useState<BPT | undefined>();
  const [currentTab, setCurrentTab] = useState<{
    documentId: string;
    type: builderPageTypes;
  }>();

  const gridReferences = useRef<IGridReferences[]>([]);

  const dockLayoutRef = useRef<DockLayout | null>(null);
  const firstRender = useRef<boolean>(true);

  const pages = useRef<boolean>(pageType === BPT.pages);
  const lookups = useRef<boolean>(pageType === BPT.lookups);
  const dataDictionary = useRef<boolean>(pageType === BPT.dataDictionaries);
  const modules = useRef<boolean>(pageType === BPT.modules);
  const moduleSections = useRef<boolean>(pageType === BPT.moduleSections);
  const workflowStates = useRef<boolean>(pageType === BPT.workflowStates);
  const taskConfigs = useRef<boolean>(pageType === BPT.taskConfigs);

  const lookupsData = useSelector((state: RootState) => state.lookup.lookups);
  const lookupsGroups = useSelector((state: RootState) => state.lookup.groups);

  const pagesData = useSelector((state: RootState) => state.page.pages);
  const pagesGroups = useSelector((state: RootState) => state.page.groups);

  const ddData = useSelector(
    (state: RootState) => state.dataDictionary.dataDictionary
  );

  const ddGroups = useSelector(
    (state: RootState) => state.dataDictionary.groups
  );

  const modulesData: any[] = useSelector(
    (state: RootState) => state.module.modules
  );
  const modulesGroups: any[] = useSelector(
    (state: RootState) => state.module.groups
  );

  const modulesSectionsData: any[] = useSelector(
    (state: RootState) => state.moduleSection.moduleSections
  );
  const modulesSectionsGroups: any[] = useSelector(
    (state: RootState) => state.moduleSection.groups
  );

  const workflowStatesData: any[] = useSelector(
    (state: RootState) => state.workflowState.workflowStates
  );
  const workflowStatesGroups: any[] = useSelector(
    (state: RootState) => state.workflowState.groups
  );

  const taskConfigsData: any[] = useSelector(
    (state: RootState) => state.taskConfig.taskConfigs
  );
  const taskConfigsGroups: any[] = useSelector(
    (state: RootState) => state.taskConfig.groups
  );

  const eventType = useSelector((state: RootState) => state.event.type);
  const node2Duplicate = useSelector(
    (state: RootState) => state.event.node2Duplicate
  );
  const initialTabParamsDefault: IInitialTabDefaultParams = {
    ref: dockLayoutRef.current,
    type: pageType ?? '',
    selectedRows,
    gridReferences: gridReferences,
    setSelectedRows: (rows: any[]) => updateSelectedRows(rows),
    setSelectedRowIndex: (id: number) => updateSelectedRowIndex(id),
    setCurrentEventAPI: (api: GridApi) => updateCurrentAPI(api),
    setTabsRef: (value: any) => updateTabsRef(value)
  };

  function updateSelectedRows(rows: any[]) {
    if (pageType === BPT.lookups) {
      if (rows[0]?.doc && !rows[0].meta) {
        rows[0].meta = [];
        rows[0]?.doc.forEach((docRow: any) => {
          console.log('CCC doc: ', docRow);
          rows[0].meta.push({
            key: docRow.fid ?? '',
            value: docRow.val ?? ''
          });
        });
      }
    }
    setSelectedRows(rows);
  }

  function updateSelectedRowIndex(id: number) {
    if (id < 0) {
      setSelectedRows([]);
      return;
    }
    setSelectedRowIndex(id);
  }

  function updateCurrentAPI(api: GridApi) {
    setCurrentEventAPI(api);
  }

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentEventAPI && currentEventAPI.deselectAll) {
      currentEventAPI.deselectAll();
    }
  }, [pageType]);

  useEffect(() => {
    collectionChangedEventSubscriber({
      gridReferences: gridReferences,
      dictionariesFetcher: () => callDataAccordingToPage(BPT.dataDictionaries),
      loopkupsFetcher: () => callDataAccordingToPage(BPT.lookups),
      moduleSectionsFetcher: () => callDataAccordingToPage(BPT.moduleSections),
      modulesFetcher: () => callDataAccordingToPage(BPT.modules),
      pagesFetcher: () => callDataAccordingToPage(BPT.pages),
      taskConfigsFetcher: () => callDataAccordingToPage(BPT.taskConfigs),
      workflowStatesFetcher: () => callDataAccordingToPage(BPT.workflowStates)
    });
  }, []);

  useEffect(() => {
    if (pages.current) {
      handleFormField();
    }
  }, [currentAgGridRef, selectedRows, pages]);

  const handleFieldClick = async (value: string) => {
    const data = {
      step_type: 'Field',
      step_option: 'Required',
      text_en: '',
      field_id: value,
      option_1: '',
      option_2: '',
      option_3: '',
      view_groups: '',
      execute_if: '',
      style: ''
    };
    const activeTab =
      dockLayoutRef.current?.getDockId().state.layout.dockbox.children[0]
        .activeId;
    const route = activeTab.replace(/_tab$/, '');
    const gridApi = currentAgGridRef?.current?.api;
    const result = await addStep(route, gridApi, data, selectedRowIndex - 1);
    if (result) {
      insertDataRow(
        pageType ?? '',
        currentEventAPI,
        selectedRowIndex,
        data,
        selectedRows
      );
      callDataAccordingToPage(BPT.pages);
    }
  };

  const handleFormField = async () => {
    const _selectRow = selectedRows?.[0];

    if (_selectRow) {
      // Ensure currentAgGridRef and its properties are defined
      if (!currentAgGridRef?.current?.api) {
        console.error('AG Grid API is not available');
        return;
      }

      const model = currentAgGridRef.current.api.getModel();
      const rowsToDisplay = model.rowsToDisplay || [];

      const _gridRows = rowsToDisplay.map((item: any) => item.data);

      // Ensure _gridRows is defined and is an array
      if (!_gridRows || !_gridRows.length) {
        console.error('Grid rows are undefined or empty');
        return;
      }

      const selectedIndex = _gridRows.findIndex(
        (row: any) => row === _selectRow
      );

      if (selectedIndex !== -1) {
        let beginFormItemIndex = -1;
        let endFormItemIndex = 0;

        for (let i = selectedIndex - 1; i >= 0; i--) {
          if (
            typeof _gridRows[i]?.step_type === 'string' &&
            _gridRows[i].step_type?.toLowerCase() === 'begin form'
          ) {
            beginFormItemIndex = i;
            break;
          }
        }

        if (beginFormItemIndex !== -1) {
          for (let i = beginFormItemIndex; i <= _gridRows.length; ++i) {
            if (
              typeof _gridRows[i]?.step_type === 'string' &&
              _gridRows[i].step_type?.toLowerCase() === 'end form'
            ) {
              endFormItemIndex = i;
              break;
            }
          }

          if (
            beginFormItemIndex <= selectedIndex &&
            selectedIndex <= endFormItemIndex &&
            _gridRows[beginFormItemIndex].step_option
          ) {
            const _dataDictionaryColumns =
              await DataDictionaryFieldCollection.getAllFieldsByProjectAndTable(
                _gridRows[beginFormItemIndex].step_option
              );

            const _columns = _dataDictionaryColumns.map((item) => {
              return {
                field: item._data.field
              };
            });

            removeFieldTab(dockLayoutRef.current);
            addFieldTab(dockLayoutRef.current, () => (
              <FieldsRenderer
                fields={_columns}
                handleFieldClick={handleFieldClick}
              />
            ));
          } else {
            removeFieldTab(dockLayoutRef.current);
          }
        } else {
          removeFieldTab(dockLayoutRef.current);
        }
      }
    }
  };

  useEffect(() => {
    const isLookupAndNoData =
      pageType === BPT.lookups &&
      (!Object.keys(lookupsData).length || !lookupsGroups.length);
    const isDictionaryAndNoData =
      pageType === BPT.dataDictionaries && (!ddData.length || !ddGroups.length);
    const isPageAndNoData =
      pageType === BPT.pages && (!pagesData || !pagesGroups.length);
    const isModuleAndNoData =
      pageType === BPT.modules &&
      (!(modulesData && Object.keys(modulesData).length) ||
        !modulesGroups.length);
    const isModuleSectionAndNoData =
      pageType === BPT.moduleSections &&
      (!modulesSectionsData || !modulesSectionsGroups.length);
    const isWorkflowStateAndNoData =
      pageType === BPT.workflowStates &&
      (!workflowStatesData || !workflowStatesGroups.length);
    const isTaskConfigAndNoData =
      pageType === BPT.taskConfigs &&
      (!taskConfigsData || !taskConfigsGroups.length);

    if (
      !firstRender.current ||
      isLookupAndNoData ||
      isDictionaryAndNoData ||
      isPageAndNoData ||
      isModuleAndNoData ||
      isModuleSectionAndNoData ||
      isWorkflowStateAndNoData ||
      isTaskConfigAndNoData
    )
      return;
    firstRender.current = false;

    initialTabParamsDefault.ref = dockLayoutRef.current;
    handleInitialEditorTab(pageType ?? '', initialTabParamsDefault);
  }, [
    lookupsData,
    lookupsGroups,
    ddData,
    ddGroups,
    pagesData,
    pagesGroups,
    modulesData,
    modulesGroups,
    modulesSectionsData,
    modulesSectionsGroups,
    workflowStatesData,
    workflowStatesGroups,
    taskConfigsData,
    taskConfigsGroups
  ]);

  useEffect(() => {
    updatePageTypeRef(pageType ?? '');
    const openBrowserTabsParams: IInitialTabDefaultParams = {
      ref: dockLayoutRef.current,
      type: pageType ?? '',
      selectedRows,
      gridReferences: gridReferences,
      setSelectedRows: (rows: any[]) => {
        updateSelectedRows(rows);
      },
      setSelectedRowIndex: (id: number) => updateSelectedRowIndex(id),
      setCurrentEventAPI: (api: GridApi) => updateCurrentAPI(api),
      setTabsRef: (value: any) => updateTabsRef(value)
    };
    handleOpenBrowserTabs(pageType ?? '', openBrowserTabsParams);
  }, [pageType]);

  useEffect(() => {
    browserCurrentLineUpdate(dockLayoutRef.current, () => (
      <CurrentLine
        type={pageType}
        selectedRows={selectedRows}
        gridRef={currentAgGridRef}
      />
    ));
    updateGridMeta();
  }, [selectedRows, currentAgGridRef]);

  useEffect(() => {
    if (!eventType) {
    } else if (eventType === 'showModalEvent') {
      setShowModal(true);
      setFormErrors({
        [BPT.pages]: null,
        [BPT.lookups]: null,
        [BPT.dataDictionaries]: null
      });
    } else if (eventType === 'showDuplicateModalEvent') {
      setShowDuplicateModal(true);
      setFormErrors({
        [BPT.pages]: null,
        [BPT.lookups]: null,
        [BPT.dataDictionaries]: null
      });
    } else if (eventType === 'hideModalEvent') {
      setShowModal(false);
      setShowDuplicateModal(false);
    } else console.log('unsupported eventType type');
  }, [eventType]);

  const [currentTooltipTarget, setCurrentTooltipTarget] = useState(null);
  const [currentTooltipText, setCurrentTooltipText] = useState<string>('');
  const [tooltipToggle, setTooltipToggle] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({
    [BPT.pages]: null,
    [BPT.lookups]: null,
    [BPT.dataDictionaries]: null
  });

  const fetchData = (
    dispatchMethod: AsyncThunk<
      any,
      void,
      {
        rejectValue: string;
      }
    >,
    groupMethod: AsyncThunk<
      ITreeViewData[],
      void,
      {
        rejectValue: string;
      }
    >
  ) => {
    batch(() => {
      dispatch(dispatchMethod());
      dispatch(groupMethod());
    });
  };

  useEffect(() => {
    const handleMouseOver = (event: any) => {
      const target = event.target;
      if (target && target.classList) {
        const classList = [...target.classList];
        if (
          classList.includes('ag-cell-value') &&
          target.children.length === 0
        ) {
          if (target.offsetWidth - 2 < target.scrollWidth) {
            setCurrentTooltipTarget(target);
            setCurrentTooltipText(target.innerText);
            setTooltipToggle(true);
            return;
          }
        }

        setCurrentTooltipTarget(null);
        setCurrentTooltipText('');
        setTooltipToggle(false);
      }
    };

    const handleMenuClick = (event: any) => {
      const target = event.target;
      if (hashAllowLinks.includes(target.innerText)) {
        setTimeout(() => {
          document.location.hash = localStorage.getItem('dwbuilder_hash') ?? '';
        }, 0);
      } else {
        localStorage.removeItem('dwbuilder_hash');
      }
    };

    const handleHashChange = () => {
      const currentHash = localStorage.getItem('dwbuilder_hash');
      if (document.location.hash && document.location.hash !== currentHash) {
        location.reload();
      }
    };

    const menu = document.getElementById('side-menu');
    if (menu) {
      menu.addEventListener('click', handleMenuClick);
    }
    window.addEventListener('mouseover', handleMouseOver);
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('mouseover', handleMouseOver);
      window.removeEventListener('hashchange', handleHashChange);
      if (menu) {
        menu.removeEventListener('click', handleMenuClick);
      }
    };
  }, []);

  async function updateGridMeta() {
    if (currentTab?.type && dockLayoutRef.current) {
      const _meta = await createGridMetaFromDocumentIdAndType(
        currentTab.documentId,
        currentTab.type
      );
      _meta &&
        updateCurrentGridDetail({
          ref: dockLayoutRef.current,
          type: currentTab.type,
          data: _meta
        });
    }
  }

  const pageTypeHandlers: Record<string, PageTypeHandler> = {
    [BPT.pages]: {
      data: pagesData,
      groups: pagesGroups,
      fetchData: () => callDataAccordingToPage(BPT.pages),
      setRowSelectedFlag: console.log,
      updateData: updatePageData
    },
    [BPT.lookups]: {
      data: lookupsData,
      groups: lookupsGroups,
      fetchData: () => callDataAccordingToPage(BPT.lookups),
      setRowSelectedFlag: console.log,
      updateData: (id, data) => {
        console.log('updateData', id, data);
      }
    },
    [BPT.dataDictionaries]: {
      data: ddData,
      groups: ddGroups,
      fetchData: () => callDataAccordingToPage(BPT.dataDictionaries),
      setRowSelectedFlag: console.log,
      updateData: async (id, data) => {
        if (!id) {
          const { activeId } = dockLayoutRef.current?.find('grid_dock') as {
            activeId: string;
          };
          const currentAgGrid = gridReferences.current.filter((item) => {
            return item.tabId === activeId;
          });
          currentAgGrid?.[currentAgGrid.length - 1]?.gridApi.refreshCells({
            columns: ['related_table'],
            force: true
          });
        }
        const result = await DataDictionaryFieldCollection.updateField(
          id,
          data
        );
        if (result && result?.saveResults?.did_save) {
          const updatedData: any = result.record?._data;
          let currentAgGrid: any[] = [];
          if ('field_type' in data) {
            currentAgGrid = gridReferences.current.filter((item) => {
              return item.tabId === updatedData.table_name + '_tab';
            });
          }
          setSelectedRows([updatedData]);
          callDataAccordingToPage(BPT.dataDictionaries);
          currentAgGrid?.[currentAgGrid.length - 1]?.gridApi.refreshCells({
            columns: ['related_table'],
            force: true
          });

          if (result?.saveResults?.is_modified) {
            // show popup if record is modified
            toast.success('DataDictionary was updated successfully.', {
              containerId: 'main-toast'
            });
          }
        } else if (
          result &&
          result?.saveResults?.validation?.results &&
          result?.saveResults?.validation?.results.length > 0
        ) {
          const { results } = result?.saveResults?.validation;
          for (let i = 0; i < results.length; i++) {
            toast.error(results[i].error_message, {
              containerId: 'main-toast'
            });
          }
        }
      },
      openTab: async (tabId: string, type: string) => {
        if (type === 'Lookup') {
          const tabAlreadyPresent = dockLayoutRef.current?.find(tabId + '_tab');

          const response = await LookupCollection.getLookupValues(tabId);
          if (!response.length) {
            await saveLookup({
              key: tabId,
              codedValue: '',
              text: '',
              export_value: '',
              group: 'Auto Generated'
            });
            return;
          }
          const rows: any[] = [];
          response.forEach((item) => {
            rows.push(item.data);
          });

          tabAlreadyPresent
            ? dockLayoutRef.current?.updateTab(tabId + '_tab', null)
            : handlePageType(
                BPT.lookups,
                ({ fetchData, setRowSelectedFlag, updateData }) => {
                  openLookupsTab({
                    rows: rows,
                    title: tabId,
                    firstTab: false,
                    fetchData,
                    setRowSelectedFlag,
                    updateData,
                    updateMetaData: (data) => {
                      updateDataEverywhere(
                        dockLayoutRef.current ?? undefined,
                        data.id,
                        data,
                        builderPageTypes.lookups,
                        () => callDataAccordingToPage(builderPageTypes.lookups)
                      );
                    },
                    ...initialTabParamsDefault,
                    ref: dockLayoutRef.current
                  });
                }
              );
        } else {
          callDataAccordingToPage(BPT.dataDictionaries);
          const tabAlreadyPresent = dockLayoutRef.current?.find(tabId + '_tab');

          const response =
            await DataDictionaryFieldCollection.getAllFieldsByProjectAndTable(
              tabId
            );
          if (!response.length) {
            await saveDataTable({
              tableName: tabId,
              description: '',
              group: 'Auto Generated'
            });
            return;
          }
          const lookupData = await LookupCollection.getAllKeys();
          const dataDictionaryData =
            await DataDictionaryTableCollection.getAllTablesWithFields();
          const rows: any[] = [];
          response.forEach((item) => {
            rows.push(item.data);
          });
          tabAlreadyPresent
            ? dockLayoutRef.current?.updateTab(tabId + '_tab', null)
            : handlePageType(
                BPT.dataDictionaries,
                ({ fetchData, setRowSelectedFlag, updateData, openTab }) => {
                  openDataDictionaryTab({
                    rows: rows,
                    title: tabId,
                    firstTab: false,
                    fetchData,
                    setRowSelectedFlag,
                    updateData,
                    openTab,
                    lookupData,
                    dataDictionaryData,
                    ...initialTabParamsDefault,
                    ref: dockLayoutRef.current
                  });
                }
              );
        }
        dispatch(setSelectedNode(tabId));
      }
    },
    [BPT.modules]: {
      data: modulesData,
      groups: modulesGroups,
      fetchData: () => callDataAccordingToPage(BPT.modules),
      setRowSelectedFlag: console.log,
      updateData: updateModuleData
    },
    [BPT.moduleSections]: {
      data: modulesSectionsData,
      groups: modulesSectionsGroups,
      fetchData: () => callDataAccordingToPage(BPT.moduleSections),
      setRowSelectedFlag: console.log,
      updateData: async (id, data) => {
        const result = await ModuleSectionCollection.updateModuleSection(
          id,
          data
        );

        if (result && result?.saveResults?.is_modified) {
          const data = result.record?._data;
          setSelectedRows([data]);
          callDataAccordingToPage(BPT.moduleSections);
          let currentAgGrid: any[] = [];
          currentAgGrid = gridReferences.current.filter((item) => {
            return item.tabId === data.section_name + '_tab';
          });
          currentAgGrid?.[currentAgGrid.length - 1]?.gridApi.setRowData([data]);
        }
      }
    },
    [BPT.workflowStates]: {
      data: workflowStatesData,
      groups: workflowStatesGroups,
      fetchData: () => callDataAccordingToPage(BPT.workflowStates),
      setRowSelectedFlag: console.log,
      updateData: updateWorkflowStateData
    },
    [BPT.taskConfigs]: {
      data: taskConfigsData,
      groups: taskConfigsGroups,
      fetchData: () => callDataAccordingToPage(BPT.taskConfigs),
      setRowSelectedFlag: console.log,
      updateData: updateTaskConfigData
    }
  };

  function handlePageType(
    pageType: string,
    callback: (handler: PageTypeHandler) => void
  ) {
    const handler = pageTypeHandlers[pageType] || {
      fetchData: () => console.log('Unmatched route', pageType),
      setRowSelectedFlag: (val: boolean) =>
        console.log('setRowSelectedFlag', val),
      updateData: (id, data) => console.log('updateData', id, data),
      openTab: (tabId: string, type: string) =>
        console.log('openTab', tabId, type)
    };

    callback(handler);
  }

  function handleInitialEditorTab(
    pageType: string,
    initialTabParams: IInitialTabDefaultParams
  ) {
    handlePageType(
      pageType,
      ({
        data,
        groups,
        fetchData,
        setRowSelectedFlag,
        updateData,
        openTab
      }) => {
        openInitialEditorTab({
          data: data || [],
          groups: groups || [],
          fetchData,
          setRowSelectedFlag,
          updateData,
          openTab,
          updateMetaData: (data) => {
            updateDataEverywhere(
              dockLayoutRef.current ?? undefined,
              data.id,
              data,
              pageType as builderPageTypes,
              () => callDataAccordingToPage(pageType as builderPageTypes)
            );
          },
          ...(pageType === BPT.dataDictionaries
            ? {
                lookupData: lookupsData,
                dataDictionaryData: ddData
              }
            : {}),
          ...initialTabParams
        });
      }
    );
  }

  function handleOpenBrowserTabs(
    pageType: string,
    openBrowserTabsParams: IInitialTabDefaultParams
  ) {
    handlePageType(
      pageType,
      ({ fetchData, setRowSelectedFlag, updateData, openTab }) => {
        openBrowserTabs({
          fetchData,
          setRowSelectedFlag,
          updateData,
          openTab,
          ...openBrowserTabsParams
        });
      }
    );
  }

  function updatePageTypeRef(pageType: string) {
    pages.current = pageType === BPT.pages;
    lookups.current = pageType === BPT.lookups;
    dataDictionary.current = pageType === BPT.dataDictionaries;
    modules.current = pageType === BPT.modules;
    moduleSections.current = pageType === BPT.moduleSections;
    workflowStates.current = pageType === BPT.workflowStates;
    taskConfigs.current = pageType === BPT.taskConfigs;
  }

  function callDataAccordingToPage(pageType: string) {
    switch (pageType) {
      case BPT.pages:
        fetchData(fetchPages, fetchPageGroups);
        break;
      case BPT.lookups:
        fetchData(fetchLookups, fetchLookupGroups);
        break;
      case BPT.dataDictionaries:
        fetchData(fetchDataDictionary, fetchDataDictionaryGroups);
        break;
      case BPT.modules:
        fetchData(fetchModules, fetchModuleGroups);
        break;
      case BPT.moduleSections:
        fetchData(fetchModuleSections, fetchModuleSectionGroups);
        break;
      case BPT.workflowStates:
        fetchData(fetchWorkflowStates, fetchWorkflowStateGroups);
        break;
      case BPT.taskConfigs:
        fetchData(fetchTaskConfigs, fetchTaskConfigGroups);
        break;
      default:
        console.log('on invalid route in callDataAccordingToPage');
        break;
    }
  }

  const updateTabsRef = (tabRef: any) => {
    setCurrentAgGridRef(tabRef.gridRef);
    setTabsRef((prevTabsRef) => {
      const existingTabIndex = prevTabsRef.findIndex(
        (item) => item.tabId === tabRef.tabId
      );
      if (existingTabIndex !== -1) {
        const updatedTabsRef = [...prevTabsRef];
        updatedTabsRef[existingTabIndex] = tabRef;
        return updatedTabsRef;
      } else {
        return [...prevTabsRef, tabRef];
      }
    });

    setSelectedRows([]);
    setSelectedRowIndex(-1);
  };

  function toggleAddModal() {
    dispatch(triggerEvent(showModal ? 'hideModalEvent' : 'showModalEvent'));
  }

  function toggleDuplicateModal() {
    dispatch(
      triggerEvent(showDuplicateModal ? 'hideModalEvent' : 'showModalEvent')
    );
  }

  async function updateWorkflowStateData(
    id: string,
    data: Partial<WorkflowStateDocumentType>
  ) {
    const result = await WorkflowStateCollection.updateWorkflowState(id, data);
    if (result && result?.saveResults?.is_modified) {
      callDataAccordingToPage(pageType ?? '');
      setSelectedRows([result.record?._data]);
    }
  }

  async function updateTaskConfigData(
    id: string,
    data: Partial<WorkflowStateDocumentType>
  ) {
    const result = await TaskConfigCollection.updateTaskConfig(id, data);
    if (result && result?.saveResults?.is_modified) {
      callDataAccordingToPage(pageType ?? '');
      setSelectedRows([result.record?._data]);
    }
  }

  async function updateModuleData(
    id: string,
    data: Partial<ModuleDocumentType>
  ) {
    const result = await ModuleCollection.updateModule(id, data);
    if (result && result?.saveResults?.is_modified) {
      callDataAccordingToPage(pageType ?? '');
      setSelectedRows([result.record?._data]);
    }
  }

  async function updatePageData(id: string, data: Partial<PageDocumentType>) {
    console.log(id, data);
    // const result = await PageCollection.updatePage(id, data);
    // if (result && result?.saveResults?.is_modified) {
    //   callDataAccordingToPage(pageType ?? '');
    //   setSelectedRows([result.record?._data]);
    // }
  }

  async function savePage({
    route,
    page_type,
    description,
    title_en,
    group
  }: handlePageSaveProps) {
    const page = PageCollection.createPage();
    page.data.page_type = page_type;
    page.data.title_en = title_en;
    page.data.description = description;
    page.data.route = route;
    page.data.group = group;
    const pageSaveRes = await page.save();

    console.log('save page response: ', pageSaveRes);

    if (pageSaveRes.did_save) {
      setFormErrors({ ...formErrors, [BPT.pages]: null });
      dispatch(setSelectedNode(route));
      callDataAccordingToPage(pageType ?? '');
      handlePageType(
        BPT.pages,
        ({ fetchData, setRowSelectedFlag, updateData }) => {
          openPagesTab({
            currentPage: page.data as PageDocumentType,
            rows: [],
            title: route,
            firstTab: false,
            fetchData,
            setRowSelectedFlag,
            updateData,
            updateMetaData: (data) => {
              updateDataEverywhere(
                dockLayoutRef.current ?? undefined,
                data.id,
                data,
                builderPageTypes.pages,
                () => callDataAccordingToPage(builderPageTypes.pages)
              );
            },
            ...initialTabParamsDefault
          });
        }
      );
    } else {
      setFormErrors({
        ...formErrors,
        [BPT.pages]: pageSaveRes.validation.results as FormErrorType[]
      });
    }
    return pageSaveRes.did_save;
  } // save page ends

  async function saveDuplicatePage({
    route,
    title,
    projectId,
    group
  }: handleDuplicatePageSaveProps) {
    const pageSaveRes = await PageCollection.duplicatePage(
      node2Duplicate ?? '',
      route,
      title,
      group,
      projectId
    );

    if (pageSaveRes && 'record' in pageSaveRes) {
      callDataAccordingToPage(BPT.pages);
      dispatch(setSelectedNode(route));
      handlePageType(
        BPT.pages,
        ({ fetchData, setRowSelectedFlag, updateData }) => {
          openPagesTab({
            rows:
              pageSaveRes.record.data.steps?.map((item: any, index: number) => {
                return {
                  ...item,
                  rownum: index + 1
                };
              }) ?? [],
            currentPage: pageSaveRes.record.data as PageDocumentType,
            title: route,
            firstTab: false,
            fetchData,
            setRowSelectedFlag,
            updateData,
            updateMetaData: (data) => {
              updateDataEverywhere(
                dockLayoutRef.current ?? undefined,
                data.id,
                data,
                builderPageTypes.pages,
                () => callDataAccordingToPage(builderPageTypes.pages)
              );
            },
            ...initialTabParamsDefault
          });
        }
      );
    }
  } // save duplicate page ends

  /**
   * callback to handle when a new lookup is created from form
   */
  async function saveLookup({
    key,
    codedValue,
    text,
    export_value,
    group
  }: ISaveLookupProps) {
    const addLookupRes = await addLookup(
      { key, codedValue, text, export_value, group },
      undefined,
      true
    );
    if (addLookupRes?.saveResults?.did_save) {
      setFormErrors({ ...formErrors, [BPT.lookups]: null });
      callDataAccordingToPage(pageType ?? '');
      dispatch(setSelectedNode(addLookupRes.record._data.key));
      const data: Partial<LookupDocumentType>[] = [];
      data.push({
        id: addLookupRes.record._data.id,
        key: addLookupRes.record._data.key,
        coded_value: addLookupRes.record._data.coded_value,
        text: addLookupRes.record._data.text,
        export_value: addLookupRes.record._data.export_value,
        group: addLookupRes.record._data.group
      });
      handlePageType(
        BPT.lookups,
        ({ fetchData, setRowSelectedFlag, updateData }) => {
          openLookupsTab({
            rows: data,
            title: addLookupRes.record._data.key,
            firstTab: false,
            fetchData,
            setRowSelectedFlag,
            updateData,
            updateMetaData: (data) => {
              updateDataEverywhere(
                dockLayoutRef.current ?? undefined,
                data.id,
                data,
                builderPageTypes.lookups,
                () => callDataAccordingToPage(builderPageTypes.lookups)
              );
            },
            ...initialTabParamsDefault
          });
        }
      );
    } else {
      setFormErrors({
        ...formErrors,
        [BPT.lookups]: addLookupRes.saveResults?.validation
          .results as FormErrorType[]
      });
    }
    console.log(addLookupRes, addLookupRes?.saveResults);
    return addLookupRes?.saveResults?.did_save ?? false;
  }

  /**
   * callback to handle when a new lookup is created from form
   */
  async function saveDuplicateLookup({
    key,
    projectId,
    group
  }: ISaveDuplicateLookupProps) {
    console.log('duplicate lookup', key, projectId, group);
    const addDuplicateLookupResTmp = await addDuplicateLookup(
      node2Duplicate ?? '',
      { key, projectId, group },
      undefined
    );
    const addDuplicateLookupRes = addDuplicateLookupResTmp[0] ?? null;
    console.log('DDLK1 --- duplicate lookup response: ', addDuplicateLookupRes);

    if (addDuplicateLookupRes?.saveResults?.did_save) {
      setFormErrors({ ...formErrors, [BPT.lookups]: null });
      callDataAccordingToPage(pageType ?? '');
      if (addDuplicateLookupRes?.saveResults?.did_save)
        dispatch(setSelectedNode(addDuplicateLookupRes.record._data.key));
      const data: Partial<LookupDocumentType>[] = [];
      data.push({
        id: addDuplicateLookupRes.record._data.id,
        key: addDuplicateLookupRes.record._data.key,
        coded_value: addDuplicateLookupRes.record._data.coded_value,
        text: addDuplicateLookupRes.record._data.text,
        export_value: addDuplicateLookupRes.record._data.export_value,
        group: addDuplicateLookupRes.record._data.group
      });
      handlePageType(
        BPT.lookups,
        ({ fetchData, setRowSelectedFlag, updateData }) => {
          openLookupsTab({
            rows: data,
            title: addDuplicateLookupRes.record._data.key,
            firstTab: false,
            fetchData,
            setRowSelectedFlag,
            updateData,
            updateMetaData: (data) => {
              updateDataEverywhere(
                dockLayoutRef.current ?? undefined,
                data.id,
                data,
                builderPageTypes.lookups,
                () => callDataAccordingToPage(builderPageTypes.lookups)
              );
            },
            ...initialTabParamsDefault
          });
        }
      );
    } else {
      setFormErrors({
        ...formErrors,
        [BPT.lookups]: null
      });
    }
    return addDuplicateLookupRes.saveResults.did_save ?? false;
  }

  async function saveDataTable({
    tableName,
    description,
    group
  }: DataTableType) {
    const table = await DataDictionaryTableCollection.createNewEmptyTable(
      tableName,
      group
    );
    table.data.description = description;
    const ddSaveRes = await table.save();
    if (ddSaveRes.did_save) {
      setFormErrors({ ...formErrors, [BPT.dataDictionaries]: null });
      callDataAccordingToPage(pageType ?? '');
      dispatch(setSelectedNode(tableName));
      handlePageType(
        BPT.dataDictionaries,
        ({ fetchData, setRowSelectedFlag, updateData, openTab }) => {
          openDataDictionaryTab({
            rows: [],
            title: tableName,
            firstTab: false,
            fetchData,
            setRowSelectedFlag,
            updateData,
            openTab,
            lookupData: lookupsData,
            dataDictionaryData: ddData,
            updateMetaData: (data) => {
              updateDataEverywhere(
                dockLayoutRef.current ?? undefined,
                data.id,
                data,
                builderPageTypes.dataDictionaries,
                () => callDataAccordingToPage(builderPageTypes.dataDictionaries)
              );
            },
            ...initialTabParamsDefault
          });
        }
      );
    } else {
      setFormErrors({
        ...formErrors,
        [BPT.dataDictionaries]: ddSaveRes?.validation.results as FormErrorType[]
      });
    }
    return ddSaveRes.did_save ?? false;
  } // save data table ends

  async function saveDuplicateDataTable({
    tableName,
    projectId,
    group
  }: DuplicateDataTableType) {
    const duplicateDdSaveRes =
      await DataDictionaryTableCollection.duplicateDDTableAndFields(
        node2Duplicate ?? '',
        tableName,
        group,
        projectId
      );
    console.log('save dup dd resp: ', duplicateDdSaveRes);

    if (duplicateDdSaveRes) {
      // duplicateDdSaveRes?.DDTable && duplicateDdSaveRes.DDTable?.length
      const ddRows: any[] = [];
      if ('DDFields' in duplicateDdSaveRes) {
        duplicateDdSaveRes.DDFields.forEach((item: { data: any }) => {
          ddRows.push(item.data);
        });
      } // dd fields exist for this

      callDataAccordingToPage(pageType ?? '');
      dispatch(setSelectedNode(tableName));
      handlePageType(
        BPT.dataDictionaries,
        ({ fetchData, setRowSelectedFlag, updateData, openTab }) => {
          openDataDictionaryTab({
            // rows:
            //   'DDFields' in duplicateDdSaveRes
            //     ? duplicateDdSaveRes?.DDFields
            //     : [],
            rows: ddRows,
            title: tableName,
            firstTab: false,
            fetchData,
            setRowSelectedFlag,
            updateData,
            openTab,
            lookupData: lookupsData,
            dataDictionaryData: ddData,
            updateMetaData: (data) => {
              updateDataEverywhere(
                dockLayoutRef.current ?? undefined,
                data.id,
                data,
                builderPageTypes.dataDictionaries,
                () => callDataAccordingToPage(builderPageTypes.dataDictionaries)
              );
            },
            ...initialTabParamsDefault
          });
        }
      );
    }
  } // save duplicate data table ends

  const validTabs = [
    'error_tab',
    'groups_tab',
    'current_tab',
    'history_tab',
    'current_line_tab'
  ];

  async function saveModule({
    module_name,
    parent_id
  }: Partial<ModuleDocumentType>) {
    const addModuleRes = await addModule({ module_name, parent_id }, undefined);
    if (addModuleRes.saveResults.did_save) {
      setFormErrors({ ...formErrors, [BPT.modules]: null });
      callDataAccordingToPage(pageType ?? '');
      const data: Partial<ModuleDocumentType>[] = [];
      data.push({
        id: addModuleRes.module._data.id,
        module_name: addModuleRes.module._data.module_name,
        default_status: 'NewRequest',
        parent_id: addModuleRes.module._data.parent_id
      });
      dispatch(setSelectedNode(addModuleRes.module._data.module_name));
      handlePageType(
        BPT.modules,
        ({ fetchData, setRowSelectedFlag, updateData }) => {
          openModulesTab({
            rows: data,
            title: addModuleRes.module._data.module_name,
            firstTab: false,
            fetchData,
            setRowSelectedFlag,
            updateData,
            updateMetaData: (data) => {
              updateDataEverywhere(
                dockLayoutRef.current ?? undefined,
                data.id,
                data,
                builderPageTypes.modules,
                () => callDataAccordingToPage(builderPageTypes.modules)
              );
            },
            ...initialTabParamsDefault
          });
        }
      );
    } else {
      setFormErrors({
        ...formErrors,
        [BPT.modules]: addModuleRes.saveResults?.validation
          .results as FormErrorType[]
      });
    }
    console.log(addModuleRes.saveResults);
    return addModuleRes.saveResults.did_save ?? false;
  }

  async function saveModuleSection({
    module_id,
    section_name
  }: ISaveModuleSectionProps) {
    const addModuleSectionRes = await addModuleSection(
      {
        module_id,
        section_name
        // wg_editors: [''],
        // wg_monitor: [''],
        // show_task_notes: false,
        // page_name: section_name
      },
      undefined
    );
    if (addModuleSectionRes.saveResults.did_save) {
      setFormErrors({ ...formErrors, [BPT.moduleSections]: null });
      callDataAccordingToPage(pageType ?? '');
      dispatch(setSelectedNode(addModuleSectionRes.record._data.section_name));
      const data: Partial<ModuleSectionDocumentType>[] = [];
      data.push({
        id: addModuleSectionRes.record._data.id,
        module_id: addModuleSectionRes.record._data.module_id,
        section_name: addModuleSectionRes.record._data.section_name,
        wg_editors: addModuleSectionRes.record._data.wg_editors,
        wg_monitor: addModuleSectionRes.record._data.wg_monitor,
        show_task_notes: addModuleSectionRes.record._data.show_task_notes,
        page_name: addModuleSectionRes.record._data.page_name
      });
      handlePageType(
        BPT.moduleSections,
        ({ fetchData, setRowSelectedFlag, updateData }) => {
          openModuleSectionsTab({
            rows: data,
            title: addModuleSectionRes.record._data.section_name,
            firstTab: false,
            fetchData,
            setRowSelectedFlag,
            updateData,
            updateMetaData: (data) => {
              updateDataEverywhere(
                dockLayoutRef.current ?? undefined,
                data.id,
                data,
                builderPageTypes.moduleSections,
                () => callDataAccordingToPage(builderPageTypes.moduleSections)
              );
            },
            ...initialTabParamsDefault
          });
        }
      );
    } else {
      setFormErrors({
        ...formErrors,
        [BPT.moduleSections]: addModuleSectionRes.saveResults?.validation
          .results as FormErrorType[]
      });
    }
    console.log(addModuleSectionRes.saveResults);
    return addModuleSectionRes.saveResults.did_save ?? false;
  } // save module section ends

  async function saveWorkflowState(data: Partial<WorkflowStateDocumentType>) {
    const res = await WorkflowStateCollection.addWorkflowState(data);
    if (res.saveResults.did_save) {
      setFormErrors({ ...formErrors, [BPT.workflowStates]: null });
      callDataAccordingToPage(pageType ?? '');
      dispatch(setSelectedNode(res.record._data.name));
      handlePageType(
        BPT.workflowStates,
        ({ fetchData, setRowSelectedFlag, updateData }) => {
          openWorkflowStatesTab({
            rows: [res.record._data],
            title: res.record._data.name,
            firstTab: false,
            fetchData,
            setRowSelectedFlag,
            updateData,
            updateMetaData: (data) => {
              updateDataEverywhere(
                dockLayoutRef.current ?? undefined,
                data.id,
                data,
                builderPageTypes.workflowStates,
                () => callDataAccordingToPage(builderPageTypes.workflowStates)
              );
            },
            ...initialTabParamsDefault
          });
        }
      );
    } else {
      setFormErrors({
        ...formErrors,
        [BPT.workflowStates]: res.saveResults?.validation
          .results as FormErrorType[]
      });
    }
    console.log(res.saveResults);
    return res.saveResults.did_save ?? false;
  }

  async function saveTaskConfig(data: TaskConfigDocumentType) {
    const res = await TaskConfigCollection.addTaskConfig(data);
    if (res.saveResults.did_save) {
      setFormErrors({ ...formErrors, [BPT.taskConfigs]: null });
      callDataAccordingToPage(pageType ?? '');
      handlePageType(
        BPT.taskConfigs,
        ({ fetchData, setRowSelectedFlag, updateData }) => {
          openTaskConfigsTab({
            rows: [res.record._data],
            title: res.record._data.name,
            firstTab: false,
            fetchData,
            setRowSelectedFlag,
            updateData,
            updateMetaData: (data) => {
              updateDataEverywhere(
                dockLayoutRef.current ?? undefined,
                data.id,
                data,
                builderPageTypes.taskConfigs,
                () => callDataAccordingToPage(builderPageTypes.taskConfigs)
              );
            },
            ...initialTabParamsDefault
          });
        }
      );
    } else {
      setFormErrors({
        ...formErrors,
        [BPT.taskConfigs]: res.saveResults?.validation
          .results as FormErrorType[]
      });
    }
    console.log(res.saveResults);
    return res.saveResults.did_save ?? false;
  }

  function manipulateCurrentTabType() {
    // activeDataGridId: string
    const { activeId = '', tabs = [] } = dockLayoutRef.current?.find(
      'grid_dock'
    ) as {
      activeId: string;
      tabs: any[];
    };
    const foundTab = tabs?.find((gd: any) => gd.id === activeId);

    if (foundTab) {
      const { group = false } = foundTab;

      if (group) {
        setCurrentTabType(JSON.parse(group).type);
      } else {
        setCurrentTabType(undefined);
      }
    }
  }

  const handleLayoutChange = useCallback(
    async (layout?: any, currentTabId?: string) => {
      const _isFind = tabsRef.find(
        (item) => item.tabId + '_tab' === currentTabId
      );

      if (_isFind && _isFind.gridRef.current && _isFind.gridRef.current.api) {
        _isFind.gridRef.current.api.clearRangeSelection();
        _isFind.gridRef.current.api.deselectAll();
        setCurrentAgGridRef(_isFind.gridRef);
        setSelectedRows([]);
        setSelectedRowIndex(-1);
      }

      if (validTabs.includes(currentTabId ?? '')) {
        return;
      }

      const { activeId } = layout.dockbox.children[0];
      const activeTab = activeId.slice(0, -4) as string;
      dispatch(setSelectedNode(activeTab));
      let workflowModuleId = '';
      if (pageType === BPT.workflowStates) {
        workflowModuleId =
          tabsRef[0]?.gridRef?.current?.props?.rowData[0]?.module_id;
      }
      try {
        workflowModuleId
          ? await GridServices.setActiveTab(
              pageType as any,
              `${activeTab}::${workflowModuleId}`
            )
          : await GridServices.setActiveTab(pageType as any, activeTab);
      } catch (error) {
        console.log('An error occured!');
      }
      const activeTabData = dockLayoutRef.current?.find(activeId) as TabData;

      if (!dockLayoutRef.current) {
        return;
      }

      // for shortcut buttons based on open tabs
      manipulateCurrentTabType(); // activeDataGridId;

      // Update currentgrid details
      const type = activeTabData?.group
        ? JSON.parse(activeTabData.group).type
        : null;
      updateCurrentGridDetail({
        type,
        ref: dockLayoutRef.current,
        data: activeTabData
          ? extractGridMetaFromTab(dockLayoutRef.current, activeId)
          : null
      });

      // saving current tab for future references
      const _meta = extractGridMetaFromTab(dockLayoutRef.current, activeId);

      activeTabData?.group &&
        JSON.parse(activeTabData.group).type &&
        setCurrentTab({
          documentId: _meta.documentId,
          type: JSON.parse(activeTabData.group).type
        });

      // Set hash
      if (activeTabData && activeTabData.group) {
        const { project, type } = JSON.parse(activeTabData.group);
        GlobalHashHelper.setOption('project', project);
        GlobalHashHelper.setOption('type', type);
        GlobalHashHelper.setOption('tab', activeTab);
        localStorage.setItem('dwbuilder_hash', document.location.hash);
      } else {
        document.location.hash = '';
        localStorage.removeItem('dwbuilder_hash');
      }
    },
    [
      tabsRef,
      pageType,
      dispatch,
      dockLayoutRef,
      setCurrentAgGridRef,
      setSelectedRows,
      setSelectedRowIndex
    ]
  );

  async function handleValidateNColorize() {
    if (pageType === BPT.pages && currentAgGridRef?.current) {
      const grid_dock = dockLayoutRef.current?.find('grid_dock') as {
        activeId: string;
      };
      const currentTabData: Page = await pagesData.find(
        (page: Page) => page.data.route === grid_dock.activeId.split('_tab')[0]
      );

      const updatedRows = currentTabData.data.steps
        ? lintProcessor(currentTabData.data.steps)
        : [];
      currentAgGridRef.current.api.setRowData(updatedRows);
      currentAgGridRef.current.api.redrawRows();
    }
  }

  function handlePubStepsSave(data: any) {
    const cloneData = { ...data };
    return cloneData.steps.map((step: any, idx: number) => {
      const clonedStep = { ...step };
      clonedStep.rownum = idx + 1;
      return clonedStep;
    });
  }

  async function handlePublish() {
    if (pageType === BPT.pages && currentAgGridRef?.current) {
      const grid_dock = dockLayoutRef.current?.find('grid_dock') as {
        activeId: string;
      };
      const pages = await PageCollection.getAllPagesByRoute(
        grid_dock.activeId.split('_tab')[0]
      );
      const page = pages[0] as Page;
      page.data.pub_steps =
        page.data.steps && page.data.steps.length > 0
          ? handlePubStepsSave(page.data)
          : [];

      if (page.data.steps === undefined) {
        page.data.steps = [];
        page.data.pub_steps = [];
      }

      const res = await page.save();
      if (res.did_save) {
        callDataAccordingToPage(BPT.pages);
      }
    }
  }

  function handleCopy(rows: any[]) {
    setCopiedRows(rows);
    console.log('copy', rows);
  }

  const handlePaste = async () => {
    for (let index = 0; index < copiedRows.length; index++) {
      const element = copiedRows[index];
      const { activeId } = dockLayoutRef.current?.find('grid_dock') as {
        activeId: string;
      };
      const activeTab = activeId.slice(0, -4) as string;
      let result;
      switch (pageType) {
        case BPT.pages: {
          result = await addStep(
            activeTab,
            currentEventAPI,
            JSON.parse(JSON.stringify(element)),
            index
          );
          break;
        }
        case BPT.dataDictionaries:
        case BPT.lookups:
          if (!element.id) {
            return;
          }
          if (pageType === BPT.dataDictionaries) {
            result = await addDataDictionaryField(
              element,
              undefined,
              activeTab
            );
          } else if (pageType === BPT.lookups) {
            result = await addLookup(
              {
                key: element.key,
                codedValue: element.coded_value,
                text: element.text,
                export_value: element.export_value,
                group: element.group
              },
              undefined,
              undefined,
              activeTab
            );
          }
          break;

        default:
          break;
      }
      if (
        (pageType === BPT.lookups && result?.saveResults?.did_save) ||
        (pageType === BPT.dataDictionaries && result?.saveResults?.did_save) ||
        (pageType === BPT.pages && result?.did_save) ||
        (pageType === BPT.modules && result?.saveResults?.did_save) ||
        (pageType === BPT.moduleSections && result?.saveResults?.did_save)
      ) {
        console.log('paste page row: ', selectedRowIndex, index, element);
        insertDataRow(
          pageType ?? '',
          currentEventAPI,
          selectedRowIndex + index,
          element,
          selectedRows
        );
        const pageTypeStr =
          pageType.charAt(0).toUpperCase() + pageType.slice(1);
        toast.success(
          `${pageTypeStr
            .replace('_', ' ')
            .slice(0, -1)} was added successfully.`,
          {
            containerId: 'main-toast'
          }
        );
      } else if (
        result?.saveResults?.validation?.results &&
        result?.saveResults?.validation?.results.length > 0
      ) {
        const { results } = result?.saveResults?.validation;
        for (let i = 0; i < results.length; i++) {
          toast.error(results[i].error_message, { containerId: 'main-toast' });
          if (pageType === BPT.lookups && results[i].field_id === 'key') {
            insertDataRow(
              pageType ?? '',
              currentEventAPI,
              selectedRowIndex + index,
              {
                ...element,
                coded_value: 'Coded values are duplicated'
              },
              selectedRows
            );
          }
          if (
            pageType === BPT.dataDictionaries &&
            results[i].field_id === 'field' &&
            results.length === 1
          ) {
            insertDataRow(
              pageType ?? '',
              currentEventAPI,
              selectedRowIndex + index,
              {
                ...element,
                field: 'Fields are duplicated'
              },
              selectedRows
            );
          }
        }
      } else {
        toast.error('An error occured', { containerId: 'main-toast' });
      }
    }
    callDataAccordingToPage(pageType ?? '');
  };

  const handleInsert = () => {
    insertEmptyRow(
      pageType,
      undefined,
      undefined,
      currentEventAPI,
      selectedRowIndex,
      selectedRows
    );
  };

  const handleInsert5 = () => {
    for (let index = 0; index < 5; index++) {
      insertEmptyRow(
        pageType,
        undefined,
        undefined,
        currentEventAPI,
        selectedRowIndex + index,
        selectedRows
      );
    }
  };

  const handleKeyDown = async (event: any) => {
    if (event.ctrlKey && event.key === 'c') {
      const selection = window.getSelection();
      if (selection?.type === 'Caret') {
        if (selectedRows.length > 0) {
          handleCopy(selectedRows);
          await navigator.clipboard.writeText('');
        }
      } else if (selection?.type === 'Range') {
        handleCopy([]);
      }
    }
    if (event.ctrlKey && event.key === 'v') {
      if (copiedRows.length > 0 && selectedRows.length > 0) {
        handlePaste();
      }
    }
    if (event.ctrlKey && event.key === 'i') {
      if (selectedRows.length > 0) {
        handleInsert();
      }
    }
  };

  return (
    <div onKeyDown={handleKeyDown}>
      <GridShortcutButtons
        numCopiedLines={copiedRows.length}
        numSelectedLines={selectedRows.length}
        onCopyButtonClicked={() => handleCopy(selectedRows)}
        onPasteButtonClicked={() => handlePaste()}
        onInsertButtonClicked={() => handleInsert()}
        onInsert5ButtonClicked={() => handleInsert5()}
        onValidateNColorizeClicked={() => handleValidateNColorize()}
        onPublishClicked={() => handlePublish()}
        pageType={currentTabType}
        isCurrentGridRef={!!currentAgGridRef?.current}
        style={{ marginBottom: '10px', marginLeft: '7px' }}
      />

      <RCDockLayout
        dockLayoutRef={dockLayoutRef}
        groups={RcGroup}
        onLayoutChange={handleLayoutChange}
        defaultLayout={{
          dockbox: {
            mode: 'horizontal',
            id: 'master_dock',
            children: [
              {
                id: 'grid_dock',
                size: 75,
                group: 'close-all',
                /**
                 * @caution this will stop tabs from coming back to panel after floating
                 * @todo find a replacement
                 */
                panelLock: { panelStyle: 'main' },
                tabs: [
                  {
                    id: 'Loading',
                    title: 'Loading',
                    closable: true,
                    content: <LoadingScreen comment='Loading' />
                  }
                ]
              },
              { ...Browser }
            ]
          }
        }}
      />

      <AddModal show={showModal} toggleShow={() => toggleAddModal()}>
        {pages.current ? (
          <PageForm
            toggleShow={() => toggleAddModal()}
            handleSave={savePage}
            validationErrors={formErrors[BPT.pages] as FormErrorType[]}
          />
        ) : lookups.current ? (
          <LookupForm
            toggleShow={() => toggleAddModal()}
            handleSave={saveLookup}
            validationErrors={formErrors[BPT.lookups] as FormErrorType[]}
          />
        ) : dataDictionary.current ? (
          <DataDictionaryForm
            toggleShow={() => toggleAddModal()}
            handleSave={async (data) => await saveDataTable(data)}
            validationErrors={
              formErrors[BPT.dataDictionaries] as FormErrorType[]
            }
          />
        ) : modules.current ? (
          <ModuleForm
            toggleShow={() => toggleAddModal()}
            handleSave={async (data) => await saveModule(data)}
            validationErrors={formErrors[BPT.modules] as FormErrorType[]}
          />
        ) : moduleSections.current ? (
          <ModuleSectionForm
            toggleShow={() => toggleAddModal()}
            handleSave={async (data) => await saveModuleSection(data)}
            validationErrors={formErrors[BPT.moduleSections] as FormErrorType[]}
          />
        ) : workflowStates.current ? (
          <WorkflowStatesForm
            toggleShow={() => toggleAddModal()}
            handleSave={async (data) => await saveWorkflowState(data)}
            validationErrors={formErrors[BPT.workflowStates] as FormErrorType[]}
          />
        ) : taskConfigs.current ? (
          <TaskConfigForm
            toggleShow={() => toggleAddModal()}
            handleSave={async (data) => await saveTaskConfig(data)}
            validationErrors={formErrors[BPT.taskConfigs] as FormErrorType[]}
          />
        ) : (
          <div>undefined page route</div>
        )}
      </AddModal>

      <AddModal
        show={showDuplicateModal}
        toggleShow={() => {
          console.log(' ---- 111 toggle show for duplicate ----- ');
          toggleDuplicateModal();
        }}
      >
        {lookups.current ? (
          <DuplicateLookupForm
            toggleShow={() => {
              console.log(
                ' ---- 222 toggle show for duplicate --- lookup ----- '
              );
              toggleDuplicateModal();
            }}
            handleSave={saveDuplicateLookup}
          />
        ) : dataDictionary.current ? (
          <DuplicateDataDictionaryForm
            toggleShow={() => toggleDuplicateModal()}
            handleSave={async (data) => {
              await saveDuplicateDataTable(data);
            }}
          />
        ) : pages.current ? (
          <DuplicatePageForm
            toggleShow={() => {
              console.log('show duplicate page form');
              toggleDuplicateModal();
            }}
            handleSave={async (data) => {
              await saveDuplicatePage(data);
            }}
          />
        ) : (
          <div>undefined page route</div>
        )}
      </AddModal>

      <CustomTooltip
        show={tooltipToggle}
        target={currentTooltipTarget}
        placement='top'
        moveLeft='100%'
      >
        {currentTooltipText}
      </CustomTooltip>
    </div>
  );
}
export default MasterEditor;
