/* eslint-disable no-unused-vars */
/** Type definitions via Enumerations & Interfaces */
import {
  DataDictionaryFieldDocumentType,
  LogicEngineDataDateTime,
  LookupDocumentType,
  ModuleSectionDocumentType,
  PageDocumentType,
  ProjectDocumentDocumentType
} from '@digitalworkflow/dwtranslateclient';
import {
  RowSelectedEvent,
  CellValueChangedEvent,
  ColDef,
  RowDataTransaction,
  GridApi,
  RowNode,
  IRowNode
} from 'ag-grid-community';
import DockLayout from 'rc-dock';
import { builderPageTypes } from '../constants/builderPageTypes';
import { ITreeViewData } from '@digitalworkflow/dwtranslateclient/lib/Models/Module/ModuleCollection';

/** Enum for change operations */
enum ChangeOperations {
  UPDATE = 'UPDATE',
  INSERT = 'INSERT',
  DELETE = 'DELETE'
}

/** Enum for Document Modal (popup) Types */
enum DocumentModalTypes {
  PREVIEW,
  RENAME,
  REPLACE,
  HISTORY,
  DELETE,
  COPY,
  DOWNLOAD
}

/** Type */
type TNullUndefinedUnion = null | undefined;

/** Interface for Option Types */
interface OptionType {
  value: string;
  label: string;
  id: string;
}

/** Interface for Page Meta Interface */
interface IPageMeta {
  route: string;
  title_en: string;
  description: string;
  page_type: string;
  id: string;
}

/** Interface for DataDictionary Meta Data */
interface IDDMeta {
  table_name: string;
  description: string;
  id: string;
}

/** Lookup Meta Data */
interface ILookupMeta {
  key: string;
  id: string;
}

/** Module Meta Data */
interface IModuleMeta {
  name: string;
  id: string;
}

/** Module Section Meta Data */
interface IModuleSectionMeta {
  name: string;
  id: string;
}

/** Workflow State Meta Data */
interface IWorkflowStateMeta {
  name: string;
  full_name: string;
  code: string;
  description: string;
  id: string;
}

/** Task Config Meta Data */
interface ITaskConfigMeta {
  name: string;
  description: string;
  id: string;
}

/** Generic Object Type */
interface IGenericAnyObject {
  [key: string]: any;
}

/** Gneric String Object Type */
interface IGenericStringObject {
  [key: string]: string;
}

/** Props Type for Saving Lookup */
interface ISaveLookupProps {
  key: string;
  codedValue: string;
  text: string;
  export_value: string;
  group: string;
}

/** Props Type for Saving Duplicate Lookup */
interface ISaveDuplicateLookupProps {
  key: string;
  projectId: string;
  group: string;
}

/** Props type for saving Page Step */
interface ISavePageStepProps {
  step_type: string;
  step_option?: string;
  text_en?: string;
  field_id?: string;
  option_1?: string;
  option_2?: string;
  option_3?: string;
  view_groups?: string;
  execute_if?: string;
  style?: string;
  rownum?: number;
}

/** Props Type for Saving Duplicate Module Section */
interface ISaveModuleSectionProps {
  module_id: string;
  section_name: string;
  section_icon?: string;
  wg_canseesection?: string[];
  status_cansee?: string[];
  wg_editors?: string[];
  wg_monitor?: string[];
  show_task_notes?: boolean;
  page_name?: string;
}

/** Base Functions Type */
interface IBaseFunctions {
  fetchData: () => void;
  setSelectedRows: (rows: any[]) => void;
  setSelectedRowIndex: (id: number) => void;
  setRowSelectedFlag: (value: boolean) => void;
}

/** Grid References Type */
interface IGridReferences {
  tabId: string;
  gridApi: GridApi;
}

/** Initial Default Params Props Type fpr a Tab */
interface IInitialTabDefaultParams {
  ref: DockLayout | null;
  type: string;
  selectedRows: any[];
  detailData?: any[];
  gridReferences: React.MutableRefObject<IGridReferences[]>;
  setSelectedRows: (rows: any[]) => void;
  setSelectedRowIndex: (id: number) => void;
  setTabsRef: (value: any) => void;
  setCurrentEventAPI: (value: GridApi) => void;
}

/** Props Type for Dropdown Listings - Lookups & Data Dictionary */
interface DataforDropdownListings {
  lookupData?: any;
  dataDictionaryData?: any;
}

/** Extended Props Type for Tab Params */
interface ITabParams
  extends IBaseFunctions,
    IInitialTabDefaultParams,
    DataforDropdownListings {
  data?: any;
  groups?: ITreeViewData[];
  rows?: any[];
  title?: string;
  firstTab?: boolean;
  currentPage?: PageDocumentType;
  updateData: (id: string, data: any) => void;
  openTab?(tabId: string, type: string): void;
  updateMetaData?: (
    data:
      | IPageMeta
      | IDDMeta
      | ILookupMeta
      | IModuleMeta
      | IModuleSectionMeta
      | ITaskConfigMeta
      | IWorkflowStateMeta
  ) => void;
}

/** Props Type for Update of Open Editors of a type */
interface IUpdateOpenedEditorsOfATypeProps {
  type: builderPageTypes;
  changeEvent: IGenericAnyObject;
  gridReferences: React.MutableRefObject<IGridReferences[]>;
}

/** Props Type for collection changed event subscriber */
interface ICollectionChangedEventSubscriber {
  gridReferences: React.MutableRefObject<IGridReferences[]>;
  dictionariesFetcher: () => void;
  loopkupsFetcher: () => void;
  pagesFetcher: () => void;
  modulesFetcher: () => void;
  moduleSectionsFetcher: () => void;
  workflowStatesFetcher: () => void;
  taskConfigsFetcher: () => void;
}

/** Props Type for action menu item */
interface IActionMenuItem {
  title: string;
  icon?: string;
  class?: string;
  onClick?: (node: IRowNode) => void;
}

/** Props Type for Document */
interface IDocumentsProps {
  documents: Partial<ProjectDocumentDocumentType>[];
  actionMenuItems: IActionMenuItem[];
  getRef: (ref: any) => void;
}

/** Props Type for uploading document */
interface IUploadDocumentProps {
  showUpload?: boolean;
  onFileInput?: (file: File) => void;
  onDocumentUpload: (file: File) => void;
  getContainerHeight?: (value: number) => void;
}

/** Props Type for action menu */
interface IActionMenuProps {
  actionMenuItems: IActionMenuItem[];
  node: IRowNode;
}

/** Props Type for uploading document */
interface IRenameModalProps {
  documentCurrentName?: string;
  onSave?: (newName: string) => void;
  onCancel?: () => void;
}

/** Props Type for Delete Modal */
interface IDeleteModalProps {
  documentCurrentName?: string;
  onSave?: () => void;
  onCancel?: () => void;
}

/** Props Type for Replace Modal */
interface IReplaceModalProps {
  documentCurrentName?: string;
  onSave?: (file: File) => void;
  onCancel?: () => void;
}

/** Props Type for Grid Meta Data */
interface GridMetaData {
  createBy: string;
  createDt: LogicEngineDataDateTime;
  updateBy: string;
  updateDt: LogicEngineDataDateTime;
  documentId: string;
  ddTableName?: string;
  ddDescription?: string;
  pageTitle?: string;
  pageRoute?: string;
  pageType?: string;
  pageDescription?: string;
  lookupKey?: string;
  moduleName?: string;
  moduleSectionName?: string;
  workflowCode?: string;
  workflowShortName?: string;
  workflowFullName?: string;
  workflowDescription?: string;
  taskConfigName?: string;
  taskConfigDescription?: string;
}

/** Props Type for Grid Detail */
interface IGridDetailProps {
  data?: GridMetaData | null;
  type?: builderPageTypes | null;
}

/** Props Type for Common Fields */
interface ICommonFieldsProps {
  createBy?: string;
  createDt?: LogicEngineDataDateTime;
  updatedBy?: string;
  updatedDt?: LogicEngineDataDateTime;
}

/** Props Type for current tab */
interface ICurrentTab {
  activeId: string;
  status: boolean;
}

/** Props Type for update of current grid detail */
interface IUpdateCurrentGridDetailProps {
  type: builderPageTypes | null;
  ref: DockLayout;
  data: GridMetaData | null;
}

/** Props Type for update of grid meta */
interface IDdGridUpdateData {
  table_name?: string;
  description?: string;
  group?: string;
}

/** Props Type for update of lookup grid data */
interface ILookupGridUpdateData {
  key?: string;
  group?: string;
}

/** Props Type for update of page grid data */
interface IPageGridUpdateData {
  route?: string;
  title_en?: string;
  description?: string;
  group?: string;
}

/** Props Type for update of module grid data */
interface IModuleGridUpdateData {
  name?: string;
}

/** Props Type for update of module section grid data */
interface IModuleSectionGridUpdateData {
  name?: string;
}

/** Props Type for update of workflow grid data */
interface IWorkflowStateGridUpdateData {
  name?: string;
  full_name?: string;
  code?: string;
  description?: string;
}

/** Props Type for update of task config grid data */
interface ITaskConfigGridUpdateData {
  name?: string;
  description?: string;
}

/** export types */
export type {
  LookupDocumentType,
  DataDictionaryFieldDocumentType,
  RowSelectedEvent,
  GridApi,
  RowNode,
  CellValueChangedEvent,
  ColDef,
  RowDataTransaction,
  DockLayout,
  TNullUndefinedUnion,
  IGenericAnyObject,
  ISaveLookupProps,
  IGenericStringObject,
  ITabParams,
  IInitialTabDefaultParams,
  ModuleSectionDocumentType,
  ISavePageStepProps,
  ISaveModuleSectionProps,
  IUpdateOpenedEditorsOfATypeProps,
  IGridReferences,
  ICollectionChangedEventSubscriber,
  IDocumentsProps,
  IUploadDocumentProps,
  IActionMenuItem,
  IActionMenuProps,
  IRenameModalProps,
  IDeleteModalProps,
  IReplaceModalProps,
  IGridDetailProps,
  ICommonFieldsProps,
  ICurrentTab,
  ISaveDuplicateLookupProps,
  GridMetaData,
  IUpdateCurrentGridDetailProps,
  IDdGridUpdateData,
  ILookupGridUpdateData,
  IPageGridUpdateData,
  IModuleGridUpdateData,
  IModuleSectionGridUpdateData,
  IWorkflowStateGridUpdateData,
  ITaskConfigGridUpdateData,
  IPageMeta,
  IDDMeta,
  ILookupMeta,
  IModuleMeta,
  IModuleSectionMeta,
  ITaskConfigMeta,
  IWorkflowStateMeta,
  OptionType
};

/** export enumerations */
export { ChangeOperations, DocumentModalTypes };
