import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  WorkflowState,
  WorkflowStateCollection
} from '@digitalworkflow/dwtranslateclient';
import { AppConfig } from '../config/constants/AppConfig';
import { ITreeViewData } from '@digitalworkflow/dwtranslateclient/lib/Models/Module/ModuleCollection';

export interface WorkflowStateInitialState {
  groups: ITreeViewData[];
  workflowStates: WorkflowState[];
  selectedRows: any;
  loading: boolean;
  error: string | null;
}

const initialState: WorkflowStateInitialState = {
  groups: [],
  workflowStates: [],
  selectedRows: [],
  loading: false,
  error: null
};

export const fetchWorkflowStateGroups = createAsyncThunk<
  ITreeViewData[],
  void,
  { rejectValue: string }
>('workflowState/fetchWorkflowStateGroups', async (_, thunkAPI) => {
  try {
    const groups: ITreeViewData[] =
      await WorkflowStateCollection.getTreeViewWorkflowState();

    if (AppConfig.evReduxLogs) {
      console.log(
        '%credux::groups::workflow-state',
        'font-size:20px;color:brown'
      );
      console.log(groups);
    }

    return groups;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch workflowState data.');
  }
});

export const fetchWorkflowStates = createAsyncThunk<
  any,
  void,
  { rejectValue: string }
>('workflowState/fetchWorkflowStates', async (_, thunkAPI) => {
  try {
    const data = await WorkflowStateCollection.findAllWorkflowStates();

    if (AppConfig.evReduxLogs) {
      console.log(
        '%credux::documents::workflow-state',
        'font-size:20px;color:brown'
      );
      console.log(data);
    }

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to fetch workflowState data.');
  }
});

export const workflowStateSlice = createSlice({
  name: 'workflowState',
  initialState,
  reducers: {
    updateWorkflowStateGroups: (state, action) => {
      return {
        ...state,
        groups: action.payload
      };
    },
    updateWorkflowStateSelectedRow: (state, action) => {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchWorkflowStateGroups.pending || fetchWorkflowStates.pending,
        (state) => ({
          ...state,
          loading: true,
          error: null
        })
      )
      .addCase(fetchWorkflowStateGroups.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        groups: action.payload
      }))
      .addCase(fetchWorkflowStates.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        workflowStates: action.payload
      }))
      .addCase(
        fetchWorkflowStateGroups.rejected || fetchWorkflowStates.rejected,
        (state, action) => ({
          ...state,
          loading: false,
          error: action.error.message || 'Something went wrong'
        })
      );
  }
});

export const { updateWorkflowStateGroups, updateWorkflowStateSelectedRow } =
  workflowStateSlice.actions;
export default workflowStateSlice.reducer;
