import React, { useState } from 'react';
import {
  Button,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'react-bootstrap';
import { IReplaceModalProps } from '../../../config/types';
import UploadDocument from '../uploadDocument';

/**
 * Replace Modal component
 * @param {object} props for replace modal component
 * @return {jsx} component
 */
export default function ReplaceModal({
  documentCurrentName,
  onSave,
  onCancel
}: IReplaceModalProps) {
  const [file, setFile] = useState<File>();
  const [fileError, setfileError] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  function clearErrors() {
    setfileError('');
  }
  /**
   * Validates form
   * @return {boolean} true or false
   */
  function validate(file: File): boolean {
    clearErrors();
    if (file.name === '') {
      setfileError('File can not be empty');
      return false;
    }
    return true;
  }

  /**
   * Handles file input
   * @param {File} file
   */
  function handleInput(file: File) {
    setFile(file);
  }

  /**
   * Handles submit event
   * @param {File} file to upload
   */
  async function handleSubmit(localFile?: File) {
    const isValidated = validate(localFile ?? (file as File));
    setIsUploading(true);
    isValidated && onSave && (await onSave(localFile ?? (file as File)));
    setIsUploading(false);
  }

  return (
    <Form>
      <ModalHeader>Replace Document</ModalHeader>
      <ModalBody>
        <span style={{ wordBreak: 'break-word' }}>
          Are you sure you want to replace document for{' '}
          <strong>{documentCurrentName}</strong> ?
        </span>
        <UploadDocument
          showUpload={false}
          onDocumentUpload={(file: File) => handleSubmit(file)}
          onFileInput={(file: File) => handleInput(file)}
        />
        {!!fileError.length && <p className='inline-error'>{fileError}</p>}
      </ModalBody>
      <ModalFooter>
        <Button className='btn btn-sm btn-danger' onClick={() => onCancel?.()}>
          Cancel
        </Button>

        <Button
          className='btn btn-sm'
          disabled={isUploading}
          onClick={async () => await handleSubmit()}
        >
          Save
        </Button>
      </ModalFooter>
    </Form>
  );
}
