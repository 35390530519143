import DockLayout from 'rc-dock';
import { GridMetaData, IWorkflowStateGridUpdateData } from '../../config/types';
import {
  LogicEngineDataDateTime,
  WorkflowState,
  WorkflowStateCollection,
  ValidationResults
} from '@digitalworkflow/dwtranslateclient';
import { generateTab } from '../generateTab';
import { builderPageTypes } from '../../config/constants/builderPageTypes';
import { removeUndefinedKeyFromObject } from '../removeUndefinedKeyFromObject';

export async function handleUpdateWorkflowState(
  ref: DockLayout | null | undefined,
  id: string,
  data: IWorkflowStateGridUpdateData,
  reduxUpdater: () => void
) {
  let saveResults: ValidationResults = {} as ValidationResults;

  // update document in db
  const { oldWorkflowState, isUpdated, newWorkflowState } =
    await _updateWorkflowStateInDB(id, data);

  saveResults =
    (isUpdated && isUpdated.saveResults.validation) ||
    ({} as ValidationResults);

  if (isUpdated && isUpdated.saveResults.did_save) {
    // if document is updated in db, update redux
    reduxUpdater();

    // if ref provided, find and update currently opened editor tab and grid
    if (ref) {
      // find tab
      const tab: any = ref.find(oldWorkflowState?.data.name + '_tab');
      if (tab) {
        // update grid meta, data(if applicable), and tab title(if applicable)
        const oldTabProps = (tab as any).content.props.children.props.children;
        newWorkflowState &&
          _updateWorkflowStateTabAndGrid(
            newWorkflowState,
            oldTabProps,
            ref,
            saveResults
          );
      }
    }
  }

  return saveResults;
}

async function _updateWorkflowStateInDB(
  id: string,
  data: IWorkflowStateGridUpdateData
) {
  const oldWorkflowState = await WorkflowStateCollection.getWorkflowStateById(
    id
  );

  const isUpdated = await WorkflowStateCollection.updateWorkflowState(
    id,
    removeUndefinedKeyFromObject({
      name: data?.name,
      code: data?.code,
      full_name: data?.full_name,
      description: data?.description
    })
  );

  const newWorkflowState = await WorkflowStateCollection.getWorkflowStateById(
    id
  );

  return { oldWorkflowState, isUpdated, newWorkflowState };
}

async function _updateWorkflowStateTabAndGrid(
  newWorkflowState: WorkflowState,
  tabProps: any,
  ref: DockLayout,
  saveResults: ValidationResults
) {
  const newGridMeta: GridMetaData = {
    createBy: newWorkflowState?.data.create_by ?? '',
    updateBy: newWorkflowState?.data.updated_by ?? '',
    createDt:
      newWorkflowState?.data.create_dt ?? ({} as LogicEngineDataDateTime),
    updateDt:
      newWorkflowState?.data.updated_dt ?? ({} as LogicEngineDataDateTime),
    workflowDescription: newWorkflowState?.data.description,
    workflowCode: newWorkflowState?.data.code,
    workflowFullName: newWorkflowState?.data.full_name,
    workflowShortName: newWorkflowState?.data.name,
    documentId: newWorkflowState?.data.id ?? ''
  };

  const tabUpdated = ref.updateTab(
    tabProps[1].props.children.props.id + '_tab',
    generateTab({
      ...tabProps[1].props.children.props,
      gridMetaData: newGridMeta,
      id: newWorkflowState?.data.name,
      title: newWorkflowState?.data.name ?? '',
      data: [newWorkflowState.data],
      group: builderPageTypes.workflowStates,
      metaGridCols: tabProps[0].props.children.props.columnDefs,
      metaGridDefaultCol: tabProps[0].props.children.props.defaultColDef,
      onMetaCellValueChanged:
        tabProps[0].props.children.props.onCellValueChanged
    })
  );

  if (!tabUpdated) {
    saveResults.addError('updateTab', 'failed to update opened tab');
  }
}
