import { createSlice } from '@reduxjs/toolkit';

export interface IEventInitialState {
  type: 'showModalEvent' | 'hideModalEvent' | 'showDuplicateModalEvent' | null;
  node2Duplicate?: null;
}

const initialState: IEventInitialState = {
  type: null,
  node2Duplicate: null
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    triggerEvent: (_, action) => {
      return {
        type: action.payload
      };
    },
    setNode2Duplicate: (_, action) => {
      return {
        type: _.type,
        node2Duplicate: action.payload
      };
    }
  }
});

export const { triggerEvent, setNode2Duplicate } = eventSlice.actions;
export default eventSlice.reducer;
