import React, { useState } from 'react';
import cls from './GridShortcutButtons.module.scss';
import { Button } from 'reactstrap';
import { builderPageTypes as BPT } from '../../config/constants/builderPageTypes';

interface GridShortcutButtonsProps {
  onInsertButtonClicked: () => void;
  onInsert5ButtonClicked: () => void;
  onCopyButtonClicked: () => void;
  onPasteButtonClicked: () => void;
  onValidateNColorizeClicked: () => void;
  onPublishClicked: () => void;
  numSelectedLines: number;
  numCopiedLines: number;
  pageType: string | undefined;
  style?: React.CSSProperties;
  isCurrentGridRef?: boolean;
}

const GridShortcutButtons: React.FC<GridShortcutButtonsProps> = ({
  onInsertButtonClicked,
  onInsert5ButtonClicked,
  onCopyButtonClicked,
  onPasteButtonClicked,
  onValidateNColorizeClicked,
  onPublishClicked,
  numCopiedLines = 0,
  numSelectedLines = 0,
  pageType,
  style,
  isCurrentGridRef = false
}) => {
  const [isColorValidate, setIsColorValidate] = useState(false);

  function areButtonsDisabled() {
    return [
      BPT.moduleSections,
      BPT.taskConfigs,
      BPT.workflowStates,
      BPT.modules
    ].includes(pageType as BPT);
  }

  return (
    <div className={cls.wrapper} style={style}>
      <Button
        onClick={onInsertButtonClicked}
        className='btn btn-sm ignore-outside-click'
        color='primary'
        disabled={numSelectedLines === 0 || areButtonsDisabled()}
      >
        Insert Line Below
      </Button>
      <Button
        onClick={onInsert5ButtonClicked}
        className='btn btn-sm ignore-outside-click'
        color='primary'
        disabled={numSelectedLines === 0 || areButtonsDisabled()}
      >
        Insert 5 Lines Below
      </Button>
      <Button
        className='btn btn-sm ignore-outside-click'
        color='primary'
        onClick={onCopyButtonClicked}
        disabled={numSelectedLines === 0 || areButtonsDisabled()}
      >
        {numSelectedLines <= 1
          ? 'Copy selected line'
          : `Copy selected ${numSelectedLines} lines`}
      </Button>
      <Button
        className='btn btn-sm ignore-outside-click'
        color='primary'
        onClick={onPasteButtonClicked}
        disabled={
          numCopiedLines === 0 || numSelectedLines === 0 || areButtonsDisabled()
        }
      >
        {numCopiedLines <= 1 ? 'Paste line' : `Paste ${numCopiedLines} lines`}
      </Button>
      <Button
        className='btn btn-sm ignore-outside-click'
        color='primary'
        disabled={pageType !== BPT.pages}
        onClick={() => {
          setIsColorValidate(true);
          onValidateNColorizeClicked();
        }}
      >
        Validate and Colorize
      </Button>
      <Button
        className='btn btn-sm ignore-outside-click'
        color='primary'
        disabled={
          pageType !== BPT.pages || !isColorValidate || !isCurrentGridRef
        }
        onClick={onPublishClicked}
      >
        Publish
      </Button>
    </div>
  );
};
export default GridShortcutButtons;
